import type { OptionsProps } from '@headrace/ui';
import { Button, Card } from '@headrace/ui';
import type { Range } from '@headrace/ui/src/forms/fields/SliderInput';
import classNames from 'classnames';
import type { Dispatch, SetStateAction } from 'react';

import useRoleEnrichmentOptions from '@/lib/hooks/useRoleEnrichmentOptions';

import HideableFiltersBar from './HideableFiltersBar';
import marketplaceFiltersInitialValue from './InitialValue';
import useFilterFields from './useFilterFields';

export interface MarketplaceFilters {
  roleType: { Public: boolean; Invite: boolean };
  function: OptionsProps[];
  companyStage: {
    'Early stage': boolean;
    'Growth stage': boolean;
    'Late stage': boolean;
  };
  location: OptionsProps[];
  roleLevel: Record<string, boolean>;
  salaryRange: Range;
  placementFee: Range;
}

export interface ActiveFilters {
  roleTypeClear: boolean;
  companyStageClear: boolean;
  functionClear: boolean;
  locationClear: boolean;
  roleLevelClear: boolean;
  salaryRangeClear: boolean;
  placementFeeClear: boolean;
}

interface MarketplaceFiltersBarProps {
  className?: string;
  filters: MarketplaceFilters;
  setFilters: Dispatch<SetStateAction<MarketplaceFilters>>;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const MarketplaceFiltersBar: React.VFC<MarketplaceFiltersBarProps> = (
  props
) => {
  const { className, filters, setFilters, openModal, setOpenModal } = props;
  const { options } = useRoleEnrichmentOptions();
  const filterFields = useFilterFields({ filters, setFilters, options });

  return (
    <>
      <div className={classNames('', className)}>
        <div className="flex justify-between items-center mb-[38px]">
          <p className="text-lg font-bold text-headraceBlack-800">Filter</p>
          <Button
            className="text-base font-light !text-headraceBlack-800 !m-0"
            buttonType="link"
            onClick={(): void => {
              setFilters(marketplaceFiltersInitialValue);
            }}
          >
            Clear
          </Button>
        </div>
        <div className="flex flex-col gap-6">
          <Card className="p-6">
            <p className="text-base font-bold text-headraceBlack-800 mb-4">
              Function
            </p>
            {filterFields.functionFilters}
          </Card>
          <Card className="p-6">
            <p className="text-base font-bold text-headraceBlack-800 mb-4">
              Company stage
            </p>
            {filterFields.companyStageFilters}
          </Card>
          <Card className="p-6">
            <p className="text-base font-bold text-headraceBlack-800 mb-4">
              Office location
            </p>
            {filterFields.locationFilters}
          </Card>
          <Card className="p-6">
            <p className="text-base font-bold text-headraceBlack-800 mb-4">
              Role level
            </p>
            {filterFields.roleLevelFilters}
          </Card>
          <Card className="p-6">
            <p className="text-base font-bold text-headraceBlack-800 mb-4">
              Salary range
            </p>
            {filterFields.salaryRangeFilters}
          </Card>
          <Card className="p-6">
            <p className="text-base font-bold text-headraceBlack-800 mb-4">
              Placement fee
            </p>
            {filterFields.placementFeeFilters}
          </Card>
        </div>
      </div>
      <HideableFiltersBar
        setFilters={setFilters}
        open={openModal}
        setOpen={setOpenModal}
        filterFields={filterFields}
      />
    </>
  );
};

export default MarketplaceFiltersBar;
