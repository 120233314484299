import type { PlacementGeographiesGraphData } from '@headrace/types';

import ErrorMessageBox from '../../ErrorMessageBox';
import LoadingSpinner from '../../LoadingSpinner';
import PlacementGeographiesGraph from '../../PlacementGeographiesGraph';
import TopCities from './TopCities';

interface Props {
  placementsData: PlacementGeographiesGraphData[];
  loading?: boolean;
  error?: string | null;
}

const PlacementGeographies: React.VFC<Props> = ({
  placementsData,
  loading,
  error,
}) => {
  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessageBox error={error} />;

  return (
    <>
      <div className="font-bold text-2xl">Placements by location</div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:pt-20 pt-6 lg:px-10">
        <div className="lg:col-span-1 col-span-1 lg:px-10 px-3">
          <TopCities cities={placementsData.slice(0, 5)} />
        </div>
        <div className="lg:col-span-2 col-span-1">
          <PlacementGeographiesGraph
            placementsData={placementsData}
            id="recruiter-placement-geographies2"
            svgClassName="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default PlacementGeographies;
