import 'yup-phone-lite';

import { LINKED_IN_URL } from '@headrace/constants';
import * as Yup from 'yup';

const SettingsGeneralFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .phone('US', 'Invalid phone number')
    .required('Phone number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  bio: Yup.string()
    .required('Bio is required')
    .min(300, 'Bio must be at least 300 characters'),
  linkedin: Yup.string()
    .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
    .required('LinkedIn URL is required'),
  location: Yup.string().required(),
});

export default SettingsGeneralFormSchema;
