import type { VFC } from 'react';
import React, { useMemo } from 'react';

import ErrorMessageBox from './ErrorMessageBox';
import LoadingSpinner from './LoadingSpinner';

interface Props {
  title: string;
  list: { name: string; number: number }[];
  currencyFormat?: boolean;
  emptyState?: React.ReactNode;
  loading?: boolean;
  error?: string | null;
}

const SimpleRankingList: VFC<Props> = ({
  title,
  list,
  emptyState = null,
  loading,
  error,
}) => {
  const getContent = useMemo(() => {
    if (loading) {
      return (
        <div className="flex flex-col justify-center items-center h-full">
          <LoadingSpinner className="p-4" />
        </div>
      );
    }
    if (error) {
      return <ErrorMessageBox error={error} />;
    }
    if (list.length > 0) {
      return (
        <ol className="relative border-l border-gray-200 w-5/6 flex flex-col gap-8">
          {list.map(({ name, number }, index) => (
            <li className="relative pl-7" key={name}>
              <div className="absolute w-8 h-8 rounded-full flex items-center justify-center -left-4 border-2 border-headraceYellow-700 bg-white font-bold">
                {index + 1}
              </div>
              <div className="flex justify-between items-center">
                <div className="truncate font-normal  -mb-1 text-xl sm:w-[70%] w-4/5">
                  {name}
                </div>
                <div className=" flex items-center justify-end gap-3">
                  <div className="text-xl font-light flex justify-end sm:justify-center w-ful rounded-full bg-headraceYellow-700 px-3">
                    {number}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      );
    }
    return <div className="p-12">{emptyState}</div>;
  }, [emptyState, error, list, loading]);
  return (
    <div className="flex flex-col gap-10 text-headraceBlack-800 items-center">
      <div className="font-bold text-center text-2xl px-10">{title}</div>
      {getContent}
    </div>
  );
};

export default SimpleRankingList;
