import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}

const StepCircleCheck: VFC<Props> = ({ className }) => {
  const iconClassName = classNames(
    'inline-block rounded-full overflow-hidden bg-gray-100',
    className
  );
  return (
    <span className={iconClassName}>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="32" height="32" rx="16" fill="#FFA300" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7071 11.7929C23.0976 12.1834 23.0976 12.8166 22.7071 13.2071L14.7071 21.2071C14.3166 21.5976 13.6834 21.5976 13.2929 21.2071L9.29289 17.2071C8.90237 16.8166 8.90237 16.1834 9.29289 15.7929C9.68342 15.4024 10.3166 15.4024 10.7071 15.7929L14 19.0858L21.2929 11.7929C21.6834 11.4024 22.3166 11.4024 22.7071 11.7929Z"
          fill="#111827"
        />
      </svg>
    </span>
  );
};

export default StepCircleCheck;
