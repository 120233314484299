import { Dialog, Transition } from '@headlessui/react';
import { Button } from '@headrace/ui';
import { XIcon } from '@heroicons/react/outline';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment } from 'react';

import type { MarketplaceFilters } from '.';
import marketplaceFiltersInitialValue from './InitialValue';
import type { FilterFieldsReturnType } from './useFilterFields';

interface HideableFiltersBarProps {
  setFilters: Dispatch<SetStateAction<MarketplaceFilters>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  filterFields: FilterFieldsReturnType;
}

const HideableFiltersBar: React.VFC<HideableFiltersBarProps> = (props) => {
  const { setFilters, open, setOpen, filterFields } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 lg:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in duration-300 transform"
          leave="transition ease-in duration-300 transform"
          leaveFrom="-translate-x-full"
          leaveTo="-translate-x-0"
        >
          <div className="absolute flex flex-col w-[320px] h-screen right-0 py-6 bg-white overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex flex-col justify-between h-full">
                <div className="px-4 flex flex-col gap-8 mb-4">
                  <div className="flex justify-between items-center">
                    <div className="text-gray-900 text-lg font-medium">
                      Filters
                    </div>
                    <div className="flex items-center gap-2 text-headraceBlack-700 font-light">
                      <button
                        type="button"
                        className="text-base"
                        onClick={(): void => {
                          setFilters(marketplaceFiltersInitialValue);
                        }}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                        onClick={(): void => {
                          setOpen(false);
                        }}
                      >
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 divide-y">
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-bold text-headraceBlack-800">
                        Function
                      </div>
                      <div className="flex relative rounded-md shadow-sm">
                        {filterFields.functionFilters}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-bold text-headraceBlack-800">
                        Company stage
                      </div>
                      <div className="relative rounded-md shadow-sm">
                        {filterFields.companyStageFilters}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-bold text-headraceBlack-800">
                        Office location
                      </div>
                      <div className="relative rounded-md shadow-sm">
                        {filterFields.locationFilters}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-bold text-headraceBlack-800">
                        Role level
                      </div>
                      <div className="relative rounded-md shadow-sm">
                        {filterFields.roleLevelFilters}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-bold text-headraceBlack-800">
                        Salary range
                      </div>
                      <div className="relative rounded-md shadow-sm">
                        {filterFields.salaryRangeFilters}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-bold text-headraceBlack-800">
                        Placement fee
                      </div>
                      <div className="relative rounded-md shadow-sm pb-8">
                        {filterFields.placementFeeFilters}
                      </div>
                    </div>
                    <Button
                      className="!mb-4"
                      buttonType="primary"
                      onClick={(): void => {
                        setOpen(false);
                      }}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Transition.Child>
        <div>
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default HideableFiltersBar;
