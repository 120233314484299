import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';

import Button from '../Button';
import SimpleModal from '../SimpleModal';
import Dots from './Dots';

interface Props {
  steps: React.ReactNode[];
  dots?: boolean;
  open: boolean;
  onClose: () => void;
  modalClassName?: string;
  customCloseText?: string;
}

const ModalStepper: VFC<Props> = ({
  steps,
  dots = true,
  open,
  onClose,
  modalClassName,
  customCloseText,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideRef = useRef<HTMLDivElement>(null);

  const removeAnimation = (): void => {
    slideRef?.current?.classList.remove('fade-anim');
  };

  const handleOnNextClick = (): void => {
    setCurrentIndex((prev) => prev + 1);
    slideRef.current?.classList.add('fade-anim');
  };
  const handleOnPrevClick = (): void => {
    const productsLength = steps.length;
    setCurrentIndex((prev) => (prev === 0 ? productsLength - 1 : prev - 1));
    slideRef.current?.classList.add('fade-anim');
  };

  const handleOnSelect = (index: number): void => {
    setCurrentIndex(index);
    slideRef.current?.classList.add('fade-anim');
  };

  const handleOnClose = (): void => {
    onClose();
  };

  useEffect(() => {
    slideRef.current?.addEventListener('animationend', removeAnimation);
    // eslint-disable-next-line
  }, []);

  return (
    <SimpleModal
      open={open}
      onClose={handleOnClose}
      className={classNames(modalClassName)}
    >
      <div ref={slideRef} className="w-full select-none relative h-full">
        <div className="aspect-w-16 aspect-h-9 h-full">
          {steps[currentIndex]}
        </div>
        {dots && (
          <div className="mb-4">
            <Dots
              total={steps.length}
              currentIndex={currentIndex}
              handleOnSelect={handleOnSelect}
            />
          </div>
        )}
        <div className="w-full flex justify-between gap-4">
          {currentIndex > 0 && (
            <Button
              onClick={handleOnPrevClick}
              buttonType="secondary"
              className="sm:w-full w-full"
            >
              Back
            </Button>
          )}
          {currentIndex < steps.length - 1 ? (
            <Button onClick={handleOnNextClick} className="sm:w-full w-full">
              Next
            </Button>
          ) : (
            <Button onClick={handleOnClose} className="sm:w-full w-full">
              {customCloseText || 'Close'}
            </Button>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export default ModalStepper;
