import * as Yup from 'yup';

const EmployerInvitationFormSchema = Yup.object().shape({
  employeeFirstName: Yup.string().required('Employee first name is required'),
  employeeLastName: Yup.string().required('Employee last name is required'),
  employeeEmail: Yup.string()
    .email('Invalid email')
    .required('Employer admin email is required'),
  employerName: Yup.string().required('Employer name is required'),
});

export default EmployerInvitationFormSchema;
