import classNames from 'classnames';
import type { FC } from 'react';

interface Props {
  className?: string;
  contentClassName?: string;
}

const WavyCard: FC<Props> = ({ className, children, contentClassName }) => {
  const boxClassName = classNames(
    className,
    'relative shadow overflow-hidden sm:rounded-lg h-full w-full h-full'
  );
  return (
    <div className={boxClassName}>
      <div className="absolute top-0 left-0 w-full h-full">
        <svg
          width="349"
          height="250"
          viewBox="0 0 349 250"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M203.137 83.8423C169.997 26.4914 106.478 2.05123 78.8616 -3H-60V289H349V257C314.187 250.395 236.277 141.193 203.137 83.8423Z" />
        </svg>
      </div>
      <div className={classNames('relative h-full', contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default WavyCard;
