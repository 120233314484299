import type { VFC } from 'react';

interface Props {
  percentage: number;
}

const ProgressBar: VFC<Props> = ({ percentage }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-100">
    <div
      className="bg-headraceYellow-700 h-2.5 rounded-full"
      style={{ width: `${percentage}%` }}
    />
  </div>
);

export default ProgressBar;
