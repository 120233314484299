import { LINKED_IN_URL } from '@headrace/constants';
import { endOfDay } from 'date-fns';
import * as Yup from 'yup';

const today = endOfDay(new Date());

const RecruiterProfileFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  linkedIn: Yup.string()
    .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
    .required('LinkedIn URL is required'),
  timeToPlacement: Yup.number()
    .typeError('Time to placement must be a number')
    .required('Time to placement is required')
    .moreThan(0, 'Time to placement must be greater than 0')
    .max(52, 'Time to placement must be less than or equal to 52'),
  roleFunctions: Yup.array()
    .required('Role functions is required')
    .max(3, 'You can only select 3 role functions'),
  bio: Yup.string()
    .required('Bio is required')
    .min(300, 'Bio must be at least 300 characters'),
  cities: Yup.array().required('Role functions is required'),
  placements: Yup.array().of(
    Yup.object().shape({
      companyId: Yup.string().required('Employer is required'),
      roleTitleId: Yup.string().required('Role is required'),
      roleSeniorityId: Yup.string().required('Level is required'),
      roleFunctionId: Yup.string().required('Function is required'),
      companyStageId: Yup.string().required(
        'Company stage at hire is required'
      ),
      cityId: Yup.string().required('Location is required'),
      placedAt: Yup.date()
        .typeError('Candidate start date must be a date')
        .max(today, 'Candidate start date must be in the past')
        .required('Candidate start date is required'),
      linkedIn: Yup.string()
        .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
        .required('LinkedIn URL is required'),
    })
  ),
  endorsements: Yup.array().of(
    Yup.object().shape({
      endorserTitle: Yup.string().required('Endorser title is required'),
      endorserCompany: Yup.string().required('Endorser company is required'),
      endorsement: Yup.string()
        .max(700, 'Endorsement must be less than 700 characters')
        .required('Endorsement is required'),
    })
  ),
});

export default RecruiterProfileFormSchema;
