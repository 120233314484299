// eslint-disable-next-line no-restricted-imports
import '../styles/globals.css';

import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { Notifications } from '@headrace/ui';
import { getScreenProperties } from '@headrace/utils';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';

import DefaultLayout from '@/components/DefaultLayout';
import PublicLayout from '@/components/PublicLayout';
import { PublicPaths } from '@/constants/PublicPaths';
import { ampli } from '@/lib/ampli';
import initAmplitude from '@/lib/amplitude';
import type { AppPropsWithLayout } from '@/types';

const onRedirectCallback = async (appState: AppState): Promise<void> => {
  await Router.replace(appState?.returnTo || '/');
};

const WrappedLayout: React.FC<AppPropsWithLayout> = ({
  Component,
  pageProps,
}) => {
  const Layout = Component.Layout ?? DefaultLayout;
  const { pathname, query } = useRouter();
  const handleRouteChange = (urlPath: string): void => {
    const { viewport, screenResolution } = getScreenProperties();
    ampli.appPageViewed(
      {
        'url path': urlPath,
        app: 'Recruiter',
      },
      {
        user_properties: {
          viewport,
          'screen resolution': screenResolution,
        },
      }
    );
  };
  // Datadog RUM
  if (
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
    process.env.NEXT_PUBLIC_DATADOG_SITE
  ) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: process.env.NEXT_PUBLIC_DATADOG_SITE,
      service: 'recruiter',
      env: process.env.NEXT_PUBLIC_HEADRACE_ENV,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
  }
  useEffect(initAmplitude, []);
  useEffect(() => {
    handleRouteChange(pathname);
  }, [pathname]);
  const checkPublicRoute = useMemo(
    () => PublicPaths.includes(pathname),
    [pathname]
  );

  useEffect(() => {
    if (pathname.includes('/dossier')) {
      const dossierId = Array.isArray(query.dossierId)
        ? query.dossierId[0]
        : query.dossierId;
      ampli.publicDossierPageViewed({
        app: 'Recruiter',
        'dossier id': dossierId || '',
      });
    }
  }, [pathname, query]);

  if (checkPublicRoute) {
    // Don't want to include the public layout header when going directly to sign-up
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isSignUpPath = useMemo(() => pathname === '/sign-up', [pathname]);
    if (isSignUpPath) {
      return (
        <>
          <Head>
            <title>HeadRace | Recruiter Web Signup Page</title>
            <meta name="robots" content="noindex" />
            <meta
              property="og:title"
              content="HeadRace | Recruiter Web Signup Page"
            />
            <meta
              name="description"
              content="HeadRace | Recruiter Web Signup Page"
              key="desc"
            />
            <meta
              property="og:description"
              content="HeadRace | Recruiter Web Signup Page"
            />
            <link rel="icon" href="/headrace_icon.svg" />
            <meta property="og:image" content="/headrace_icon.svg" />
          </Head>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </>
      );
    }
    return (
      <>
        <Head>
          <title> HeadRace | Web App Recruiter Page </title>
          <meta name="robots" content="noindex" />
          <meta
            property="og:title"
            content=" HeadRace | Web App Recruiter Page "
          />
          <meta
            name="description"
            content=" HeadRace | Web App Recruiter Page "
            key="desc"
          />
          <meta
            property="og:description"
            content=" HeadRace | Web App Recruiter Page "
          />
          <link rel="icon" href="/headrace_icon.svg" />
          <meta property="og:image" content="/headrace_icon.svg" />
        </Head>
        <PublicLayout>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </PublicLayout>
      </>
    );
  }
  return (
    <>
      <Head>
        <title> HeadRace | Web App Recruiter Page </title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content=" HeadRace | Web App Recruiter Page "
        />
        <meta
          name="description"
          content=" HeadRace | Web App Recruiter Page "
          key="desc"
        />
        <meta
          property="og:description"
          content=" HeadRace | Web App Recruiter Page "
        />
        <link rel="icon" href="/headrace_icon.svg" />
        <meta property="og:image" content="/headrace_icon.svg" />
      </Head>
      <Notifications />
      <Layout>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

const MyApp: React.FC<AppPropsWithLayout> = (props) => {
  const domain: string = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
  const clientId: string = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
  const audience = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;
  const redirectUri =
    (typeof window !== 'undefined' && window.location.origin) || undefined;
  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedLayout {...props} />
    </Auth0Provider>
  );
};

export default MyApp;
