import { OnboardingStatus } from '@headrace/types';
import type { NavigationItem } from '@headrace/ui';
import { CandidatesIcon, MarketPlaceIcon } from '@headrace/ui';
import {
  CurrencyDollarIcon,
  DocumentTextIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/outline';

import { navBarItems } from '@/components/settings/MainLayout';
import type { CurrentRecruiterQuery } from '@/graphql/generated';

// eslint-disable-next-line import/prefer-default-export
export const navigation = (
  recruiter: CurrentRecruiterQuery['currentRecruiter']
): NavigationItem[] => {
  const allAccessNavigation: NavigationItem[] = [
    {
      name: 'Dashboard',
      href: '/',
      icon: HomeIcon,
      showNavigation: true,
    },
    {
      name: 'Roles',
      href: '/roles',
      icon: UsersIcon,
      showNavigation: true,
    },
    {
      name: 'Role Details',
      title: 'Role Details',
      href: '/roles/[roleId]',
      backArrow: '/roles/',
      children: ['/roles/[roleId]/dossier', '/roles/[roleId]/messaging'],
    },
    {
      name: 'Submit candidate',
      title: 'Submit candidate',
      href: '/roles/[roleId]/submit-candidate',
      backArrow: true,
    },
    {
      name: 'Lead details',
      title: 'Lead details',
      href: '/leads/[leadId]',
      backArrow: '/roles?tab=leads',
    },
    {
      name: 'Marketplace',
      href: '/marketplace',
      icon: MarketPlaceIcon,
      showNavigation:
        recruiter?.isMarketplaceRecruiter && recruiter.showMarketplace,
      yellowStripe: false,
    },
    {
      name: 'Marketplace',
      href: '/marketplace/[roleId]',
      icon: MarketPlaceIcon,
      showNavigation: false,
      backArrow: '/marketplace',
    },
    {
      name: 'Searches',
      href: '/searches',
      icon: DocumentTextIcon,
      showNavigation:
        recruiter?.showSearches != null ? recruiter.showSearches : true,
    },
    {
      name: 'Document Overview',
      title: 'Search agreement details',
      href: '/searches/[searchId]',
      icon: DocumentTextIcon,
      yellowStripe: true,
    },
    {
      name: 'Edit Search Agreement',
      title: 'Edit Search Agreement',
      href: '/searches/[searchId]/edit',
    },
    {
      name: 'Create Search Agreement',
      title: 'Create Search Agreement',
      href: '/searches/create',
    },
    {
      name: 'Candidate Details',
      title: 'Candidate Details',
      href: '/candidates/[candidateId]',
      backArrow: true,
    },
    {
      name: 'Earnings',
      href: '/earnings',
      icon: CurrencyDollarIcon,
      showNavigation: true,
      yellowStripe: false,
    },
    {
      name: 'Create shared lead',
      title: 'Create shared lead',
      href: '/leads/create',
    },
    {
      name: 'Candidates',
      title: 'Candidates',
      href: '/candidates',
      icon: CandidatesIcon,
      showNavigation: true,
    },
    ...navBarItems,
  ];

  const waitlistNavigation = [
    {
      name: 'Dashboard',
      href: '/onboarding',
      icon: HomeIcon,
      showNavigation: true,
      yellowStripe: false,
      customMargin: true,
    },
    {
      name: 'Profile',
      href: '/profile',
      showNavigation: false,
      yellowStripe: false,
      backArrow: true,
    },
  ];

  return recruiter?.onboardingStatus === OnboardingStatus.COMPLETED
    ? allAccessNavigation
    : waitlistNavigation;
};
