import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ProfileCard: VFC<Props> = ({ children, className }) => {
  const boxClassName = classNames(
    className,
    'bg-white shadow p-10 overflow-hidden sm:rounded-lg h-full'
  );
  return <div className={boxClassName}>{children}</div>;
};

export default ProfileCard;
