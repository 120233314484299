import type { VFC } from 'react';

const EarningsNoData: VFC = () => (
  <div className="flex flex-col justify-center items-center h-full">
    <div className="text-gray-600 text-center font-medium ">
      No data to display
    </div>
  </div>
);

export default EarningsNoData;
