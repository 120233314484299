import classNames from 'classnames';
import type { VFC } from 'react';

import { QuoteIcon } from '../CustomIcons';

interface Props {
  quote: string;
  quoteBy: string;
  className?: string;
  withIcon?: boolean;
}

const QuoteCard: VFC<Props> = ({ quote, quoteBy, className, withIcon }) => (
  <div className="bg-white flex flex-col gap-10  px-16 lg:px-20 justify-center h-full items-center">
    {withIcon && <QuoteIcon className="w-12" />}
    <div className="flex flex-col gap-8 text-center h-full justify-center items-center">
      <p
        className={classNames(
          'sm:text-lg text-base font-light text-center flex items-center justify-center',
          className
        )}
      >
        {quote}
      </p>
      <p className="text-gray-500 sm:text-lg text-sm italic font-medium">
        {quoteBy}
      </p>
    </div>
  </div>
);

export default QuoteCard;
