import type { SearchReview } from '@headrace/types';
import type { VFC } from 'react';

import ErrorMessageBox from '../../../ErrorMessageBox';
import LoadingSpinner from '../../../LoadingSpinner';
import type { ModalReport } from '../../../Rating/ReportReviewModal';
import ReviewsPagination from '../../../Rating/ReviewsPagination';
import ProfileCard from '../../ProfileCard';
import OverallRating from './OverallRating';

export interface ReviewAverage {
  comunicationAverage?: number;
  timelessAverage?: number;
  overallRatingAverage?: number;
  candidateSubmissionQualityAverage?: number;
  timeToPlacementAverage?: number;
}

interface ReviewsProps {
  searchReviews?: SearchReview[];
  reviewAverages: ReviewAverage | null;
  error?: string | null;
  loading?: boolean;
  onSubmit: (values: ModalReport & SearchReview) => void;
}

const Reviews: VFC<ReviewsProps> = ({
  searchReviews,
  reviewAverages,
  error,
  loading,
  onSubmit,
}) => {
  if (loading) return <LoadingSpinner />;
  if (error) {
    return <ErrorMessageBox error={error} />;
  }
  if (searchReviews && searchReviews?.length > 0 && reviewAverages) {
    return (
      <>
        <ProfileCard className="mt-6 p-0 rounded-b-none sm:rounded-b-none">
          <OverallRating
            comunicationAverage={reviewAverages?.comunicationAverage || 0}
            timelessAverage={reviewAverages?.timelessAverage || 0}
            overallRatingAverage={reviewAverages?.overallRatingAverage || 0}
            candidateSubmissionQualityAverage={
              reviewAverages?.candidateSubmissionQualityAverage || 0
            }
            timeToPlacementAverage={reviewAverages?.timeToPlacementAverage || 0}
          />
        </ProfileCard>
        <ReviewsPagination searchReviews={searchReviews} onSubmit={onSubmit} />
      </>
    );
  }

  return null;
};

export default Reviews;
