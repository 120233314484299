import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { ReactElement } from 'react';

export interface ProfileOnboardingStep {
  name: string;
  isCompleted: boolean;
}

interface ToDoProps {
  profileOnboardingSteps: ProfileOnboardingStep[];
}

const ToDo = ({ profileOnboardingSteps }: ToDoProps): ReactElement => (
  <div className="w-full h-full flex flex-col gap-y-6">
    <div className="font-bold text-lg">To do</div>
    {profileOnboardingSteps.map((profileOnboardingStep) => (
      <div
        className="flex text-xs items-center text-headraceBlack-900 font-medium"
        key={profileOnboardingStep.name}
      >
        <div
          className={classNames(
            'flex items-center justify-center rounded-full w-8 h-8  mr-4 content-center',
            profileOnboardingStep.isCompleted
              ? 'bg-headraceYellow-700'
              : 'border-2 border-gray-300'
          )}
        >
          {profileOnboardingStep.isCompleted && (
            <CheckIcon className="w-5 text-headraceBlack-900" />
          )}
        </div>
        <div>{profileOnboardingStep.name}</div>
      </div>
    ))}
  </div>
);

export default ToDo;
