import { LINKED_IN_URL } from '@headrace/constants';
import type { PlacementData } from '@headrace/types';
import { completeUrl, isValidHttpUrl } from '@headrace/utils';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { format } from 'date-fns';
import Image from 'next/image';
import type { Dispatch, SetStateAction, VFC } from 'react';

import ImageWithFallback from '../../../ImageWithFallback';
import type { PlacementForm } from '../EditMode/PlacementFormModal';

interface Props {
  placement: PlacementData;
  editMode?: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPlacementToEdit: (placement: PlacementForm) => void;
}

const HistoryCard: VFC<Props> = ({
  placement,
  editMode = false,
  setOpenModal,
  setPlacementToEdit,
}) => (
  <div className="h-full w-full flex gap-10 py-6 pr-10 group">
    <div className="border-r-2 w-44 border-headraceYellow-700">
      <div className="flex justify-center items-center py-10">
        {placement.company.logoUrl ? (
          <ImageWithFallback
            className="object-scale-down shadow-lg rounded-lg"
            src={placement.company.logoUrl}
            alt="company-logo"
            objectFit="contain"
            width="100%"
            height={60}
            fallbackSrc="/placeholders/company_logo_placeholder.png"
          />
        ) : (
          <Image
            className="object-scale-down shadow-lg rounded-lg"
            src="/placeholders/company_logo_placeholder.png"
            alt="company-logo"
            objectFit="contain"
            width="100%"
            height={60}
          />
        )}
      </div>
    </div>
    <div className="flex flex-col w-full gap-8">
      <div className="flex justify-between gap-4 w-full items-start">
        <div>
          <div
            className={classNames('flex items-center text-lg font-medium ', {
              'text-blue-500 cursor-pointer':
                isValidHttpUrl(placement.linkedIn) ||
                (LINKED_IN_URL.test(placement.linkedIn) &&
                  isValidHttpUrl(completeUrl(placement.linkedIn))),
            })}
          >
            {isValidHttpUrl(placement.linkedIn) ||
            (LINKED_IN_URL.test(placement.linkedIn) &&
              isValidHttpUrl(completeUrl(placement.linkedIn))) ? (
              <>
                <a
                  href={completeUrl(placement.linkedIn)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {placement.roleTitle}
                </a>
                <a
                  href={completeUrl(placement.linkedIn)}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ExternalLinkIcon className="pl-1 w-4 h-4 text-blue-500 cursor-pointer" />
                </a>
              </>
            ) : (
              <p>{placement.roleTitle}</p>
            )}
          </div>
          <div className="text-gray-600 text-lg font-normal">
            {placement.company.name}{' '}
            {placement.cityName ? ` | ${placement.cityName}` : ''}
          </div>
        </div>
        <div className="flex items-end gap-5">
          <div className="text-gray-400 text-base font-normal">
            Hired {format(new Date(placement.placedAt), 'MMMM yyyy')}
          </div>
          {editMode && !placement.candidateId && (
            <button
              className="py-1 group-hover:block xl:hidden lg:block"
              type="button"
              onClick={(): void => {
                setPlacementToEdit({
                  id: placement.id,
                  companyId: placement.company.id,
                  roleTitleId: placement.roleTitleId ?? '',
                  roleSeniorityId: placement.roleSeniorityId ?? '',
                  roleFunctionId: placement.roleFunctionId ?? '',
                  companyStageId: placement.companyStage?.id ?? '',
                  cityId: placement.cityId ?? '',
                  firstYearBaseSalary: placement.firstYearBaseSalary ?? 0,
                  placedAt: placement.placedAt,
                  linkedIn: placement.linkedIn ?? '',
                });
                setOpenModal(true);
              }}
            >
              <PencilIcon className="w-5 text-blue-500" />
            </button>
          )}
        </div>
      </div>
      <div className="grid sm:grid-cols-4 grid-cols-1 gap-x-4 gap-y-8 text-headraceBlack-700">
        <div className="col-span-1 flex flex-col">
          <div className="text-sm font-medium">Function</div>
          <div className=" text-sm break-words font-normal">
            {placement.roleFunction}
          </div>
        </div>
        <div className="col-span-1 flex flex-col">
          <div className="text-sm font-medium">Level</div>
          <div className=" text-sm break-words font-normal">
            {placement.roleSeniority}
          </div>
        </div>
        <div className="col-span-1 flex flex-col">
          <div className="text-sm font-medium">Industry</div>
          <div className=" text-sm break-words font-normal">
            {placement.company.industry?.name || ''}
          </div>
        </div>
        {placement.companyStage?.name && (
          <div className="col-span-1 flex flex-col">
            <div className="text-sm font-medium">Company stage at hire</div>
            <div className=" text-sm break-words font-normal">
              {placement.companyStage?.name}
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default HistoryCard;
