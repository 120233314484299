import type { CandidateActivityHistoryActivityType } from '@headrace/types';
import type { CandidateActivityHistoryEntityType } from '@headrace/types';
import type { CandidateDocumentTypeEnum } from '@headrace/types';
import type { CandidateExpirationReasonEnum } from '@headrace/types';
import type { CandidateInterestVerificationType } from '@headrace/types';
import type { CandidateRejectedReasonEnum } from '@headrace/types';
import type { CollaborationStatus } from '@headrace/types';
import type { CompanyDataIngestSource } from '@headrace/types';
import type { CompanyPerkEnum } from '@headrace/types';
import type { EnrichmentProspectStatus } from '@headrace/types';
import type { EntityTypeEnum } from '@headrace/types';
import type { EvaluationStatus } from '@headrace/types';
import type { ExpectedPlacementsEnum } from '@headrace/types';
import type { FeeAdjustmentTypeEnum } from '@headrace/types';
import type { FeeScheduleTypeEnum } from '@headrace/types';
import type { FeeTriggerTypeEnum } from '@headrace/types';
import type { InvitationStatus } from '@headrace/types';
import type { LatestFundingRoundTypeEnum } from '@headrace/types';
import type { LeadStatusEnum } from '@headrace/types';
import type { MarketplaceRoleRecruiterStatus } from '@headrace/types';
import type { MarketplaceVisibilityEnum } from '@headrace/types';
import type { MeetRequirementEnum } from '@headrace/types';
import type { MessageDirectionType } from '@headrace/types';
import type { OnboardingStatus } from '@headrace/types';
import type { OutreachChannelEnum } from '@headrace/types';
import type { OutreachMessageType } from '@headrace/types';
import type { OutreachSequenceStatus } from '@headrace/types';
import type { PayoutTypeEnum } from '@headrace/types';
import type { PricingTypeEnum } from '@headrace/types';
import type { ProspectAssigneeEnum } from '@headrace/types';
import type { ProspectSearchType } from '@headrace/types';
import type { ProspectStatus } from '@headrace/types';
import type { RecruiterSearchAgreementActivityCategory } from '@headrace/types';
import type { RemoteWorkPolicyEnum } from '@headrace/types';
import type { RoleCollaboratorTypeEnum } from '@headrace/types';
import type { RoleQuestionStatus } from '@headrace/types';
import type { RoleTypeEnum } from '@headrace/types';
import type { SearchPreferenceEnum } from '@headrace/types';
import type { UserNotificationChannel } from '@headrace/types';
import type { UserNotificationEnum } from '@headrace/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AcceptanceRateMetricsOutput = {
  __typename?: 'AcceptanceRateMetricsOutput';
  quartileAcceptanceRate: Scalars['Float'];
  recruiterAcceptanceRate: Scalars['Float'];
};

export type AcceptedLead = {
  __typename?: 'AcceptedLead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementCreatedId?: Maybe<Scalars['String']>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Agency = {
  __typename?: 'Agency';
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  isTestAccount: Scalars['Boolean'];
  leads: Array<Lead>;
  linkedIn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payouts: Array<Payout>;
  recruiters: Array<Recruiter>;
  searchAgreements: Array<SearchAgreement>;
  singularEmployers: Array<Employer>;
  slackChannelAbbreviation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
};

export type AgencyEarning = {
  __typename?: 'AgencyEarning';
  id: Scalars['String'];
  placementRevenue: Scalars['Float'];
  retainerRevenue: Scalars['Float'];
};

export type AgencyEarningsInput = {
  endDate?: InputMaybe<Scalars['String']>;
  periodicity: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type AgencyMonthlyEarning = {
  __typename?: 'AgencyMonthlyEarning';
  month: Scalars['String'];
  placementEarnings: Scalars['Float'];
  retainerEarnings: Scalars['Float'];
  totalEarnings: Scalars['Float'];
};

export type AtsIntegration = {
  __typename?: 'AtsIntegration';
  accountToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employer: Employer;
  id: Scalars['ID'];
  mergeJobs: MergeJob;
  name: Scalars['String'];
  shareCandidateFeedback: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  userEmail: Scalars['String'];
};

export type Candidate = {
  __typename?: 'Candidate';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidateAcceptOfferInput = {
  baseSalary?: InputMaybe<Scalars['Float']>;
  candidateId: Scalars['String'];
  shareCount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CandidateActivityHistoryActivityType>;
};

export type CandidateActivityHistory = {
  __typename?: 'CandidateActivityHistory';
  activityMeta?: Maybe<CandidateActivityHistoryActivityMetaType>;
  activityType: CandidateActivityHistoryActivityType;
  candidate: Candidate;
  candidateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
  entityType: CandidateActivityHistoryEntityType;
  eventOccurredAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type CandidateActivityHistoryActivityMetaType = {
  __typename?: 'CandidateActivityHistoryActivityMetaType';
  expirationReasonCategory?: Maybe<CandidateExpirationReasonEnum>;
  mergeInterviewId?: Maybe<Scalars['String']>;
  mergeRejectReasonId?: Maybe<Scalars['String']>;
  rejectReasonCategory?: Maybe<CandidateRejectedReasonEnum>;
  rejectReasonDetails?: Maybe<Scalars['String']>;
  shareRejectionInfo?: Maybe<Scalars['Boolean']>;
};

export { CandidateActivityHistoryActivityType };

export { CandidateActivityHistoryEntityType };

export type CandidateActivityHistoryFilter = {
  candidateId: Scalars['String'];
};

export type CandidateActivityHistoryOutput = {
  __typename?: 'CandidateActivityHistoryOutput';
  candidateHistory: Array<CandidateActivityHistory>;
};

export type CandidateDetailsOutput = {
  __typename?: 'CandidateDetailsOutput';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  feeSplitPercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCollaborationCandidate?: Maybe<Scalars['Boolean']>;
  isCollaborationRecruiter: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidateDocument = {
  __typename?: 'CandidateDocument';
  candidate: Candidate;
  candidateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  document: Scalars['String'];
  documentPath?: Maybe<Scalars['String']>;
  documentType?: Maybe<CandidateDocumentTypeEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { CandidateDocumentTypeEnum };

export { CandidateExpirationReasonEnum };

export { CandidateInterestVerificationType };

export type CandidateMarketPlaceMetricsOutput = {
  __typename?: 'CandidateMarketPlaceMetricsOutput';
  hiredCount: Scalars['Float'];
  inReviewCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
};

export type CandidateMetrics = {
  __typename?: 'CandidateMetrics';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export { CandidateRejectedReasonEnum };

export type CandidateRoleRequirement = {
  __typename?: 'CandidateRoleRequirement';
  aiMeetRequirement?: Maybe<Scalars['String']>;
  aiMeetRequirementExplanation?: Maybe<Scalars['String']>;
  candidate: Candidate;
  candidateExplanation?: Maybe<Scalars['String']>;
  candidateMeetsRequirement?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  meetRequirement?: Maybe<MeetRequirementEnum>;
  meetRequirementOverride?: Maybe<MeetRequirementEnum>;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  roleRequirement?: Maybe<RoleRequirement>;
  roleRequirementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vellumMeetRequirement?: Maybe<Scalars['String']>;
  vellumMeetRequirementExplanation?: Maybe<Scalars['String']>;
};

export type CandidateStatusMetricsOutput = {
  __typename?: 'CandidateStatusMetricsOutput';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export type CandidateWithMetrics = {
  __typename?: 'CandidateWithMetrics';
  candidateMetrics: CandidateMetrics;
  candidates: Array<CandidateWithSearchAgreement>;
  candidatesLast7Days?: Maybe<Array<Candidate>>;
  globalCandidateMetrics?: Maybe<CandidateMetrics>;
  submissionAvailableDate?: Maybe<Scalars['String']>;
};

export type CandidateWithSearchAgreement = {
  __typename?: 'CandidateWithSearchAgreement';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  marketplaceVisibility: Scalars['String'];
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidatesByAgency = {
  __typename?: 'CandidatesByAgency';
  candidateMetrics: CandidateMetrics;
  candidates: Array<CandidatesWithDates>;
  recruitersByAgencyCount: Scalars['Float'];
};

export type CandidatesWithDates = {
  __typename?: 'CandidatesWithDates';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  fee?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCollaborationCandidate: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  submitDate: Scalars['DateTime'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type City = {
  __typename?: 'City';
  cityCode: Scalars['ID'];
  cityName: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placements: Array<Placement>;
  region?: Maybe<Scalars['String']>;
  stateAbbrev: Scalars['String'];
  stateName: Scalars['String'];
  timezone?: Maybe<Timezone>;
  timezoneId?: Maybe<Scalars['String']>;
};

export type ClientCompanyStage = {
  __typename?: 'ClientCompanyStage';
  count: Scalars['Float'];
  name: Scalars['String'];
};

export type ClosedSearchProduct = {
  __typename?: 'ClosedSearchProduct';
  closedSearchAgreement?: Maybe<SearchAgreement>;
  closedSearchProductMarketplaceRolePrice?: Maybe<PlacementFeeOutput>;
};

export type Collaboration = {
  __typename?: 'Collaboration';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCandidateOwnerAgnostic?: Maybe<Scalars['Boolean']>;
  primaryRecruiter: Recruiter;
  recruiter: Recruiter;
  searchAgreementRole: SearchAgreementRole;
  splitPercentage: Scalars['Float'];
  status: CollaborationStatus;
  updatedAt: Scalars['DateTime'];
};

export type CollaborationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleId: Scalars['String'];
  searchAgreementRoleId: Scalars['String'];
  splitPercentage: Scalars['Float'];
};

export { CollaborationStatus };

export type CommonEducationInstitution = {
  __typename?: 'CommonEducationInstitution';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
};

export type CommonIndustries = {
  __typename?: 'CommonIndustries';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
  placementsCount: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  companyDataIngestRaw?: Maybe<Array<CompanyDataIngestRaw>>;
  companyStage?: Maybe<CompanyStage>;
  companyStageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crunchbase?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: Maybe<Industry>;
  industryId?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  introJSON?: Maybe<Scalars['String']>;
  investors?: Maybe<Scalars['String']>;
  latestFundingRoundAmount?: Maybe<Scalars['Float']>;
  latestFundingRoundType?: Maybe<LatestFundingRoundTypeEnum>;
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  logo?: Maybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  overviewJSON?: Maybe<Scalars['String']>;
  perks: Array<CompanyPerk>;
  placements: Array<Placement>;
  press: Array<CompanyPress>;
  revenue?: Maybe<Scalars['Float']>;
  tagline?: Maybe<Scalars['String']>;
  tam?: Maybe<Scalars['Float']>;
  teamMembers: Array<CompanyTeamMember>;
  totalFunding?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
  yoyGrowth?: Maybe<Scalars['Float']>;
};

export type CompanyDataIngestRaw = {
  __typename?: 'CompanyDataIngestRaw';
  company?: Maybe<Array<Company>>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  linkedInCategories?: Maybe<Array<Scalars['String']>>;
  linkedInCompanySizeOnLinkedIn?: Maybe<Scalars['Float']>;
  linkedInCompanyType?: Maybe<Scalars['String']>;
  linkedInCrunchbaseProfileUrl?: Maybe<Scalars['String']>;
  linkedInDescription?: Maybe<Scalars['String']>;
  linkedInFoundedYear?: Maybe<Scalars['Int']>;
  linkedInFundingData?: Maybe<Array<LinkedInFundingRoundMetaType>>;
  linkedInHq?: Maybe<LinkedInCompanyLocationMeta>;
  linkedInIndustry?: Maybe<Scalars['String']>;
  linkedInTagline?: Maybe<Scalars['String']>;
  linkedInTotalFundingAmount?: Maybe<Scalars['Float']>;
  linkedInWebsite?: Maybe<Scalars['String']>;
  source: CompanyDataIngestSource;
  updatedAt: Scalars['DateTime'];
};

export { CompanyDataIngestSource };

export type CompanyPerk = {
  __typename?: 'CompanyPerk';
  company: Company;
  id: Scalars['ID'];
  type: CompanyPerkEnum;
};

export { CompanyPerkEnum };

export type CompanyPress = {
  __typename?: 'CompanyPress';
  company: Company;
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CompanyStage = {
  __typename?: 'CompanyStage';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type CompanyTeamMember = {
  __typename?: 'CompanyTeamMember';
  company: Company;
  id: Scalars['ID'];
  linkedin: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
};

export type CreateCandidateDocumentInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  document: Scalars['String'];
  documentType: CandidateDocumentTypeEnum;
  name: Scalars['String'];
};

export type CreateCandidateInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  candidateVerification: Scalars['Boolean'];
  documents: Array<CreateCandidateDocumentInput>;
  justification: Scalars['String'];
  justificationJSON: Scalars['String'];
  roleId: Scalars['String'];
  roleRequirements?: InputMaybe<Array<CreateCandidateRoleRequirementInput>>;
  talent: CreateTalentInput;
};

export type CreateCandidateRoleRequirementInput = {
  explanation?: InputMaybe<Scalars['String']>;
  meetRequirement: MeetRequirementEnum;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
};

export type CreateCompanyInput = {
  companyStage: Scalars['String'];
  industry: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateFeeScheduleInput = {
  feeAdjustmentType?: InputMaybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: InputMaybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  oneTimeFeeRelativeSigningDays?: InputMaybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: InputMaybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: InputMaybe<Scalars['Int']>;
};

export type CreateMediaUploadLinkInput = {
  filename: Scalars['String'];
  folder: Scalars['String'];
};

export type CreateRecruiterInput = {
  agencyName?: InputMaybe<Scalars['String']>;
  tou: Scalars['Boolean'];
  user: CreateUserInput;
};

export type CreateRoleInput = {
  cities: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expectedFirstYearSalaryMax?: InputMaybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: InputMaybe<Scalars['Float']>;
  expectedFirstYearShareCount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newRole?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Int']>;
  roleFunction?: InputMaybe<Scalars['String']>;
  roleSeniority?: InputMaybe<Scalars['String']>;
};

export type CreateRoleQuestionInput = {
  question: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreateRoleRequirementInput = {
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreateSearchAgreementRecruiterInput = {
  beginAt: Scalars['DateTime'];
  employerId: Scalars['String'];
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates: Scalars['String'];
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<CreateFeeScheduleInput>;
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  replacementSearchNotificationWindowDays?: InputMaybe<Scalars['Int']>;
  replacementSearchTerminateDays?: InputMaybe<Scalars['Int']>;
  replacementSearchWindowMonths?: InputMaybe<Scalars['Int']>;
  roles: Array<CreateRoleInput>;
  signedByEmployeeId: Scalars['String'];
};

export type CreateTalentInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CurrentRecruiter = {
  __typename?: 'CurrentRecruiter';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showMarketplace: Scalars['Boolean'];
  showProfile: Scalars['Boolean'];
  showSearches: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type DetailedLead = {
  __typename?: 'DetailedLead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Document = {
  documentType: CandidateDocumentTypeEnum;
  id: Scalars['String'];
};

export type DownloadDocumentsResultOutput = {
  __typename?: 'DownloadDocumentsResultOutput';
  data: Scalars['String'];
};

export type EarningsMetricsOutput = {
  __typename?: 'EarningsMetricsOutput';
  current: Scalars['Float'];
  hasData: Scalars['Boolean'];
  previous?: Maybe<Scalars['Float']>;
};

export type EducationDegree = {
  __typename?: 'EducationDegree';
  id: Scalars['ID'];
  placements: Array<Placement>;
  type: Scalars['String'];
};

export type EducationInstitution = {
  __typename?: 'EducationInstitution';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type EducationMajor = {
  __typename?: 'EducationMajor';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type Employee = {
  __typename?: 'Employee';
  acceptedLeads: Array<Lead>;
  canceledSearchAgreements: Array<SearchAgreement>;
  createdAt: Scalars['DateTime'];
  employer: Employer;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  requestedRoles: Array<Role>;
  roles: Array<Role>;
  signedSearchAgreements: Array<SearchAgreement>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type Employer = {
  __typename?: 'Employer';
  company: Company;
  createdAt: Scalars['DateTime'];
  defaultMarketplacePrice: Scalars['Int'];
  employees: Array<Employee>;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  frontChannelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ignoreForRecruiterAcceptanceRate: Scalars['Boolean'];
  isHeadRaceAI: Scalars['Boolean'];
  isTestAccount: Scalars['Boolean'];
  leads: Array<Lead>;
  outreachChannel: OutreachChannelEnum;
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  primaryAgency?: Maybe<Agency>;
  primaryAgencyId?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  searchAgreements: Array<SearchAgreement>;
  slackChannelAbbreviation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EmployerInvitationByRecruiter = {
  __typename?: 'EmployerInvitationByRecruiter';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  employer: Employer;
  id: Scalars['ID'];
  recruiter: Recruiter;
  status: InvitationStatus;
};

export type EmployerInvitationByRecruiterInput = {
  employeeEmail: Scalars['String'];
  employeeFirstName: Scalars['String'];
  employeeLastName: Scalars['String'];
  employeePhone?: InputMaybe<Scalars['String']>;
  employerName: Scalars['String'];
};

export type Endorsement = {
  __typename?: 'Endorsement';
  endorsement: Scalars['String'];
  endorserCompany: Scalars['String'];
  endorserTitle: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Float'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
};

export { EnrichmentProspectStatus };

export { EntityTypeEnum };

export { EvaluationStatus };

export { ExpectedPlacementsEnum };

export { FeeAdjustmentTypeEnum };

export type FeeScheduleInput = {
  feeAdjustmentType?: InputMaybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: InputMaybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  oneTimeFeeRelativeSigningDays?: InputMaybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: InputMaybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: InputMaybe<Scalars['Int']>;
};

export { FeeScheduleTypeEnum };

export { FeeTriggerTypeEnum };

export type FrontEmailMessage = {
  __typename?: 'FrontEmailMessage';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  from: Scalars['String'];
  frontConversationId: Scalars['String'];
  frontConversationLink: Scalars['String'];
  frontMessageId: Scalars['String'];
  id: Scalars['ID'];
  outreachMessage?: Maybe<OutreachMessage>;
  outreachMessageId?: Maybe<Scalars['String']>;
  outreachSequence: OutreachSequence;
  outreachSequenceId: Scalars['String'];
  to: Scalars['String'];
  type: MessageDirectionType;
};

export type IdealCandidateProfile = {
  __typename?: 'IdealCandidateProfile';
  id: Scalars['ID'];
  linkedIn: Scalars['String'];
  prospectSearch?: Maybe<ProspectSearch>;
  prospectSearchId?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
};

export type Industry = {
  __typename?: 'Industry';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { InvitationStatus };

export type InviteTeamMemberInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export { LatestFundingRoundTypeEnum };

export type Lead = {
  __typename?: 'Lead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type LeadOutput = {
  __typename?: 'LeadOutput';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export { LeadStatusEnum };

export type LinkedInCompanyLocationMeta = {
  __typename?: 'LinkedInCompanyLocationMeta';
  city: Scalars['String'];
  country: Scalars['String'];
  is_hq: Scalars['Boolean'];
  line_1: Scalars['String'];
  postal_code: Scalars['String'];
  state: Scalars['String'];
};

export type LinkedInDateMetaType = {
  __typename?: 'LinkedInDateMetaType';
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type LinkedInFundingRoundMetaType = {
  __typename?: 'LinkedInFundingRoundMetaType';
  announced_date: LinkedInDateMetaType;
  funding_type: Scalars['String'];
  investor_list: Array<LinkedInInvestorMetaType>;
  money_raised: Scalars['Float'];
  number_of_investor: Scalars['Int'];
};

export type LinkedInInvestorMetaType = {
  __typename?: 'LinkedInInvestorMetaType';
  linkedin_profile_url: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type LinkedInProfile = {
  __typename?: 'LinkedInProfile';
  agency: Agency;
  agencyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  html: Scalars['String'];
  id: Scalars['ID'];
  parsedLinkedInProfile?: Maybe<ParsedLinkedInProfile>;
  talent?: Maybe<Talent>;
  talentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlPath: Scalars['String'];
};

export type LinkedInProfileRaw = {
  __typename?: 'LinkedInProfileRaw';
  background_cover_image_url: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Scalars['JSON']>>;
  experiences?: Maybe<Array<Scalars['JSON']>>;
  firstName: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  lastRefreshDate: Scalars['DateTime'];
  last_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  occupation?: Maybe<Scalars['String']>;
  profile_pic_url?: Maybe<Scalars['String']>;
  public_identifier: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type MarketplaceRoleOutput = {
  __typename?: 'MarketplaceRoleOutput';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  fee: Scalars['Float'];
  feeGuidance?: Maybe<Scalars['String']>;
  feeType?: Maybe<FeeScheduleTypeEnum>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type MarketplaceRoleRecruiter = {
  __typename?: 'MarketplaceRoleRecruiter';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  recruiter: Recruiter;
  role: Role;
  status: MarketplaceRoleRecruiterStatus;
  updatedAt: Scalars['DateTime'];
};

export { MarketplaceRoleRecruiterStatus };

export type MarketplaceRoleWithStatus = {
  __typename?: 'MarketplaceRoleWithStatus';
  createdAt: Scalars['DateTime'];
  globalCandidateCount: Scalars['Int'];
  role: Role;
  searchInfo: SearchInfo;
  status: MarketplaceRoleRecruiterStatus;
};

export { MarketplaceVisibilityEnum };

export type MediaUploadLink = {
  __typename?: 'MediaUploadLink';
  fields: MediaUploadLinkFields;
  url: Scalars['String'];
};

export type MediaUploadLinkFields = {
  __typename?: 'MediaUploadLinkFields';
  Policy: Scalars['String'];
  X_Amz_Algorithm: Scalars['String'];
  X_Amz_Credential: Scalars['String'];
  X_Amz_Date: Scalars['String'];
  X_Amz_Security_Token?: Maybe<Scalars['String']>;
  X_Amz_Signature: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export { MeetRequirementEnum };

export type MergeApplication = {
  __typename?: 'MergeApplication';
  appliedAt?: Maybe<Scalars['DateTime']>;
  candidate: Candidate;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentMergeJobInterviewStageId?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeCandidate: MergeCandidate;
  mergeCandidateId?: Maybe<Scalars['String']>;
  mergeInterviews: Array<MergeInterview>;
  mergeJob: MergeJob;
  mergeJobId?: Maybe<Scalars['String']>;
  mergeRejectReason: MergeRejectReason;
  mergeRejectReasonId?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeCandidate = {
  __typename?: 'MergeCandidate';
  atsIntegration: AtsIntegration;
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastInteractionAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  mergeApplications: Array<MergeApplication>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  remoteUpdatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeDepartment = {
  __typename?: 'MergeDepartment';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MergeInterview = {
  __typename?: 'MergeInterview';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  mergeApplication: MergeApplication;
  mergeApplicationId: Scalars['String'];
  mergeJobInterviewStage: MergeJobInterviewStage;
  mergeJobInterviewStageId?: Maybe<Scalars['String']>;
  mergeScorecards?: Maybe<Array<MergeScorecard>>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  remoteUpdatedAt?: Maybe<Scalars['DateTime']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeJob = {
  __typename?: 'MergeJob';
  atsIntegration: AtsIntegration;
  confidential: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mergeDepartments: Array<MergeDepartment>;
  mergeHiringManagers: Array<MergeUser>;
  mergeOffices: Array<MergeOffice>;
  mergeRecruiters: Array<MergeUser>;
  name?: Maybe<Scalars['String']>;
  remoteCreatedAt: Scalars['DateTime'];
  remoteId: Scalars['String'];
  remoteUpdatedAt: Scalars['DateTime'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MergeJobInterviewStage = {
  __typename?: 'MergeJobInterviewStage';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeJobId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeOffice = {
  __typename?: 'MergeOffice';
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MergeRejectReason = {
  __typename?: 'MergeRejectReason';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeScorecard = {
  __typename?: 'MergeScorecard';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeApplicationId?: Maybe<Scalars['String']>;
  mergeInterview: MergeInterview;
  mergeInterviewId?: Maybe<Scalars['String']>;
  mergeInterviewerId?: Maybe<Scalars['String']>;
  overallRecommendation?: Maybe<Scalars['String']>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeUser = {
  __typename?: 'MergeUser';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { MessageDirectionType };

export type MostCommondRejectReasons = {
  __typename?: 'MostCommondRejectReasons';
  count: Scalars['Float'];
  description: Scalars['String'];
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCandidate: Candidate;
  acceptCandidateCollaboration: Candidate;
  acceptLead?: Maybe<AcceptedLead>;
  archiveSearchAgreement?: Maybe<Scalars['String']>;
  cancelCollaboration: Scalars['Boolean'];
  cancelSearchAgreement?: Maybe<Scalars['String']>;
  candidateAcceptOffer: Candidate;
  createCandidate: Candidate;
  createCandidateDocument: Candidate;
  createCollaboration: Scalars['Boolean'];
  createCompany?: Maybe<Company>;
  createEmployerInvitation?: Maybe<EmployerInvitationByRecruiter>;
  createEtchPacketAndGenerateEtchSignURL?: Maybe<Scalars['String']>;
  createOrUpdateRoleRequirements: Scalars['Boolean'];
  createPublicDossier: Scalars['String'];
  createRecruiter: Recruiter;
  createRoleQuestion: RoleQuestion;
  createRoleRequirement: RoleRequirement;
  createRoleRequirements: Array<RoleRequirement>;
  createRoleTitle?: Maybe<RoleTitle>;
  createSearchAgreement?: Maybe<SearchAgreement>;
  createSearchReview: Scalars['Boolean'];
  deleteCandidateDocument: Scalars['Boolean'];
  deleteRecruiterPlacement: Scalars['Boolean'];
  deleteRoleRequirement: Scalars['Boolean'];
  deleteRoleRequirements: Scalars['Boolean'];
  inviteTeamMember: Scalars['String'];
  loadFixtures: Scalars['Boolean'];
  rejectCandidate: Candidate;
  rejectLead?: Maybe<Lead>;
  rejectPublicCandidate: Candidate;
  removeTeamMember: Scalars['Boolean'];
  sendSearchAgreementReminder: Scalars['Boolean'];
  setUserNotificationSetting: Scalars['Boolean'];
  unarchiveSearchAgreement?: Maybe<Scalars['String']>;
  updateCandidate: Candidate;
  updateCandidateDocuments: Candidate;
  updateCandidateRoleRequirements: Scalars['Boolean'];
  updateMarketplaceRoleStatus?: Maybe<MarketplaceRoleRecruiter>;
  updateOnboardingStatusToInReview: Recruiter;
  updateOrCreateRecruiterPlacement?: Maybe<Recruiter>;
  updatePublicCandidate: Candidate;
  updateRecruiter?: Maybe<Recruiter>;
  updateRecruiterProfile?: Maybe<Recruiter>;
  updateRecruiterProfileBio?: Maybe<Recruiter>;
  updateRecruiterProfileEndorsements?: Maybe<Recruiter>;
  updateRecruiterTimeToPlacement?: Maybe<Recruiter>;
  updateRoleRequirement: RoleRequirement;
  updateRoleRequirements: Array<RoleRequirement>;
  updateSearchAgreement?: Maybe<SearchAgreement>;
  updateSearchAgreementAfterRecruiterSignature?: Maybe<Scalars['String']>;
  updateUser: User;
  updateUserTermsAndPolicy: User;
  voidSearchAgreement?: Maybe<Scalars['String']>;
};

export type MutationAcceptCandidateArgs = {
  input: RecruiterAcceptCandidateInput;
};

export type MutationAcceptCandidateCollaborationArgs = {
  input: RecruiterAcceptCandidateInput;
};

export type MutationAcceptLeadArgs = {
  id: Scalars['String'];
};

export type MutationArchiveSearchAgreementArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationCancelCollaborationArgs = {
  collaborationId: Scalars['String'];
  roleId: Scalars['String'];
};

export type MutationCancelSearchAgreementArgs = {
  input: RecruiterTerminatedSearchAgreementInput;
};

export type MutationCandidateAcceptOfferArgs = {
  input: CandidateAcceptOfferInput;
};

export type MutationCreateCandidateArgs = {
  input: CreateCandidateInput;
};

export type MutationCreateCandidateDocumentArgs = {
  input: CreateCandidateDocumentInput;
};

export type MutationCreateCollaborationArgs = {
  input: CollaborationInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateEmployerInvitationArgs = {
  input: EmployerInvitationByRecruiterInput;
};

export type MutationCreateEtchPacketAndGenerateEtchSignUrlArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationCreateOrUpdateRoleRequirementsArgs = {
  input: UpdateRoleRequirementsInput;
};

export type MutationCreatePublicDossierArgs = {
  roleId: Scalars['String'];
};

export type MutationCreateRecruiterArgs = {
  input: CreateRecruiterInput;
};

export type MutationCreateRoleQuestionArgs = {
  input: CreateRoleQuestionInput;
};

export type MutationCreateRoleRequirementArgs = {
  input: CreateRoleRequirementInput;
};

export type MutationCreateRoleRequirementsArgs = {
  inputs: Array<CreateRoleRequirementInput>;
};

export type MutationCreateRoleTitleArgs = {
  roleName: Scalars['String'];
};

export type MutationCreateSearchAgreementArgs = {
  searchAgreementInput: CreateSearchAgreementRecruiterInput;
};

export type MutationCreateSearchReviewArgs = {
  input: RecruiterSearchReviewInput;
  searchAgreementRoleId: Scalars['String'];
};

export type MutationDeleteCandidateDocumentArgs = {
  documentId: Scalars['String'];
};

export type MutationDeleteRecruiterPlacementArgs = {
  placementId: Scalars['String'];
};

export type MutationDeleteRoleRequirementArgs = {
  roleRequirementId: Scalars['String'];
};

export type MutationDeleteRoleRequirementsArgs = {
  roleIds: Array<Scalars['String']>;
};

export type MutationInviteTeamMemberArgs = {
  data: InviteTeamMemberInput;
};

export type MutationRejectCandidateArgs = {
  input: RejectCandidateInput;
};

export type MutationRejectLeadArgs = {
  id: Scalars['String'];
};

export type MutationRejectPublicCandidateArgs = {
  input: RejectCandidateInput;
};

export type MutationRemoveTeamMemberArgs = {
  userId: Scalars['String'];
};

export type MutationSendSearchAgreementReminderArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationSetUserNotificationSettingArgs = {
  input: Array<SetUserNotificationSettingInput>;
};

export type MutationUnarchiveSearchAgreementArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationUpdateCandidateArgs = {
  input: UpdateCandidateInput;
};

export type MutationUpdateCandidateDocumentsArgs = {
  input: UpdateCandidateDocumentsInput;
};

export type MutationUpdateCandidateRoleRequirementsArgs = {
  inputs: Array<UpdateCandidateRoleRequirementInput>;
};

export type MutationUpdateMarketplaceRoleStatusArgs = {
  marketplaceVisibility: MarketplaceVisibilityEnum;
  roleId: Scalars['String'];
  status: MarketplaceRoleRecruiterStatus;
};

export type MutationUpdateOrCreateRecruiterPlacementArgs = {
  placement: UpdateRecruiterProfilePlacementInput;
};

export type MutationUpdatePublicCandidateArgs = {
  publicDossierId: Scalars['String'];
};

export type MutationUpdateRecruiterArgs = {
  data: UpdateRecruiterInput;
};

export type MutationUpdateRecruiterProfileArgs = {
  data: UpdateRecruiterProfileInput;
};

export type MutationUpdateRecruiterProfileBioArgs = {
  bio: Scalars['String'];
  bioJSON: Scalars['String'];
};

export type MutationUpdateRecruiterProfileEndorsementsArgs = {
  endorsements: Array<UpdateRecruiterProfileEndorsementsInput>;
};

export type MutationUpdateRecruiterTimeToPlacementArgs = {
  timeToPlacement: Scalars['Float'];
};

export type MutationUpdateRoleRequirementArgs = {
  input: UpdateRoleRequirementInput;
};

export type MutationUpdateRoleRequirementsArgs = {
  inputs: Array<UpdateRoleRequirementInput>;
};

export type MutationUpdateSearchAgreementArgs = {
  searchAgreementInput: UpdateSearchAgreementInput;
};

export type MutationUpdateSearchAgreementAfterRecruiterSignatureArgs = {
  input: UpdateSearchAgreementAfterRecruiterSignatureInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserTermsAndPolicyArgs = {
  data: UpdateUserTermsAndPolicyInput;
};

export type MutationVoidSearchAgreementArgs = {
  input: RecruiterTerminatedSearchAgreementInput;
};

export type NumberCandidates = {
  __typename?: 'NumberCandidates';
  current: Scalars['Float'];
  previous: Scalars['Float'];
};

export { OnboardingStatus };

export type Options = {
  __typename?: 'Options';
  label: Scalars['String'];
  value: Scalars['String'];
};

export { OutreachChannelEnum };

export type OutreachMessage = {
  __typename?: 'OutreachMessage';
  createdAt: Scalars['DateTime'];
  frontEmailMessage?: Maybe<FrontEmailMessage>;
  id: Scalars['ID'];
  message: Scalars['String'];
  outreachSequence: OutreachSequence;
  outreachSequenceId: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  type: OutreachMessageType;
};

export { OutreachMessageType };

export type OutreachSequence = {
  __typename?: 'OutreachSequence';
  assignee?: Maybe<ProspectAssigneeEnum>;
  candidateResponse?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  frontEmailMessages: Array<FrontEmailMessage>;
  id: Scalars['ID'];
  isBackfill: Scalars['Boolean'];
  lastFrontEmailMessage?: Maybe<FrontEmailMessage>;
  lastMessageSent?: Maybe<OutreachMessage>;
  lastScheduleMessage?: Maybe<OutreachMessage>;
  messageToSend?: Maybe<OutreachMessage>;
  messagesStage?: Maybe<OutreachMessageType>;
  outreachChannel: OutreachChannelEnum;
  outreachMessages: Array<OutreachMessage>;
  prospect: Prospect;
  prospectId: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  status: OutreachSequenceStatus;
};

export { OutreachSequenceStatus };

export type ParsedLinkedInProfile = {
  __typename?: 'ParsedLinkedInProfile';
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkedInProfile: LinkedInProfile;
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  workExperience?: Maybe<Array<ParsedWorkExpirienceType>>;
};

export type ParsedWorkExpirienceType = {
  __typename?: 'ParsedWorkExpirienceType';
  company: Company;
  roleTitle: Scalars['String'];
};

export type Payout = {
  __typename?: 'Payout';
  agency: Agency;
  amount: Scalars['Float'];
  amountWithoutAdjustment: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['DateTime']>;
  payoutType: PayoutTypeEnum;
  searchAgreement?: Maybe<SearchAgreement>;
};

export type PayoutOutput = {
  __typename?: 'PayoutOutput';
  agency: Agency;
  amount: Scalars['Float'];
  amountWithoutAdjustment: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  employerName: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['ID'];
  paidAmount: Scalars['Float'];
  paidAt?: Maybe<Scalars['DateTime']>;
  payoutType: PayoutTypeEnum;
  recruiterName?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  searchAgreement?: Maybe<SearchAgreement>;
  status: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export { PayoutTypeEnum };

export type Period = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type Placement = {
  __typename?: 'Placement';
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']>;
  company: Company;
  companyId: Scalars['String'];
  companyStage?: Maybe<CompanyStage>;
  companyStageId?: Maybe<Scalars['String']>;
  educationDegree: EducationDegree;
  educationDegreeId?: Maybe<Scalars['String']>;
  educationInstitution: EducationInstitution;
  educationInstitutionId?: Maybe<Scalars['String']>;
  educationMajor: EducationMajor;
  educationMajorId?: Maybe<Scalars['String']>;
  firstYearBaseSalary?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  linkedIn: Scalars['String'];
  placedAt: Scalars['DateTime'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  roleFunction: RoleFunction;
  roleFunctionId: Scalars['String'];
  roleSeniority: RoleSeniority;
  roleSeniorityId: Scalars['String'];
  roleTitle?: Maybe<RoleTitle>;
  roleTitleId?: Maybe<Scalars['String']>;
  tenureMonths?: Maybe<Scalars['Float']>;
  yoeMonth?: Maybe<Scalars['Float']>;
};

export type PlacementFeeOutput = {
  __typename?: 'PlacementFeeOutput';
  amount: Scalars['Float'];
  feeType: FeeScheduleTypeEnum;
};

export type PlacementGeographyGraphData = {
  __typename?: 'PlacementGeographyGraphData';
  cityCode: Scalars['ID'];
  cityName: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  region?: Maybe<Scalars['String']>;
  stateAbbrev: Scalars['String'];
  stateName: Scalars['String'];
  timezone?: Maybe<Timezone>;
  timezoneId?: Maybe<Scalars['String']>;
};

export type PlacementsByRangeDateOutput = {
  __typename?: 'PlacementsByRangeDateOutput';
  candidateCount: Scalars['Float'];
  showEmptyState: Scalars['Boolean'];
};

export type PotentialEarningOutput = {
  __typename?: 'PotentialEarningOutput';
  date: Scalars['String'];
  employerName: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['String'];
  lowerEarning?: Maybe<Scalars['Float']>;
  roleName?: Maybe<Scalars['String']>;
  searchAgreementId: Scalars['String'];
  upperEarning: Scalars['Float'];
};

export type PotentialSearchValueOutput = {
  __typename?: 'PotentialSearchValueOutput';
  maxPotentialSearchValue: Scalars['Float'];
  minPotentialSearchValue: Scalars['Float'];
  showEmptyState: Scalars['Boolean'];
};

export { PricingTypeEnum };

export type ProfileOnboardingStep = {
  __typename?: 'ProfileOnboardingStep';
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Prospect = {
  __typename?: 'Prospect';
  averageRoleRequirementsScore: Scalars['Float'];
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailEnrichmentStatus: EnrichmentProspectStatus;
  evaluationScore?: Maybe<EvaluationStatus>;
  id: Scalars['ID'];
  isQualified?: Maybe<Scalars['Boolean']>;
  linkedInProfile: LinkedInProfileRaw;
  manualDisqualifyOverride: Scalars['Boolean'];
  matchPercentage: Scalars['Float'];
  outreachSequence?: Maybe<OutreachSequence>;
  outreachSequenceExists: Scalars['Boolean'];
  phoneNumberEnrichmentStatus: EnrichmentProspectStatus;
  prospectPhoneNumbers?: Maybe<Array<ProspectPhoneNumber>>;
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  prospectSearch: ProspectSearch;
  prospectSearchId: Scalars['String'];
  publicIdentifier: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  roleMeetRequirementsCount: Scalars['Float'];
  roleRequirementsEvaluatedCount: Scalars['Float'];
  rrMustHaveFailingCount: Scalars['Float'];
  rrPassingPercent: Scalars['Float'];
  score: Scalars['Float'];
  source: Scalars['String'];
  status: ProspectStatus;
};

export { ProspectAssigneeEnum };

export type ProspectPhoneNumber = {
  __typename?: 'ProspectPhoneNumber';
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  prospect?: Maybe<Prospect>;
  prospectId?: Maybe<Scalars['String']>;
};

export type ProspectRoleRequirement = {
  __typename?: 'ProspectRoleRequirement';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  llmEvaluatorType: Scalars['String'];
  meetRequirement?: Maybe<Scalars['String']>;
  meetRequirementExplanation?: Maybe<Scalars['String']>;
  parentWorkflowId: Scalars['String'];
  prospect: Prospect;
  prospectId: Scalars['String'];
  roleRequirement?: Maybe<RoleRequirement>;
  roleRequirementId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workflowId?: Maybe<Scalars['String']>;
};

export type ProspectSearch = {
  __typename?: 'ProspectSearch';
  continuationScore?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastExpansionTime?: Maybe<Scalars['DateTime']>;
  lastKLimit?: Maybe<Scalars['Float']>;
  lastSearchDuplicateCount?: Maybe<Scalars['Float']>;
  lastSearchNewCount?: Maybe<Scalars['Float']>;
  linkedInPublicIdentifier?: Maybe<Scalars['String']>;
  parentProspectSearchId?: Maybe<Scalars['String']>;
  prospects: Array<Prospect>;
  prospectsInterestedCount: Scalars['Float'];
  qualifiedProspectPercentage: Scalars['Float'];
  qualifiedProspectsCount: Scalars['Float'];
  query?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
  searchFilters: SearchFilters;
  searchType: ProspectSearchType;
  showQualifiedProspectsCreatedBefore?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export { ProspectSearchType };

export { ProspectStatus };

export type PublicDossier = {
  __typename?: 'PublicDossier';
  candidate?: Maybe<Candidate>;
  dossierURL: Scalars['String'];
  id: Scalars['ID'];
  prospect?: Maybe<Prospect>;
  role: Role;
  shortCode: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  acceptanceRateMetrics: AcceptanceRateMetricsOutput;
  agencyEarnings: Array<AgencyEarning>;
  agencyLastYearEarnings: Array<AgencyMonthlyEarning>;
  agencyTransactions?: Maybe<Array<PayoutOutput>>;
  allowRecruiterVerifiedInterest: Scalars['Boolean'];
  apiVersion: Scalars['String'];
  availableEmployers: Array<Employer>;
  availableRoles?: Maybe<Array<Role>>;
  availableRolesByEmployer?: Maybe<Array<Role>>;
  calculateTimeToPlacementByRecruiter: Scalars['Float'];
  candidateActivityHistories: CandidateActivityHistoryOutput;
  candidateById: CandidateDetailsOutput;
  candidateMarketplaceMetrics: CandidateMarketPlaceMetricsOutput;
  candidateMetrics: CandidateStatusMetricsOutput;
  candidates: CandidatesByAgency;
  candidatesForRole: CandidateWithMetrics;
  checkStatusOrGenerateAccountLinkURL?: Maybe<StripeStatus>;
  cities?: Maybe<Array<City>>;
  clientCompanyStages: Array<ClientCompanyStage>;
  companies: Array<Company>;
  countActiveRoles: Scalars['Int'];
  countSignedSearchAgreements: EarningsMetricsOutput;
  createMediaUploadLink: MediaUploadLink;
  currentRecruiter?: Maybe<CurrentRecruiter>;
  currentRecruiterTeam?: Maybe<Array<Recruiter>>;
  employeesByEmployer: Array<Employee>;
  employerInvitations: Array<EmployerInvitationByRecruiter>;
  endorsementsByRecruiter: Array<Endorsement>;
  getAnvilSignaturedFiles?: Maybe<DownloadDocumentsResultOutput>;
  getTimeZone?: Maybe<Array<Timezone>>;
  getUser?: Maybe<User>;
  hasArchivedSearchAgreements: Scalars['Boolean'];
  hasFirstInvoicePaid: Scalars['Boolean'];
  industries: Array<Industry>;
  invoicePdfUrl?: Maybe<Scalars['String']>;
  latestMarketplaceRoles: Array<MarketplaceRoleOutput>;
  leadById?: Maybe<LeadOutput>;
  leadsByRecruiterAgency: Array<DetailedLead>;
  listUserNotificationSetting: Array<UserNotificationSetting>;
  marketplaceRoles: Array<MarketplaceRoleWithStatus>;
  payoutsByAgency: Scalars['Float'];
  placementAverageTenure: Scalars['Float'];
  placementCompanies: Array<Company>;
  placementsByRangeDate: PlacementsByRangeDateOutput;
  placementsByRecruiter: Array<Placement>;
  potentialEarnings: Array<PotentialEarningOutput>;
  potentialSearchValue: PotentialSearchValueOutput;
  profileOnboardingSteps: Array<ProfileOnboardingStep>;
  publicDossier?: Maybe<PublicDossier>;
  recentActivity: Array<RecentActivityOutput>;
  recruiterById: Recruiter;
  recruiterCommonIndustries: Array<CommonIndustries>;
  recruiterCommonPlacedInstitutions: Array<CommonEducationInstitution>;
  recruiterFormOptions: RecruiterFormOptionsOutput;
  recruiterPlacementGeographies: Array<PlacementGeographyGraphData>;
  recruiterSearchAgreementById?: Maybe<SearchAgreement>;
  recruiterTopSeniority: Array<TopRoleSeniority>;
  recruiters: Array<Recruiter>;
  recruitersByAgency: Array<Recruiter>;
  rejectReasons: Array<CandidateActivityHistory>;
  reviewsByRecruiter: ReviewWithAverage;
  roleById?: Maybe<RecruiterRoleWithStatus>;
  roleCandidateSubmissionLimit?: Maybe<Scalars['Int']>;
  roleDossier: RoleDossierOutput;
  roleFunctions?: Maybe<Array<RoleFunction>>;
  roleFunctionsAndCities?: Maybe<RoleFunctionsAndCitiesOutput>;
  roleQuestions: Array<RoleQuestion>;
  roleRequirementTemplates: Array<RoleRequirementTemplate>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniorities?: Maybe<Array<RoleSeniority>>;
  roles: Array<RecruiterRoleWithStatus>;
  searchAgreementByRecruiterAgency?: Maybe<Array<SearchAgreement>>;
  searchArchivedAgreementByRecruiter?: Maybe<Array<SearchAgreement>>;
  sendReportRecruiterReviewEmail: Scalars['Boolean'];
  successfulPlacementsByRecruiter: Scalars['Float'];
  topClientsByPlacement: Array<RankingOutput>;
  topClientsByRevenue: Array<RankingOutput>;
  topRecruiterBySubmittedCandidate: TopRecruitersBySubmittedCandidateOutput;
  topRecruitersByRevenue: Array<RankingOutput>;
  topRoleFunctions: Array<TopRoleFunctions>;
  upcomingEarnings?: Maybe<Array<UpcomingEarningsOutput>>;
};

export type QueryAgencyEarningsArgs = {
  input: AgencyEarningsInput;
};

export type QueryAgencyLastYearEarningsArgs = {
  paymentStatus: Scalars['String'];
};

export type QueryAgencyTransactionsArgs = {
  rangeDate: RangeDateInput;
};

export type QueryAvailableRolesArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
};

export type QueryAvailableRolesByEmployerArgs = {
  employerId: Scalars['String'];
};

export type QueryCalculateTimeToPlacementByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCandidateActivityHistoriesArgs = {
  filter: CandidateActivityHistoryFilter;
  order: Scalars['String'];
};

export type QueryCandidateByIdArgs = {
  id: Scalars['String'];
};

export type QueryCandidateMarketplaceMetricsArgs = {
  id: Scalars['String'];
};

export type QueryCandidatesForRoleArgs = {
  id: Scalars['String'];
};

export type QueryClientCompanyStagesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCountSignedSearchAgreementsArgs = {
  input: RangeDateInput;
};

export type QueryCreateMediaUploadLinkArgs = {
  data: CreateMediaUploadLinkInput;
};

export type QueryEmployeesByEmployerArgs = {
  employerId: Scalars['String'];
};

export type QueryEndorsementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryGetAnvilSignaturedFilesArgs = {
  searchAgreementId: Scalars['String'];
};

export type QueryInvoicePdfUrlArgs = {
  invoiceId: Scalars['String'];
};

export type QueryLeadByIdArgs = {
  id: Scalars['String'];
};

export type QueryPayoutsByAgencyArgs = {
  input: RangeDateInput;
};

export type QueryPlacementAverageTenureArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPlacementCompaniesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPlacementsByRangeDateArgs = {
  input: RangeDateInput;
};

export type QueryPlacementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPublicDossierArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterByIdArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterCommonIndustriesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterCommonPlacedInstitutionsArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterPlacementGeographiesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterSearchAgreementByIdArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterTopSeniorityArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRejectReasonsArgs = {
  roleId: Scalars['String'];
};

export type QueryReviewsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRoleByIdArgs = {
  id: Scalars['String'];
};

export type QueryRoleCandidateSubmissionLimitArgs = {
  roleId: Scalars['String'];
};

export type QueryRoleDossierArgs = {
  roleId: Scalars['String'];
};

export type QueryRoleQuestionsArgs = {
  filter: RoleQuestionFilter;
};

export type QueryRoleRequirementTemplatesArgs = {
  filter: RoleRequirementTemplateFilter;
};

export type QueryRoleRequirementsArgs = {
  filter: RoleRequirementFilter;
};

export type QuerySearchAgreementByRecruiterAgencyArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchArchivedAgreementByRecruiterArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
};

export type QuerySendReportRecruiterReviewEmailArgs = {
  input: SendReportRecruiterReviewInput;
};

export type QuerySuccessfulPlacementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryTopClientsByPlacementArgs = {
  rangeDate: RangeDateInput;
};

export type QueryTopClientsByRevenueArgs = {
  rangeDate: RangeDateInput;
};

export type QueryTopRecruiterBySubmittedCandidateArgs = {
  input: RangeDateInput;
};

export type QueryTopRecruitersByRevenueArgs = {
  rangeDate: RangeDateInput;
};

export type QueryTopRoleFunctionsArgs = {
  recruiterId: Scalars['String'];
};

export type RangeDateInput = {
  currentPeriod: Period;
  previousPeriod?: InputMaybe<Period>;
};

export type RankingOutput = {
  __typename?: 'RankingOutput';
  hasData?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RecentActivityOutput = {
  __typename?: 'RecentActivityOutput';
  actualSalaryRange?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<Scalars['String']>;
  candidateActivity?: Maybe<CandidateActivityHistoryActivityType>;
  candidateId?: Maybe<Scalars['String']>;
  candidateName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  employerName: Scalars['String'];
  id: Scalars['String'];
  oldSalaryRange?: Maybe<Scalars['String']>;
  pausedBy?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  rolePrice?: Maybe<PlacementFeeOutput>;
  roleType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type RecommendedRecruiter = {
  __typename?: 'RecommendedRecruiter';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInvitedToMarketplaceRole: Scalars['Boolean'];
  isPrimaryAgency: Scalars['Boolean'];
  isRequested: Scalars['Boolean'];
  isRequestedToDirect: Scalars['Boolean'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Recruiter = {
  __typename?: 'Recruiter';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showProfile: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type RecruiterAcceptCandidateInput = {
  candidateId: Scalars['String'];
};

export type RecruiterFormOptionsOutput = {
  __typename?: 'RecruiterFormOptionsOutput';
  cityOptions: Array<Options>;
  companyStageOptions: Array<Options>;
  industryOptions: Array<Options>;
  roleFunctionOptions: Array<Options>;
  roleSeniorityOptions: Array<Options>;
  roleTitleOptions: Array<Options>;
};

export type RecruiterInfo = {
  __typename?: 'RecruiterInfo';
  name: Scalars['String'];
  number: Scalars['Float'];
};

export type RecruiterRoleWithStatus = {
  __typename?: 'RecruiterRoleWithStatus';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  agencyName?: Maybe<Scalars['String']>;
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateCount: Scalars['Float'];
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  closedSearchProduct?: Maybe<ClosedSearchProduct>;
  collaboration?: Maybe<Collaboration>;
  collaborationAgencyName?: Maybe<Scalars['String']>;
  collaborationRecruiterName?: Maybe<Scalars['String']>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hasReview: Scalars['Boolean'];
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isCollaborationRecruiter?: Maybe<Scalars['Boolean']>;
  isDossierShareable: Scalars['Boolean'];
  isPrimaryAgency?: Maybe<Scalars['Boolean']>;
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mostCommonRejectReasons?: Maybe<Array<MostCommondRejectReasons>>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  primarySearchAgreement?: Maybe<SearchAgreement>;
  primarySearchAgreementEndAt?: Maybe<Scalars['DateTime']>;
  primarySearchAgreementFeeTriggerType?: Maybe<Scalars['String']>;
  primarySearchAgreementId?: Maybe<Scalars['String']>;
  primarySearchAgreementMarketplaceRolePrice?: Maybe<PlacementFeeOutput>;
  primarySearchAgreementRoleId?: Maybe<Scalars['String']>;
  primarySearchAgreementStatus: Scalars['String'];
  primarySearchAgreementVisibility: MarketplaceVisibilityEnum;
  prospectSearches: Array<ProspectSearch>;
  publicDossierUrl?: Maybe<Scalars['String']>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  recruiterName: Scalars['String'];
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  showRequirementsBillboard?: Maybe<Scalars['Boolean']>;
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export { RecruiterSearchAgreementActivityCategory };

export type RecruiterSearchReviewInput = {
  communication: Scalars['Float'];
  employerId: Scalars['String'];
  overallRating: Scalars['Float'];
  responsiveness: Scalars['Float'];
  review: Scalars['String'];
  timeliness: Scalars['Float'];
};

export type RecruiterTerminatedSearchAgreementInput = {
  reasonCategory: RecruiterSearchAgreementActivityCategory;
  reasonDetails: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type RejectCandidateInput = {
  candidateId: Scalars['String'];
  rejectFeedback?: InputMaybe<Scalars['String']>;
  rejectReason?: InputMaybe<CandidateRejectedReasonEnum>;
  shareRejectionInfo?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<CandidateActivityHistoryActivityType>;
};

export { RemoteWorkPolicyEnum };

export type ReviewAverages = {
  __typename?: 'ReviewAverages';
  candidateSubmissionQualityAverage: Scalars['Float'];
  comunicationAverage: Scalars['Float'];
  overallRatingAverage: Scalars['Float'];
  timeToPlacementAverage: Scalars['Float'];
  timelessAverage: Scalars['Float'];
};

export type ReviewWithAverage = {
  __typename?: 'ReviewWithAverage';
  reviewAverages: ReviewAverages;
  searchReviews: Array<SearchAgreementReview>;
};

export type Role = {
  __typename?: 'Role';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type RoleAgencyAllowList = {
  __typename?: 'RoleAgencyAllowList';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
};

export type RoleCollaborator = {
  __typename?: 'RoleCollaborator';
  collaboratorType: RoleCollaboratorTypeEnum;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
  roleId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export { RoleCollaboratorTypeEnum };

export type RoleDossierOutput = {
  __typename?: 'RoleDossierOutput';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  marketplaceVisibility?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  placementFee?: Maybe<PlacementFeeOutput>;
  prospectSearches: Array<ProspectSearch>;
  publicDossierUrl?: Maybe<Scalars['String']>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  searchStatus?: Maybe<Scalars['String']>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RoleFunction = {
  __typename?: 'RoleFunction';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  roles: Array<Role>;
};

export type RoleFunctionsAndCitiesOutput = {
  __typename?: 'RoleFunctionsAndCitiesOutput';
  cities: Array<City>;
  roleFunctions: Array<RoleFunction>;
};

export type RoleQuestion = {
  __typename?: 'RoleQuestion';
  answer?: Maybe<Scalars['String']>;
  answerer?: Maybe<Employee>;
  answererId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  question: Scalars['String'];
  status: RoleQuestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleQuestionFilter = {
  roleId: Scalars['String'];
};

export { RoleQuestionStatus };

export type RoleRequirement = {
  __typename?: 'RoleRequirement';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceCandidateReview: Scalars['Boolean'];
  id: Scalars['ID'];
  isCritical: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleRequirementFilter = {
  roleId: Scalars['String'];
};

export type RoleRequirementInput = {
  id: Scalars['String'];
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
};

export type RoleRequirementTemplate = {
  __typename?: 'RoleRequirementTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  template: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RoleRequirementTemplateFilter = {
  roleFunctionId: Scalars['String'];
};

export type RoleSeniority = {
  __typename?: 'RoleSeniority';
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  placements: Array<Placement>;
  roles: Array<Role>;
};

export type RoleTitle = {
  __typename?: 'RoleTitle';
  createdByRecruiter?: Maybe<Recruiter>;
  createdByRecruiterId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  needsReview: Scalars['Boolean'];
  placements: Array<Placement>;
  roles: Array<Role>;
};

export { RoleTypeEnum };

export type SearchAgreement = {
  __typename?: 'SearchAgreement';
  addendums: Array<SearchAgreementAddendum>;
  agency?: Maybe<Agency>;
  anvilDocumentGroupEid?: Maybe<Scalars['String']>;
  archivedStatus: Scalars['String'];
  beginAt?: Maybe<Scalars['DateTime']>;
  canceledByEmployee?: Maybe<Employee>;
  canceledByRecruiter?: Maybe<Recruiter>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByEmployerId?: Maybe<Scalars['String']>;
  deletedByRecruiterId?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  employeeCanceledAt?: Maybe<Scalars['DateTime']>;
  employeeSignedAt?: Maybe<Scalars['DateTime']>;
  employeeVoidedAt?: Maybe<Scalars['DateTime']>;
  employer: Employer;
  endAt?: Maybe<Scalars['DateTime']>;
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates?: Maybe<Scalars['String']>;
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<SearchAgreementFeeSchedule>;
  feeType: Scalars['String'];
  fixedMarketplacePercentFee?: Maybe<Scalars['Float']>;
  fulfilledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  isPaused: Scalars['Boolean'];
  isProspect: Scalars['Boolean'];
  isSignedOffPlatform: Scalars['Boolean'];
  marketplacePercentFee: Scalars['Float'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  payouts: Array<Payout>;
  placementFeeSchedule?: Maybe<SearchAgreementFeeSchedule>;
  previousPriceChangeSearchAgreementId?: Maybe<Scalars['String']>;
  pricingType: PricingTypeEnum;
  recruiterCanceledAt?: Maybe<Scalars['DateTime']>;
  recruiterSignedAt?: Maybe<Scalars['DateTime']>;
  recruiterVoidedAt?: Maybe<Scalars['DateTime']>;
  replacementSearchNotificationWindowDays?: Maybe<Scalars['Int']>;
  replacementSearchTerminateDays?: Maybe<Scalars['Int']>;
  replacementSearchWindowMonths?: Maybe<Scalars['Int']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  signedByEmployee?: Maybe<Employee>;
  signedByEmployeeId?: Maybe<Scalars['String']>;
  signedByRecruiter?: Maybe<Recruiter>;
  signedByRecruiterId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  voidByEmployee: Employee;
  voidByRecruiter: Recruiter;
  whenFirstInvoice: Scalars['String'];
};

export type SearchAgreementAddendum = {
  __typename?: 'SearchAgreementAddendum';
  description: Scalars['String'];
  id: Scalars['ID'];
  searchAgreement: SearchAgreement;
  searchAgreementId: Scalars['String'];
};

export type SearchAgreementFeeSchedule = {
  __typename?: 'SearchAgreementFeeSchedule';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  feeAdjustmentType?: Maybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: Maybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  id: Scalars['ID'];
  oneTimeFeeRelativeSigningDays?: Maybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: Maybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: Maybe<Scalars['Int']>;
  searchAgreement: SearchAgreement;
  updatedAt: Scalars['DateTime'];
};

export type SearchAgreementReview = {
  __typename?: 'SearchAgreementReview';
  candidateSubmissionQuality?: Maybe<Scalars['Float']>;
  communication: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  employer?: Maybe<Employer>;
  id: Scalars['ID'];
  overallRating: Scalars['Float'];
  recruiter?: Maybe<Recruiter>;
  recruiterId?: Maybe<Scalars['String']>;
  responsiveness?: Maybe<Scalars['Float']>;
  review?: Maybe<Scalars['String']>;
  reviewedByEmployee?: Maybe<Employee>;
  reviewedByEmployeeId?: Maybe<Scalars['String']>;
  reviewedByRecruiter?: Maybe<Recruiter>;
  reviewedByRecruiterId?: Maybe<Scalars['String']>;
  searchAgreementRole: SearchAgreementRole;
  timeToPlacement?: Maybe<Scalars['Float']>;
  timeliness: Scalars['Float'];
};

export type SearchAgreementRole = {
  __typename?: 'SearchAgreementRole';
  candidates: Array<Candidate>;
  collaborations: Array<Collaboration>;
  countActiveRecruiters: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
  roleId: Scalars['String'];
  searchAgreement: SearchAgreement;
  searchAgreementId: Scalars['String'];
  searchReviews: Array<SearchAgreementReview>;
  updatedAt: Scalars['DateTime'];
};

export type SearchAgreementRoleInput = {
  added?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  role: CreateRoleInput;
};

export type SearchFilters = {
  __typename?: 'SearchFilters';
  must: Array<Scalars['JSON']>;
  must_not: Array<Scalars['JSON']>;
  should: Array<Scalars['JSON']>;
};

export type SearchInfo = {
  __typename?: 'SearchInfo';
  feeType?: Maybe<FeeScheduleTypeEnum>;
  id: Scalars['String'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  placementFee?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export { SearchPreferenceEnum };

export type SendReportRecruiterReviewInput = {
  employerName: Scalars['String'];
  reason: Scalars['String'];
  recruiterId: Scalars['String'];
  recruiterName: Scalars['String'];
  review: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewId: Scalars['String'];
};

export type SetUserNotificationSettingInput = {
  channel: UserNotificationChannel;
  enabled: Scalars['Boolean'];
  type: UserNotificationEnum;
};

export type StripeStatus = {
  __typename?: 'StripeStatus';
  check: Scalars['Boolean'];
  url: Scalars['String'];
};

export type Talent = {
  __typename?: 'Talent';
  agency?: Maybe<Agency>;
  candidates: Array<Candidate>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  linkedInProfile?: Maybe<LinkedInProfile>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Timezone = {
  __typename?: 'Timezone';
  cities: Array<City>;
  id: Scalars['ID'];
  recruiters: Array<Recruiter>;
  timezoneAbbrev: Scalars['String'];
  timezoneName: Scalars['String'];
  timezoneOffset: Scalars['String'];
};

export type TopRecruitersBySubmittedCandidateOutput = {
  __typename?: 'TopRecruitersBySubmittedCandidateOutput';
  candidates?: Maybe<NumberCandidates>;
  current: Scalars['Float'];
  hasData: Scalars['Boolean'];
  previous?: Maybe<Scalars['Float']>;
  recruiters?: Maybe<Array<RecruiterInfo>>;
};

export type TopRoleFunctions = {
  __typename?: 'TopRoleFunctions';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  roles: Array<Role>;
};

export type TopRoleSeniority = {
  __typename?: 'TopRoleSeniority';
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  roles: Array<Role>;
};

export type UpcomingEarningsOutput = {
  __typename?: 'UpcomingEarningsOutput';
  amount: Scalars['Float'];
  date: Scalars['String'];
  employerName: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['String'];
  recruiterName: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type UpdateCandidateDocumentsInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  documents: Array<Document>;
};

export type UpdateCandidateInput = {
  baseSalary?: InputMaybe<Scalars['Float']>;
  candidateId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  justification?: InputMaybe<Scalars['String']>;
  justificationJSON?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shareCount?: InputMaybe<Scalars['Float']>;
  skipScheduleConfirmation?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type UpdateCandidateRoleRequirementInput = {
  id: Scalars['String'];
  meetRequirement: MeetRequirementEnum;
};

export type UpdateRecruiterInput = {
  agencyName?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  bioJSON?: InputMaybe<Scalars['String']>;
  cities?: InputMaybe<Array<Scalars['String']>>;
  linkedIn?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  maxPricePercent?: InputMaybe<Scalars['Int']>;
  minPricePercent?: InputMaybe<Scalars['Int']>;
  roleFunctions?: InputMaybe<Array<Scalars['String']>>;
  schedulingLink15Min?: InputMaybe<Scalars['String']>;
  schedulingLink30Min?: InputMaybe<Scalars['String']>;
  schedulingLink60Min?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRecruiterProfileEndorsementsInput = {
  endorsement: Scalars['String'];
  endorserCompany: Scalars['String'];
  endorserTitle: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Float'];
};

export type UpdateRecruiterProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  bioJSON?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  maxPricePercent?: InputMaybe<Scalars['Int']>;
  minPricePercent?: InputMaybe<Scalars['Int']>;
  photo?: InputMaybe<Scalars['String']>;
  roleFunctions?: InputMaybe<Array<Scalars['String']>>;
  searchPreference?: InputMaybe<SearchPreferenceEnum>;
  timeToPlacement?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRecruiterProfilePlacementInput = {
  cityId: Scalars['String'];
  companyId: Scalars['String'];
  companyStageId: Scalars['String'];
  firstYearBaseSalary?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  linkedIn: Scalars['String'];
  placedAt: Scalars['DateTime'];
  roleFunctionId: Scalars['String'];
  roleSeniorityId: Scalars['String'];
  roleTitleId: Scalars['String'];
};

export type UpdateRoleRequirementInput = {
  id: Scalars['String'];
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
  roleId: Scalars['String'];
};

export type UpdateRoleRequirementsInput = {
  deletedRequirementIds: Array<Scalars['String']>;
  roleId: Scalars['String'];
  roleRequirements: Array<RoleRequirementInput>;
};

export type UpdateSearchAgreementAfterRecruiterSignatureInput = {
  anvilDocumentGroupEid: Scalars['String'];
  anvilEmployeeSignerEid: Scalars['String'];
  anvilEtchPacketEid: Scalars['String'];
  anvilRecruiterSignerEid: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type UpdateSearchAgreementInput = {
  beginAt: Scalars['DateTime'];
  employerId: Scalars['String'];
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates: Scalars['String'];
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<FeeScheduleInput>;
  id: Scalars['String'];
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  replacementSearchNotificationWindowDays?: InputMaybe<Scalars['Int']>;
  replacementSearchTerminateDays?: InputMaybe<Scalars['Int']>;
  replacementSearchWindowMonths?: InputMaybe<Scalars['Int']>;
  roles: Array<SearchAgreementRoleInput>;
  signedByEmployeeId: Scalars['String'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
};

export type UpdateUserTermsAndPolicyInput = {
  entity?: InputMaybe<EntityTypeEnum>;
  tou: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  employee?: Maybe<Employee>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isHeadRaceAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  notificationSettings: Array<UserNotificationSetting>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoUrl: Scalars['String'];
  recruiter?: Maybe<Recruiter>;
  slackUserId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userConsents: Array<UserConsent>;
};

export type UserConsent = {
  __typename?: 'UserConsent';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: User;
  userAgent: Scalars['String'];
  userConsentType: Scalars['String'];
  userId: Scalars['String'];
  versionNumber: Scalars['String'];
};

export { UserNotificationChannel };

export { UserNotificationEnum };

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  channel: UserNotificationChannel;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  type: UserNotificationEnum;
  user: User;
};

export type AcceptCandidateCollaborationMutationVariables = Exact<{
  input: RecruiterAcceptCandidateInput;
}>;

export type AcceptCandidateCollaborationMutation = {
  __typename?: 'Mutation';
  acceptCandidateCollaboration: { __typename?: 'Candidate'; id: string };
};

export type AcceptCandidateMutationVariables = Exact<{
  input: RecruiterAcceptCandidateInput;
}>;

export type AcceptCandidateMutation = {
  __typename?: 'Mutation';
  acceptCandidate: { __typename?: 'Candidate'; id: string };
};

export type AcceptLeadMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type AcceptLeadMutation = {
  __typename?: 'Mutation';
  acceptLead?: {
    __typename?: 'AcceptedLead';
    searchAgreementCreatedId?: string | null;
    recruiterStatus: LeadStatusEnum;
    employerStatus?: LeadStatusEnum | null;
  } | null;
};

export type AcceptanceRateMetricsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AcceptanceRateMetricsQuery = {
  __typename?: 'Query';
  acceptanceRateMetrics: {
    __typename?: 'AcceptanceRateMetricsOutput';
    recruiterAcceptanceRate: number;
    quartileAcceptanceRate: number;
  };
};

export type AgencyEarningsQueryVariables = Exact<{
  input: AgencyEarningsInput;
}>;

export type AgencyEarningsQuery = {
  __typename?: 'Query';
  agencyEarnings: Array<{
    __typename?: 'AgencyEarning';
    id: string;
    placementRevenue: number;
    retainerRevenue: number;
  }>;
};

export type AgencyLastYearEarningsQueryVariables = Exact<{
  paymentStatus: Scalars['String'];
}>;

export type AgencyLastYearEarningsQuery = {
  __typename?: 'Query';
  agencyLastYearEarnings: Array<{
    __typename?: 'AgencyMonthlyEarning';
    month: string;
    placementEarnings: number;
    retainerEarnings: number;
    totalEarnings: number;
  }>;
};

export type AgencyTransactionsQueryVariables = Exact<{
  rangeDate: RangeDateInput;
}>;

export type AgencyTransactionsQuery = {
  __typename?: 'Query';
  agencyTransactions?: Array<{
    __typename?: 'PayoutOutput';
    id: string;
    status: string;
    feeType: string;
    payoutType: PayoutTypeEnum;
    paidAmount: number;
    paidAt?: Date | null;
    employerName: string;
    createdAt: Date;
    recruiterName?: string | null;
    stripeInvoiceId?: string | null;
    searchAgreement?: { __typename?: 'SearchAgreement'; id: string } | null;
    role?: { __typename?: 'Role'; id: string; name: string } | null;
  }> | null;
};

export type CandidatesQueryVariables = Exact<{ [key: string]: never }>;

export type CandidatesQuery = {
  __typename?: 'Query';
  candidates: {
    __typename?: 'CandidatesByAgency';
    recruitersByAgencyCount: number;
    candidateMetrics: {
      __typename?: 'CandidateMetrics';
      submittedCount: number;
      interviewingCount: number;
      hiredCount: number;
    };
    candidates: Array<{
      __typename?: 'CandidatesWithDates';
      id: string;
      status: CandidateActivityHistoryActivityType;
      justification?: string | null;
      updatedAt: Date;
      submitDate: Date;
      fee?: number | null;
      isCollaborationCandidate: boolean;
      isImported: boolean;
      recruiter?: {
        __typename?: 'Recruiter';
        id: string;
        agency: { __typename?: 'Agency'; name?: string | null };
        user: { __typename?: 'User'; name: string };
      } | null;
      talent: {
        __typename?: 'Talent';
        name: string;
        firstName: string;
        lastName: string;
        linkedIn: string;
        email?: string | null;
        phone?: string | null;
        createdAt: Date;
        linkedInProfile?: {
          __typename?: 'LinkedInProfile';
          agency: { __typename?: 'Agency'; name?: string | null };
          parsedLinkedInProfile?: {
            __typename?: 'ParsedLinkedInProfile';
            headline?: string | null;
            workExperience?: Array<{
              __typename?: 'ParsedWorkExpirienceType';
              roleTitle: string;
              company: { __typename?: 'Company'; name: string };
            }> | null;
          } | null;
        } | null;
      };
      candidateActivityHistories: Array<{
        __typename?: 'CandidateActivityHistory';
        activityMeta?: {
          __typename?: 'CandidateActivityHistoryActivityMetaType';
          rejectReasonCategory?: CandidateRejectedReasonEnum | null;
          shareRejectionInfo?: boolean | null;
        } | null;
      }>;
      roleRequirements?: Array<{
        __typename?: 'CandidateRoleRequirement';
        id: string;
        mustHave: boolean;
        prompt: string;
        meetRequirement?: MeetRequirementEnum | null;
      }> | null;
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        id: string;
        role: {
          __typename?: 'Role';
          id: string;
          name: string;
          roleType: RoleTypeEnum;
          hiringManager?: { __typename?: 'Employee'; id: string } | null;
          employer: {
            __typename?: 'Employer';
            id: string;
            company: { __typename?: 'Company'; name: string; logoUrl: string };
          };
        };
      };
      documents?: Array<{
        __typename?: 'CandidateDocument';
        id: string;
        documentPath?: string | null;
        documentType?: CandidateDocumentTypeEnum | null;
        name: string;
      }> | null;
    }>;
  };
};

export type AllowRecruiterVerifiedInterestQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllowRecruiterVerifiedInterestQuery = {
  __typename?: 'Query';
  allowRecruiterVerifiedInterest: boolean;
};

export type AnvilSignaturedFilesQueryVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type AnvilSignaturedFilesQuery = {
  __typename?: 'Query';
  getAnvilSignaturedFiles?: {
    __typename?: 'DownloadDocumentsResultOutput';
    data: string;
  } | null;
};

export type ArchiveSearchAgreementMutationVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type ArchiveSearchAgreementMutation = {
  __typename?: 'Mutation';
  archiveSearchAgreement?: string | null;
};

export type AvailableEmployersQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableEmployersQuery = {
  __typename?: 'Query';
  availableEmployers: Array<{
    __typename?: 'Employer';
    id: string;
    company: { __typename?: 'Company'; name: string };
  }>;
};

export type AvailableRolesByEmployerQueryVariables = Exact<{
  employerId: Scalars['String'];
}>;

export type AvailableRolesByEmployerQuery = {
  __typename?: 'Query';
  availableRolesByEmployer?: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    expectedFirstYearShareCount?: number | null;
    cities: Array<{ __typename?: 'City'; id: string; cityName: string }>;
  }> | null;
};

export type AvailableRolesQueryVariables = Exact<{
  maxCount?: InputMaybe<Scalars['Int']>;
}>;

export type AvailableRolesQuery = {
  __typename?: 'Query';
  availableRoles?: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    roleType: RoleTypeEnum;
    cities: Array<{ __typename?: 'City'; cityName: string }>;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
  }> | null;
};

export type CancelCollaborationMutationVariables = Exact<{
  roleId: Scalars['String'];
  collaborationId: Scalars['String'];
}>;

export type CancelCollaborationMutation = {
  __typename?: 'Mutation';
  cancelCollaboration: boolean;
};

export type CancelSearchAgreementMutationVariables = Exact<{
  input: RecruiterTerminatedSearchAgreementInput;
}>;

export type CancelSearchAgreementMutation = {
  __typename?: 'Mutation';
  cancelSearchAgreement?: string | null;
};

export type CandidateAcceptOfferMutationVariables = Exact<{
  input: CandidateAcceptOfferInput;
}>;

export type CandidateAcceptOfferMutation = {
  __typename?: 'Mutation';
  candidateAcceptOffer: { __typename?: 'Candidate'; id: string };
};

export type CandidateActivityHistoriesQueryVariables = Exact<{
  filter: CandidateActivityHistoryFilter;
  order: Scalars['String'];
}>;

export type CandidateActivityHistoriesQuery = {
  __typename?: 'Query';
  candidateActivityHistories: {
    __typename?: 'CandidateActivityHistoryOutput';
    candidateHistory: Array<{
      __typename?: 'CandidateActivityHistory';
      id: string;
      createdAt: Date;
      activityType: CandidateActivityHistoryActivityType;
      entityType: CandidateActivityHistoryEntityType;
      activityMeta?: {
        __typename?: 'CandidateActivityHistoryActivityMetaType';
        rejectReasonCategory?: CandidateRejectedReasonEnum | null;
        rejectReasonDetails?: string | null;
        shareRejectionInfo?: boolean | null;
      } | null;
      candidate: {
        __typename?: 'Candidate';
        talent: { __typename?: 'Talent'; firstName: string; lastName: string };
        recruiter?: {
          __typename?: 'Recruiter';
          id: string;
          agency: { __typename?: 'Agency'; name?: string | null };
          user: { __typename?: 'User'; name: string };
        } | null;
        collaboration?: {
          __typename?: 'Collaboration';
          primaryRecruiter: {
            __typename?: 'Recruiter';
            id: string;
            agency: { __typename?: 'Agency'; name?: string | null };
            user: { __typename?: 'User'; name: string };
          };
        } | null;
      };
    }>;
  };
};

export type CandidateByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CandidateByIdQuery = {
  __typename?: 'Query';
  candidateById: {
    __typename?: 'CandidateDetailsOutput';
    id: string;
    createdAt: Date;
    status: CandidateActivityHistoryActivityType;
    justification?: string | null;
    justificationJSON?: string | null;
    baseSalary?: number | null;
    shareCount?: number | null;
    isCollaborationRecruiter: boolean;
    isCollaborationCandidate?: boolean | null;
    feeSplitPercentage?: number | null;
    placementFee?: number | null;
    placementFeeType?: string | null;
    startDate?: Date | null;
    statusWithInterestVerification: string;
    roleRequirements?: Array<{
      __typename?: 'CandidateRoleRequirement';
      prompt: string;
      id: string;
      mustHave: boolean;
      meetRequirement?: MeetRequirementEnum | null;
    }> | null;
    talent: {
      __typename?: 'Talent';
      firstName: string;
      lastName: string;
      linkedIn: string;
      email?: string | null;
      phone?: string | null;
      name: string;
    };
    documents?: Array<{
      __typename?: 'CandidateDocument';
      name: string;
      documentType?: CandidateDocumentTypeEnum | null;
      documentPath?: string | null;
      createdAt: Date;
      id: string;
    }> | null;
    recruiter?: {
      __typename?: 'Recruiter';
      agency: { __typename?: 'Agency'; id: string; name?: string | null };
      user: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
        name: string;
        email: string;
      };
    } | null;
    searchAgreementRole: {
      __typename?: 'SearchAgreementRole';
      id: string;
      searchReviews: Array<{
        __typename?: 'SearchAgreementReview';
        reviewedByRecruiterId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description?: string | null;
        employerId: string;
        hiringManager?: { __typename?: 'Employee'; id: string } | null;
        employer: {
          __typename?: 'Employer';
          id: string;
          company: { __typename?: 'Company'; name: string; logoUrl: string };
        };
      };
      searchAgreement: {
        __typename?: 'SearchAgreement';
        id: string;
        marketplaceVisibility: MarketplaceVisibilityEnum;
        agency?: {
          __typename?: 'Agency';
          id: string;
          name?: string | null;
        } | null;
        feeSchedules: Array<{
          __typename?: 'SearchAgreementFeeSchedule';
          feeTriggerType: FeeTriggerTypeEnum;
          feeScheduleType: FeeScheduleTypeEnum;
          feeAmount: number;
        }>;
      };
    };
  };
};

export type CandidateMetricsQueryVariables = Exact<{ [key: string]: never }>;

export type CandidateMetricsQuery = {
  __typename?: 'Query';
  candidateMetrics: {
    __typename?: 'CandidateStatusMetricsOutput';
    interviewingCount: number;
    hiredCount: number;
    submittedCount: number;
  };
};

export type CandidateMarketplaceMetricsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CandidateMarketplaceMetricsQuery = {
  __typename?: 'Query';
  candidateMarketplaceMetrics: {
    __typename?: 'CandidateMarketPlaceMetricsOutput';
    inReviewCount: number;
    interviewingCount: number;
    hiredCount: number;
  };
};

export type PlacementsByRangeDateQueryVariables = Exact<{
  input: RangeDateInput;
}>;

export type PlacementsByRangeDateQuery = {
  __typename?: 'Query';
  placementsByRangeDate: {
    __typename?: 'PlacementsByRangeDateOutput';
    showEmptyState: boolean;
    candidateCount: number;
  };
};

export type CandidatesForRoleQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type CandidatesForRoleQuery = {
  __typename?: 'Query';
  candidatesForRole: {
    __typename?: 'CandidateWithMetrics';
    submissionAvailableDate?: string | null;
    candidates: Array<{
      __typename?: 'CandidateWithSearchAgreement';
      id: string;
      createdAt: Date;
      status: CandidateActivityHistoryActivityType;
      marketplaceVisibility: string;
      candidateInterestVerificationStatus: CandidateInterestVerificationType;
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        id: string;
        role: { __typename?: 'Role'; employerId: string };
        searchReviews: Array<{
          __typename?: 'SearchAgreementReview';
          reviewedByRecruiterId?: string | null;
        }>;
        searchAgreement: {
          __typename?: 'SearchAgreement';
          marketplaceVisibility: MarketplaceVisibilityEnum;
        };
      };
      talent: {
        __typename?: 'Talent';
        id: string;
        firstName: string;
        lastName: string;
        name: string;
        email?: string | null;
        linkedIn: string;
      };
      recruiter?: {
        __typename?: 'Recruiter';
        id: string;
        user: { __typename?: 'User'; name: string };
        agency: { __typename?: 'Agency'; id: string; name?: string | null };
      } | null;
    }>;
    candidateMetrics: {
      __typename?: 'CandidateMetrics';
      submittedCount: number;
      interviewingCount: number;
      hiredCount: number;
    };
    candidatesLast7Days?: Array<{
      __typename?: 'Candidate';
      createdAt: Date;
    }> | null;
    globalCandidateMetrics?: {
      __typename?: 'CandidateMetrics';
      submittedCount: number;
      interviewingCount: number;
      hiredCount: number;
    } | null;
  };
};

export type CompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type CompaniesQuery = {
  __typename?: 'Query';
  companies: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    companyStage?: {
      __typename?: 'CompanyStage';
      id: string;
      name: string;
    } | null;
  }>;
};

export type CountActiveRolesQueryVariables = Exact<{ [key: string]: never }>;

export type CountActiveRolesQuery = {
  __typename?: 'Query';
  countActiveRoles: number;
};

export type CountSignedSearchAgreementsQueryVariables = Exact<{
  input: RangeDateInput;
}>;

export type CountSignedSearchAgreementsQuery = {
  __typename?: 'Query';
  countSignedSearchAgreements: {
    __typename?: 'EarningsMetricsOutput';
    current: number;
    previous?: number | null;
    hasData: boolean;
  };
};

export type CreateCandidateDocumentMutationVariables = Exact<{
  input: CreateCandidateDocumentInput;
}>;

export type CreateCandidateDocumentMutation = {
  __typename?: 'Mutation';
  createCandidateDocument: { __typename?: 'Candidate'; id: string };
};

export type CreateCandidateMutationVariables = Exact<{
  input: CreateCandidateInput;
}>;

export type CreateCandidateMutation = {
  __typename?: 'Mutation';
  createCandidate: { __typename?: 'Candidate'; id: string };
};

export type CreateCollaborationMutationVariables = Exact<{
  input: CollaborationInput;
}>;

export type CreateCollaborationMutation = {
  __typename?: 'Mutation';
  createCollaboration: boolean;
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany?: {
    __typename?: 'Company';
    id: string;
    companyStageId?: string | null;
  } | null;
};

export type CreateEmployerInvitationMutationVariables = Exact<{
  input: EmployerInvitationByRecruiterInput;
}>;

export type CreateEmployerInvitationMutation = {
  __typename?: 'Mutation';
  createEmployerInvitation?: {
    __typename?: 'EmployerInvitationByRecruiter';
    id: string;
    employee: { __typename?: 'Employee'; id: string };
    employer: { __typename?: 'Employer'; id: string };
  } | null;
};

export type CreateMediaUploadLinkQueryVariables = Exact<{
  data: CreateMediaUploadLinkInput;
}>;

export type CreateMediaUploadLinkQuery = {
  __typename?: 'Query';
  createMediaUploadLink: {
    __typename?: 'MediaUploadLink';
    url: string;
    fields: {
      __typename?: 'MediaUploadLinkFields';
      Policy: string;
      X_Amz_Algorithm: string;
      X_Amz_Credential: string;
      X_Amz_Date: string;
      X_Amz_Signature: string;
      X_Amz_Security_Token?: string | null;
      bucket: string;
      key: string;
    };
  };
};

export type CreatePublicDossierMutationVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type CreatePublicDossierMutation = {
  __typename?: 'Mutation';
  createPublicDossier: string;
};

export type CreateRecruiterMutationVariables = Exact<{
  input: CreateRecruiterInput;
}>;

export type CreateRecruiterMutation = {
  __typename?: 'Mutation';
  createRecruiter: { __typename?: 'Recruiter'; id: string };
};

export type CreateRoleQuestionMutationVariables = Exact<{
  input: CreateRoleQuestionInput;
}>;

export type CreateRoleQuestionMutation = {
  __typename?: 'Mutation';
  createRoleQuestion: { __typename?: 'RoleQuestion'; id: string };
};

export type CreateRoleRequirementMutationVariables = Exact<{
  input: CreateRoleRequirementInput;
}>;

export type CreateRoleRequirementMutation = {
  __typename?: 'Mutation';
  createRoleRequirement: {
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    id: string;
    mustHave: boolean;
    prompt: string;
    updatedAt?: Date | null;
  };
};

export type CreateRoleRequirementsMutationVariables = Exact<{
  inputs: Array<CreateRoleRequirementInput> | CreateRoleRequirementInput;
}>;

export type CreateRoleRequirementsMutation = {
  __typename?: 'Mutation';
  createRoleRequirements: Array<{
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    id: string;
    mustHave: boolean;
    prompt: string;
    updatedAt?: Date | null;
  }>;
};

export type CreateRoleTitleMutationVariables = Exact<{
  roleName: Scalars['String'];
}>;

export type CreateRoleTitleMutation = {
  __typename?: 'Mutation';
  createRoleTitle?: { __typename?: 'RoleTitle'; id: string } | null;
};

export type CreateSearchAgreementMutationVariables = Exact<{
  searchAgreementInput: CreateSearchAgreementRecruiterInput;
}>;

export type CreateSearchAgreementMutation = {
  __typename?: 'Mutation';
  createSearchAgreement?: { __typename?: 'SearchAgreement'; id: string } | null;
};

export type CreateSearchReviewMutationVariables = Exact<{
  input: RecruiterSearchReviewInput;
  searchAgreementRoleId: Scalars['String'];
}>;

export type CreateSearchReviewMutation = {
  __typename?: 'Mutation';
  createSearchReview: boolean;
};

export type CurrentRecruiterQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentRecruiterQuery = {
  __typename?: 'Query';
  currentRecruiter?: {
    __typename?: 'CurrentRecruiter';
    id: string;
    bio?: string | null;
    bioJSON?: string | null;
    showMarketplace: boolean;
    showSearches: boolean;
    minPricePercent?: number | null;
    maxPricePercent?: number | null;
    schedulingLink15Min?: string | null;
    schedulingLink30Min?: string | null;
    schedulingLink60Min?: string | null;
    isMarketplaceRecruiter: boolean;
    linkedIn?: string | null;
    title?: string | null;
    onboardingStatus: OnboardingStatus;
    location?: { __typename?: 'City'; id: string; cityName: string } | null;
    cities: Array<{ __typename?: 'City'; id: string; cityName: string }>;
    roleFunctions: Array<{
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    }>;
    timezone?: {
      __typename?: 'Timezone';
      id: string;
      timezoneName: string;
      timezoneOffset: string;
    } | null;
    agency: {
      __typename?: 'Agency';
      id: string;
      name?: string | null;
      isTestAccount: boolean;
      createdAt: Date;
    };
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      name: string;
      phoneNumber?: string | null;
      photoUrl: string;
      isHeadRaceAdmin: boolean;
      email: string;
      userConsents: Array<{
        __typename?: 'UserConsent';
        id: string;
        versionNumber: string;
        userConsentType: string;
      }>;
    };
  } | null;
};

export type CurrentRecruiterTeamQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentRecruiterTeamQuery = {
  __typename?: 'Query';
  currentRecruiterTeam?: Array<{
    __typename?: 'Recruiter';
    id: string;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      email: string;
      name: string;
      photoUrl: string;
    };
  }> | null;
};

export type DeleteCandidateDocumentMutationVariables = Exact<{
  documentId: Scalars['String'];
}>;

export type DeleteCandidateDocumentMutation = {
  __typename?: 'Mutation';
  deleteCandidateDocument: boolean;
};

export type DeleteRecruiterPlacementMutationVariables = Exact<{
  placementId: Scalars['String'];
}>;

export type DeleteRecruiterPlacementMutation = {
  __typename?: 'Mutation';
  deleteRecruiterPlacement: boolean;
};

export type DeleteRoleRequirementMutationVariables = Exact<{
  roleRequirementId: Scalars['String'];
}>;

export type DeleteRoleRequirementMutation = {
  __typename?: 'Mutation';
  deleteRoleRequirement: boolean;
};

export type DeleteRoleRequirementsMutationVariables = Exact<{
  roleIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteRoleRequirementsMutation = {
  __typename?: 'Mutation';
  deleteRoleRequirements: boolean;
};

export type EmployeesByEmployerQueryVariables = Exact<{
  employerId: Scalars['String'];
}>;

export type EmployeesByEmployerQuery = {
  __typename?: 'Query';
  employeesByEmployer: Array<{
    __typename?: 'Employee';
    id: string;
    user: { __typename?: 'User'; firstName: string; lastName: string };
  }>;
};

export type EmployerInvitationsQueryVariables = Exact<{ [key: string]: never }>;

export type EmployerInvitationsQuery = {
  __typename?: 'Query';
  employerInvitations: Array<{
    __typename?: 'EmployerInvitationByRecruiter';
    id: string;
    status: InvitationStatus;
    employee: {
      __typename?: 'Employee';
      user: {
        __typename?: 'User';
        email: string;
        name: string;
        photoUrl: string;
      };
      employer: {
        __typename?: 'Employer';
        company: { __typename?: 'Company'; name: string };
      };
    };
  }>;
};

export type HasArchivedSearchAgreementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HasArchivedSearchAgreementsQuery = {
  __typename?: 'Query';
  hasArchivedSearchAgreements: boolean;
};

export type HasFirstInvoicePaidQueryVariables = Exact<{ [key: string]: never }>;

export type HasFirstInvoicePaidQuery = {
  __typename?: 'Query';
  hasFirstInvoicePaid: boolean;
};

export type InvoicePdfUrlQueryVariables = Exact<{
  invoiceId: Scalars['String'];
}>;

export type InvoicePdfUrlQuery = {
  __typename?: 'Query';
  invoicePdfUrl?: string | null;
};

export type LatestMarketplaceRolesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LatestMarketplaceRolesQuery = {
  __typename?: 'Query';
  latestMarketplaceRoles: Array<{
    __typename?: 'MarketplaceRoleOutput';
    id: string;
    name: string;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    fee: number;
    feeType?: FeeScheduleTypeEnum | null;
    marketplaceVisibility: MarketplaceVisibilityEnum;
    roleType: RoleTypeEnum;
    cities: Array<{ __typename?: 'City'; cityName: string }>;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
  }>;
};

export type LeadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type LeadByIdQuery = {
  __typename?: 'Query';
  leadById?: {
    __typename?: 'LeadOutput';
    id: string;
    createdAt: Date;
    displayStatus: string;
    recruiterStatus: LeadStatusEnum;
    employerStatus?: LeadStatusEnum | null;
    searchAgreementId?: string | null;
    placementFee?: number | null;
    placementFeeType?: string | null;
    searchAgreement?: {
      __typename?: 'SearchAgreement';
      id: string;
      createdAt: Date;
      exclusive: boolean;
      whenFirstInvoice: string;
      marketplacePercentFee: number;
      status: string;
      signedByRecruiter?: {
        __typename?: 'Recruiter';
        user: { __typename?: 'User'; name: string };
        agency: { __typename?: 'Agency'; name?: string | null };
      } | null;
    } | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description?: string | null;
      descriptionJSON?: string | null;
      createdAt: Date;
      quantity?: number | null;
      expectedFirstYearSalaryMin?: number | null;
      expectedFirstYearSalaryMax?: number | null;
      expectedFirstYearShareCount?: number | null;
      remoteWorkPolicy: RemoteWorkPolicyEnum;
      hiringManager?: {
        __typename?: 'Employee';
        id: string;
        user: { __typename?: 'User'; name: string };
      } | null;
      roleRequirements: Array<{
        __typename?: 'RoleRequirement';
        mustHave: boolean;
        id: string;
        prompt: string;
      }>;
      roleSeniority?: {
        __typename?: 'RoleSeniority';
        id: string;
        name: string;
      } | null;
      roleFunction?: {
        __typename?: 'RoleFunction';
        id: string;
        name: string;
      } | null;
      cities: Array<{
        __typename?: 'City';
        id: string;
        cityName: string;
        stateAbbrev: string;
      }>;
      employer: {
        __typename?: 'Employer';
        id: string;
        company: {
          __typename?: 'Company';
          name: string;
          logo?: string | null;
          logoUrl: string;
          linkedIn?: string | null;
          crunchbase?: string | null;
          website?: string | null;
          intro?: string | null;
          introJSON?: string | null;
          tagline?: string | null;
          overviewJSON?: string | null;
          revenue?: number | null;
          totalFunding?: number | null;
          yoyGrowth?: number | null;
          tam?: number | null;
          latestFundingRoundAmount?: number | null;
          latestFundingRoundType?: LatestFundingRoundTypeEnum | null;
          investors?: string | null;
          employeeCount?: string | null;
          teamMembers: Array<{
            __typename?: 'CompanyTeamMember';
            id: string;
            name: string;
            title: string;
            linkedin: string;
          }>;
          perks: Array<{
            __typename?: 'CompanyPerk';
            id: string;
            type: CompanyPerkEnum;
          }>;
          press: Array<{
            __typename?: 'CompanyPress';
            id: string;
            title: string;
            url: string;
          }>;
          companyStage?: {
            __typename?: 'CompanyStage';
            id: string;
            name: string;
          } | null;
        };
      };
      searchAgreementRoles: Array<{
        __typename?: 'SearchAgreementRole';
        searchAgreement: { __typename?: 'SearchAgreement'; id: string };
      }>;
    };
    collaboration?: {
      __typename?: 'Collaboration';
      status: CollaborationStatus;
      splitPercentage: number;
      primaryRecruiter: {
        __typename?: 'Recruiter';
        user: { __typename?: 'User'; name: string };
      };
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        searchAgreement: {
          __typename?: 'SearchAgreement';
          signedByRecruiter?: {
            __typename?: 'Recruiter';
            user: { __typename?: 'User'; name: string };
            agency: { __typename?: 'Agency'; name?: string | null };
          } | null;
        };
      };
      agency: { __typename?: 'Agency'; name?: string | null };
    } | null;
  } | null;
};

export type LeadsByRecruiterAgencyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LeadsByRecruiterAgencyQuery = {
  __typename?: 'Query';
  leadsByRecruiterAgency: Array<{
    __typename?: 'DetailedLead';
    id: string;
    createdAt: Date;
    recruiterStatus: LeadStatusEnum;
    employerStatus?: LeadStatusEnum | null;
    displayStatus: string;
    companyName?: string | null;
    searchAgreementId?: string | null;
    collaborationId?: string | null;
    role: { __typename?: 'Role'; name: string };
  }>;
};

export type LoadFixturesMutationVariables = Exact<{ [key: string]: never }>;

export type LoadFixturesMutation = {
  __typename?: 'Mutation';
  loadFixtures: boolean;
};

export type MarketplaceRolesQueryVariables = Exact<{ [key: string]: never }>;

export type MarketplaceRolesQuery = {
  __typename?: 'Query';
  marketplaceRoles: Array<{
    __typename?: 'MarketplaceRoleWithStatus';
    status: MarketplaceRoleRecruiterStatus;
    createdAt: Date;
    globalCandidateCount: number;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description?: string | null;
      expectedFirstYearSalaryMin?: number | null;
      expectedFirstYearSalaryMax?: number | null;
      remoteWorkPolicy: RemoteWorkPolicyEnum;
      roleFunction?: {
        __typename?: 'RoleFunction';
        id: string;
        name: string;
      } | null;
      roleSeniority?: { __typename?: 'RoleSeniority'; id: string } | null;
      employer: {
        __typename?: 'Employer';
        company: {
          __typename?: 'Company';
          name: string;
          logoUrl: string;
          companyStage?: {
            __typename?: 'CompanyStage';
            id: string;
            name: string;
          } | null;
        };
      };
      cities: Array<{
        __typename?: 'City';
        id: string;
        cityName: string;
        stateAbbrev: string;
      }>;
    };
    searchInfo: {
      __typename?: 'SearchInfo';
      id: string;
      placementFee?: number | null;
      marketplaceVisibility: MarketplaceVisibilityEnum;
      status: string;
      feeType?: FeeScheduleTypeEnum | null;
    };
  }>;
};

export type PayoutsByAgencyQueryVariables = Exact<{
  input: RangeDateInput;
}>;

export type PayoutsByAgencyQuery = {
  __typename?: 'Query';
  payoutsByAgency: number;
};

export type PotentialSearchValueQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PotentialSearchValueQuery = {
  __typename?: 'Query';
  potentialSearchValue: {
    __typename?: 'PotentialSearchValueOutput';
    minPotentialSearchValue: number;
    maxPotentialSearchValue: number;
    showEmptyState: boolean;
  };
};

export type PotentialEarningsQueryVariables = Exact<{ [key: string]: never }>;

export type PotentialEarningsQuery = {
  __typename?: 'Query';
  potentialEarnings: Array<{
    __typename?: 'PotentialEarningOutput';
    id: string;
    lowerEarning?: number | null;
    upperEarning: number;
    searchAgreementId: string;
    date: string;
    roleName?: string | null;
    employerName: string;
    feeType: string;
  }>;
};

export type PublicDossierQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PublicDossierQuery = {
  __typename?: 'Query';
  publicDossier?: {
    __typename?: 'PublicDossier';
    id: string;
    candidate?: {
      __typename?: 'Candidate';
      id: string;
      status: CandidateActivityHistoryActivityType;
      candidateInterestVerificationStatus: CandidateInterestVerificationType;
      recruiter?: {
        __typename?: 'Recruiter';
        id: string;
        title?: string | null;
        agency: { __typename?: 'Agency'; name?: string | null };
        user: {
          __typename?: 'User';
          name: string;
          email: string;
          photoUrl: string;
        };
      } | null;
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        searchAgreement: { __typename?: 'SearchAgreement'; status: string };
      };
    } | null;
    role: {
      __typename?: 'Role';
      name: string;
      createdAt: Date;
      quantity?: number | null;
      description?: string | null;
      descriptionJSON?: string | null;
      expectedFirstYearSalaryMin?: number | null;
      expectedFirstYearSalaryMax?: number | null;
      expectedFirstYearShareCount?: number | null;
      remoteWorkPolicy: RemoteWorkPolicyEnum;
      employer: {
        __typename?: 'Employer';
        company: {
          __typename?: 'Company';
          name: string;
          logo?: string | null;
          logoUrl: string;
          linkedIn?: string | null;
          crunchbase?: string | null;
          website?: string | null;
          intro?: string | null;
          introJSON?: string | null;
          tagline?: string | null;
          overviewJSON?: string | null;
          revenue?: number | null;
          totalFunding?: number | null;
          yoyGrowth?: number | null;
          tam?: number | null;
          latestFundingRoundAmount?: number | null;
          latestFundingRoundType?: LatestFundingRoundTypeEnum | null;
          investors?: string | null;
          employeeCount?: string | null;
          teamMembers: Array<{
            __typename?: 'CompanyTeamMember';
            id: string;
            name: string;
            title: string;
            linkedin: string;
          }>;
          perks: Array<{
            __typename?: 'CompanyPerk';
            id: string;
            type: CompanyPerkEnum;
          }>;
          press: Array<{
            __typename?: 'CompanyPress';
            id: string;
            title: string;
            url: string;
          }>;
          companyStage?: {
            __typename?: 'CompanyStage';
            id: string;
            name: string;
          } | null;
        };
      };
      roleSeniority?: {
        __typename?: 'RoleSeniority';
        id: string;
        name: string;
      } | null;
      roleFunction?: {
        __typename?: 'RoleFunction';
        id: string;
        name: string;
      } | null;
      cities: Array<{
        __typename?: 'City';
        id: string;
        cityName: string;
        stateAbbrev: string;
      }>;
      roleRequirements: Array<{
        __typename?: 'RoleRequirement';
        mustHave: boolean;
        id: string;
        prompt: string;
      }>;
    };
  } | null;
};

export type RecentActivityQueryVariables = Exact<{ [key: string]: never }>;

export type RecentActivityQuery = {
  __typename?: 'Query';
  recentActivity: Array<{
    __typename?: 'RecentActivityOutput';
    id: string;
    roleId: string;
    type: string;
    candidateName?: string | null;
    roleName: string;
    date: Date;
    candidateActivity?: CandidateActivityHistoryActivityType | null;
    canceledBy?: string | null;
    pausedBy?: string | null;
    actualSalaryRange?: string | null;
    oldSalaryRange?: string | null;
    roleType?: string | null;
    employerName: string;
    rolePrice?: {
      __typename?: 'PlacementFeeOutput';
      feeType: FeeScheduleTypeEnum;
      amount: number;
    } | null;
  }>;
};

export type RecruiterFormOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecruiterFormOptionsQuery = {
  __typename?: 'Query';
  recruiterFormOptions: {
    __typename?: 'RecruiterFormOptionsOutput';
    roleFunctionOptions: Array<{
      __typename?: 'Options';
      label: string;
      value: string;
    }>;
    roleTitleOptions: Array<{
      __typename?: 'Options';
      label: string;
      value: string;
    }>;
    roleSeniorityOptions: Array<{
      __typename?: 'Options';
      label: string;
      value: string;
    }>;
    cityOptions: Array<{
      __typename?: 'Options';
      label: string;
      value: string;
    }>;
    companyStageOptions: Array<{
      __typename?: 'Options';
      label: string;
      value: string;
    }>;
    industryOptions: Array<{
      __typename?: 'Options';
      label: string;
      value: string;
    }>;
  };
};

export type RecruitersQueryVariables = Exact<{ [key: string]: never }>;

export type RecruitersQuery = {
  __typename?: 'Query';
  recruiters: Array<{
    __typename?: 'Recruiter';
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      isHeadRaceAdmin: boolean;
      userConsents: Array<{
        __typename?: 'UserConsent';
        versionNumber: string;
      }>;
    };
    agency: { __typename?: 'Agency'; name?: string | null };
  }>;
};

export type RecruitersByAgencyQueryVariables = Exact<{ [key: string]: never }>;

export type RecruitersByAgencyQuery = {
  __typename?: 'Query';
  recruitersByAgency: Array<{
    __typename?: 'Recruiter';
    id: string;
    user: { __typename?: 'User'; name: string };
  }>;
};

export type RejectCandidateMutationVariables = Exact<{
  input: RejectCandidateInput;
}>;

export type RejectCandidateMutation = {
  __typename?: 'Mutation';
  rejectCandidate: { __typename?: 'Candidate'; id: string };
};

export type RejectLeadMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RejectLeadMutation = {
  __typename?: 'Mutation';
  rejectLead?: { __typename?: 'Lead'; id: string } | null;
};

export type RejectPublicCandidateMutationVariables = Exact<{
  input: RejectCandidateInput;
}>;

export type RejectPublicCandidateMutation = {
  __typename?: 'Mutation';
  rejectPublicCandidate: { __typename?: 'Candidate'; id: string };
};

export type RejectReasonsQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type RejectReasonsQuery = {
  __typename?: 'Query';
  rejectReasons: Array<{
    __typename?: 'CandidateActivityHistory';
    activityMeta?: {
      __typename?: 'CandidateActivityHistoryActivityMetaType';
      rejectReasonCategory?: CandidateRejectedReasonEnum | null;
      rejectReasonDetails?: string | null;
    } | null;
  }>;
};

export type RoleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type RoleByIdQuery = {
  __typename?: 'Query';
  roleById?: {
    __typename?: 'RecruiterRoleWithStatus';
    id: string;
    publicDossierUrl?: string | null;
    isDossierShareable: boolean;
    isPrimaryAgency?: boolean | null;
    roleType: RoleTypeEnum;
    name: string;
    description?: string | null;
    jobDescriptionLink?: string | null;
    createdAt: Date;
    updatedAt: Date;
    quantity?: number | null;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    expectedFirstYearShareCount?: number | null;
    remoteWorkPolicy: RemoteWorkPolicyEnum;
    noteJSON?: string | null;
    primarySearchAgreementId?: string | null;
    primarySearchAgreementStatus: string;
    primarySearchAgreementEndAt?: Date | null;
    primarySearchAgreementVisibility: MarketplaceVisibilityEnum;
    primarySearchAgreementRoleId?: string | null;
    primarySearchAgreementFeeTriggerType?: string | null;
    showRequirementsBillboard?: boolean | null;
    recruiterName: string;
    agencyName?: string | null;
    hasReview: boolean;
    placementFee?: number | null;
    placementFeeType?: string | null;
    collaborationAgencyName?: string | null;
    collaborationRecruiterName?: string | null;
    isCollaborationRecruiter?: boolean | null;
    employer: {
      __typename?: 'Employer';
      id: string;
      primaryAgencyId?: string | null;
      company: {
        __typename?: 'Company';
        name: string;
        description?: string | null;
        logoUrl: string;
        tagline?: string | null;
        linkedIn?: string | null;
        crunchbase?: string | null;
        website?: string | null;
      };
    };
    hiringManager?: {
      __typename?: 'Employee';
      id: string;
      user: {
        __typename?: 'User';
        name: string;
        email: string;
        phoneNumber?: string | null;
      };
    } | null;
    roleFunction?: {
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    } | null;
    roleSeniority?: { __typename?: 'RoleSeniority'; name: string } | null;
    cities: Array<{
      __typename?: 'City';
      cityName: string;
      stateAbbrev: string;
    }>;
    primarySearchAgreement?: {
      __typename?: 'SearchAgreement';
      exclusive: boolean;
      whenFirstInvoice: string;
      beginAt?: Date | null;
      marketplacePercentFee: number;
      includeReplacementSearch: boolean;
      replacementSearchTerminateDays?: number | null;
      placementFeeSchedule?: {
        __typename?: 'SearchAgreementFeeSchedule';
        feeAmount: number;
        feeScheduleType: FeeScheduleTypeEnum;
      } | null;
      signedByRecruiter?: {
        __typename?: 'Recruiter';
        agency: { __typename?: 'Agency'; id: string; name?: string | null };
        user: { __typename?: 'User'; name: string };
      } | null;
    } | null;
    primarySearchAgreementMarketplaceRolePrice?: {
      __typename?: 'PlacementFeeOutput';
      amount: number;
      feeType: FeeScheduleTypeEnum;
    } | null;
    collaboration?: {
      __typename?: 'Collaboration';
      id: string;
      splitPercentage: number;
      status: CollaborationStatus;
      primaryRecruiter: {
        __typename?: 'Recruiter';
        user: { __typename?: 'User'; name: string };
      };
      agency: { __typename?: 'Agency'; id: string };
      recruiter: {
        __typename?: 'Recruiter';
        user: { __typename?: 'User'; name: string };
      };
    } | null;
    mostCommonRejectReasons?: Array<{
      __typename?: 'MostCommondRejectReasons';
      title: string;
      description: string;
    }> | null;
    closedSearchProduct?: {
      __typename?: 'ClosedSearchProduct';
      closedSearchAgreement?: {
        __typename?: 'SearchAgreement';
        id: string;
        marketplaceVisibility: MarketplaceVisibilityEnum;
        status: string;
      } | null;
      closedSearchProductMarketplaceRolePrice?: {
        __typename?: 'PlacementFeeOutput';
        amount: number;
        feeType: FeeScheduleTypeEnum;
      } | null;
    } | null;
  } | null;
};

export type RoleCandidateSubmissionLimitQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type RoleCandidateSubmissionLimitQuery = {
  __typename?: 'Query';
  roleCandidateSubmissionLimit?: number | null;
};

export type RoleEnrichmentOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RoleEnrichmentOptionsQuery = {
  __typename?: 'Query';
  roleFunctions?: Array<{
    __typename?: 'RoleFunction';
    id: string;
    name: string;
  }> | null;
  roleSeniorities?: Array<{
    __typename?: 'RoleSeniority';
    id: string;
    name: string;
  }> | null;
  cities?: Array<{
    __typename?: 'City';
    id: string;
    cityName: string;
    stateName: string;
  }> | null;
};

export type RoleFunctionsAndCitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RoleFunctionsAndCitiesQuery = {
  __typename?: 'Query';
  roleFunctionsAndCities?: {
    __typename?: 'RoleFunctionsAndCitiesOutput';
    roleFunctions: Array<{
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    }>;
    cities: Array<{
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    }>;
  } | null;
};

export type RoleQuestionsQueryVariables = Exact<{
  filter: RoleQuestionFilter;
}>;

export type RoleQuestionsQuery = {
  __typename?: 'Query';
  roleQuestions: Array<{
    __typename?: 'RoleQuestion';
    id: string;
    question: string;
    answer?: string | null;
    updatedAt?: Date | null;
    answerer?: {
      __typename?: 'Employee';
      user: { __typename?: 'User'; photoUrl: string; name: string };
    } | null;
  }>;
};

export type RoleRequirementTemplatesQueryVariables = Exact<{
  filter: RoleRequirementTemplateFilter;
}>;

export type RoleRequirementTemplatesQuery = {
  __typename?: 'Query';
  roleRequirementTemplates: Array<{
    __typename?: 'RoleRequirementTemplate';
    createdAt: Date;
    id: string;
    template: string;
    updatedAt: Date;
  }>;
};

export type RoleRequirementsQueryVariables = Exact<{
  filter: RoleRequirementFilter;
}>;

export type RoleRequirementsQuery = {
  __typename?: 'Query';
  roleRequirements: Array<{
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    mustHave: boolean;
    id: string;
    prompt: string;
    updatedAt?: Date | null;
  }>;
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = {
  __typename?: 'Query';
  roles: Array<{
    __typename?: 'RecruiterRoleWithStatus';
    id: string;
    name: string;
    description?: string | null;
    quantity?: number | null;
    createdAt: Date;
    updatedAt: Date;
    candidateCount: number;
    roleType: RoleTypeEnum;
    primarySearchAgreementId?: string | null;
    primarySearchAgreementVisibility: MarketplaceVisibilityEnum;
    primarySearchAgreementStatus: string;
    employer: {
      __typename?: 'Employer';
      id: string;
      company: {
        __typename?: 'Company';
        name: string;
        description?: string | null;
      };
    };
    cities: Array<{
      __typename?: 'City';
      cityName: string;
      stateAbbrev: string;
    }>;
    collaboration?: {
      __typename?: 'Collaboration';
      status: CollaborationStatus;
    } | null;
  }>;
};

export type SearchAgreementbyIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SearchAgreementbyIdQuery = {
  __typename?: 'Query';
  recruiterSearchAgreementById?: {
    __typename?: 'SearchAgreement';
    id: string;
    status: string;
    createdAt: Date;
    beginAt?: Date | null;
    includeReplacementSearch: boolean;
    replacementSearchTerminateDays?: number | null;
    replacementSearchWindowMonths?: number | null;
    replacementSearchNotificationWindowDays?: number | null;
    isExclusivityCarveOutExplicitListCandidates: boolean;
    isExclusivityCarveOutCandidatesSourcedByEmployer: boolean;
    exclusivityCarveOutCandidates?: string | null;
    expectedPlacements: ExpectedPlacementsEnum;
    whenFirstInvoice: string;
    feeType: string;
    employeeCanceledAt?: Date | null;
    recruiterCanceledAt?: Date | null;
    employeeSignedAt?: Date | null;
    fulfilledAt?: Date | null;
    endAt?: Date | null;
    exclusive: boolean;
    marketplacePercentFee: number;
    isSignedOffPlatform: boolean;
    addendums: Array<{
      __typename?: 'SearchAgreementAddendum';
      id: string;
      description: string;
    }>;
    signedByEmployee?: {
      __typename?: 'Employee';
      id: string;
      user: { __typename?: 'User'; name: string; email: string };
    } | null;
    employer: {
      __typename?: 'Employer';
      id: string;
      company: {
        __typename?: 'Company';
        name: string;
        description?: string | null;
        linkedIn?: string | null;
        crunchbase?: string | null;
      };
    };
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      id: string;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        expectedFirstYearSalaryMin?: number | null;
        expectedFirstYearSalaryMax?: number | null;
        expectedFirstYearShareCount?: number | null;
        cities: Array<{
          __typename?: 'City';
          id: string;
          cityName: string;
          stateAbbrev: string;
        }>;
      };
    }>;
    agency?: { __typename?: 'Agency'; name?: string | null } | null;
    feeSchedules: Array<{
      __typename?: 'SearchAgreementFeeSchedule';
      id: string;
      feeScheduleType: FeeScheduleTypeEnum;
      feeAmount: number;
      feeTriggerType: FeeTriggerTypeEnum;
      feeAdjustmentType?: FeeAdjustmentTypeEnum | null;
      feePaymentTermNetDays: number;
      feeTriggerLimit?: number | null;
      performanceCandidateAcceptQuantity?: number | null;
      oneTimeFeeTriggerAt?: Date | null;
      oneTimeFeeRelativeSigningDays?: number | null;
    }>;
    canceledByEmployee?: {
      __typename?: 'Employee';
      user: { __typename?: 'User'; name: string };
    } | null;
    canceledByRecruiter?: {
      __typename?: 'Recruiter';
      user: { __typename?: 'User'; name: string };
    } | null;
  } | null;
};

export type SearchAgreementByRecruiterQueryVariables = Exact<{
  maxCount?: InputMaybe<Scalars['Int']>;
}>;

export type SearchAgreementByRecruiterQuery = {
  __typename?: 'Query';
  searchAgreementByRecruiterAgency?: Array<{
    __typename?: 'SearchAgreement';
    id: string;
    status: string;
    updatedAt: Date;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      id: string;
      role: { __typename?: 'Role'; id: string; name: string };
    }>;
  }> | null;
};

export type SearchArchivedAgreementByRecruiterQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SearchArchivedAgreementByRecruiterQuery = {
  __typename?: 'Query';
  searchArchivedAgreementByRecruiter?: Array<{
    __typename?: 'SearchAgreement';
    id: string;
    status: string;
    updatedAt: Date;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      id: string;
      role: { __typename?: 'Role'; id: string; name: string };
    }>;
  }> | null;
};

export type SendSearchAgreementReminderMutationVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type SendSearchAgreementReminderMutation = {
  __typename?: 'Mutation';
  sendSearchAgreementReminder: boolean;
};

export type CheckStatusOrGenerateAccountLinkUrlQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CheckStatusOrGenerateAccountLinkUrlQuery = {
  __typename?: 'Query';
  checkStatusOrGenerateAccountLinkURL?: {
    __typename?: 'StripeStatus';
    check: boolean;
    url: string;
  } | null;
};

export type TimeZoneQueryVariables = Exact<{ [key: string]: never }>;

export type TimeZoneQuery = {
  __typename?: 'Query';
  getTimeZone?: Array<{
    __typename?: 'Timezone';
    id: string;
    timezoneOffset: string;
    timezoneName: string;
  }> | null;
};

export type TopClientsByPlacementQueryVariables = Exact<{
  rangeDate: RangeDateInput;
}>;

export type TopClientsByPlacementQuery = {
  __typename?: 'Query';
  topClientsByPlacement: Array<{
    __typename?: 'RankingOutput';
    name: string;
    quantity: number;
  }>;
};

export type TopClientsByRevenueQueryVariables = Exact<{
  rangeDate: RangeDateInput;
}>;

export type TopClientsByRevenueQuery = {
  __typename?: 'Query';
  topClientsByRevenue: Array<{
    __typename?: 'RankingOutput';
    name: string;
    quantity: number;
  }>;
};

export type TopRecruiterBySubmittedCandidateQueryVariables = Exact<{
  input: RangeDateInput;
}>;

export type TopRecruiterBySubmittedCandidateQuery = {
  __typename?: 'Query';
  topRecruiterBySubmittedCandidate: {
    __typename?: 'TopRecruitersBySubmittedCandidateOutput';
    hasData: boolean;
    recruiters?: Array<{
      __typename?: 'RecruiterInfo';
      name: string;
      number: number;
    }> | null;
    candidates?: {
      __typename?: 'NumberCandidates';
      previous: number;
      current: number;
    } | null;
  };
};

export type TopRecruitersByRevenueQueryVariables = Exact<{
  rangeDate: RangeDateInput;
}>;

export type TopRecruitersByRevenueQuery = {
  __typename?: 'Query';
  topRecruitersByRevenue: Array<{
    __typename?: 'RankingOutput';
    name: string;
    quantity: number;
  }>;
};

export type UnarchiveSearchAgreementMutationVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type UnarchiveSearchAgreementMutation = {
  __typename?: 'Mutation';
  unarchiveSearchAgreement?: string | null;
};

export type UpcomingEarningsQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingEarningsQuery = {
  __typename?: 'Query';
  upcomingEarnings?: Array<{
    __typename?: 'UpcomingEarningsOutput';
    id: string;
    date: string;
    amount: number;
    employerName: string;
    feeType: string;
    searchAgreementId: string;
    recruiterName: string;
  }> | null;
};

export type UpdateCandidateDocumentsMutationVariables = Exact<{
  input: UpdateCandidateDocumentsInput;
}>;

export type UpdateCandidateDocumentsMutation = {
  __typename?: 'Mutation';
  updateCandidateDocuments: { __typename?: 'Candidate'; id: string };
};

export type UpdateCandidateMutationVariables = Exact<{
  input: UpdateCandidateInput;
}>;

export type UpdateCandidateMutation = {
  __typename?: 'Mutation';
  updateCandidate: { __typename?: 'Candidate'; id: string };
};

export type UpdateCandidateRoleRequirementsMutationVariables = Exact<{
  inputs:
    | Array<UpdateCandidateRoleRequirementInput>
    | UpdateCandidateRoleRequirementInput;
}>;

export type UpdateCandidateRoleRequirementsMutation = {
  __typename?: 'Mutation';
  updateCandidateRoleRequirements: boolean;
};

export type UpdateOnboardingStatusToInReviewMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateOnboardingStatusToInReviewMutation = {
  __typename?: 'Mutation';
  updateOnboardingStatusToInReview: {
    __typename?: 'Recruiter';
    id: string;
    onboardingStatus: OnboardingStatus;
  };
};

export type UpdateOrCreateRecruiterPlacementMutationVariables = Exact<{
  placement: UpdateRecruiterProfilePlacementInput;
}>;

export type UpdateOrCreateRecruiterPlacementMutation = {
  __typename?: 'Mutation';
  updateOrCreateRecruiterPlacement?: {
    __typename?: 'Recruiter';
    id: string;
  } | null;
};

export type UpdatePublicCandidateMutationVariables = Exact<{
  publicDossierId: Scalars['String'];
}>;

export type UpdatePublicCandidateMutation = {
  __typename?: 'Mutation';
  updatePublicCandidate: { __typename?: 'Candidate'; id: string };
};

export type UpdateRecruiterMutationVariables = Exact<{
  data: UpdateRecruiterInput;
}>;

export type UpdateRecruiterMutation = {
  __typename?: 'Mutation';
  updateRecruiter?: {
    __typename?: 'Recruiter';
    id: string;
    agency: { __typename?: 'Agency'; name?: string | null };
  } | null;
};

export type UpdateRecruiterProfileEndorsementsMutationVariables = Exact<{
  endorsements:
    | Array<UpdateRecruiterProfileEndorsementsInput>
    | UpdateRecruiterProfileEndorsementsInput;
}>;

export type UpdateRecruiterProfileEndorsementsMutation = {
  __typename?: 'Mutation';
  updateRecruiterProfileEndorsements?: {
    __typename?: 'Recruiter';
    id: string;
  } | null;
};

export type UpdateRecruiterProfileMutationVariables = Exact<{
  data: UpdateRecruiterProfileInput;
}>;

export type UpdateRecruiterProfileMutation = {
  __typename?: 'Mutation';
  updateRecruiterProfile?: {
    __typename?: 'Recruiter';
    id: string;
    user: { __typename?: 'User'; photoUrl: string };
  } | null;
};

export type UpdateRecruiterTimeToPlacementMutationVariables = Exact<{
  timeToPlacement: Scalars['Float'];
}>;

export type UpdateRecruiterTimeToPlacementMutation = {
  __typename?: 'Mutation';
  updateRecruiterTimeToPlacement?: {
    __typename?: 'Recruiter';
    id: string;
  } | null;
};

export type UpdateRoleRequirementsMutationVariables = Exact<{
  inputs: Array<UpdateRoleRequirementInput> | UpdateRoleRequirementInput;
}>;

export type UpdateRoleRequirementsMutation = {
  __typename?: 'Mutation';
  updateRoleRequirements: Array<{
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    id: string;
    mustHave: boolean;
    prompt: string;
    updatedAt?: Date | null;
  }>;
};

export type UpdateMarketplaceRoleStatusMutationVariables = Exact<{
  status: MarketplaceRoleRecruiterStatus;
  roleId: Scalars['String'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
}>;

export type UpdateMarketplaceRoleStatusMutation = {
  __typename?: 'Mutation';
  updateMarketplaceRoleStatus?: {
    __typename?: 'MarketplaceRoleRecruiter';
    id: string;
    status: MarketplaceRoleRecruiterStatus;
  } | null;
};

export type UpdateSearchAgreementMutationVariables = Exact<{
  searchAgreementInput: UpdateSearchAgreementInput;
}>;

export type UpdateSearchAgreementMutation = {
  __typename?: 'Mutation';
  updateSearchAgreement?: { __typename?: 'SearchAgreement'; id: string } | null;
};

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'User'; id: string; photoUrl: string };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    userConsents: Array<{ __typename?: 'UserConsent'; id: string }>;
  } | null;
};

export type VoidSearchAgreementMutationVariables = Exact<{
  input: RecruiterTerminatedSearchAgreementInput;
}>;

export type VoidSearchAgreementMutation = {
  __typename?: 'Mutation';
  voidSearchAgreement?: string | null;
};

export type UpdateRecruiterProfileBioMutationVariables = Exact<{
  bio: Scalars['String'];
  bioJSON: Scalars['String'];
}>;

export type UpdateRecruiterProfileBioMutation = {
  __typename?: 'Mutation';
  updateRecruiterProfileBio?: { __typename?: 'Recruiter'; id: string } | null;
};

export const AcceptCandidateCollaborationDocument = gql`
  mutation AcceptCandidateCollaboration(
    $input: RecruiterAcceptCandidateInput!
  ) {
    acceptCandidateCollaboration(input: $input) {
      id
    }
  }
`;
export type AcceptCandidateCollaborationMutationFn = Apollo.MutationFunction<
  AcceptCandidateCollaborationMutation,
  AcceptCandidateCollaborationMutationVariables
>;

/**
 * __useAcceptCandidateCollaborationMutation__
 *
 * To run a mutation, you first call `useAcceptCandidateCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCandidateCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCandidateCollaborationMutation, { data, loading, error }] = useAcceptCandidateCollaborationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCandidateCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptCandidateCollaborationMutation,
    AcceptCandidateCollaborationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptCandidateCollaborationMutation,
    AcceptCandidateCollaborationMutationVariables
  >(AcceptCandidateCollaborationDocument, options);
}
export type AcceptCandidateCollaborationMutationHookResult = ReturnType<
  typeof useAcceptCandidateCollaborationMutation
>;
export type AcceptCandidateCollaborationMutationResult =
  Apollo.MutationResult<AcceptCandidateCollaborationMutation>;
export type AcceptCandidateCollaborationMutationOptions =
  Apollo.BaseMutationOptions<
    AcceptCandidateCollaborationMutation,
    AcceptCandidateCollaborationMutationVariables
  >;
export const AcceptCandidateDocument = gql`
  mutation AcceptCandidate($input: RecruiterAcceptCandidateInput!) {
    acceptCandidate(input: $input) {
      id
    }
  }
`;
export type AcceptCandidateMutationFn = Apollo.MutationFunction<
  AcceptCandidateMutation,
  AcceptCandidateMutationVariables
>;

/**
 * __useAcceptCandidateMutation__
 *
 * To run a mutation, you first call `useAcceptCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCandidateMutation, { data, loading, error }] = useAcceptCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptCandidateMutation,
    AcceptCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptCandidateMutation,
    AcceptCandidateMutationVariables
  >(AcceptCandidateDocument, options);
}
export type AcceptCandidateMutationHookResult = ReturnType<
  typeof useAcceptCandidateMutation
>;
export type AcceptCandidateMutationResult =
  Apollo.MutationResult<AcceptCandidateMutation>;
export type AcceptCandidateMutationOptions = Apollo.BaseMutationOptions<
  AcceptCandidateMutation,
  AcceptCandidateMutationVariables
>;
export const AcceptLeadDocument = gql`
  mutation AcceptLead($id: String!) {
    acceptLead(id: $id) {
      searchAgreementCreatedId
      recruiterStatus
      employerStatus
    }
  }
`;
export type AcceptLeadMutationFn = Apollo.MutationFunction<
  AcceptLeadMutation,
  AcceptLeadMutationVariables
>;

/**
 * __useAcceptLeadMutation__
 *
 * To run a mutation, you first call `useAcceptLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptLeadMutation, { data, loading, error }] = useAcceptLeadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptLeadMutation,
    AcceptLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptLeadMutation, AcceptLeadMutationVariables>(
    AcceptLeadDocument,
    options
  );
}
export type AcceptLeadMutationHookResult = ReturnType<
  typeof useAcceptLeadMutation
>;
export type AcceptLeadMutationResult =
  Apollo.MutationResult<AcceptLeadMutation>;
export type AcceptLeadMutationOptions = Apollo.BaseMutationOptions<
  AcceptLeadMutation,
  AcceptLeadMutationVariables
>;
export const AcceptanceRateMetricsDocument = gql`
  query AcceptanceRateMetrics {
    acceptanceRateMetrics {
      recruiterAcceptanceRate
      quartileAcceptanceRate
    }
  }
`;

/**
 * __useAcceptanceRateMetricsQuery__
 *
 * To run a query within a React component, call `useAcceptanceRateMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptanceRateMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptanceRateMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcceptanceRateMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AcceptanceRateMetricsQuery,
    AcceptanceRateMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AcceptanceRateMetricsQuery,
    AcceptanceRateMetricsQueryVariables
  >(AcceptanceRateMetricsDocument, options);
}
export function useAcceptanceRateMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcceptanceRateMetricsQuery,
    AcceptanceRateMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AcceptanceRateMetricsQuery,
    AcceptanceRateMetricsQueryVariables
  >(AcceptanceRateMetricsDocument, options);
}
export type AcceptanceRateMetricsQueryHookResult = ReturnType<
  typeof useAcceptanceRateMetricsQuery
>;
export type AcceptanceRateMetricsLazyQueryHookResult = ReturnType<
  typeof useAcceptanceRateMetricsLazyQuery
>;
export type AcceptanceRateMetricsQueryResult = Apollo.QueryResult<
  AcceptanceRateMetricsQuery,
  AcceptanceRateMetricsQueryVariables
>;
export function refetchAcceptanceRateMetricsQuery(
  variables?: AcceptanceRateMetricsQueryVariables
) {
  return { query: AcceptanceRateMetricsDocument, variables: variables };
}
export const AgencyEarningsDocument = gql`
  query AgencyEarnings($input: AgencyEarningsInput!) {
    agencyEarnings(input: $input) {
      id
      placementRevenue
      retainerRevenue
    }
  }
`;

/**
 * __useAgencyEarningsQuery__
 *
 * To run a query within a React component, call `useAgencyEarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyEarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyEarningsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgencyEarningsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyEarningsQuery,
    AgencyEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyEarningsQuery, AgencyEarningsQueryVariables>(
    AgencyEarningsDocument,
    options
  );
}
export function useAgencyEarningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyEarningsQuery,
    AgencyEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyEarningsQuery, AgencyEarningsQueryVariables>(
    AgencyEarningsDocument,
    options
  );
}
export type AgencyEarningsQueryHookResult = ReturnType<
  typeof useAgencyEarningsQuery
>;
export type AgencyEarningsLazyQueryHookResult = ReturnType<
  typeof useAgencyEarningsLazyQuery
>;
export type AgencyEarningsQueryResult = Apollo.QueryResult<
  AgencyEarningsQuery,
  AgencyEarningsQueryVariables
>;
export function refetchAgencyEarningsQuery(
  variables: AgencyEarningsQueryVariables
) {
  return { query: AgencyEarningsDocument, variables: variables };
}
export const AgencyLastYearEarningsDocument = gql`
  query AgencyLastYearEarnings($paymentStatus: String!) {
    agencyLastYearEarnings(paymentStatus: $paymentStatus) {
      month
      placementEarnings
      retainerEarnings
      totalEarnings
    }
  }
`;

/**
 * __useAgencyLastYearEarningsQuery__
 *
 * To run a query within a React component, call `useAgencyLastYearEarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyLastYearEarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyLastYearEarningsQuery({
 *   variables: {
 *      paymentStatus: // value for 'paymentStatus'
 *   },
 * });
 */
export function useAgencyLastYearEarningsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyLastYearEarningsQuery,
    AgencyLastYearEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyLastYearEarningsQuery,
    AgencyLastYearEarningsQueryVariables
  >(AgencyLastYearEarningsDocument, options);
}
export function useAgencyLastYearEarningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyLastYearEarningsQuery,
    AgencyLastYearEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyLastYearEarningsQuery,
    AgencyLastYearEarningsQueryVariables
  >(AgencyLastYearEarningsDocument, options);
}
export type AgencyLastYearEarningsQueryHookResult = ReturnType<
  typeof useAgencyLastYearEarningsQuery
>;
export type AgencyLastYearEarningsLazyQueryHookResult = ReturnType<
  typeof useAgencyLastYearEarningsLazyQuery
>;
export type AgencyLastYearEarningsQueryResult = Apollo.QueryResult<
  AgencyLastYearEarningsQuery,
  AgencyLastYearEarningsQueryVariables
>;
export function refetchAgencyLastYearEarningsQuery(
  variables: AgencyLastYearEarningsQueryVariables
) {
  return { query: AgencyLastYearEarningsDocument, variables: variables };
}
export const AgencyTransactionsDocument = gql`
  query AgencyTransactions($rangeDate: RangeDateInput!) {
    agencyTransactions(rangeDate: $rangeDate) {
      id
      status
      feeType
      payoutType
      paidAmount
      paidAt
      employerName
      searchAgreement {
        id
      }
      createdAt
      recruiterName
      role {
        id
        name
      }
      stripeInvoiceId
    }
  }
`;

/**
 * __useAgencyTransactionsQuery__
 *
 * To run a query within a React component, call `useAgencyTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyTransactionsQuery({
 *   variables: {
 *      rangeDate: // value for 'rangeDate'
 *   },
 * });
 */
export function useAgencyTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyTransactionsQuery,
    AgencyTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyTransactionsQuery,
    AgencyTransactionsQueryVariables
  >(AgencyTransactionsDocument, options);
}
export function useAgencyTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyTransactionsQuery,
    AgencyTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyTransactionsQuery,
    AgencyTransactionsQueryVariables
  >(AgencyTransactionsDocument, options);
}
export type AgencyTransactionsQueryHookResult = ReturnType<
  typeof useAgencyTransactionsQuery
>;
export type AgencyTransactionsLazyQueryHookResult = ReturnType<
  typeof useAgencyTransactionsLazyQuery
>;
export type AgencyTransactionsQueryResult = Apollo.QueryResult<
  AgencyTransactionsQuery,
  AgencyTransactionsQueryVariables
>;
export function refetchAgencyTransactionsQuery(
  variables: AgencyTransactionsQueryVariables
) {
  return { query: AgencyTransactionsDocument, variables: variables };
}
export const CandidatesDocument = gql`
  query Candidates {
    candidates {
      recruitersByAgencyCount
      candidateMetrics {
        submittedCount
        interviewingCount
        hiredCount
      }
      candidates {
        id
        status
        justification
        updatedAt
        submitDate
        fee
        isCollaborationCandidate
        isImported
        recruiter {
          id
          agency {
            name
          }
          user {
            name
          }
        }
        talent {
          name
          firstName
          lastName
          linkedIn
          email
          phone
          createdAt
          linkedInProfile {
            agency {
              name
            }
            parsedLinkedInProfile {
              headline
              workExperience {
                company {
                  name
                }
                roleTitle
              }
            }
          }
        }
        candidateActivityHistories {
          activityMeta {
            rejectReasonCategory
            shareRejectionInfo
          }
        }
        roleRequirements {
          id
          mustHave
          prompt
          meetRequirement
        }
        searchAgreementRole {
          id
          role {
            id
            name
            roleType
            hiringManager {
              id
            }
            employer {
              id
              company {
                name
                logoUrl
              }
            }
          }
        }
        documents {
          id
          documentPath
          documentType
          name
        }
      }
    }
  }
`;

/**
 * __useCandidatesQuery__
 *
 * To run a query within a React component, call `useCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidatesQuery,
    CandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidatesQuery, CandidatesQueryVariables>(
    CandidatesDocument,
    options
  );
}
export function useCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesQuery,
    CandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidatesQuery, CandidatesQueryVariables>(
    CandidatesDocument,
    options
  );
}
export type CandidatesQueryHookResult = ReturnType<typeof useCandidatesQuery>;
export type CandidatesLazyQueryHookResult = ReturnType<
  typeof useCandidatesLazyQuery
>;
export type CandidatesQueryResult = Apollo.QueryResult<
  CandidatesQuery,
  CandidatesQueryVariables
>;
export function refetchCandidatesQuery(variables?: CandidatesQueryVariables) {
  return { query: CandidatesDocument, variables: variables };
}
export const AllowRecruiterVerifiedInterestDocument = gql`
  query AllowRecruiterVerifiedInterest {
    allowRecruiterVerifiedInterest
  }
`;

/**
 * __useAllowRecruiterVerifiedInterestQuery__
 *
 * To run a query within a React component, call `useAllowRecruiterVerifiedInterestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowRecruiterVerifiedInterestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowRecruiterVerifiedInterestQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowRecruiterVerifiedInterestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowRecruiterVerifiedInterestQuery,
    AllowRecruiterVerifiedInterestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowRecruiterVerifiedInterestQuery,
    AllowRecruiterVerifiedInterestQueryVariables
  >(AllowRecruiterVerifiedInterestDocument, options);
}
export function useAllowRecruiterVerifiedInterestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowRecruiterVerifiedInterestQuery,
    AllowRecruiterVerifiedInterestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowRecruiterVerifiedInterestQuery,
    AllowRecruiterVerifiedInterestQueryVariables
  >(AllowRecruiterVerifiedInterestDocument, options);
}
export type AllowRecruiterVerifiedInterestQueryHookResult = ReturnType<
  typeof useAllowRecruiterVerifiedInterestQuery
>;
export type AllowRecruiterVerifiedInterestLazyQueryHookResult = ReturnType<
  typeof useAllowRecruiterVerifiedInterestLazyQuery
>;
export type AllowRecruiterVerifiedInterestQueryResult = Apollo.QueryResult<
  AllowRecruiterVerifiedInterestQuery,
  AllowRecruiterVerifiedInterestQueryVariables
>;
export function refetchAllowRecruiterVerifiedInterestQuery(
  variables?: AllowRecruiterVerifiedInterestQueryVariables
) {
  return {
    query: AllowRecruiterVerifiedInterestDocument,
    variables: variables,
  };
}
export const AnvilSignaturedFilesDocument = gql`
  query AnvilSignaturedFiles($searchAgreementId: String!) {
    getAnvilSignaturedFiles(searchAgreementId: $searchAgreementId) {
      data
    }
  }
`;

/**
 * __useAnvilSignaturedFilesQuery__
 *
 * To run a query within a React component, call `useAnvilSignaturedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnvilSignaturedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnvilSignaturedFilesQuery({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useAnvilSignaturedFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >(AnvilSignaturedFilesDocument, options);
}
export function useAnvilSignaturedFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >(AnvilSignaturedFilesDocument, options);
}
export type AnvilSignaturedFilesQueryHookResult = ReturnType<
  typeof useAnvilSignaturedFilesQuery
>;
export type AnvilSignaturedFilesLazyQueryHookResult = ReturnType<
  typeof useAnvilSignaturedFilesLazyQuery
>;
export type AnvilSignaturedFilesQueryResult = Apollo.QueryResult<
  AnvilSignaturedFilesQuery,
  AnvilSignaturedFilesQueryVariables
>;
export function refetchAnvilSignaturedFilesQuery(
  variables: AnvilSignaturedFilesQueryVariables
) {
  return { query: AnvilSignaturedFilesDocument, variables: variables };
}
export const ArchiveSearchAgreementDocument = gql`
  mutation ArchiveSearchAgreement($searchAgreementId: String!) {
    archiveSearchAgreement(searchAgreementId: $searchAgreementId)
  }
`;
export type ArchiveSearchAgreementMutationFn = Apollo.MutationFunction<
  ArchiveSearchAgreementMutation,
  ArchiveSearchAgreementMutationVariables
>;

/**
 * __useArchiveSearchAgreementMutation__
 *
 * To run a mutation, you first call `useArchiveSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSearchAgreementMutation, { data, loading, error }] = useArchiveSearchAgreementMutation({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useArchiveSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSearchAgreementMutation,
    ArchiveSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveSearchAgreementMutation,
    ArchiveSearchAgreementMutationVariables
  >(ArchiveSearchAgreementDocument, options);
}
export type ArchiveSearchAgreementMutationHookResult = ReturnType<
  typeof useArchiveSearchAgreementMutation
>;
export type ArchiveSearchAgreementMutationResult =
  Apollo.MutationResult<ArchiveSearchAgreementMutation>;
export type ArchiveSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSearchAgreementMutation,
  ArchiveSearchAgreementMutationVariables
>;
export const AvailableEmployersDocument = gql`
  query AvailableEmployers {
    availableEmployers {
      id
      company {
        name
      }
    }
  }
`;

/**
 * __useAvailableEmployersQuery__
 *
 * To run a query within a React component, call `useAvailableEmployersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableEmployersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableEmployersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableEmployersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableEmployersQuery,
    AvailableEmployersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableEmployersQuery,
    AvailableEmployersQueryVariables
  >(AvailableEmployersDocument, options);
}
export function useAvailableEmployersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableEmployersQuery,
    AvailableEmployersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableEmployersQuery,
    AvailableEmployersQueryVariables
  >(AvailableEmployersDocument, options);
}
export type AvailableEmployersQueryHookResult = ReturnType<
  typeof useAvailableEmployersQuery
>;
export type AvailableEmployersLazyQueryHookResult = ReturnType<
  typeof useAvailableEmployersLazyQuery
>;
export type AvailableEmployersQueryResult = Apollo.QueryResult<
  AvailableEmployersQuery,
  AvailableEmployersQueryVariables
>;
export function refetchAvailableEmployersQuery(
  variables?: AvailableEmployersQueryVariables
) {
  return { query: AvailableEmployersDocument, variables: variables };
}
export const AvailableRolesByEmployerDocument = gql`
  query AvailableRolesByEmployer($employerId: String!) {
    availableRolesByEmployer(employerId: $employerId) {
      id
      name
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      expectedFirstYearShareCount
      cities {
        id
        cityName
      }
    }
  }
`;

/**
 * __useAvailableRolesByEmployerQuery__
 *
 * To run a query within a React component, call `useAvailableRolesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableRolesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableRolesByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useAvailableRolesByEmployerQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableRolesByEmployerQuery,
    AvailableRolesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableRolesByEmployerQuery,
    AvailableRolesByEmployerQueryVariables
  >(AvailableRolesByEmployerDocument, options);
}
export function useAvailableRolesByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableRolesByEmployerQuery,
    AvailableRolesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableRolesByEmployerQuery,
    AvailableRolesByEmployerQueryVariables
  >(AvailableRolesByEmployerDocument, options);
}
export type AvailableRolesByEmployerQueryHookResult = ReturnType<
  typeof useAvailableRolesByEmployerQuery
>;
export type AvailableRolesByEmployerLazyQueryHookResult = ReturnType<
  typeof useAvailableRolesByEmployerLazyQuery
>;
export type AvailableRolesByEmployerQueryResult = Apollo.QueryResult<
  AvailableRolesByEmployerQuery,
  AvailableRolesByEmployerQueryVariables
>;
export function refetchAvailableRolesByEmployerQuery(
  variables: AvailableRolesByEmployerQueryVariables
) {
  return { query: AvailableRolesByEmployerDocument, variables: variables };
}
export const AvailableRolesDocument = gql`
  query AvailableRoles($maxCount: Int) {
    availableRoles(maxCount: $maxCount) {
      id
      name
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      roleType
      cities {
        cityName
      }
      employer {
        company {
          name
        }
      }
    }
  }
`;

/**
 * __useAvailableRolesQuery__
 *
 * To run a query within a React component, call `useAvailableRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableRolesQuery({
 *   variables: {
 *      maxCount: // value for 'maxCount'
 *   },
 * });
 */
export function useAvailableRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableRolesQuery,
    AvailableRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableRolesQuery, AvailableRolesQueryVariables>(
    AvailableRolesDocument,
    options
  );
}
export function useAvailableRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableRolesQuery,
    AvailableRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailableRolesQuery, AvailableRolesQueryVariables>(
    AvailableRolesDocument,
    options
  );
}
export type AvailableRolesQueryHookResult = ReturnType<
  typeof useAvailableRolesQuery
>;
export type AvailableRolesLazyQueryHookResult = ReturnType<
  typeof useAvailableRolesLazyQuery
>;
export type AvailableRolesQueryResult = Apollo.QueryResult<
  AvailableRolesQuery,
  AvailableRolesQueryVariables
>;
export function refetchAvailableRolesQuery(
  variables?: AvailableRolesQueryVariables
) {
  return { query: AvailableRolesDocument, variables: variables };
}
export const CancelCollaborationDocument = gql`
  mutation CancelCollaboration($roleId: String!, $collaborationId: String!) {
    cancelCollaboration(roleId: $roleId, collaborationId: $collaborationId)
  }
`;
export type CancelCollaborationMutationFn = Apollo.MutationFunction<
  CancelCollaborationMutation,
  CancelCollaborationMutationVariables
>;

/**
 * __useCancelCollaborationMutation__
 *
 * To run a mutation, you first call `useCancelCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCollaborationMutation, { data, loading, error }] = useCancelCollaborationMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      collaborationId: // value for 'collaborationId'
 *   },
 * });
 */
export function useCancelCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelCollaborationMutation,
    CancelCollaborationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelCollaborationMutation,
    CancelCollaborationMutationVariables
  >(CancelCollaborationDocument, options);
}
export type CancelCollaborationMutationHookResult = ReturnType<
  typeof useCancelCollaborationMutation
>;
export type CancelCollaborationMutationResult =
  Apollo.MutationResult<CancelCollaborationMutation>;
export type CancelCollaborationMutationOptions = Apollo.BaseMutationOptions<
  CancelCollaborationMutation,
  CancelCollaborationMutationVariables
>;
export const CancelSearchAgreementDocument = gql`
  mutation CancelSearchAgreement(
    $input: RecruiterTerminatedSearchAgreementInput!
  ) {
    cancelSearchAgreement(input: $input)
  }
`;
export type CancelSearchAgreementMutationFn = Apollo.MutationFunction<
  CancelSearchAgreementMutation,
  CancelSearchAgreementMutationVariables
>;

/**
 * __useCancelSearchAgreementMutation__
 *
 * To run a mutation, you first call `useCancelSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSearchAgreementMutation, { data, loading, error }] = useCancelSearchAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSearchAgreementMutation,
    CancelSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSearchAgreementMutation,
    CancelSearchAgreementMutationVariables
  >(CancelSearchAgreementDocument, options);
}
export type CancelSearchAgreementMutationHookResult = ReturnType<
  typeof useCancelSearchAgreementMutation
>;
export type CancelSearchAgreementMutationResult =
  Apollo.MutationResult<CancelSearchAgreementMutation>;
export type CancelSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  CancelSearchAgreementMutation,
  CancelSearchAgreementMutationVariables
>;
export const CandidateAcceptOfferDocument = gql`
  mutation CandidateAcceptOffer($input: CandidateAcceptOfferInput!) {
    candidateAcceptOffer(input: $input) {
      id
    }
  }
`;
export type CandidateAcceptOfferMutationFn = Apollo.MutationFunction<
  CandidateAcceptOfferMutation,
  CandidateAcceptOfferMutationVariables
>;

/**
 * __useCandidateAcceptOfferMutation__
 *
 * To run a mutation, you first call `useCandidateAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateAcceptOfferMutation, { data, loading, error }] = useCandidateAcceptOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateAcceptOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CandidateAcceptOfferMutation,
    CandidateAcceptOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CandidateAcceptOfferMutation,
    CandidateAcceptOfferMutationVariables
  >(CandidateAcceptOfferDocument, options);
}
export type CandidateAcceptOfferMutationHookResult = ReturnType<
  typeof useCandidateAcceptOfferMutation
>;
export type CandidateAcceptOfferMutationResult =
  Apollo.MutationResult<CandidateAcceptOfferMutation>;
export type CandidateAcceptOfferMutationOptions = Apollo.BaseMutationOptions<
  CandidateAcceptOfferMutation,
  CandidateAcceptOfferMutationVariables
>;
export const CandidateActivityHistoriesDocument = gql`
  query CandidateActivityHistories(
    $filter: CandidateActivityHistoryFilter!
    $order: String!
  ) {
    candidateActivityHistories(filter: $filter, order: $order) {
      candidateHistory {
        id
        createdAt
        activityType
        entityType
        activityMeta {
          rejectReasonCategory
          rejectReasonDetails
          shareRejectionInfo
        }
        candidate {
          talent {
            firstName
            lastName
          }
          recruiter {
            id
            agency {
              name
            }
            user {
              name
            }
          }
          collaboration {
            primaryRecruiter {
              id
              agency {
                name
              }
              user {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCandidateActivityHistoriesQuery__
 *
 * To run a query within a React component, call `useCandidateActivityHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateActivityHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateActivityHistoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCandidateActivityHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateActivityHistoriesQuery,
    CandidateActivityHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateActivityHistoriesQuery,
    CandidateActivityHistoriesQueryVariables
  >(CandidateActivityHistoriesDocument, options);
}
export function useCandidateActivityHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateActivityHistoriesQuery,
    CandidateActivityHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateActivityHistoriesQuery,
    CandidateActivityHistoriesQueryVariables
  >(CandidateActivityHistoriesDocument, options);
}
export type CandidateActivityHistoriesQueryHookResult = ReturnType<
  typeof useCandidateActivityHistoriesQuery
>;
export type CandidateActivityHistoriesLazyQueryHookResult = ReturnType<
  typeof useCandidateActivityHistoriesLazyQuery
>;
export type CandidateActivityHistoriesQueryResult = Apollo.QueryResult<
  CandidateActivityHistoriesQuery,
  CandidateActivityHistoriesQueryVariables
>;
export function refetchCandidateActivityHistoriesQuery(
  variables: CandidateActivityHistoriesQueryVariables
) {
  return { query: CandidateActivityHistoriesDocument, variables: variables };
}
export const CandidateByIdDocument = gql`
  query CandidateById($id: String!) {
    candidateById(id: $id) {
      id
      createdAt
      status
      justification
      justificationJSON
      baseSalary
      shareCount
      isCollaborationRecruiter
      isCollaborationCandidate
      feeSplitPercentage
      roleRequirements {
        prompt
        id
        mustHave
        meetRequirement
      }
      talent {
        firstName
        lastName
        linkedIn
        email
        phone
        name
      }
      documents {
        name
        documentType
        documentPath
        createdAt
        id
      }
      recruiter {
        agency {
          id
          name
        }
        user {
          firstName
          lastName
          name
          email
        }
      }
      searchAgreementRole {
        id
        searchReviews {
          reviewedByRecruiterId
        }
        role {
          id
          name
          description
          hiringManager {
            id
          }
          employer {
            id
            company {
              name
              logoUrl
            }
          }
          employerId
        }
        searchAgreement {
          agency {
            id
            name
          }
          id
          marketplaceVisibility
          feeSchedules {
            feeTriggerType
            feeScheduleType
            feeAmount
          }
        }
      }
      placementFee
      placementFeeType
      startDate
      statusWithInterestVerification
    }
  }
`;

/**
 * __useCandidateByIdQuery__
 *
 * To run a query within a React component, call `useCandidateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateByIdQuery,
    CandidateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateByIdQuery, CandidateByIdQueryVariables>(
    CandidateByIdDocument,
    options
  );
}
export function useCandidateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateByIdQuery,
    CandidateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidateByIdQuery, CandidateByIdQueryVariables>(
    CandidateByIdDocument,
    options
  );
}
export type CandidateByIdQueryHookResult = ReturnType<
  typeof useCandidateByIdQuery
>;
export type CandidateByIdLazyQueryHookResult = ReturnType<
  typeof useCandidateByIdLazyQuery
>;
export type CandidateByIdQueryResult = Apollo.QueryResult<
  CandidateByIdQuery,
  CandidateByIdQueryVariables
>;
export function refetchCandidateByIdQuery(
  variables: CandidateByIdQueryVariables
) {
  return { query: CandidateByIdDocument, variables: variables };
}
export const CandidateMetricsDocument = gql`
  query CandidateMetrics {
    candidateMetrics {
      interviewingCount
      hiredCount
      submittedCount
    }
  }
`;

/**
 * __useCandidateMetricsQuery__
 *
 * To run a query within a React component, call `useCandidateMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidateMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidateMetricsQuery,
    CandidateMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateMetricsQuery, CandidateMetricsQueryVariables>(
    CandidateMetricsDocument,
    options
  );
}
export function useCandidateMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateMetricsQuery,
    CandidateMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateMetricsQuery,
    CandidateMetricsQueryVariables
  >(CandidateMetricsDocument, options);
}
export type CandidateMetricsQueryHookResult = ReturnType<
  typeof useCandidateMetricsQuery
>;
export type CandidateMetricsLazyQueryHookResult = ReturnType<
  typeof useCandidateMetricsLazyQuery
>;
export type CandidateMetricsQueryResult = Apollo.QueryResult<
  CandidateMetricsQuery,
  CandidateMetricsQueryVariables
>;
export function refetchCandidateMetricsQuery(
  variables?: CandidateMetricsQueryVariables
) {
  return { query: CandidateMetricsDocument, variables: variables };
}
export const CandidateMarketplaceMetricsDocument = gql`
  query CandidateMarketplaceMetrics($id: String!) {
    candidateMarketplaceMetrics(id: $id) {
      inReviewCount
      interviewingCount
      hiredCount
    }
  }
`;

/**
 * __useCandidateMarketplaceMetricsQuery__
 *
 * To run a query within a React component, call `useCandidateMarketplaceMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateMarketplaceMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateMarketplaceMetricsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateMarketplaceMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >(CandidateMarketplaceMetricsDocument, options);
}
export function useCandidateMarketplaceMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >(CandidateMarketplaceMetricsDocument, options);
}
export type CandidateMarketplaceMetricsQueryHookResult = ReturnType<
  typeof useCandidateMarketplaceMetricsQuery
>;
export type CandidateMarketplaceMetricsLazyQueryHookResult = ReturnType<
  typeof useCandidateMarketplaceMetricsLazyQuery
>;
export type CandidateMarketplaceMetricsQueryResult = Apollo.QueryResult<
  CandidateMarketplaceMetricsQuery,
  CandidateMarketplaceMetricsQueryVariables
>;
export function refetchCandidateMarketplaceMetricsQuery(
  variables: CandidateMarketplaceMetricsQueryVariables
) {
  return { query: CandidateMarketplaceMetricsDocument, variables: variables };
}
export const PlacementsByRangeDateDocument = gql`
  query PlacementsByRangeDate($input: RangeDateInput!) {
    placementsByRangeDate(input: $input) {
      showEmptyState
      candidateCount
    }
  }
`;

/**
 * __usePlacementsByRangeDateQuery__
 *
 * To run a query within a React component, call `usePlacementsByRangeDateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementsByRangeDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementsByRangeDateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlacementsByRangeDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlacementsByRangeDateQuery,
    PlacementsByRangeDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlacementsByRangeDateQuery,
    PlacementsByRangeDateQueryVariables
  >(PlacementsByRangeDateDocument, options);
}
export function usePlacementsByRangeDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlacementsByRangeDateQuery,
    PlacementsByRangeDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlacementsByRangeDateQuery,
    PlacementsByRangeDateQueryVariables
  >(PlacementsByRangeDateDocument, options);
}
export type PlacementsByRangeDateQueryHookResult = ReturnType<
  typeof usePlacementsByRangeDateQuery
>;
export type PlacementsByRangeDateLazyQueryHookResult = ReturnType<
  typeof usePlacementsByRangeDateLazyQuery
>;
export type PlacementsByRangeDateQueryResult = Apollo.QueryResult<
  PlacementsByRangeDateQuery,
  PlacementsByRangeDateQueryVariables
>;
export function refetchPlacementsByRangeDateQuery(
  variables: PlacementsByRangeDateQueryVariables
) {
  return { query: PlacementsByRangeDateDocument, variables: variables };
}
export const CandidatesForRoleDocument = gql`
  query CandidatesForRole($roleId: String!) {
    candidatesForRole(id: $roleId) {
      candidates {
        id
        createdAt
        status
        marketplaceVisibility
        candidateInterestVerificationStatus
        searchAgreementRole {
          id
          role {
            employerId
          }
          searchReviews {
            reviewedByRecruiterId
          }
          searchAgreement {
            marketplaceVisibility
          }
        }
        talent {
          id
          firstName
          lastName
          name
          email
          linkedIn
        }
        recruiter {
          id
          user {
            name
          }
          agency {
            id
            name
          }
        }
      }
      candidateMetrics {
        submittedCount
        interviewingCount
        hiredCount
      }
      candidatesLast7Days {
        createdAt
      }
      globalCandidateMetrics {
        submittedCount
        interviewingCount
        hiredCount
      }
      submissionAvailableDate
    }
  }
`;

/**
 * __useCandidatesForRoleQuery__
 *
 * To run a query within a React component, call `useCandidatesForRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesForRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesForRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCandidatesForRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatesForRoleQuery,
    CandidatesForRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatesForRoleQuery,
    CandidatesForRoleQueryVariables
  >(CandidatesForRoleDocument, options);
}
export function useCandidatesForRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesForRoleQuery,
    CandidatesForRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesForRoleQuery,
    CandidatesForRoleQueryVariables
  >(CandidatesForRoleDocument, options);
}
export type CandidatesForRoleQueryHookResult = ReturnType<
  typeof useCandidatesForRoleQuery
>;
export type CandidatesForRoleLazyQueryHookResult = ReturnType<
  typeof useCandidatesForRoleLazyQuery
>;
export type CandidatesForRoleQueryResult = Apollo.QueryResult<
  CandidatesForRoleQuery,
  CandidatesForRoleQueryVariables
>;
export function refetchCandidatesForRoleQuery(
  variables: CandidatesForRoleQueryVariables
) {
  return { query: CandidatesForRoleDocument, variables: variables };
}
export const CompaniesDocument = gql`
  query Companies {
    companies {
      id
      name
      companyStage {
        id
        name
      }
    }
  }
`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    options
  );
}
export function useCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    options
  );
}
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<
  typeof useCompaniesLazyQuery
>;
export type CompaniesQueryResult = Apollo.QueryResult<
  CompaniesQuery,
  CompaniesQueryVariables
>;
export function refetchCompaniesQuery(variables?: CompaniesQueryVariables) {
  return { query: CompaniesDocument, variables: variables };
}
export const CountActiveRolesDocument = gql`
  query CountActiveRoles {
    countActiveRoles
  }
`;

/**
 * __useCountActiveRolesQuery__
 *
 * To run a query within a React component, call `useCountActiveRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountActiveRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountActiveRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountActiveRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountActiveRolesQuery,
    CountActiveRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountActiveRolesQuery, CountActiveRolesQueryVariables>(
    CountActiveRolesDocument,
    options
  );
}
export function useCountActiveRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountActiveRolesQuery,
    CountActiveRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountActiveRolesQuery,
    CountActiveRolesQueryVariables
  >(CountActiveRolesDocument, options);
}
export type CountActiveRolesQueryHookResult = ReturnType<
  typeof useCountActiveRolesQuery
>;
export type CountActiveRolesLazyQueryHookResult = ReturnType<
  typeof useCountActiveRolesLazyQuery
>;
export type CountActiveRolesQueryResult = Apollo.QueryResult<
  CountActiveRolesQuery,
  CountActiveRolesQueryVariables
>;
export function refetchCountActiveRolesQuery(
  variables?: CountActiveRolesQueryVariables
) {
  return { query: CountActiveRolesDocument, variables: variables };
}
export const CountSignedSearchAgreementsDocument = gql`
  query CountSignedSearchAgreements($input: RangeDateInput!) {
    countSignedSearchAgreements(input: $input) {
      current
      previous
      hasData
    }
  }
`;

/**
 * __useCountSignedSearchAgreementsQuery__
 *
 * To run a query within a React component, call `useCountSignedSearchAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountSignedSearchAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSignedSearchAgreementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountSignedSearchAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountSignedSearchAgreementsQuery,
    CountSignedSearchAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountSignedSearchAgreementsQuery,
    CountSignedSearchAgreementsQueryVariables
  >(CountSignedSearchAgreementsDocument, options);
}
export function useCountSignedSearchAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountSignedSearchAgreementsQuery,
    CountSignedSearchAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountSignedSearchAgreementsQuery,
    CountSignedSearchAgreementsQueryVariables
  >(CountSignedSearchAgreementsDocument, options);
}
export type CountSignedSearchAgreementsQueryHookResult = ReturnType<
  typeof useCountSignedSearchAgreementsQuery
>;
export type CountSignedSearchAgreementsLazyQueryHookResult = ReturnType<
  typeof useCountSignedSearchAgreementsLazyQuery
>;
export type CountSignedSearchAgreementsQueryResult = Apollo.QueryResult<
  CountSignedSearchAgreementsQuery,
  CountSignedSearchAgreementsQueryVariables
>;
export function refetchCountSignedSearchAgreementsQuery(
  variables: CountSignedSearchAgreementsQueryVariables
) {
  return { query: CountSignedSearchAgreementsDocument, variables: variables };
}
export const CreateCandidateDocumentDocument = gql`
  mutation CreateCandidateDocument($input: CreateCandidateDocumentInput!) {
    createCandidateDocument(input: $input) {
      id
    }
  }
`;
export type CreateCandidateDocumentMutationFn = Apollo.MutationFunction<
  CreateCandidateDocumentMutation,
  CreateCandidateDocumentMutationVariables
>;

/**
 * __useCreateCandidateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateCandidateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateDocumentMutation, { data, loading, error }] = useCreateCandidateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCandidateDocumentMutation,
    CreateCandidateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCandidateDocumentMutation,
    CreateCandidateDocumentMutationVariables
  >(CreateCandidateDocumentDocument, options);
}
export type CreateCandidateDocumentMutationHookResult = ReturnType<
  typeof useCreateCandidateDocumentMutation
>;
export type CreateCandidateDocumentMutationResult =
  Apollo.MutationResult<CreateCandidateDocumentMutation>;
export type CreateCandidateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateDocumentMutation,
  CreateCandidateDocumentMutationVariables
>;
export const CreateCandidateDocument = gql`
  mutation CreateCandidate($input: CreateCandidateInput!) {
    createCandidate(input: $input) {
      id
    }
  }
`;
export type CreateCandidateMutationFn = Apollo.MutationFunction<
  CreateCandidateMutation,
  CreateCandidateMutationVariables
>;

/**
 * __useCreateCandidateMutation__
 *
 * To run a mutation, you first call `useCreateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateMutation, { data, loading, error }] = useCreateCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCandidateMutation,
    CreateCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCandidateMutation,
    CreateCandidateMutationVariables
  >(CreateCandidateDocument, options);
}
export type CreateCandidateMutationHookResult = ReturnType<
  typeof useCreateCandidateMutation
>;
export type CreateCandidateMutationResult =
  Apollo.MutationResult<CreateCandidateMutation>;
export type CreateCandidateMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateMutation,
  CreateCandidateMutationVariables
>;
export const CreateCollaborationDocument = gql`
  mutation CreateCollaboration($input: CollaborationInput!) {
    createCollaboration(input: $input)
  }
`;
export type CreateCollaborationMutationFn = Apollo.MutationFunction<
  CreateCollaborationMutation,
  CreateCollaborationMutationVariables
>;

/**
 * __useCreateCollaborationMutation__
 *
 * To run a mutation, you first call `useCreateCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollaborationMutation, { data, loading, error }] = useCreateCollaborationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollaborationMutation,
    CreateCollaborationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCollaborationMutation,
    CreateCollaborationMutationVariables
  >(CreateCollaborationDocument, options);
}
export type CreateCollaborationMutationHookResult = ReturnType<
  typeof useCreateCollaborationMutation
>;
export type CreateCollaborationMutationResult =
  Apollo.MutationResult<CreateCollaborationMutation>;
export type CreateCollaborationMutationOptions = Apollo.BaseMutationOptions<
  CreateCollaborationMutation,
  CreateCollaborationMutationVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      companyStageId
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult =
  Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CreateEmployerInvitationDocument = gql`
  mutation CreateEmployerInvitation(
    $input: EmployerInvitationByRecruiterInput!
  ) {
    createEmployerInvitation(input: $input) {
      id
      employee {
        id
      }
      employer {
        id
      }
    }
  }
`;
export type CreateEmployerInvitationMutationFn = Apollo.MutationFunction<
  CreateEmployerInvitationMutation,
  CreateEmployerInvitationMutationVariables
>;

/**
 * __useCreateEmployerInvitationMutation__
 *
 * To run a mutation, you first call `useCreateEmployerInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployerInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployerInvitationMutation, { data, loading, error }] = useCreateEmployerInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployerInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmployerInvitationMutation,
    CreateEmployerInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmployerInvitationMutation,
    CreateEmployerInvitationMutationVariables
  >(CreateEmployerInvitationDocument, options);
}
export type CreateEmployerInvitationMutationHookResult = ReturnType<
  typeof useCreateEmployerInvitationMutation
>;
export type CreateEmployerInvitationMutationResult =
  Apollo.MutationResult<CreateEmployerInvitationMutation>;
export type CreateEmployerInvitationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEmployerInvitationMutation,
    CreateEmployerInvitationMutationVariables
  >;
export const CreateMediaUploadLinkDocument = gql`
  query CreateMediaUploadLink($data: CreateMediaUploadLinkInput!) {
    createMediaUploadLink(data: $data) {
      url
      fields {
        Policy
        X_Amz_Algorithm
        X_Amz_Credential
        X_Amz_Date
        X_Amz_Signature
        X_Amz_Security_Token
        bucket
        key
      }
    }
  }
`;

/**
 * __useCreateMediaUploadLinkQuery__
 *
 * To run a query within a React component, call `useCreateMediaUploadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaUploadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMediaUploadLinkQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMediaUploadLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >(CreateMediaUploadLinkDocument, options);
}
export function useCreateMediaUploadLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >(CreateMediaUploadLinkDocument, options);
}
export type CreateMediaUploadLinkQueryHookResult = ReturnType<
  typeof useCreateMediaUploadLinkQuery
>;
export type CreateMediaUploadLinkLazyQueryHookResult = ReturnType<
  typeof useCreateMediaUploadLinkLazyQuery
>;
export type CreateMediaUploadLinkQueryResult = Apollo.QueryResult<
  CreateMediaUploadLinkQuery,
  CreateMediaUploadLinkQueryVariables
>;
export function refetchCreateMediaUploadLinkQuery(
  variables: CreateMediaUploadLinkQueryVariables
) {
  return { query: CreateMediaUploadLinkDocument, variables: variables };
}
export const CreatePublicDossierDocument = gql`
  mutation CreatePublicDossier($roleId: String!) {
    createPublicDossier(roleId: $roleId)
  }
`;
export type CreatePublicDossierMutationFn = Apollo.MutationFunction<
  CreatePublicDossierMutation,
  CreatePublicDossierMutationVariables
>;

/**
 * __useCreatePublicDossierMutation__
 *
 * To run a mutation, you first call `useCreatePublicDossierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicDossierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicDossierMutation, { data, loading, error }] = useCreatePublicDossierMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCreatePublicDossierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePublicDossierMutation,
    CreatePublicDossierMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePublicDossierMutation,
    CreatePublicDossierMutationVariables
  >(CreatePublicDossierDocument, options);
}
export type CreatePublicDossierMutationHookResult = ReturnType<
  typeof useCreatePublicDossierMutation
>;
export type CreatePublicDossierMutationResult =
  Apollo.MutationResult<CreatePublicDossierMutation>;
export type CreatePublicDossierMutationOptions = Apollo.BaseMutationOptions<
  CreatePublicDossierMutation,
  CreatePublicDossierMutationVariables
>;
export const CreateRecruiterDocument = gql`
  mutation CreateRecruiter($input: CreateRecruiterInput!) {
    createRecruiter(input: $input) {
      id
    }
  }
`;
export type CreateRecruiterMutationFn = Apollo.MutationFunction<
  CreateRecruiterMutation,
  CreateRecruiterMutationVariables
>;

/**
 * __useCreateRecruiterMutation__
 *
 * To run a mutation, you first call `useCreateRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecruiterMutation, { data, loading, error }] = useCreateRecruiterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecruiterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecruiterMutation,
    CreateRecruiterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecruiterMutation,
    CreateRecruiterMutationVariables
  >(CreateRecruiterDocument, options);
}
export type CreateRecruiterMutationHookResult = ReturnType<
  typeof useCreateRecruiterMutation
>;
export type CreateRecruiterMutationResult =
  Apollo.MutationResult<CreateRecruiterMutation>;
export type CreateRecruiterMutationOptions = Apollo.BaseMutationOptions<
  CreateRecruiterMutation,
  CreateRecruiterMutationVariables
>;
export const CreateRoleQuestionDocument = gql`
  mutation CreateRoleQuestion($input: CreateRoleQuestionInput!) {
    createRoleQuestion(input: $input) {
      id
    }
  }
`;
export type CreateRoleQuestionMutationFn = Apollo.MutationFunction<
  CreateRoleQuestionMutation,
  CreateRoleQuestionMutationVariables
>;

/**
 * __useCreateRoleQuestionMutation__
 *
 * To run a mutation, you first call `useCreateRoleQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleQuestionMutation, { data, loading, error }] = useCreateRoleQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleQuestionMutation,
    CreateRoleQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleQuestionMutation,
    CreateRoleQuestionMutationVariables
  >(CreateRoleQuestionDocument, options);
}
export type CreateRoleQuestionMutationHookResult = ReturnType<
  typeof useCreateRoleQuestionMutation
>;
export type CreateRoleQuestionMutationResult =
  Apollo.MutationResult<CreateRoleQuestionMutation>;
export type CreateRoleQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleQuestionMutation,
  CreateRoleQuestionMutationVariables
>;
export const CreateRoleRequirementDocument = gql`
  mutation CreateRoleRequirement($input: CreateRoleRequirementInput!) {
    createRoleRequirement(input: $input) {
      createdAt
      deletedAt
      id
      mustHave
      prompt
      updatedAt
    }
  }
`;
export type CreateRoleRequirementMutationFn = Apollo.MutationFunction<
  CreateRoleRequirementMutation,
  CreateRoleRequirementMutationVariables
>;

/**
 * __useCreateRoleRequirementMutation__
 *
 * To run a mutation, you first call `useCreateRoleRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleRequirementMutation, { data, loading, error }] = useCreateRoleRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleRequirementMutation,
    CreateRoleRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleRequirementMutation,
    CreateRoleRequirementMutationVariables
  >(CreateRoleRequirementDocument, options);
}
export type CreateRoleRequirementMutationHookResult = ReturnType<
  typeof useCreateRoleRequirementMutation
>;
export type CreateRoleRequirementMutationResult =
  Apollo.MutationResult<CreateRoleRequirementMutation>;
export type CreateRoleRequirementMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleRequirementMutation,
  CreateRoleRequirementMutationVariables
>;
export const CreateRoleRequirementsDocument = gql`
  mutation CreateRoleRequirements($inputs: [CreateRoleRequirementInput!]!) {
    createRoleRequirements(inputs: $inputs) {
      createdAt
      deletedAt
      id
      mustHave
      prompt
      updatedAt
    }
  }
`;
export type CreateRoleRequirementsMutationFn = Apollo.MutationFunction<
  CreateRoleRequirementsMutation,
  CreateRoleRequirementsMutationVariables
>;

/**
 * __useCreateRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useCreateRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleRequirementsMutation, { data, loading, error }] = useCreateRoleRequirementsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleRequirementsMutation,
    CreateRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleRequirementsMutation,
    CreateRoleRequirementsMutationVariables
  >(CreateRoleRequirementsDocument, options);
}
export type CreateRoleRequirementsMutationHookResult = ReturnType<
  typeof useCreateRoleRequirementsMutation
>;
export type CreateRoleRequirementsMutationResult =
  Apollo.MutationResult<CreateRoleRequirementsMutation>;
export type CreateRoleRequirementsMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleRequirementsMutation,
  CreateRoleRequirementsMutationVariables
>;
export const CreateRoleTitleDocument = gql`
  mutation CreateRoleTitle($roleName: String!) {
    createRoleTitle(roleName: $roleName) {
      id
    }
  }
`;
export type CreateRoleTitleMutationFn = Apollo.MutationFunction<
  CreateRoleTitleMutation,
  CreateRoleTitleMutationVariables
>;

/**
 * __useCreateRoleTitleMutation__
 *
 * To run a mutation, you first call `useCreateRoleTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleTitleMutation, { data, loading, error }] = useCreateRoleTitleMutation({
 *   variables: {
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useCreateRoleTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleTitleMutation,
    CreateRoleTitleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleTitleMutation,
    CreateRoleTitleMutationVariables
  >(CreateRoleTitleDocument, options);
}
export type CreateRoleTitleMutationHookResult = ReturnType<
  typeof useCreateRoleTitleMutation
>;
export type CreateRoleTitleMutationResult =
  Apollo.MutationResult<CreateRoleTitleMutation>;
export type CreateRoleTitleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleTitleMutation,
  CreateRoleTitleMutationVariables
>;
export const CreateSearchAgreementDocument = gql`
  mutation CreateSearchAgreement(
    $searchAgreementInput: CreateSearchAgreementRecruiterInput!
  ) {
    createSearchAgreement(searchAgreementInput: $searchAgreementInput) {
      id
    }
  }
`;
export type CreateSearchAgreementMutationFn = Apollo.MutationFunction<
  CreateSearchAgreementMutation,
  CreateSearchAgreementMutationVariables
>;

/**
 * __useCreateSearchAgreementMutation__
 *
 * To run a mutation, you first call `useCreateSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchAgreementMutation, { data, loading, error }] = useCreateSearchAgreementMutation({
 *   variables: {
 *      searchAgreementInput: // value for 'searchAgreementInput'
 *   },
 * });
 */
export function useCreateSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchAgreementMutation,
    CreateSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSearchAgreementMutation,
    CreateSearchAgreementMutationVariables
  >(CreateSearchAgreementDocument, options);
}
export type CreateSearchAgreementMutationHookResult = ReturnType<
  typeof useCreateSearchAgreementMutation
>;
export type CreateSearchAgreementMutationResult =
  Apollo.MutationResult<CreateSearchAgreementMutation>;
export type CreateSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  CreateSearchAgreementMutation,
  CreateSearchAgreementMutationVariables
>;
export const CreateSearchReviewDocument = gql`
  mutation CreateSearchReview(
    $input: RecruiterSearchReviewInput!
    $searchAgreementRoleId: String!
  ) {
    createSearchReview(
      input: $input
      searchAgreementRoleId: $searchAgreementRoleId
    )
  }
`;
export type CreateSearchReviewMutationFn = Apollo.MutationFunction<
  CreateSearchReviewMutation,
  CreateSearchReviewMutationVariables
>;

/**
 * __useCreateSearchReviewMutation__
 *
 * To run a mutation, you first call `useCreateSearchReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchReviewMutation, { data, loading, error }] = useCreateSearchReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *      searchAgreementRoleId: // value for 'searchAgreementRoleId'
 *   },
 * });
 */
export function useCreateSearchReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchReviewMutation,
    CreateSearchReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSearchReviewMutation,
    CreateSearchReviewMutationVariables
  >(CreateSearchReviewDocument, options);
}
export type CreateSearchReviewMutationHookResult = ReturnType<
  typeof useCreateSearchReviewMutation
>;
export type CreateSearchReviewMutationResult =
  Apollo.MutationResult<CreateSearchReviewMutation>;
export type CreateSearchReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateSearchReviewMutation,
  CreateSearchReviewMutationVariables
>;
export const CurrentRecruiterDocument = gql`
  query CurrentRecruiter {
    currentRecruiter {
      id
      bio
      bioJSON
      showMarketplace
      showSearches
      minPricePercent
      maxPricePercent
      schedulingLink15Min
      schedulingLink30Min
      schedulingLink60Min
      isMarketplaceRecruiter
      location {
        id
        cityName
      }
      cities {
        id
        cityName
      }
      roleFunctions {
        id
        name
      }
      timezone {
        id
        timezoneName
        timezoneOffset
      }
      agency {
        id
        name
        isTestAccount
        createdAt
      }
      linkedIn
      title
      onboardingStatus
      user {
        firstName
        lastName
        name
        phoneNumber
        photoUrl
        isHeadRaceAdmin
        email
        userConsents {
          id
          versionNumber
          userConsentType
        }
      }
    }
  }
`;

/**
 * __useCurrentRecruiterQuery__
 *
 * To run a query within a React component, call `useCurrentRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentRecruiterQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentRecruiterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentRecruiterQuery,
    CurrentRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentRecruiterQuery, CurrentRecruiterQueryVariables>(
    CurrentRecruiterDocument,
    options
  );
}
export function useCurrentRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentRecruiterQuery,
    CurrentRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentRecruiterQuery,
    CurrentRecruiterQueryVariables
  >(CurrentRecruiterDocument, options);
}
export type CurrentRecruiterQueryHookResult = ReturnType<
  typeof useCurrentRecruiterQuery
>;
export type CurrentRecruiterLazyQueryHookResult = ReturnType<
  typeof useCurrentRecruiterLazyQuery
>;
export type CurrentRecruiterQueryResult = Apollo.QueryResult<
  CurrentRecruiterQuery,
  CurrentRecruiterQueryVariables
>;
export function refetchCurrentRecruiterQuery(
  variables?: CurrentRecruiterQueryVariables
) {
  return { query: CurrentRecruiterDocument, variables: variables };
}
export const CurrentRecruiterTeamDocument = gql`
  query CurrentRecruiterTeam {
    currentRecruiterTeam {
      id
      user {
        firstName
        lastName
        email
        name
        photoUrl
      }
    }
  }
`;

/**
 * __useCurrentRecruiterTeamQuery__
 *
 * To run a query within a React component, call `useCurrentRecruiterTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentRecruiterTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentRecruiterTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentRecruiterTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentRecruiterTeamQuery,
    CurrentRecruiterTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentRecruiterTeamQuery,
    CurrentRecruiterTeamQueryVariables
  >(CurrentRecruiterTeamDocument, options);
}
export function useCurrentRecruiterTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentRecruiterTeamQuery,
    CurrentRecruiterTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentRecruiterTeamQuery,
    CurrentRecruiterTeamQueryVariables
  >(CurrentRecruiterTeamDocument, options);
}
export type CurrentRecruiterTeamQueryHookResult = ReturnType<
  typeof useCurrentRecruiterTeamQuery
>;
export type CurrentRecruiterTeamLazyQueryHookResult = ReturnType<
  typeof useCurrentRecruiterTeamLazyQuery
>;
export type CurrentRecruiterTeamQueryResult = Apollo.QueryResult<
  CurrentRecruiterTeamQuery,
  CurrentRecruiterTeamQueryVariables
>;
export function refetchCurrentRecruiterTeamQuery(
  variables?: CurrentRecruiterTeamQueryVariables
) {
  return { query: CurrentRecruiterTeamDocument, variables: variables };
}
export const DeleteCandidateDocumentDocument = gql`
  mutation DeleteCandidateDocument($documentId: String!) {
    deleteCandidateDocument(documentId: $documentId)
  }
`;
export type DeleteCandidateDocumentMutationFn = Apollo.MutationFunction<
  DeleteCandidateDocumentMutation,
  DeleteCandidateDocumentMutationVariables
>;

/**
 * __useDeleteCandidateDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateDocumentMutation, { data, loading, error }] = useDeleteCandidateDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteCandidateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCandidateDocumentMutation,
    DeleteCandidateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCandidateDocumentMutation,
    DeleteCandidateDocumentMutationVariables
  >(DeleteCandidateDocumentDocument, options);
}
export type DeleteCandidateDocumentMutationHookResult = ReturnType<
  typeof useDeleteCandidateDocumentMutation
>;
export type DeleteCandidateDocumentMutationResult =
  Apollo.MutationResult<DeleteCandidateDocumentMutation>;
export type DeleteCandidateDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateDocumentMutation,
  DeleteCandidateDocumentMutationVariables
>;
export const DeleteRecruiterPlacementDocument = gql`
  mutation DeleteRecruiterPlacement($placementId: String!) {
    deleteRecruiterPlacement(placementId: $placementId)
  }
`;
export type DeleteRecruiterPlacementMutationFn = Apollo.MutationFunction<
  DeleteRecruiterPlacementMutation,
  DeleteRecruiterPlacementMutationVariables
>;

/**
 * __useDeleteRecruiterPlacementMutation__
 *
 * To run a mutation, you first call `useDeleteRecruiterPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecruiterPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecruiterPlacementMutation, { data, loading, error }] = useDeleteRecruiterPlacementMutation({
 *   variables: {
 *      placementId: // value for 'placementId'
 *   },
 * });
 */
export function useDeleteRecruiterPlacementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRecruiterPlacementMutation,
    DeleteRecruiterPlacementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRecruiterPlacementMutation,
    DeleteRecruiterPlacementMutationVariables
  >(DeleteRecruiterPlacementDocument, options);
}
export type DeleteRecruiterPlacementMutationHookResult = ReturnType<
  typeof useDeleteRecruiterPlacementMutation
>;
export type DeleteRecruiterPlacementMutationResult =
  Apollo.MutationResult<DeleteRecruiterPlacementMutation>;
export type DeleteRecruiterPlacementMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteRecruiterPlacementMutation,
    DeleteRecruiterPlacementMutationVariables
  >;
export const DeleteRoleRequirementDocument = gql`
  mutation DeleteRoleRequirement($roleRequirementId: String!) {
    deleteRoleRequirement(roleRequirementId: $roleRequirementId)
  }
`;
export type DeleteRoleRequirementMutationFn = Apollo.MutationFunction<
  DeleteRoleRequirementMutation,
  DeleteRoleRequirementMutationVariables
>;

/**
 * __useDeleteRoleRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteRoleRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleRequirementMutation, { data, loading, error }] = useDeleteRoleRequirementMutation({
 *   variables: {
 *      roleRequirementId: // value for 'roleRequirementId'
 *   },
 * });
 */
export function useDeleteRoleRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleRequirementMutation,
    DeleteRoleRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRoleRequirementMutation,
    DeleteRoleRequirementMutationVariables
  >(DeleteRoleRequirementDocument, options);
}
export type DeleteRoleRequirementMutationHookResult = ReturnType<
  typeof useDeleteRoleRequirementMutation
>;
export type DeleteRoleRequirementMutationResult =
  Apollo.MutationResult<DeleteRoleRequirementMutation>;
export type DeleteRoleRequirementMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleRequirementMutation,
  DeleteRoleRequirementMutationVariables
>;
export const DeleteRoleRequirementsDocument = gql`
  mutation DeleteRoleRequirements($roleIds: [String!]!) {
    deleteRoleRequirements(roleIds: $roleIds)
  }
`;
export type DeleteRoleRequirementsMutationFn = Apollo.MutationFunction<
  DeleteRoleRequirementsMutation,
  DeleteRoleRequirementsMutationVariables
>;

/**
 * __useDeleteRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useDeleteRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleRequirementsMutation, { data, loading, error }] = useDeleteRoleRequirementsMutation({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useDeleteRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleRequirementsMutation,
    DeleteRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRoleRequirementsMutation,
    DeleteRoleRequirementsMutationVariables
  >(DeleteRoleRequirementsDocument, options);
}
export type DeleteRoleRequirementsMutationHookResult = ReturnType<
  typeof useDeleteRoleRequirementsMutation
>;
export type DeleteRoleRequirementsMutationResult =
  Apollo.MutationResult<DeleteRoleRequirementsMutation>;
export type DeleteRoleRequirementsMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleRequirementsMutation,
  DeleteRoleRequirementsMutationVariables
>;
export const EmployeesByEmployerDocument = gql`
  query EmployeesByEmployer($employerId: String!) {
    employeesByEmployer(employerId: $employerId) {
      id
      user {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useEmployeesByEmployerQuery__
 *
 * To run a query within a React component, call `useEmployeesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useEmployeesByEmployerQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >(EmployeesByEmployerDocument, options);
}
export function useEmployeesByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >(EmployeesByEmployerDocument, options);
}
export type EmployeesByEmployerQueryHookResult = ReturnType<
  typeof useEmployeesByEmployerQuery
>;
export type EmployeesByEmployerLazyQueryHookResult = ReturnType<
  typeof useEmployeesByEmployerLazyQuery
>;
export type EmployeesByEmployerQueryResult = Apollo.QueryResult<
  EmployeesByEmployerQuery,
  EmployeesByEmployerQueryVariables
>;
export function refetchEmployeesByEmployerQuery(
  variables: EmployeesByEmployerQueryVariables
) {
  return { query: EmployeesByEmployerDocument, variables: variables };
}
export const EmployerInvitationsDocument = gql`
  query EmployerInvitations {
    employerInvitations {
      id
      employee {
        user {
          email
          name
          photoUrl
        }
        employer {
          company {
            name
          }
        }
      }
      status
    }
  }
`;

/**
 * __useEmployerInvitationsQuery__
 *
 * To run a query within a React component, call `useEmployerInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployerInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployerInvitationsQuery,
    EmployerInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployerInvitationsQuery,
    EmployerInvitationsQueryVariables
  >(EmployerInvitationsDocument, options);
}
export function useEmployerInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployerInvitationsQuery,
    EmployerInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployerInvitationsQuery,
    EmployerInvitationsQueryVariables
  >(EmployerInvitationsDocument, options);
}
export type EmployerInvitationsQueryHookResult = ReturnType<
  typeof useEmployerInvitationsQuery
>;
export type EmployerInvitationsLazyQueryHookResult = ReturnType<
  typeof useEmployerInvitationsLazyQuery
>;
export type EmployerInvitationsQueryResult = Apollo.QueryResult<
  EmployerInvitationsQuery,
  EmployerInvitationsQueryVariables
>;
export function refetchEmployerInvitationsQuery(
  variables?: EmployerInvitationsQueryVariables
) {
  return { query: EmployerInvitationsDocument, variables: variables };
}
export const HasArchivedSearchAgreementsDocument = gql`
  query HasArchivedSearchAgreements {
    hasArchivedSearchAgreements
  }
`;

/**
 * __useHasArchivedSearchAgreementsQuery__
 *
 * To run a query within a React component, call `useHasArchivedSearchAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasArchivedSearchAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasArchivedSearchAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasArchivedSearchAgreementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >(HasArchivedSearchAgreementsDocument, options);
}
export function useHasArchivedSearchAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >(HasArchivedSearchAgreementsDocument, options);
}
export type HasArchivedSearchAgreementsQueryHookResult = ReturnType<
  typeof useHasArchivedSearchAgreementsQuery
>;
export type HasArchivedSearchAgreementsLazyQueryHookResult = ReturnType<
  typeof useHasArchivedSearchAgreementsLazyQuery
>;
export type HasArchivedSearchAgreementsQueryResult = Apollo.QueryResult<
  HasArchivedSearchAgreementsQuery,
  HasArchivedSearchAgreementsQueryVariables
>;
export function refetchHasArchivedSearchAgreementsQuery(
  variables?: HasArchivedSearchAgreementsQueryVariables
) {
  return { query: HasArchivedSearchAgreementsDocument, variables: variables };
}
export const HasFirstInvoicePaidDocument = gql`
  query HasFirstInvoicePaid {
    hasFirstInvoicePaid
  }
`;

/**
 * __useHasFirstInvoicePaidQuery__
 *
 * To run a query within a React component, call `useHasFirstInvoicePaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasFirstInvoicePaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasFirstInvoicePaidQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasFirstInvoicePaidQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasFirstInvoicePaidQuery,
    HasFirstInvoicePaidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HasFirstInvoicePaidQuery,
    HasFirstInvoicePaidQueryVariables
  >(HasFirstInvoicePaidDocument, options);
}
export function useHasFirstInvoicePaidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasFirstInvoicePaidQuery,
    HasFirstInvoicePaidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasFirstInvoicePaidQuery,
    HasFirstInvoicePaidQueryVariables
  >(HasFirstInvoicePaidDocument, options);
}
export type HasFirstInvoicePaidQueryHookResult = ReturnType<
  typeof useHasFirstInvoicePaidQuery
>;
export type HasFirstInvoicePaidLazyQueryHookResult = ReturnType<
  typeof useHasFirstInvoicePaidLazyQuery
>;
export type HasFirstInvoicePaidQueryResult = Apollo.QueryResult<
  HasFirstInvoicePaidQuery,
  HasFirstInvoicePaidQueryVariables
>;
export function refetchHasFirstInvoicePaidQuery(
  variables?: HasFirstInvoicePaidQueryVariables
) {
  return { query: HasFirstInvoicePaidDocument, variables: variables };
}
export const InvoicePdfUrlDocument = gql`
  query InvoicePdfUrl($invoiceId: String!) {
    invoicePdfUrl(invoiceId: $invoiceId)
  }
`;

/**
 * __useInvoicePdfUrlQuery__
 *
 * To run a query within a React component, call `useInvoicePdfUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePdfUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePdfUrlQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoicePdfUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoicePdfUrlQuery,
    InvoicePdfUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicePdfUrlQuery, InvoicePdfUrlQueryVariables>(
    InvoicePdfUrlDocument,
    options
  );
}
export function useInvoicePdfUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoicePdfUrlQuery,
    InvoicePdfUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicePdfUrlQuery, InvoicePdfUrlQueryVariables>(
    InvoicePdfUrlDocument,
    options
  );
}
export type InvoicePdfUrlQueryHookResult = ReturnType<
  typeof useInvoicePdfUrlQuery
>;
export type InvoicePdfUrlLazyQueryHookResult = ReturnType<
  typeof useInvoicePdfUrlLazyQuery
>;
export type InvoicePdfUrlQueryResult = Apollo.QueryResult<
  InvoicePdfUrlQuery,
  InvoicePdfUrlQueryVariables
>;
export function refetchInvoicePdfUrlQuery(
  variables: InvoicePdfUrlQueryVariables
) {
  return { query: InvoicePdfUrlDocument, variables: variables };
}
export const LatestMarketplaceRolesDocument = gql`
  query LatestMarketplaceRoles {
    latestMarketplaceRoles {
      id
      name
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      cities {
        cityName
      }
      employer {
        company {
          name
        }
      }
      fee
      feeType
      marketplaceVisibility
      roleType
    }
  }
`;

/**
 * __useLatestMarketplaceRolesQuery__
 *
 * To run a query within a React component, call `useLatestMarketplaceRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestMarketplaceRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestMarketplaceRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestMarketplaceRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestMarketplaceRolesQuery,
    LatestMarketplaceRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestMarketplaceRolesQuery,
    LatestMarketplaceRolesQueryVariables
  >(LatestMarketplaceRolesDocument, options);
}
export function useLatestMarketplaceRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestMarketplaceRolesQuery,
    LatestMarketplaceRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestMarketplaceRolesQuery,
    LatestMarketplaceRolesQueryVariables
  >(LatestMarketplaceRolesDocument, options);
}
export type LatestMarketplaceRolesQueryHookResult = ReturnType<
  typeof useLatestMarketplaceRolesQuery
>;
export type LatestMarketplaceRolesLazyQueryHookResult = ReturnType<
  typeof useLatestMarketplaceRolesLazyQuery
>;
export type LatestMarketplaceRolesQueryResult = Apollo.QueryResult<
  LatestMarketplaceRolesQuery,
  LatestMarketplaceRolesQueryVariables
>;
export function refetchLatestMarketplaceRolesQuery(
  variables?: LatestMarketplaceRolesQueryVariables
) {
  return { query: LatestMarketplaceRolesDocument, variables: variables };
}
export const LeadByIdDocument = gql`
  query LeadById($id: String!) {
    leadById(id: $id) {
      id
      createdAt
      displayStatus
      recruiterStatus
      employerStatus
      searchAgreement {
        id
        createdAt
        exclusive
        whenFirstInvoice
        marketplacePercentFee
        signedByRecruiter {
          user {
            name
          }
          agency {
            name
          }
        }
      }
      searchAgreementId
      searchAgreement {
        status
      }
      role {
        id
        name
        description
        descriptionJSON
        createdAt
        quantity
        expectedFirstYearSalaryMin
        expectedFirstYearSalaryMax
        expectedFirstYearShareCount
        remoteWorkPolicy
        hiringManager {
          id
          user {
            name
          }
        }
        roleRequirements {
          mustHave
          id
          prompt
        }
        roleSeniority {
          id
          name
        }
        roleFunction {
          id
          name
        }
        cities {
          id
          cityName
          stateAbbrev
        }
        employer {
          id
          company {
            name
            logo
            logoUrl
            linkedIn
            crunchbase
            website
            intro
            introJSON
            tagline
            overviewJSON
            revenue
            totalFunding
            yoyGrowth
            tam
            latestFundingRoundAmount
            latestFundingRoundType
            investors
            employeeCount
            teamMembers {
              id
              name
              title
              linkedin
            }
            perks {
              id
              type
            }
            press {
              id
              title
              url
            }
            companyStage {
              id
              name
            }
          }
        }
        searchAgreementRoles {
          searchAgreement {
            id
          }
        }
      }
      collaboration {
        status
        splitPercentage
        primaryRecruiter {
          user {
            name
          }
        }
        searchAgreementRole {
          searchAgreement {
            signedByRecruiter {
              user {
                name
              }
              agency {
                name
              }
            }
          }
        }
        agency {
          name
        }
      }
      placementFee
      placementFeeType
    }
  }
`;

/**
 * __useLeadByIdQuery__
 *
 * To run a query within a React component, call `useLeadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeadByIdQuery(
  baseOptions: Apollo.QueryHookOptions<LeadByIdQuery, LeadByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeadByIdQuery, LeadByIdQueryVariables>(
    LeadByIdDocument,
    options
  );
}
export function useLeadByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeadByIdQuery,
    LeadByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeadByIdQuery, LeadByIdQueryVariables>(
    LeadByIdDocument,
    options
  );
}
export type LeadByIdQueryHookResult = ReturnType<typeof useLeadByIdQuery>;
export type LeadByIdLazyQueryHookResult = ReturnType<
  typeof useLeadByIdLazyQuery
>;
export type LeadByIdQueryResult = Apollo.QueryResult<
  LeadByIdQuery,
  LeadByIdQueryVariables
>;
export function refetchLeadByIdQuery(variables: LeadByIdQueryVariables) {
  return { query: LeadByIdDocument, variables: variables };
}
export const LeadsByRecruiterAgencyDocument = gql`
  query LeadsByRecruiterAgency {
    leadsByRecruiterAgency {
      id
      createdAt
      recruiterStatus
      employerStatus
      displayStatus
      role {
        name
      }
      companyName
      searchAgreementId
      collaborationId
    }
  }
`;

/**
 * __useLeadsByRecruiterAgencyQuery__
 *
 * To run a query within a React component, call `useLeadsByRecruiterAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsByRecruiterAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsByRecruiterAgencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeadsByRecruiterAgencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeadsByRecruiterAgencyQuery,
    LeadsByRecruiterAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LeadsByRecruiterAgencyQuery,
    LeadsByRecruiterAgencyQueryVariables
  >(LeadsByRecruiterAgencyDocument, options);
}
export function useLeadsByRecruiterAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeadsByRecruiterAgencyQuery,
    LeadsByRecruiterAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeadsByRecruiterAgencyQuery,
    LeadsByRecruiterAgencyQueryVariables
  >(LeadsByRecruiterAgencyDocument, options);
}
export type LeadsByRecruiterAgencyQueryHookResult = ReturnType<
  typeof useLeadsByRecruiterAgencyQuery
>;
export type LeadsByRecruiterAgencyLazyQueryHookResult = ReturnType<
  typeof useLeadsByRecruiterAgencyLazyQuery
>;
export type LeadsByRecruiterAgencyQueryResult = Apollo.QueryResult<
  LeadsByRecruiterAgencyQuery,
  LeadsByRecruiterAgencyQueryVariables
>;
export function refetchLeadsByRecruiterAgencyQuery(
  variables?: LeadsByRecruiterAgencyQueryVariables
) {
  return { query: LeadsByRecruiterAgencyDocument, variables: variables };
}
export const LoadFixturesDocument = gql`
  mutation LoadFixtures {
    loadFixtures
  }
`;
export type LoadFixturesMutationFn = Apollo.MutationFunction<
  LoadFixturesMutation,
  LoadFixturesMutationVariables
>;

/**
 * __useLoadFixturesMutation__
 *
 * To run a mutation, you first call `useLoadFixturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadFixturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadFixturesMutation, { data, loading, error }] = useLoadFixturesMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoadFixturesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoadFixturesMutation,
    LoadFixturesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoadFixturesMutation,
    LoadFixturesMutationVariables
  >(LoadFixturesDocument, options);
}
export type LoadFixturesMutationHookResult = ReturnType<
  typeof useLoadFixturesMutation
>;
export type LoadFixturesMutationResult =
  Apollo.MutationResult<LoadFixturesMutation>;
export type LoadFixturesMutationOptions = Apollo.BaseMutationOptions<
  LoadFixturesMutation,
  LoadFixturesMutationVariables
>;
export const MarketplaceRolesDocument = gql`
  query MarketplaceRoles {
    marketplaceRoles {
      status
      createdAt
      globalCandidateCount
      role {
        id
        name
        description
        expectedFirstYearSalaryMin
        expectedFirstYearSalaryMax
        remoteWorkPolicy
        roleFunction {
          id
          name
        }
        roleSeniority {
          id
        }
        employer {
          company {
            name
            logoUrl
            companyStage {
              id
              name
            }
          }
        }
        cities {
          id
          cityName
          stateAbbrev
        }
      }
      searchInfo {
        id
        placementFee
        marketplaceVisibility
        status
        feeType
      }
    }
  }
`;

/**
 * __useMarketplaceRolesQuery__
 *
 * To run a query within a React component, call `useMarketplaceRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketplaceRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketplaceRolesQuery,
    MarketplaceRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketplaceRolesQuery, MarketplaceRolesQueryVariables>(
    MarketplaceRolesDocument,
    options
  );
}
export function useMarketplaceRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketplaceRolesQuery,
    MarketplaceRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketplaceRolesQuery,
    MarketplaceRolesQueryVariables
  >(MarketplaceRolesDocument, options);
}
export type MarketplaceRolesQueryHookResult = ReturnType<
  typeof useMarketplaceRolesQuery
>;
export type MarketplaceRolesLazyQueryHookResult = ReturnType<
  typeof useMarketplaceRolesLazyQuery
>;
export type MarketplaceRolesQueryResult = Apollo.QueryResult<
  MarketplaceRolesQuery,
  MarketplaceRolesQueryVariables
>;
export function refetchMarketplaceRolesQuery(
  variables?: MarketplaceRolesQueryVariables
) {
  return { query: MarketplaceRolesDocument, variables: variables };
}
export const PayoutsByAgencyDocument = gql`
  query PayoutsByAgency($input: RangeDateInput!) {
    payoutsByAgency(input: $input)
  }
`;

/**
 * __usePayoutsByAgencyQuery__
 *
 * To run a query within a React component, call `usePayoutsByAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutsByAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutsByAgencyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayoutsByAgencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayoutsByAgencyQuery,
    PayoutsByAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoutsByAgencyQuery, PayoutsByAgencyQueryVariables>(
    PayoutsByAgencyDocument,
    options
  );
}
export function usePayoutsByAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayoutsByAgencyQuery,
    PayoutsByAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayoutsByAgencyQuery,
    PayoutsByAgencyQueryVariables
  >(PayoutsByAgencyDocument, options);
}
export type PayoutsByAgencyQueryHookResult = ReturnType<
  typeof usePayoutsByAgencyQuery
>;
export type PayoutsByAgencyLazyQueryHookResult = ReturnType<
  typeof usePayoutsByAgencyLazyQuery
>;
export type PayoutsByAgencyQueryResult = Apollo.QueryResult<
  PayoutsByAgencyQuery,
  PayoutsByAgencyQueryVariables
>;
export function refetchPayoutsByAgencyQuery(
  variables: PayoutsByAgencyQueryVariables
) {
  return { query: PayoutsByAgencyDocument, variables: variables };
}
export const PotentialSearchValueDocument = gql`
  query PotentialSearchValue {
    potentialSearchValue {
      minPotentialSearchValue
      maxPotentialSearchValue
      showEmptyState
    }
  }
`;

/**
 * __usePotentialSearchValueQuery__
 *
 * To run a query within a React component, call `usePotentialSearchValueQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialSearchValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialSearchValueQuery({
 *   variables: {
 *   },
 * });
 */
export function usePotentialSearchValueQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PotentialSearchValueQuery,
    PotentialSearchValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PotentialSearchValueQuery,
    PotentialSearchValueQueryVariables
  >(PotentialSearchValueDocument, options);
}
export function usePotentialSearchValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PotentialSearchValueQuery,
    PotentialSearchValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PotentialSearchValueQuery,
    PotentialSearchValueQueryVariables
  >(PotentialSearchValueDocument, options);
}
export type PotentialSearchValueQueryHookResult = ReturnType<
  typeof usePotentialSearchValueQuery
>;
export type PotentialSearchValueLazyQueryHookResult = ReturnType<
  typeof usePotentialSearchValueLazyQuery
>;
export type PotentialSearchValueQueryResult = Apollo.QueryResult<
  PotentialSearchValueQuery,
  PotentialSearchValueQueryVariables
>;
export function refetchPotentialSearchValueQuery(
  variables?: PotentialSearchValueQueryVariables
) {
  return { query: PotentialSearchValueDocument, variables: variables };
}
export const PotentialEarningsDocument = gql`
  query PotentialEarnings {
    potentialEarnings {
      id
      lowerEarning
      upperEarning
      searchAgreementId
      date
      roleName
      employerName
      feeType
    }
  }
`;

/**
 * __usePotentialEarningsQuery__
 *
 * To run a query within a React component, call `usePotentialEarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialEarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialEarningsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePotentialEarningsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PotentialEarningsQuery,
    PotentialEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PotentialEarningsQuery,
    PotentialEarningsQueryVariables
  >(PotentialEarningsDocument, options);
}
export function usePotentialEarningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PotentialEarningsQuery,
    PotentialEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PotentialEarningsQuery,
    PotentialEarningsQueryVariables
  >(PotentialEarningsDocument, options);
}
export type PotentialEarningsQueryHookResult = ReturnType<
  typeof usePotentialEarningsQuery
>;
export type PotentialEarningsLazyQueryHookResult = ReturnType<
  typeof usePotentialEarningsLazyQuery
>;
export type PotentialEarningsQueryResult = Apollo.QueryResult<
  PotentialEarningsQuery,
  PotentialEarningsQueryVariables
>;
export function refetchPotentialEarningsQuery(
  variables?: PotentialEarningsQueryVariables
) {
  return { query: PotentialEarningsDocument, variables: variables };
}
export const PublicDossierDocument = gql`
  query publicDossier($id: String!) {
    publicDossier(id: $id) {
      id
      candidate {
        id
        status
        candidateInterestVerificationStatus
        recruiter {
          id
          title
          agency {
            name
          }
          user {
            name
            email
            photoUrl
          }
        }
        searchAgreementRole {
          searchAgreement {
            status
          }
        }
      }
      role {
        name
        createdAt
        quantity
        description
        descriptionJSON
        expectedFirstYearSalaryMin
        expectedFirstYearSalaryMax
        expectedFirstYearShareCount
        remoteWorkPolicy
        employer {
          company {
            name
            logo
            logoUrl
            linkedIn
            crunchbase
            website
            intro
            introJSON
            tagline
            overviewJSON
            revenue
            totalFunding
            yoyGrowth
            tam
            latestFundingRoundAmount
            latestFundingRoundType
            investors
            employeeCount
            teamMembers {
              id
              name
              title
              linkedin
            }
            perks {
              id
              type
            }
            press {
              id
              title
              url
            }
            companyStage {
              id
              name
            }
          }
        }
        roleSeniority {
          id
          name
        }
        roleFunction {
          id
          name
        }
        cities {
          id
          cityName
          stateAbbrev
        }
        roleRequirements {
          mustHave
          id
          prompt
        }
      }
    }
  }
`;

/**
 * __usePublicDossierQuery__
 *
 * To run a query within a React component, call `usePublicDossierQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicDossierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicDossierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicDossierQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicDossierQuery,
    PublicDossierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicDossierQuery, PublicDossierQueryVariables>(
    PublicDossierDocument,
    options
  );
}
export function usePublicDossierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicDossierQuery,
    PublicDossierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicDossierQuery, PublicDossierQueryVariables>(
    PublicDossierDocument,
    options
  );
}
export type PublicDossierQueryHookResult = ReturnType<
  typeof usePublicDossierQuery
>;
export type PublicDossierLazyQueryHookResult = ReturnType<
  typeof usePublicDossierLazyQuery
>;
export type PublicDossierQueryResult = Apollo.QueryResult<
  PublicDossierQuery,
  PublicDossierQueryVariables
>;
export function refetchPublicDossierQuery(
  variables: PublicDossierQueryVariables
) {
  return { query: PublicDossierDocument, variables: variables };
}
export const RecentActivityDocument = gql`
  query RecentActivity {
    recentActivity {
      id
      roleId
      type
      candidateName
      roleName
      date
      candidateActivity
      canceledBy
      pausedBy
      rolePrice {
        feeType
        amount
      }
      actualSalaryRange
      oldSalaryRange
      roleType
      employerName
    }
  }
`;

/**
 * __useRecentActivityQuery__
 *
 * To run a query within a React component, call `useRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentActivityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentActivityQuery,
    RecentActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentActivityQuery, RecentActivityQueryVariables>(
    RecentActivityDocument,
    options
  );
}
export function useRecentActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentActivityQuery,
    RecentActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentActivityQuery, RecentActivityQueryVariables>(
    RecentActivityDocument,
    options
  );
}
export type RecentActivityQueryHookResult = ReturnType<
  typeof useRecentActivityQuery
>;
export type RecentActivityLazyQueryHookResult = ReturnType<
  typeof useRecentActivityLazyQuery
>;
export type RecentActivityQueryResult = Apollo.QueryResult<
  RecentActivityQuery,
  RecentActivityQueryVariables
>;
export function refetchRecentActivityQuery(
  variables?: RecentActivityQueryVariables
) {
  return { query: RecentActivityDocument, variables: variables };
}
export const RecruiterFormOptionsDocument = gql`
  query RecruiterFormOptions {
    recruiterFormOptions {
      roleFunctionOptions {
        label
        value
      }
      roleTitleOptions {
        label
        value
      }
      roleSeniorityOptions {
        label
        value
      }
      cityOptions {
        label
        value
      }
      companyStageOptions {
        label
        value
      }
      industryOptions {
        label
        value
      }
    }
  }
`;

/**
 * __useRecruiterFormOptionsQuery__
 *
 * To run a query within a React component, call `useRecruiterFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterFormOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecruiterFormOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecruiterFormOptionsQuery,
    RecruiterFormOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterFormOptionsQuery,
    RecruiterFormOptionsQueryVariables
  >(RecruiterFormOptionsDocument, options);
}
export function useRecruiterFormOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterFormOptionsQuery,
    RecruiterFormOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterFormOptionsQuery,
    RecruiterFormOptionsQueryVariables
  >(RecruiterFormOptionsDocument, options);
}
export type RecruiterFormOptionsQueryHookResult = ReturnType<
  typeof useRecruiterFormOptionsQuery
>;
export type RecruiterFormOptionsLazyQueryHookResult = ReturnType<
  typeof useRecruiterFormOptionsLazyQuery
>;
export type RecruiterFormOptionsQueryResult = Apollo.QueryResult<
  RecruiterFormOptionsQuery,
  RecruiterFormOptionsQueryVariables
>;
export function refetchRecruiterFormOptionsQuery(
  variables?: RecruiterFormOptionsQueryVariables
) {
  return { query: RecruiterFormOptionsDocument, variables: variables };
}
export const RecruitersDocument = gql`
  query Recruiters {
    recruiters {
      user {
        id
        name
        isHeadRaceAdmin
        userConsents {
          versionNumber
        }
      }
      agency {
        name
      }
    }
  }
`;

/**
 * __useRecruitersQuery__
 *
 * To run a query within a React component, call `useRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecruitersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecruitersQuery,
    RecruitersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecruitersQuery, RecruitersQueryVariables>(
    RecruitersDocument,
    options
  );
}
export function useRecruitersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruitersQuery,
    RecruitersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecruitersQuery, RecruitersQueryVariables>(
    RecruitersDocument,
    options
  );
}
export type RecruitersQueryHookResult = ReturnType<typeof useRecruitersQuery>;
export type RecruitersLazyQueryHookResult = ReturnType<
  typeof useRecruitersLazyQuery
>;
export type RecruitersQueryResult = Apollo.QueryResult<
  RecruitersQuery,
  RecruitersQueryVariables
>;
export function refetchRecruitersQuery(variables?: RecruitersQueryVariables) {
  return { query: RecruitersDocument, variables: variables };
}
export const RecruitersByAgencyDocument = gql`
  query RecruitersByAgency {
    recruitersByAgency {
      id
      user {
        name
      }
    }
  }
`;

/**
 * __useRecruitersByAgencyQuery__
 *
 * To run a query within a React component, call `useRecruitersByAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitersByAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitersByAgencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecruitersByAgencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecruitersByAgencyQuery,
    RecruitersByAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruitersByAgencyQuery,
    RecruitersByAgencyQueryVariables
  >(RecruitersByAgencyDocument, options);
}
export function useRecruitersByAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruitersByAgencyQuery,
    RecruitersByAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruitersByAgencyQuery,
    RecruitersByAgencyQueryVariables
  >(RecruitersByAgencyDocument, options);
}
export type RecruitersByAgencyQueryHookResult = ReturnType<
  typeof useRecruitersByAgencyQuery
>;
export type RecruitersByAgencyLazyQueryHookResult = ReturnType<
  typeof useRecruitersByAgencyLazyQuery
>;
export type RecruitersByAgencyQueryResult = Apollo.QueryResult<
  RecruitersByAgencyQuery,
  RecruitersByAgencyQueryVariables
>;
export function refetchRecruitersByAgencyQuery(
  variables?: RecruitersByAgencyQueryVariables
) {
  return { query: RecruitersByAgencyDocument, variables: variables };
}
export const RejectCandidateDocument = gql`
  mutation RejectCandidate($input: RejectCandidateInput!) {
    rejectCandidate(input: $input) {
      id
    }
  }
`;
export type RejectCandidateMutationFn = Apollo.MutationFunction<
  RejectCandidateMutation,
  RejectCandidateMutationVariables
>;

/**
 * __useRejectCandidateMutation__
 *
 * To run a mutation, you first call `useRejectCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCandidateMutation, { data, loading, error }] = useRejectCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectCandidateMutation,
    RejectCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectCandidateMutation,
    RejectCandidateMutationVariables
  >(RejectCandidateDocument, options);
}
export type RejectCandidateMutationHookResult = ReturnType<
  typeof useRejectCandidateMutation
>;
export type RejectCandidateMutationResult =
  Apollo.MutationResult<RejectCandidateMutation>;
export type RejectCandidateMutationOptions = Apollo.BaseMutationOptions<
  RejectCandidateMutation,
  RejectCandidateMutationVariables
>;
export const RejectLeadDocument = gql`
  mutation RejectLead($id: String!) {
    rejectLead(id: $id) {
      id
    }
  }
`;
export type RejectLeadMutationFn = Apollo.MutationFunction<
  RejectLeadMutation,
  RejectLeadMutationVariables
>;

/**
 * __useRejectLeadMutation__
 *
 * To run a mutation, you first call `useRejectLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectLeadMutation, { data, loading, error }] = useRejectLeadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectLeadMutation,
    RejectLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectLeadMutation, RejectLeadMutationVariables>(
    RejectLeadDocument,
    options
  );
}
export type RejectLeadMutationHookResult = ReturnType<
  typeof useRejectLeadMutation
>;
export type RejectLeadMutationResult =
  Apollo.MutationResult<RejectLeadMutation>;
export type RejectLeadMutationOptions = Apollo.BaseMutationOptions<
  RejectLeadMutation,
  RejectLeadMutationVariables
>;
export const RejectPublicCandidateDocument = gql`
  mutation RejectPublicCandidate($input: RejectCandidateInput!) {
    rejectPublicCandidate(input: $input) {
      id
    }
  }
`;
export type RejectPublicCandidateMutationFn = Apollo.MutationFunction<
  RejectPublicCandidateMutation,
  RejectPublicCandidateMutationVariables
>;

/**
 * __useRejectPublicCandidateMutation__
 *
 * To run a mutation, you first call `useRejectPublicCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPublicCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPublicCandidateMutation, { data, loading, error }] = useRejectPublicCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectPublicCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectPublicCandidateMutation,
    RejectPublicCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectPublicCandidateMutation,
    RejectPublicCandidateMutationVariables
  >(RejectPublicCandidateDocument, options);
}
export type RejectPublicCandidateMutationHookResult = ReturnType<
  typeof useRejectPublicCandidateMutation
>;
export type RejectPublicCandidateMutationResult =
  Apollo.MutationResult<RejectPublicCandidateMutation>;
export type RejectPublicCandidateMutationOptions = Apollo.BaseMutationOptions<
  RejectPublicCandidateMutation,
  RejectPublicCandidateMutationVariables
>;
export const RejectReasonsDocument = gql`
  query RejectReasons($roleId: String!) {
    rejectReasons(roleId: $roleId) {
      activityMeta {
        rejectReasonCategory
        rejectReasonDetails
      }
    }
  }
`;

/**
 * __useRejectReasonsQuery__
 *
 * To run a query within a React component, call `useRejectReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRejectReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRejectReasonsQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRejectReasonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RejectReasonsQuery,
    RejectReasonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RejectReasonsQuery, RejectReasonsQueryVariables>(
    RejectReasonsDocument,
    options
  );
}
export function useRejectReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RejectReasonsQuery,
    RejectReasonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RejectReasonsQuery, RejectReasonsQueryVariables>(
    RejectReasonsDocument,
    options
  );
}
export type RejectReasonsQueryHookResult = ReturnType<
  typeof useRejectReasonsQuery
>;
export type RejectReasonsLazyQueryHookResult = ReturnType<
  typeof useRejectReasonsLazyQuery
>;
export type RejectReasonsQueryResult = Apollo.QueryResult<
  RejectReasonsQuery,
  RejectReasonsQueryVariables
>;
export function refetchRejectReasonsQuery(
  variables: RejectReasonsQueryVariables
) {
  return { query: RejectReasonsDocument, variables: variables };
}
export const RoleByIdDocument = gql`
  query RoleById($id: String!) {
    roleById(id: $id) {
      id
      publicDossierUrl
      isDossierShareable
      isPrimaryAgency
      roleType
      employer {
        id
        primaryAgencyId
        company {
          name
          description
          logoUrl
          tagline
          linkedIn
          crunchbase
          website
        }
      }
      name
      description
      jobDescriptionLink
      createdAt
      updatedAt
      quantity
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      expectedFirstYearShareCount
      remoteWorkPolicy
      noteJSON
      hiringManager {
        id
        user {
          name
          email
          phoneNumber
        }
      }
      roleFunction {
        id
        name
      }
      roleSeniority {
        name
      }
      cities {
        cityName
        stateAbbrev
      }
      primarySearchAgreementId
      primarySearchAgreementStatus
      primarySearchAgreementEndAt
      primarySearchAgreement {
        exclusive
        whenFirstInvoice
        beginAt
        marketplacePercentFee
        includeReplacementSearch
        replacementSearchTerminateDays
        placementFeeSchedule {
          feeAmount
          feeScheduleType
        }
        signedByRecruiter {
          agency {
            id
            name
          }
          user {
            name
          }
        }
      }
      primarySearchAgreementVisibility
      primarySearchAgreementRoleId
      primarySearchAgreementFeeTriggerType
      primarySearchAgreementMarketplaceRolePrice {
        amount
        feeType
      }
      showRequirementsBillboard
      recruiterName
      agencyName
      collaboration {
        id
        splitPercentage
        status
        primaryRecruiter {
          user {
            name
          }
        }
        agency {
          id
        }
        recruiter {
          user {
            name
          }
        }
      }
      mostCommonRejectReasons {
        title
        description
      }
      hasReview
      placementFee
      placementFeeType
      collaborationAgencyName
      collaborationRecruiterName
      isCollaborationRecruiter
      closedSearchProduct {
        closedSearchAgreement {
          id
          marketplaceVisibility
          status
        }
        closedSearchProductMarketplaceRolePrice {
          amount
          feeType
        }
      }
    }
  }
`;

/**
 * __useRoleByIdQuery__
 *
 * To run a query within a React component, call `useRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<RoleByIdQuery, RoleByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleByIdQuery, RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export function useRoleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleByIdQuery,
    RoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleByIdQuery, RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export type RoleByIdQueryHookResult = ReturnType<typeof useRoleByIdQuery>;
export type RoleByIdLazyQueryHookResult = ReturnType<
  typeof useRoleByIdLazyQuery
>;
export type RoleByIdQueryResult = Apollo.QueryResult<
  RoleByIdQuery,
  RoleByIdQueryVariables
>;
export function refetchRoleByIdQuery(variables: RoleByIdQueryVariables) {
  return { query: RoleByIdDocument, variables: variables };
}
export const RoleCandidateSubmissionLimitDocument = gql`
  query RoleCandidateSubmissionLimit($roleId: String!) {
    roleCandidateSubmissionLimit(roleId: $roleId)
  }
`;

/**
 * __useRoleCandidateSubmissionLimitQuery__
 *
 * To run a query within a React component, call `useRoleCandidateSubmissionLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleCandidateSubmissionLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleCandidateSubmissionLimitQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRoleCandidateSubmissionLimitQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleCandidateSubmissionLimitQuery,
    RoleCandidateSubmissionLimitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleCandidateSubmissionLimitQuery,
    RoleCandidateSubmissionLimitQueryVariables
  >(RoleCandidateSubmissionLimitDocument, options);
}
export function useRoleCandidateSubmissionLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleCandidateSubmissionLimitQuery,
    RoleCandidateSubmissionLimitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleCandidateSubmissionLimitQuery,
    RoleCandidateSubmissionLimitQueryVariables
  >(RoleCandidateSubmissionLimitDocument, options);
}
export type RoleCandidateSubmissionLimitQueryHookResult = ReturnType<
  typeof useRoleCandidateSubmissionLimitQuery
>;
export type RoleCandidateSubmissionLimitLazyQueryHookResult = ReturnType<
  typeof useRoleCandidateSubmissionLimitLazyQuery
>;
export type RoleCandidateSubmissionLimitQueryResult = Apollo.QueryResult<
  RoleCandidateSubmissionLimitQuery,
  RoleCandidateSubmissionLimitQueryVariables
>;
export function refetchRoleCandidateSubmissionLimitQuery(
  variables: RoleCandidateSubmissionLimitQueryVariables
) {
  return { query: RoleCandidateSubmissionLimitDocument, variables: variables };
}
export const RoleEnrichmentOptionsDocument = gql`
  query RoleEnrichmentOptions {
    roleFunctions {
      id
      name
    }
    roleSeniorities {
      id
      name
    }
    cities {
      id
      cityName
      stateName
    }
  }
`;

/**
 * __useRoleEnrichmentOptionsQuery__
 *
 * To run a query within a React component, call `useRoleEnrichmentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleEnrichmentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleEnrichmentOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleEnrichmentOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >(RoleEnrichmentOptionsDocument, options);
}
export function useRoleEnrichmentOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >(RoleEnrichmentOptionsDocument, options);
}
export type RoleEnrichmentOptionsQueryHookResult = ReturnType<
  typeof useRoleEnrichmentOptionsQuery
>;
export type RoleEnrichmentOptionsLazyQueryHookResult = ReturnType<
  typeof useRoleEnrichmentOptionsLazyQuery
>;
export type RoleEnrichmentOptionsQueryResult = Apollo.QueryResult<
  RoleEnrichmentOptionsQuery,
  RoleEnrichmentOptionsQueryVariables
>;
export function refetchRoleEnrichmentOptionsQuery(
  variables?: RoleEnrichmentOptionsQueryVariables
) {
  return { query: RoleEnrichmentOptionsDocument, variables: variables };
}
export const RoleFunctionsAndCitiesDocument = gql`
  query RoleFunctionsAndCities {
    roleFunctionsAndCities {
      roleFunctions {
        id
        name
      }
      cities {
        id
        cityName
        stateAbbrev
      }
    }
  }
`;

/**
 * __useRoleFunctionsAndCitiesQuery__
 *
 * To run a query within a React component, call `useRoleFunctionsAndCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleFunctionsAndCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleFunctionsAndCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleFunctionsAndCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RoleFunctionsAndCitiesQuery,
    RoleFunctionsAndCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleFunctionsAndCitiesQuery,
    RoleFunctionsAndCitiesQueryVariables
  >(RoleFunctionsAndCitiesDocument, options);
}
export function useRoleFunctionsAndCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleFunctionsAndCitiesQuery,
    RoleFunctionsAndCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleFunctionsAndCitiesQuery,
    RoleFunctionsAndCitiesQueryVariables
  >(RoleFunctionsAndCitiesDocument, options);
}
export type RoleFunctionsAndCitiesQueryHookResult = ReturnType<
  typeof useRoleFunctionsAndCitiesQuery
>;
export type RoleFunctionsAndCitiesLazyQueryHookResult = ReturnType<
  typeof useRoleFunctionsAndCitiesLazyQuery
>;
export type RoleFunctionsAndCitiesQueryResult = Apollo.QueryResult<
  RoleFunctionsAndCitiesQuery,
  RoleFunctionsAndCitiesQueryVariables
>;
export function refetchRoleFunctionsAndCitiesQuery(
  variables?: RoleFunctionsAndCitiesQueryVariables
) {
  return { query: RoleFunctionsAndCitiesDocument, variables: variables };
}
export const RoleQuestionsDocument = gql`
  query RoleQuestions($filter: RoleQuestionFilter!) {
    roleQuestions(filter: $filter) {
      id
      question
      answer
      updatedAt
      answerer {
        user {
          photoUrl
          name
        }
      }
    }
  }
`;

/**
 * __useRoleQuestionsQuery__
 *
 * To run a query within a React component, call `useRoleQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoleQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleQuestionsQuery,
    RoleQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleQuestionsQuery, RoleQuestionsQueryVariables>(
    RoleQuestionsDocument,
    options
  );
}
export function useRoleQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleQuestionsQuery,
    RoleQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleQuestionsQuery, RoleQuestionsQueryVariables>(
    RoleQuestionsDocument,
    options
  );
}
export type RoleQuestionsQueryHookResult = ReturnType<
  typeof useRoleQuestionsQuery
>;
export type RoleQuestionsLazyQueryHookResult = ReturnType<
  typeof useRoleQuestionsLazyQuery
>;
export type RoleQuestionsQueryResult = Apollo.QueryResult<
  RoleQuestionsQuery,
  RoleQuestionsQueryVariables
>;
export function refetchRoleQuestionsQuery(
  variables: RoleQuestionsQueryVariables
) {
  return { query: RoleQuestionsDocument, variables: variables };
}
export const RoleRequirementTemplatesDocument = gql`
  query RoleRequirementTemplates($filter: RoleRequirementTemplateFilter!) {
    roleRequirementTemplates(filter: $filter) {
      createdAt
      id
      template
      updatedAt
    }
  }
`;

/**
 * __useRoleRequirementTemplatesQuery__
 *
 * To run a query within a React component, call `useRoleRequirementTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleRequirementTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleRequirementTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoleRequirementTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >(RoleRequirementTemplatesDocument, options);
}
export function useRoleRequirementTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >(RoleRequirementTemplatesDocument, options);
}
export type RoleRequirementTemplatesQueryHookResult = ReturnType<
  typeof useRoleRequirementTemplatesQuery
>;
export type RoleRequirementTemplatesLazyQueryHookResult = ReturnType<
  typeof useRoleRequirementTemplatesLazyQuery
>;
export type RoleRequirementTemplatesQueryResult = Apollo.QueryResult<
  RoleRequirementTemplatesQuery,
  RoleRequirementTemplatesQueryVariables
>;
export function refetchRoleRequirementTemplatesQuery(
  variables: RoleRequirementTemplatesQueryVariables
) {
  return { query: RoleRequirementTemplatesDocument, variables: variables };
}
export const RoleRequirementsDocument = gql`
  query RoleRequirements($filter: RoleRequirementFilter!) {
    roleRequirements(filter: $filter) {
      createdAt
      deletedAt
      mustHave
      id
      prompt
      updatedAt
    }
  }
`;

/**
 * __useRoleRequirementsQuery__
 *
 * To run a query within a React component, call `useRoleRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleRequirementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoleRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleRequirementsQuery,
    RoleRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleRequirementsQuery, RoleRequirementsQueryVariables>(
    RoleRequirementsDocument,
    options
  );
}
export function useRoleRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleRequirementsQuery,
    RoleRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleRequirementsQuery,
    RoleRequirementsQueryVariables
  >(RoleRequirementsDocument, options);
}
export type RoleRequirementsQueryHookResult = ReturnType<
  typeof useRoleRequirementsQuery
>;
export type RoleRequirementsLazyQueryHookResult = ReturnType<
  typeof useRoleRequirementsLazyQuery
>;
export type RoleRequirementsQueryResult = Apollo.QueryResult<
  RoleRequirementsQuery,
  RoleRequirementsQueryVariables
>;
export function refetchRoleRequirementsQuery(
  variables: RoleRequirementsQueryVariables
) {
  return { query: RoleRequirementsDocument, variables: variables };
}
export const RolesDocument = gql`
  query Roles {
    roles {
      id
      name
      description
      quantity
      createdAt
      updatedAt
      candidateCount
      roleType
      employer {
        id
        company {
          name
          description
        }
      }
      cities {
        cityName
        stateAbbrev
      }
      primarySearchAgreementId
      primarySearchAgreementVisibility
      primarySearchAgreementStatus
      collaboration {
        status
      }
    }
  }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options
  );
}
export function useRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options
  );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<
  RolesQuery,
  RolesQueryVariables
>;
export function refetchRolesQuery(variables?: RolesQueryVariables) {
  return { query: RolesDocument, variables: variables };
}
export const SearchAgreementbyIdDocument = gql`
  query SearchAgreementbyId($id: String!) {
    recruiterSearchAgreementById(id: $id) {
      id
      status
      createdAt
      beginAt
      includeReplacementSearch
      replacementSearchTerminateDays
      replacementSearchWindowMonths
      replacementSearchNotificationWindowDays
      isExclusivityCarveOutExplicitListCandidates
      isExclusivityCarveOutCandidatesSourcedByEmployer
      exclusivityCarveOutCandidates
      addendums {
        id
        description
      }
      signedByEmployee {
        id
        user {
          name
          email
        }
      }
      expectedPlacements
      whenFirstInvoice
      employer {
        id
        company {
          name
          description
          linkedIn
          crunchbase
        }
      }
      searchAgreementRoles {
        id
        role {
          id
          name
          expectedFirstYearSalaryMin
          expectedFirstYearSalaryMax
          expectedFirstYearShareCount
          cities {
            id
            cityName
            stateAbbrev
          }
        }
      }
      agency {
        name
      }
      feeSchedules {
        id
        feeScheduleType
        feeAmount
        feeTriggerType
        feeAdjustmentType
        feePaymentTermNetDays
        feeTriggerLimit
        performanceCandidateAcceptQuantity
        oneTimeFeeTriggerAt
        oneTimeFeeRelativeSigningDays
      }
      feeType
      signedByEmployee {
        id
        user {
          name
        }
      }
      canceledByEmployee {
        user {
          name
        }
      }
      employeeCanceledAt
      canceledByRecruiter {
        user {
          name
        }
      }
      recruiterCanceledAt
      employeeSignedAt
      fulfilledAt
      endAt
      exclusive
      marketplacePercentFee
      isSignedOffPlatform
    }
  }
`;

/**
 * __useSearchAgreementbyIdQuery__
 *
 * To run a query within a React component, call `useSearchAgreementbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgreementbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgreementbyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchAgreementbyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAgreementbyIdQuery,
    SearchAgreementbyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAgreementbyIdQuery,
    SearchAgreementbyIdQueryVariables
  >(SearchAgreementbyIdDocument, options);
}
export function useSearchAgreementbyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAgreementbyIdQuery,
    SearchAgreementbyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAgreementbyIdQuery,
    SearchAgreementbyIdQueryVariables
  >(SearchAgreementbyIdDocument, options);
}
export type SearchAgreementbyIdQueryHookResult = ReturnType<
  typeof useSearchAgreementbyIdQuery
>;
export type SearchAgreementbyIdLazyQueryHookResult = ReturnType<
  typeof useSearchAgreementbyIdLazyQuery
>;
export type SearchAgreementbyIdQueryResult = Apollo.QueryResult<
  SearchAgreementbyIdQuery,
  SearchAgreementbyIdQueryVariables
>;
export function refetchSearchAgreementbyIdQuery(
  variables: SearchAgreementbyIdQueryVariables
) {
  return { query: SearchAgreementbyIdDocument, variables: variables };
}
export const SearchAgreementByRecruiterDocument = gql`
  query SearchAgreementByRecruiter($maxCount: Int) {
    searchAgreementByRecruiterAgency(maxCount: $maxCount) {
      id
      status
      employer {
        company {
          name
        }
      }
      searchAgreementRoles {
        id
        role {
          id
          name
        }
      }
      updatedAt
    }
  }
`;

/**
 * __useSearchAgreementByRecruiterQuery__
 *
 * To run a query within a React component, call `useSearchAgreementByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgreementByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgreementByRecruiterQuery({
 *   variables: {
 *      maxCount: // value for 'maxCount'
 *   },
 * });
 */
export function useSearchAgreementByRecruiterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchAgreementByRecruiterQuery,
    SearchAgreementByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAgreementByRecruiterQuery,
    SearchAgreementByRecruiterQueryVariables
  >(SearchAgreementByRecruiterDocument, options);
}
export function useSearchAgreementByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAgreementByRecruiterQuery,
    SearchAgreementByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAgreementByRecruiterQuery,
    SearchAgreementByRecruiterQueryVariables
  >(SearchAgreementByRecruiterDocument, options);
}
export type SearchAgreementByRecruiterQueryHookResult = ReturnType<
  typeof useSearchAgreementByRecruiterQuery
>;
export type SearchAgreementByRecruiterLazyQueryHookResult = ReturnType<
  typeof useSearchAgreementByRecruiterLazyQuery
>;
export type SearchAgreementByRecruiterQueryResult = Apollo.QueryResult<
  SearchAgreementByRecruiterQuery,
  SearchAgreementByRecruiterQueryVariables
>;
export function refetchSearchAgreementByRecruiterQuery(
  variables?: SearchAgreementByRecruiterQueryVariables
) {
  return { query: SearchAgreementByRecruiterDocument, variables: variables };
}
export const SearchArchivedAgreementByRecruiterDocument = gql`
  query SearchArchivedAgreementByRecruiter {
    searchArchivedAgreementByRecruiter {
      id
      status
      employer {
        company {
          name
        }
      }
      searchAgreementRoles {
        id
        role {
          id
          name
        }
      }
      updatedAt
    }
  }
`;

/**
 * __useSearchArchivedAgreementByRecruiterQuery__
 *
 * To run a query within a React component, call `useSearchArchivedAgreementByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchArchivedAgreementByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchArchivedAgreementByRecruiterQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchArchivedAgreementByRecruiterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchArchivedAgreementByRecruiterQuery,
    SearchArchivedAgreementByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchArchivedAgreementByRecruiterQuery,
    SearchArchivedAgreementByRecruiterQueryVariables
  >(SearchArchivedAgreementByRecruiterDocument, options);
}
export function useSearchArchivedAgreementByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchArchivedAgreementByRecruiterQuery,
    SearchArchivedAgreementByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchArchivedAgreementByRecruiterQuery,
    SearchArchivedAgreementByRecruiterQueryVariables
  >(SearchArchivedAgreementByRecruiterDocument, options);
}
export type SearchArchivedAgreementByRecruiterQueryHookResult = ReturnType<
  typeof useSearchArchivedAgreementByRecruiterQuery
>;
export type SearchArchivedAgreementByRecruiterLazyQueryHookResult = ReturnType<
  typeof useSearchArchivedAgreementByRecruiterLazyQuery
>;
export type SearchArchivedAgreementByRecruiterQueryResult = Apollo.QueryResult<
  SearchArchivedAgreementByRecruiterQuery,
  SearchArchivedAgreementByRecruiterQueryVariables
>;
export function refetchSearchArchivedAgreementByRecruiterQuery(
  variables?: SearchArchivedAgreementByRecruiterQueryVariables
) {
  return {
    query: SearchArchivedAgreementByRecruiterDocument,
    variables: variables,
  };
}
export const SendSearchAgreementReminderDocument = gql`
  mutation SendSearchAgreementReminder($searchAgreementId: String!) {
    sendSearchAgreementReminder(searchAgreementId: $searchAgreementId)
  }
`;
export type SendSearchAgreementReminderMutationFn = Apollo.MutationFunction<
  SendSearchAgreementReminderMutation,
  SendSearchAgreementReminderMutationVariables
>;

/**
 * __useSendSearchAgreementReminderMutation__
 *
 * To run a mutation, you first call `useSendSearchAgreementReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSearchAgreementReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSearchAgreementReminderMutation, { data, loading, error }] = useSendSearchAgreementReminderMutation({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useSendSearchAgreementReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSearchAgreementReminderMutation,
    SendSearchAgreementReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSearchAgreementReminderMutation,
    SendSearchAgreementReminderMutationVariables
  >(SendSearchAgreementReminderDocument, options);
}
export type SendSearchAgreementReminderMutationHookResult = ReturnType<
  typeof useSendSearchAgreementReminderMutation
>;
export type SendSearchAgreementReminderMutationResult =
  Apollo.MutationResult<SendSearchAgreementReminderMutation>;
export type SendSearchAgreementReminderMutationOptions =
  Apollo.BaseMutationOptions<
    SendSearchAgreementReminderMutation,
    SendSearchAgreementReminderMutationVariables
  >;
export const CheckStatusOrGenerateAccountLinkUrlDocument = gql`
  query CheckStatusOrGenerateAccountLinkURL {
    checkStatusOrGenerateAccountLinkURL {
      check
      url
    }
  }
`;

/**
 * __useCheckStatusOrGenerateAccountLinkUrlQuery__
 *
 * To run a query within a React component, call `useCheckStatusOrGenerateAccountLinkUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStatusOrGenerateAccountLinkUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStatusOrGenerateAccountLinkUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckStatusOrGenerateAccountLinkUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckStatusOrGenerateAccountLinkUrlQuery,
    CheckStatusOrGenerateAccountLinkUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckStatusOrGenerateAccountLinkUrlQuery,
    CheckStatusOrGenerateAccountLinkUrlQueryVariables
  >(CheckStatusOrGenerateAccountLinkUrlDocument, options);
}
export function useCheckStatusOrGenerateAccountLinkUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckStatusOrGenerateAccountLinkUrlQuery,
    CheckStatusOrGenerateAccountLinkUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckStatusOrGenerateAccountLinkUrlQuery,
    CheckStatusOrGenerateAccountLinkUrlQueryVariables
  >(CheckStatusOrGenerateAccountLinkUrlDocument, options);
}
export type CheckStatusOrGenerateAccountLinkUrlQueryHookResult = ReturnType<
  typeof useCheckStatusOrGenerateAccountLinkUrlQuery
>;
export type CheckStatusOrGenerateAccountLinkUrlLazyQueryHookResult = ReturnType<
  typeof useCheckStatusOrGenerateAccountLinkUrlLazyQuery
>;
export type CheckStatusOrGenerateAccountLinkUrlQueryResult = Apollo.QueryResult<
  CheckStatusOrGenerateAccountLinkUrlQuery,
  CheckStatusOrGenerateAccountLinkUrlQueryVariables
>;
export function refetchCheckStatusOrGenerateAccountLinkUrlQuery(
  variables?: CheckStatusOrGenerateAccountLinkUrlQueryVariables
) {
  return {
    query: CheckStatusOrGenerateAccountLinkUrlDocument,
    variables: variables,
  };
}
export const TimeZoneDocument = gql`
  query TimeZone {
    getTimeZone {
      id
      timezoneOffset
      timezoneName
    }
  }
`;

/**
 * __useTimeZoneQuery__
 *
 * To run a query within a React component, call `useTimeZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeZoneQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeZoneQuery, TimeZoneQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeZoneQuery, TimeZoneQueryVariables>(
    TimeZoneDocument,
    options
  );
}
export function useTimeZoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeZoneQuery,
    TimeZoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeZoneQuery, TimeZoneQueryVariables>(
    TimeZoneDocument,
    options
  );
}
export type TimeZoneQueryHookResult = ReturnType<typeof useTimeZoneQuery>;
export type TimeZoneLazyQueryHookResult = ReturnType<
  typeof useTimeZoneLazyQuery
>;
export type TimeZoneQueryResult = Apollo.QueryResult<
  TimeZoneQuery,
  TimeZoneQueryVariables
>;
export function refetchTimeZoneQuery(variables?: TimeZoneQueryVariables) {
  return { query: TimeZoneDocument, variables: variables };
}
export const TopClientsByPlacementDocument = gql`
  query TopClientsByPlacement($rangeDate: RangeDateInput!) {
    topClientsByPlacement(rangeDate: $rangeDate) {
      name
      quantity
    }
  }
`;

/**
 * __useTopClientsByPlacementQuery__
 *
 * To run a query within a React component, call `useTopClientsByPlacementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopClientsByPlacementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopClientsByPlacementQuery({
 *   variables: {
 *      rangeDate: // value for 'rangeDate'
 *   },
 * });
 */
export function useTopClientsByPlacementQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopClientsByPlacementQuery,
    TopClientsByPlacementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TopClientsByPlacementQuery,
    TopClientsByPlacementQueryVariables
  >(TopClientsByPlacementDocument, options);
}
export function useTopClientsByPlacementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopClientsByPlacementQuery,
    TopClientsByPlacementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopClientsByPlacementQuery,
    TopClientsByPlacementQueryVariables
  >(TopClientsByPlacementDocument, options);
}
export type TopClientsByPlacementQueryHookResult = ReturnType<
  typeof useTopClientsByPlacementQuery
>;
export type TopClientsByPlacementLazyQueryHookResult = ReturnType<
  typeof useTopClientsByPlacementLazyQuery
>;
export type TopClientsByPlacementQueryResult = Apollo.QueryResult<
  TopClientsByPlacementQuery,
  TopClientsByPlacementQueryVariables
>;
export function refetchTopClientsByPlacementQuery(
  variables: TopClientsByPlacementQueryVariables
) {
  return { query: TopClientsByPlacementDocument, variables: variables };
}
export const TopClientsByRevenueDocument = gql`
  query TopClientsByRevenue($rangeDate: RangeDateInput!) {
    topClientsByRevenue(rangeDate: $rangeDate) {
      name
      quantity
    }
  }
`;

/**
 * __useTopClientsByRevenueQuery__
 *
 * To run a query within a React component, call `useTopClientsByRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopClientsByRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopClientsByRevenueQuery({
 *   variables: {
 *      rangeDate: // value for 'rangeDate'
 *   },
 * });
 */
export function useTopClientsByRevenueQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopClientsByRevenueQuery,
    TopClientsByRevenueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TopClientsByRevenueQuery,
    TopClientsByRevenueQueryVariables
  >(TopClientsByRevenueDocument, options);
}
export function useTopClientsByRevenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopClientsByRevenueQuery,
    TopClientsByRevenueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopClientsByRevenueQuery,
    TopClientsByRevenueQueryVariables
  >(TopClientsByRevenueDocument, options);
}
export type TopClientsByRevenueQueryHookResult = ReturnType<
  typeof useTopClientsByRevenueQuery
>;
export type TopClientsByRevenueLazyQueryHookResult = ReturnType<
  typeof useTopClientsByRevenueLazyQuery
>;
export type TopClientsByRevenueQueryResult = Apollo.QueryResult<
  TopClientsByRevenueQuery,
  TopClientsByRevenueQueryVariables
>;
export function refetchTopClientsByRevenueQuery(
  variables: TopClientsByRevenueQueryVariables
) {
  return { query: TopClientsByRevenueDocument, variables: variables };
}
export const TopRecruiterBySubmittedCandidateDocument = gql`
  query TopRecruiterBySubmittedCandidate($input: RangeDateInput!) {
    topRecruiterBySubmittedCandidate(input: $input) {
      recruiters {
        name
        number
      }
      candidates {
        previous
        current
      }
      hasData
    }
  }
`;

/**
 * __useTopRecruiterBySubmittedCandidateQuery__
 *
 * To run a query within a React component, call `useTopRecruiterBySubmittedCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopRecruiterBySubmittedCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopRecruiterBySubmittedCandidateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTopRecruiterBySubmittedCandidateQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopRecruiterBySubmittedCandidateQuery,
    TopRecruiterBySubmittedCandidateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TopRecruiterBySubmittedCandidateQuery,
    TopRecruiterBySubmittedCandidateQueryVariables
  >(TopRecruiterBySubmittedCandidateDocument, options);
}
export function useTopRecruiterBySubmittedCandidateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopRecruiterBySubmittedCandidateQuery,
    TopRecruiterBySubmittedCandidateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopRecruiterBySubmittedCandidateQuery,
    TopRecruiterBySubmittedCandidateQueryVariables
  >(TopRecruiterBySubmittedCandidateDocument, options);
}
export type TopRecruiterBySubmittedCandidateQueryHookResult = ReturnType<
  typeof useTopRecruiterBySubmittedCandidateQuery
>;
export type TopRecruiterBySubmittedCandidateLazyQueryHookResult = ReturnType<
  typeof useTopRecruiterBySubmittedCandidateLazyQuery
>;
export type TopRecruiterBySubmittedCandidateQueryResult = Apollo.QueryResult<
  TopRecruiterBySubmittedCandidateQuery,
  TopRecruiterBySubmittedCandidateQueryVariables
>;
export function refetchTopRecruiterBySubmittedCandidateQuery(
  variables: TopRecruiterBySubmittedCandidateQueryVariables
) {
  return {
    query: TopRecruiterBySubmittedCandidateDocument,
    variables: variables,
  };
}
export const TopRecruitersByRevenueDocument = gql`
  query TopRecruitersByRevenue($rangeDate: RangeDateInput!) {
    topRecruitersByRevenue(rangeDate: $rangeDate) {
      name
      quantity
    }
  }
`;

/**
 * __useTopRecruitersByRevenueQuery__
 *
 * To run a query within a React component, call `useTopRecruitersByRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopRecruitersByRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopRecruitersByRevenueQuery({
 *   variables: {
 *      rangeDate: // value for 'rangeDate'
 *   },
 * });
 */
export function useTopRecruitersByRevenueQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopRecruitersByRevenueQuery,
    TopRecruitersByRevenueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TopRecruitersByRevenueQuery,
    TopRecruitersByRevenueQueryVariables
  >(TopRecruitersByRevenueDocument, options);
}
export function useTopRecruitersByRevenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopRecruitersByRevenueQuery,
    TopRecruitersByRevenueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopRecruitersByRevenueQuery,
    TopRecruitersByRevenueQueryVariables
  >(TopRecruitersByRevenueDocument, options);
}
export type TopRecruitersByRevenueQueryHookResult = ReturnType<
  typeof useTopRecruitersByRevenueQuery
>;
export type TopRecruitersByRevenueLazyQueryHookResult = ReturnType<
  typeof useTopRecruitersByRevenueLazyQuery
>;
export type TopRecruitersByRevenueQueryResult = Apollo.QueryResult<
  TopRecruitersByRevenueQuery,
  TopRecruitersByRevenueQueryVariables
>;
export function refetchTopRecruitersByRevenueQuery(
  variables: TopRecruitersByRevenueQueryVariables
) {
  return { query: TopRecruitersByRevenueDocument, variables: variables };
}
export const UnarchiveSearchAgreementDocument = gql`
  mutation UnarchiveSearchAgreement($searchAgreementId: String!) {
    unarchiveSearchAgreement(searchAgreementId: $searchAgreementId)
  }
`;
export type UnarchiveSearchAgreementMutationFn = Apollo.MutationFunction<
  UnarchiveSearchAgreementMutation,
  UnarchiveSearchAgreementMutationVariables
>;

/**
 * __useUnarchiveSearchAgreementMutation__
 *
 * To run a mutation, you first call `useUnarchiveSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveSearchAgreementMutation, { data, loading, error }] = useUnarchiveSearchAgreementMutation({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useUnarchiveSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveSearchAgreementMutation,
    UnarchiveSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveSearchAgreementMutation,
    UnarchiveSearchAgreementMutationVariables
  >(UnarchiveSearchAgreementDocument, options);
}
export type UnarchiveSearchAgreementMutationHookResult = ReturnType<
  typeof useUnarchiveSearchAgreementMutation
>;
export type UnarchiveSearchAgreementMutationResult =
  Apollo.MutationResult<UnarchiveSearchAgreementMutation>;
export type UnarchiveSearchAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    UnarchiveSearchAgreementMutation,
    UnarchiveSearchAgreementMutationVariables
  >;
export const UpcomingEarningsDocument = gql`
  query UpcomingEarnings {
    upcomingEarnings {
      id
      date
      amount
      employerName
      feeType
      searchAgreementId
      recruiterName
    }
  }
`;

/**
 * __useUpcomingEarningsQuery__
 *
 * To run a query within a React component, call `useUpcomingEarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEarningsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingEarningsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UpcomingEarningsQuery,
    UpcomingEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpcomingEarningsQuery, UpcomingEarningsQueryVariables>(
    UpcomingEarningsDocument,
    options
  );
}
export function useUpcomingEarningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingEarningsQuery,
    UpcomingEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpcomingEarningsQuery,
    UpcomingEarningsQueryVariables
  >(UpcomingEarningsDocument, options);
}
export type UpcomingEarningsQueryHookResult = ReturnType<
  typeof useUpcomingEarningsQuery
>;
export type UpcomingEarningsLazyQueryHookResult = ReturnType<
  typeof useUpcomingEarningsLazyQuery
>;
export type UpcomingEarningsQueryResult = Apollo.QueryResult<
  UpcomingEarningsQuery,
  UpcomingEarningsQueryVariables
>;
export function refetchUpcomingEarningsQuery(
  variables?: UpcomingEarningsQueryVariables
) {
  return { query: UpcomingEarningsDocument, variables: variables };
}
export const UpdateCandidateDocumentsDocument = gql`
  mutation UpdateCandidateDocuments($input: UpdateCandidateDocumentsInput!) {
    updateCandidateDocuments(input: $input) {
      id
    }
  }
`;
export type UpdateCandidateDocumentsMutationFn = Apollo.MutationFunction<
  UpdateCandidateDocumentsMutation,
  UpdateCandidateDocumentsMutationVariables
>;

/**
 * __useUpdateCandidateDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateDocumentsMutation, { data, loading, error }] = useUpdateCandidateDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateDocumentsMutation,
    UpdateCandidateDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateDocumentsMutation,
    UpdateCandidateDocumentsMutationVariables
  >(UpdateCandidateDocumentsDocument, options);
}
export type UpdateCandidateDocumentsMutationHookResult = ReturnType<
  typeof useUpdateCandidateDocumentsMutation
>;
export type UpdateCandidateDocumentsMutationResult =
  Apollo.MutationResult<UpdateCandidateDocumentsMutation>;
export type UpdateCandidateDocumentsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCandidateDocumentsMutation,
    UpdateCandidateDocumentsMutationVariables
  >;
export const UpdateCandidateDocument = gql`
  mutation UpdateCandidate($input: UpdateCandidateInput!) {
    updateCandidate(input: $input) {
      id
    }
  }
`;
export type UpdateCandidateMutationFn = Apollo.MutationFunction<
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables
>;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >(UpdateCandidateDocument, options);
}
export type UpdateCandidateMutationHookResult = ReturnType<
  typeof useUpdateCandidateMutation
>;
export type UpdateCandidateMutationResult =
  Apollo.MutationResult<UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions = Apollo.BaseMutationOptions<
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables
>;
export const UpdateCandidateRoleRequirementsDocument = gql`
  mutation UpdateCandidateRoleRequirements(
    $inputs: [UpdateCandidateRoleRequirementInput!]!
  ) {
    updateCandidateRoleRequirements(inputs: $inputs)
  }
`;
export type UpdateCandidateRoleRequirementsMutationFn = Apollo.MutationFunction<
  UpdateCandidateRoleRequirementsMutation,
  UpdateCandidateRoleRequirementsMutationVariables
>;

/**
 * __useUpdateCandidateRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateRoleRequirementsMutation, { data, loading, error }] = useUpdateCandidateRoleRequirementsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateCandidateRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateRoleRequirementsMutation,
    UpdateCandidateRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateRoleRequirementsMutation,
    UpdateCandidateRoleRequirementsMutationVariables
  >(UpdateCandidateRoleRequirementsDocument, options);
}
export type UpdateCandidateRoleRequirementsMutationHookResult = ReturnType<
  typeof useUpdateCandidateRoleRequirementsMutation
>;
export type UpdateCandidateRoleRequirementsMutationResult =
  Apollo.MutationResult<UpdateCandidateRoleRequirementsMutation>;
export type UpdateCandidateRoleRequirementsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCandidateRoleRequirementsMutation,
    UpdateCandidateRoleRequirementsMutationVariables
  >;
export const UpdateOnboardingStatusToInReviewDocument = gql`
  mutation UpdateOnboardingStatusToInReview {
    updateOnboardingStatusToInReview {
      id
      onboardingStatus
    }
  }
`;
export type UpdateOnboardingStatusToInReviewMutationFn =
  Apollo.MutationFunction<
    UpdateOnboardingStatusToInReviewMutation,
    UpdateOnboardingStatusToInReviewMutationVariables
  >;

/**
 * __useUpdateOnboardingStatusToInReviewMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStatusToInReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStatusToInReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStatusToInReviewMutation, { data, loading, error }] = useUpdateOnboardingStatusToInReviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateOnboardingStatusToInReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingStatusToInReviewMutation,
    UpdateOnboardingStatusToInReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOnboardingStatusToInReviewMutation,
    UpdateOnboardingStatusToInReviewMutationVariables
  >(UpdateOnboardingStatusToInReviewDocument, options);
}
export type UpdateOnboardingStatusToInReviewMutationHookResult = ReturnType<
  typeof useUpdateOnboardingStatusToInReviewMutation
>;
export type UpdateOnboardingStatusToInReviewMutationResult =
  Apollo.MutationResult<UpdateOnboardingStatusToInReviewMutation>;
export type UpdateOnboardingStatusToInReviewMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOnboardingStatusToInReviewMutation,
    UpdateOnboardingStatusToInReviewMutationVariables
  >;
export const UpdateOrCreateRecruiterPlacementDocument = gql`
  mutation UpdateOrCreateRecruiterPlacement(
    $placement: UpdateRecruiterProfilePlacementInput!
  ) {
    updateOrCreateRecruiterPlacement(placement: $placement) {
      id
    }
  }
`;
export type UpdateOrCreateRecruiterPlacementMutationFn =
  Apollo.MutationFunction<
    UpdateOrCreateRecruiterPlacementMutation,
    UpdateOrCreateRecruiterPlacementMutationVariables
  >;

/**
 * __useUpdateOrCreateRecruiterPlacementMutation__
 *
 * To run a mutation, you first call `useUpdateOrCreateRecruiterPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrCreateRecruiterPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrCreateRecruiterPlacementMutation, { data, loading, error }] = useUpdateOrCreateRecruiterPlacementMutation({
 *   variables: {
 *      placement: // value for 'placement'
 *   },
 * });
 */
export function useUpdateOrCreateRecruiterPlacementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrCreateRecruiterPlacementMutation,
    UpdateOrCreateRecruiterPlacementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrCreateRecruiterPlacementMutation,
    UpdateOrCreateRecruiterPlacementMutationVariables
  >(UpdateOrCreateRecruiterPlacementDocument, options);
}
export type UpdateOrCreateRecruiterPlacementMutationHookResult = ReturnType<
  typeof useUpdateOrCreateRecruiterPlacementMutation
>;
export type UpdateOrCreateRecruiterPlacementMutationResult =
  Apollo.MutationResult<UpdateOrCreateRecruiterPlacementMutation>;
export type UpdateOrCreateRecruiterPlacementMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrCreateRecruiterPlacementMutation,
    UpdateOrCreateRecruiterPlacementMutationVariables
  >;
export const UpdatePublicCandidateDocument = gql`
  mutation UpdatePublicCandidate($publicDossierId: String!) {
    updatePublicCandidate(publicDossierId: $publicDossierId) {
      id
    }
  }
`;
export type UpdatePublicCandidateMutationFn = Apollo.MutationFunction<
  UpdatePublicCandidateMutation,
  UpdatePublicCandidateMutationVariables
>;

/**
 * __useUpdatePublicCandidateMutation__
 *
 * To run a mutation, you first call `useUpdatePublicCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicCandidateMutation, { data, loading, error }] = useUpdatePublicCandidateMutation({
 *   variables: {
 *      publicDossierId: // value for 'publicDossierId'
 *   },
 * });
 */
export function useUpdatePublicCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicCandidateMutation,
    UpdatePublicCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePublicCandidateMutation,
    UpdatePublicCandidateMutationVariables
  >(UpdatePublicCandidateDocument, options);
}
export type UpdatePublicCandidateMutationHookResult = ReturnType<
  typeof useUpdatePublicCandidateMutation
>;
export type UpdatePublicCandidateMutationResult =
  Apollo.MutationResult<UpdatePublicCandidateMutation>;
export type UpdatePublicCandidateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicCandidateMutation,
  UpdatePublicCandidateMutationVariables
>;
export const UpdateRecruiterDocument = gql`
  mutation UpdateRecruiter($data: UpdateRecruiterInput!) {
    updateRecruiter(data: $data) {
      id
      agency {
        name
      }
    }
  }
`;
export type UpdateRecruiterMutationFn = Apollo.MutationFunction<
  UpdateRecruiterMutation,
  UpdateRecruiterMutationVariables
>;

/**
 * __useUpdateRecruiterMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterMutation, { data, loading, error }] = useUpdateRecruiterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRecruiterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecruiterMutation,
    UpdateRecruiterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecruiterMutation,
    UpdateRecruiterMutationVariables
  >(UpdateRecruiterDocument, options);
}
export type UpdateRecruiterMutationHookResult = ReturnType<
  typeof useUpdateRecruiterMutation
>;
export type UpdateRecruiterMutationResult =
  Apollo.MutationResult<UpdateRecruiterMutation>;
export type UpdateRecruiterMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecruiterMutation,
  UpdateRecruiterMutationVariables
>;
export const UpdateRecruiterProfileEndorsementsDocument = gql`
  mutation UpdateRecruiterProfileEndorsements(
    $endorsements: [UpdateRecruiterProfileEndorsementsInput!]!
  ) {
    updateRecruiterProfileEndorsements(endorsements: $endorsements) {
      id
    }
  }
`;
export type UpdateRecruiterProfileEndorsementsMutationFn =
  Apollo.MutationFunction<
    UpdateRecruiterProfileEndorsementsMutation,
    UpdateRecruiterProfileEndorsementsMutationVariables
  >;

/**
 * __useUpdateRecruiterProfileEndorsementsMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterProfileEndorsementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterProfileEndorsementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterProfileEndorsementsMutation, { data, loading, error }] = useUpdateRecruiterProfileEndorsementsMutation({
 *   variables: {
 *      endorsements: // value for 'endorsements'
 *   },
 * });
 */
export function useUpdateRecruiterProfileEndorsementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecruiterProfileEndorsementsMutation,
    UpdateRecruiterProfileEndorsementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecruiterProfileEndorsementsMutation,
    UpdateRecruiterProfileEndorsementsMutationVariables
  >(UpdateRecruiterProfileEndorsementsDocument, options);
}
export type UpdateRecruiterProfileEndorsementsMutationHookResult = ReturnType<
  typeof useUpdateRecruiterProfileEndorsementsMutation
>;
export type UpdateRecruiterProfileEndorsementsMutationResult =
  Apollo.MutationResult<UpdateRecruiterProfileEndorsementsMutation>;
export type UpdateRecruiterProfileEndorsementsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRecruiterProfileEndorsementsMutation,
    UpdateRecruiterProfileEndorsementsMutationVariables
  >;
export const UpdateRecruiterProfileDocument = gql`
  mutation UpdateRecruiterProfile($data: UpdateRecruiterProfileInput!) {
    updateRecruiterProfile(data: $data) {
      id
      user {
        photoUrl
      }
    }
  }
`;
export type UpdateRecruiterProfileMutationFn = Apollo.MutationFunction<
  UpdateRecruiterProfileMutation,
  UpdateRecruiterProfileMutationVariables
>;

/**
 * __useUpdateRecruiterProfileMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterProfileMutation, { data, loading, error }] = useUpdateRecruiterProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRecruiterProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecruiterProfileMutation,
    UpdateRecruiterProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecruiterProfileMutation,
    UpdateRecruiterProfileMutationVariables
  >(UpdateRecruiterProfileDocument, options);
}
export type UpdateRecruiterProfileMutationHookResult = ReturnType<
  typeof useUpdateRecruiterProfileMutation
>;
export type UpdateRecruiterProfileMutationResult =
  Apollo.MutationResult<UpdateRecruiterProfileMutation>;
export type UpdateRecruiterProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecruiterProfileMutation,
  UpdateRecruiterProfileMutationVariables
>;
export const UpdateRecruiterTimeToPlacementDocument = gql`
  mutation UpdateRecruiterTimeToPlacement($timeToPlacement: Float!) {
    updateRecruiterTimeToPlacement(timeToPlacement: $timeToPlacement) {
      id
    }
  }
`;
export type UpdateRecruiterTimeToPlacementMutationFn = Apollo.MutationFunction<
  UpdateRecruiterTimeToPlacementMutation,
  UpdateRecruiterTimeToPlacementMutationVariables
>;

/**
 * __useUpdateRecruiterTimeToPlacementMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterTimeToPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterTimeToPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterTimeToPlacementMutation, { data, loading, error }] = useUpdateRecruiterTimeToPlacementMutation({
 *   variables: {
 *      timeToPlacement: // value for 'timeToPlacement'
 *   },
 * });
 */
export function useUpdateRecruiterTimeToPlacementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecruiterTimeToPlacementMutation,
    UpdateRecruiterTimeToPlacementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecruiterTimeToPlacementMutation,
    UpdateRecruiterTimeToPlacementMutationVariables
  >(UpdateRecruiterTimeToPlacementDocument, options);
}
export type UpdateRecruiterTimeToPlacementMutationHookResult = ReturnType<
  typeof useUpdateRecruiterTimeToPlacementMutation
>;
export type UpdateRecruiterTimeToPlacementMutationResult =
  Apollo.MutationResult<UpdateRecruiterTimeToPlacementMutation>;
export type UpdateRecruiterTimeToPlacementMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRecruiterTimeToPlacementMutation,
    UpdateRecruiterTimeToPlacementMutationVariables
  >;
export const UpdateRoleRequirementsDocument = gql`
  mutation UpdateRoleRequirements($inputs: [UpdateRoleRequirementInput!]!) {
    updateRoleRequirements(inputs: $inputs) {
      createdAt
      deletedAt
      id
      mustHave
      prompt
      updatedAt
    }
  }
`;
export type UpdateRoleRequirementsMutationFn = Apollo.MutationFunction<
  UpdateRoleRequirementsMutation,
  UpdateRoleRequirementsMutationVariables
>;

/**
 * __useUpdateRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useUpdateRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleRequirementsMutation, { data, loading, error }] = useUpdateRoleRequirementsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleRequirementsMutation,
    UpdateRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleRequirementsMutation,
    UpdateRoleRequirementsMutationVariables
  >(UpdateRoleRequirementsDocument, options);
}
export type UpdateRoleRequirementsMutationHookResult = ReturnType<
  typeof useUpdateRoleRequirementsMutation
>;
export type UpdateRoleRequirementsMutationResult =
  Apollo.MutationResult<UpdateRoleRequirementsMutation>;
export type UpdateRoleRequirementsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleRequirementsMutation,
  UpdateRoleRequirementsMutationVariables
>;
export const UpdateMarketplaceRoleStatusDocument = gql`
  mutation UpdateMarketplaceRoleStatus(
    $status: MarketplaceRoleRecruiterStatus!
    $roleId: String!
    $marketplaceVisibility: MarketplaceVisibilityEnum!
  ) {
    updateMarketplaceRoleStatus(
      status: $status
      roleId: $roleId
      marketplaceVisibility: $marketplaceVisibility
    ) {
      id
      status
    }
  }
`;
export type UpdateMarketplaceRoleStatusMutationFn = Apollo.MutationFunction<
  UpdateMarketplaceRoleStatusMutation,
  UpdateMarketplaceRoleStatusMutationVariables
>;

/**
 * __useUpdateMarketplaceRoleStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMarketplaceRoleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketplaceRoleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketplaceRoleStatusMutation, { data, loading, error }] = useUpdateMarketplaceRoleStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      roleId: // value for 'roleId'
 *      marketplaceVisibility: // value for 'marketplaceVisibility'
 *   },
 * });
 */
export function useUpdateMarketplaceRoleStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMarketplaceRoleStatusMutation,
    UpdateMarketplaceRoleStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMarketplaceRoleStatusMutation,
    UpdateMarketplaceRoleStatusMutationVariables
  >(UpdateMarketplaceRoleStatusDocument, options);
}
export type UpdateMarketplaceRoleStatusMutationHookResult = ReturnType<
  typeof useUpdateMarketplaceRoleStatusMutation
>;
export type UpdateMarketplaceRoleStatusMutationResult =
  Apollo.MutationResult<UpdateMarketplaceRoleStatusMutation>;
export type UpdateMarketplaceRoleStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMarketplaceRoleStatusMutation,
    UpdateMarketplaceRoleStatusMutationVariables
  >;
export const UpdateSearchAgreementDocument = gql`
  mutation UpdateSearchAgreement(
    $searchAgreementInput: UpdateSearchAgreementInput!
  ) {
    updateSearchAgreement(searchAgreementInput: $searchAgreementInput) {
      id
    }
  }
`;
export type UpdateSearchAgreementMutationFn = Apollo.MutationFunction<
  UpdateSearchAgreementMutation,
  UpdateSearchAgreementMutationVariables
>;

/**
 * __useUpdateSearchAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchAgreementMutation, { data, loading, error }] = useUpdateSearchAgreementMutation({
 *   variables: {
 *      searchAgreementInput: // value for 'searchAgreementInput'
 *   },
 * });
 */
export function useUpdateSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSearchAgreementMutation,
    UpdateSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSearchAgreementMutation,
    UpdateSearchAgreementMutationVariables
  >(UpdateSearchAgreementDocument, options);
}
export type UpdateSearchAgreementMutationHookResult = ReturnType<
  typeof useUpdateSearchAgreementMutation
>;
export type UpdateSearchAgreementMutationResult =
  Apollo.MutationResult<UpdateSearchAgreementMutation>;
export type UpdateSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  UpdateSearchAgreementMutation,
  UpdateSearchAgreementMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      photoUrl
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserDocument = gql`
  query User {
    getUser {
      email
      firstName
      lastName
      phoneNumber
      userConsents {
        id
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables?: UserQueryVariables) {
  return { query: UserDocument, variables: variables };
}
export const VoidSearchAgreementDocument = gql`
  mutation VoidSearchAgreement(
    $input: RecruiterTerminatedSearchAgreementInput!
  ) {
    voidSearchAgreement(input: $input)
  }
`;
export type VoidSearchAgreementMutationFn = Apollo.MutationFunction<
  VoidSearchAgreementMutation,
  VoidSearchAgreementMutationVariables
>;

/**
 * __useVoidSearchAgreementMutation__
 *
 * To run a mutation, you first call `useVoidSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidSearchAgreementMutation, { data, loading, error }] = useVoidSearchAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidSearchAgreementMutation,
    VoidSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VoidSearchAgreementMutation,
    VoidSearchAgreementMutationVariables
  >(VoidSearchAgreementDocument, options);
}
export type VoidSearchAgreementMutationHookResult = ReturnType<
  typeof useVoidSearchAgreementMutation
>;
export type VoidSearchAgreementMutationResult =
  Apollo.MutationResult<VoidSearchAgreementMutation>;
export type VoidSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  VoidSearchAgreementMutation,
  VoidSearchAgreementMutationVariables
>;
export const UpdateRecruiterProfileBioDocument = gql`
  mutation UpdateRecruiterProfileBio($bio: String!, $bioJSON: String!) {
    updateRecruiterProfileBio(bio: $bio, bioJSON: $bioJSON) {
      id
    }
  }
`;
export type UpdateRecruiterProfileBioMutationFn = Apollo.MutationFunction<
  UpdateRecruiterProfileBioMutation,
  UpdateRecruiterProfileBioMutationVariables
>;

/**
 * __useUpdateRecruiterProfileBioMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterProfileBioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterProfileBioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterProfileBioMutation, { data, loading, error }] = useUpdateRecruiterProfileBioMutation({
 *   variables: {
 *      bio: // value for 'bio'
 *      bioJSON: // value for 'bioJSON'
 *   },
 * });
 */
export function useUpdateRecruiterProfileBioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecruiterProfileBioMutation,
    UpdateRecruiterProfileBioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecruiterProfileBioMutation,
    UpdateRecruiterProfileBioMutationVariables
  >(UpdateRecruiterProfileBioDocument, options);
}
export type UpdateRecruiterProfileBioMutationHookResult = ReturnType<
  typeof useUpdateRecruiterProfileBioMutation
>;
export type UpdateRecruiterProfileBioMutationResult =
  Apollo.MutationResult<UpdateRecruiterProfileBioMutation>;
export type UpdateRecruiterProfileBioMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRecruiterProfileBioMutation,
    UpdateRecruiterProfileBioMutationVariables
  >;
