import { ExternalLinkIcon } from '@heroicons/react/outline';
import type { VFC } from 'react';

interface Props {
  link: string;
  name: string;
}
const LabelExternalLink: VFC<Props> = ({ link, name }) => (
  <>
    <div className="flex items-center inline-flex text-sm font-medium text-headraceBlack-800">
      {name}
      <a target="_blank" href={link} rel="noreferrer">
        <ExternalLinkIcon
          className="h-4 w-4 ml-2.5 text-blue-600"
          aria-hidden="true"
        />
      </a>
    </div>
    <br />
    <a
      target="_blank"
      className="text-sm font-normal text-blue-500"
      href={link}
      rel="noreferrer"
    >
      {link}
    </a>
  </>
);

export default LabelExternalLink;
