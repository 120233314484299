import type { ApolloError } from '@apollo/client';
import { MarketplaceRoleRecruiterStatus } from '@headrace/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import type {
  MarketplaceRolesQuery,
  MarketplaceVisibilityEnum,
} from '@/graphql/generated';
import {
  useMarketplaceRolesQuery,
  useUpdateMarketplaceRoleStatusMutation,
} from '@/graphql/generated';

import type {
  ActiveFilters,
  MarketplaceFilters,
} from './MarketplaceFiltersBar';
import marketplaceFiltersInitialValue from './MarketplaceFiltersBar/InitialValue';

export const PageTabs = {
  invites: 0,
  myRoles: 1,
  notInterested: 2,
};

interface MarketplaceProviderContextData {
  marketplaceRoles: {
    data?: MarketplaceRolesQuery['marketplaceRoles'];
    loading: boolean;
    error?: ApolloError;
  };
  refetchMarketplaceRoles: () => Promise<void>;
  handleUpdateMarketplaceRoleStatus: (
    roleId: string,
    status: MarketplaceRoleRecruiterStatus,
    marketplaceVisibility: MarketplaceVisibilityEnum,
    onError: () => void,
    onCompleted: () => void
  ) => void;
  filters: MarketplaceFilters;
  setFilters: React.Dispatch<React.SetStateAction<MarketplaceFilters>>;
  isFiltering: boolean;
  setIsFiltering: React.Dispatch<React.SetStateAction<boolean>>;
  isFiltersOpen: boolean;
  setIsFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeFilters: ActiveFilters;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}

const MarketplaceContext = React.createContext<MarketplaceProviderContextData>({
  refetchMarketplaceRoles: () => Promise.resolve(),
  marketplaceRoles: {
    loading: false,
  },
  handleUpdateMarketplaceRoleStatus: () => Promise.resolve(),
  filters: marketplaceFiltersInitialValue,
  setFilters: () => Promise.resolve(),
  isFiltering: false,
  setIsFiltering: () => Promise.resolve(),
  isFiltersOpen: false,
  setIsFiltersOpen: () => Promise.resolve(),
  activeFilters: {
    roleTypeClear: false,
    companyStageClear: false,
    functionClear: false,
    locationClear: false,
    roleLevelClear: false,
    salaryRangeClear: false,
    placementFeeClear: false,
  },
  currentTab: PageTabs.invites,
  setCurrentTab: () => Promise.resolve(),
});

const MarketplaceProvider: React.FC = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(PageTabs.invites);
  // Filters
  const [filters, setFilters] = useState<MarketplaceFilters>(
    marketplaceFiltersInitialValue
  );
  const [isFiltering, setIsFiltering] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const activeFilters = useMemo<ActiveFilters>(
    () => ({
      roleTypeClear: !filters.roleType.Public && !filters.roleType.Invite,
      companyStageClear:
        !filters.companyStage['Early stage'] &&
        !filters.companyStage['Growth stage'] &&
        !filters.companyStage['Late stage'],
      functionClear: filters.function.length === 0,
      locationClear: filters.location.length === 0,
      roleLevelClear: !Object.entries(filters.roleLevel).find(
        (entry) => entry[1]
      ),
      salaryRangeClear:
        filters.salaryRange.min === 0 && filters.salaryRange.max === 300000,
      placementFeeClear:
        filters.placementFee.min === 0 && filters.placementFee.max === 50,
    }),
    [filters]
  );

  useEffect(() => {
    setIsFiltering(
      !(
        activeFilters.roleTypeClear &&
        activeFilters.companyStageClear &&
        activeFilters.functionClear &&
        activeFilters.locationClear &&
        activeFilters.roleLevelClear &&
        activeFilters.salaryRangeClear &&
        activeFilters.placementFeeClear
      )
    );
  }, [activeFilters, setIsFiltering]);

  const {
    data: marketplaceRolesData,
    loading: marketplaceRolesLoading,
    error: marketplaceRolesError,
    refetch: marketplaceRolesRefetch,
  } = useMarketplaceRolesQuery();
  const [updateMarketplaceRoleStatus] = useUpdateMarketplaceRoleStatusMutation({
    onCompleted: async (response) => {
      await marketplaceRolesRefetch();
      if (response.updateMarketplaceRoleStatus?.status)
        toast.success(
          response.updateMarketplaceRoleStatus.status ===
            MarketplaceRoleRecruiterStatus.BOOKMARKED
            ? 'Role saved successfully'
            : 'Role marked as not interested'
        );
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const refetchMarketplaceRoles = useCallback(async (): Promise<void> => {
    await marketplaceRolesRefetch();
  }, [marketplaceRolesRefetch]);

  const handleUpdateMarketplaceRoleStatus = useCallback(
    async (
      roleId: string,
      status: MarketplaceRoleRecruiterStatus,
      marketplaceVisibility: MarketplaceVisibilityEnum,
      onError: () => void,
      onCompleted: () => void
    ) => {
      await updateMarketplaceRoleStatus({
        variables: {
          roleId,
          status,
          marketplaceVisibility,
        },
        onError: (): void => {
          onError();
        },
        onCompleted: (): void => {
          onCompleted();
        },
      });
    },
    [updateMarketplaceRoleStatus]
  );

  const marketplaceContextState: MarketplaceProviderContextData = useMemo(
    () => ({
      refetchMarketplaceRoles,
      marketplaceRoles: {
        data: marketplaceRolesData?.marketplaceRoles,
        loading: marketplaceRolesLoading,
        error: marketplaceRolesError,
      },
      handleUpdateMarketplaceRoleStatus,
      filters,
      setFilters,
      isFiltering,
      setIsFiltering,
      isFiltersOpen,
      setIsFiltersOpen,
      activeFilters,
      currentTab,
      setCurrentTab,
    }),
    [
      refetchMarketplaceRoles,
      marketplaceRolesData?.marketplaceRoles,
      marketplaceRolesLoading,
      marketplaceRolesError,
      handleUpdateMarketplaceRoleStatus,
      filters,
      isFiltering,
      isFiltersOpen,
      activeFilters,
      currentTab,
    ]
  );

  return (
    <MarketplaceContext.Provider value={marketplaceContextState}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export const useMarketplaceRoles = (): MarketplaceProviderContextData =>
  React.useContext(MarketplaceContext);

export default MarketplaceProvider;
