import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}

const StepCircleDot: VFC<Props> = ({ className }) => {
  const iconClassName = classNames(
    'inline-block rounded-full overflow-hidden bg-gray-100',
    className
  );
  return (
    <span className={iconClassName}>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1.5" width="30" height="30" rx="15" fill="white" />
        <circle cx="16" cy="16.5" r="5" fill="#FFA300" />
        <rect
          x="1"
          y="1.5"
          width="30"
          height="30"
          rx="15"
          stroke="#FFA300"
          strokeWidth="2"
        />
      </svg>
    </span>
  );
};

export default StepCircleDot;
