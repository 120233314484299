import type { PlacementGeographiesGraphData } from '@headrace/types';
import { extent, scaleLinear, select, selectAll } from 'd3';
import { geoMercator, geoPath } from 'd3-geo';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import useWindowSize from '../utils/useWindowSize';
import usaStatesFeature from './usaStatesFeature.json';

const DRAWING_PROPORTION = 1.78;
const DRAWING_WIDTH = 300;
const DRAWING_HEIGHT = 164;
const DRAWING_X_CENTER = -27;
const DRAWING_Y_CENTER = 8;
const DRAWING_DEFAULT_SCALE = DRAWING_WIDTH - 19;
const DRAWING_BUBBLE_MAX_SIZE = 15;

const getMapScaleByCanvasSize = (width: number): number =>
  Math.max((width * 9) / 10, 275);
const getMapCenterCordsByCanvasSize = (
  {
    width,
    height,
  }: {
    width: number;
    height: number;
  },
  scale: number
): [number, number] => {
  const scaleOffsetX = (scale / DRAWING_DEFAULT_SCALE) ** -0.98;
  const pixelRatioX = 0.312 * scaleOffsetX;
  const offsetX = (width - DRAWING_WIDTH) * pixelRatioX;
  const centerX = DRAWING_X_CENTER - offsetX;
  const scaleOffsetY = (DRAWING_DEFAULT_SCALE / scale) ** 0.85;
  const pixelRatioY = 0.215 * scaleOffsetY;
  const offsetY = (height - DRAWING_HEIGHT) * pixelRatioY;
  const centerY = DRAWING_Y_CENTER + offsetY;
  return [centerX, centerY];
};

const PlacementGeographiesGraph: React.VFC<{
  placementsData: PlacementGeographiesGraphData[];
  svgClassName?: string;
  tooltipClassName?: string;
  id: string;
}> = ({ placementsData, svgClassName = '', tooltipClassName = '', id }) => {
  const svgElementRef = useRef<SVGSVGElement>(null);
  const [height, setHeight] = useState(1);
  const [screenWidth] = useWindowSize();

  const drawGeographies = useCallback(() => {
    if (!svgElementRef.current) return;

    const [min, max] = extent(placementsData.map((d) => d.placementsCount));

    selectAll(`svg#${svgElementRef.current.id} > *`).remove();
    const svgElement = select(svgElementRef.current);
    const canvasDimensions = {
      width: svgElementRef.current.clientWidth,
      height,
    };
    const drawingScale = getMapScaleByCanvasSize(canvasDimensions.width);

    const sizeScale = scaleLinear()
      .domain([0, max || min || 0])
      .range([
        0,
        (DRAWING_BUBBLE_MAX_SIZE * canvasDimensions.width) / DRAWING_WIDTH,
      ]);

    // Draw USA map
    const projection = geoMercator()
      .scale(drawingScale)
      .center(getMapCenterCordsByCanvasSize(canvasDimensions, drawingScale));

    // Draw the map
    svgElement
      .append('g')
      .selectAll('path')
      .data(usaStatesFeature)
      .join('path')
      .style('fill', '#F9F9F9')
      .attr('d', geoPath().projection(projection))
      .style('stroke', 'black');

    // Add bubbles
    svgElement
      .selectAll('bubbles')
      .data(placementsData)
      .join('circle')
      .attr('cx', (data) => {
        const coords = projection([data.longitude, data.latitude]);
        return coords ? coords[0] : 0;
      })
      .attr('cy', (data) => {
        const coords = projection([data.longitude, data.latitude]);
        return coords ? coords[1] : 0;
      })
      .attr('r', (data) => sizeScale(data.placementsCount))
      .style('fill', '#FFA300')
      .style('outline', 'white')
      .attr('stroke-width', 1.5)
      .attr('fill-opacity', 0.5)
      .attr('data-tip', (data) => `${data.cityName}: ${data.placementsCount}`);

    // Enable tooltip in dynamically generated bubbles
    ReactTooltip.rebuild();
  }, [height, placementsData]);

  useEffect(() => {
    if (!svgElementRef.current || !svgElementRef.current.parentElement) return;
    setHeight(
      Number(
        (
          svgElementRef.current.parentElement.clientWidth / DRAWING_PROPORTION
        ).toFixed(0)
      )
    );
    if (height !== 1) drawGeographies();
  }, [height, screenWidth, drawGeographies]);

  return (
    <>
      <div
        className={`min-w-[300px] min-h-[164px] ${svgClassName}`}
        style={{
          height: `${height}px`,
        }}
      >
        <svg
          id={id}
          preserveAspectRatio="xMinYMid meet"
          ref={svgElementRef}
          viewBox={`0 0 ${
            svgElementRef.current?.parentElement?.clientWidth || 0
          } ${height}`}
        />
      </div>

      <ReactTooltip
        place="top"
        effect="solid"
        type="light"
        multiline
        offset={{ top: -5 }}
        className={tooltipClassName}
      />
    </>
  );
};

export default PlacementGeographiesGraph;
