import type { ApolloError } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { agencyDemoAccounts } from '@headrace/constants';
import { toastMessage } from '@headrace/ui';
import { checkDemoAccount, getScreenProperties } from '@headrace/utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import { hotjar } from 'react-hotjar';

import type {
  CurrentRecruiterQuery,
  CurrentRecruiterQueryResult,
} from '@/graphql/generated';
import { useCurrentRecruiterLazyQuery } from '@/graphql/generated';

import { ampli } from './ampli';

export interface NewMessage {
  channelUrl: string;
  message: string;
  senderNickname: string;
  senderProfileUrl?: string;
}

interface RecruiterContextData {
  recruiter: CurrentRecruiterQuery['currentRecruiter'];
  loading: boolean;
  fetchRecruiter: () => void;
  isDemoAccount: boolean;
  loadingRecruiter: boolean;
}

interface RecruiterStateData {
  loading?: boolean;
  error?: { message: string };
  data?: CurrentRecruiterQueryResult['data'];
}

const RecruiterContext = React.createContext<RecruiterContextData>({
  recruiter: undefined,
  loading: false,
  fetchRecruiter: () => {},
  isDemoAccount: false,
  loadingRecruiter: false,
});

const RecruiterProvider: React.FC = ({ children }) => {
  const dataFetchedOnce = useRef<boolean>(false);
  const [{ loading, error, data }, setRecruiterData] =
    useState<RecruiterStateData>({});
  const { isLoading, user } = useAuth0();
  const [isDemoAccount, setIsDemoAccount] = useState<boolean>(false);
  const [cookies] = useCookies(['become-user-id']);

  const [lazyGetRecruiter, { loading: loadingRecruiter }] =
    useCurrentRecruiterLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const fetchRecruiter = useCallback(() => {
    if (!dataFetchedOnce.current) dataFetchedOnce.current = true;

    lazyGetRecruiter()
      .then((recruiterResponse) => {
        setRecruiterData({
          loading: recruiterResponse.loading,
          error: recruiterResponse.error,
          data: recruiterResponse.data,
        });
        if (recruiterResponse.data && recruiterResponse.data.currentRecruiter) {
          setIsDemoAccount(
            checkDemoAccount(
              recruiterResponse.data.currentRecruiter.agency.id,
              agencyDemoAccounts
            )
          );
        }
      })
      .catch((errorMessage: ApolloError) => {
        setRecruiterData({
          loading: false,
          error: errorMessage,
          data: undefined,
        });
      });
  }, [lazyGetRecruiter]);

  const recruiterContextState: RecruiterContextData = useMemo(
    () => ({
      recruiter: data?.currentRecruiter,
      // loading is true until recruiters have been fetched once
      loading: loading || isLoading || !dataFetchedOnce.current,
      fetchRecruiter,
      isDemoAccount,
      loadingRecruiter,
    }),
    [
      data?.currentRecruiter,
      loading,
      isLoading,
      fetchRecruiter,
      isDemoAccount,
      loadingRecruiter,
    ]
  );

  // First query execution (after Auth0's user is loaded)
  useEffect(() => {
    if (isLoading) return;
    const { viewport, screenResolution } = getScreenProperties();
    ampli.loginAuth0UserLoaded(
      {
        app: 'Recruiter',
      },
      {
        user_properties: {
          viewport,
          'screen resolution': screenResolution,
        },
      }
    );
    fetchRecruiter();
  }, [isLoading, user, fetchRecruiter]);

  // Handle error in GetCurrentRecruiterQuery
  useEffect(() => {
    if (!loading && error) {
      // Temporary toast, refactor to redirect to Error page
      toast.error(
        toastMessage({
          title: 'An error has ocurred while loading your data',
          subtitle: error.message,
        }),
        { duration: 5000 }
      );
    }
  }, [loading, error]);

  // Set user props in Amplitude
  useEffect(() => {
    if (!data || !data.currentRecruiter || !user) return;
    const { currentRecruiter } = data;
    ampli.identify(user.sub, {
      'agency id': currentRecruiter.agency.id,
      'recruiter id': currentRecruiter.id,
      'is test account': currentRecruiter.agency.isTestAccount,
      'is headrace admin': !!cookies['become-user-id'],
    });
    // Check if Hotjar has been initialized before calling its methods
    if (hotjar.initialized()) {
      hotjar.identify(currentRecruiter.id, {
        agencyId: currentRecruiter.agency.id,
        isTestAccount: currentRecruiter.agency.isTestAccount,
      });
    }
  }, [data, user, cookies]);
  useEffect(() => {
    if (isDemoAccount) ampli.client.setOptOut(true);
  }, [isDemoAccount]);

  return (
    <RecruiterContext.Provider value={recruiterContextState}>
      {children}
    </RecruiterContext.Provider>
  );
};

export const useRecruiter = (): RecruiterContextData =>
  React.useContext(RecruiterContext);

export default RecruiterProvider;
