import { LINKED_IN_URL } from '@headrace/constants';
import { TrashIcon } from '@heroicons/react/outline';
import { endOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import type { Dispatch, VFC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '../../../Button';
import { DatePickerField, Input, Select } from '../../../forms';
import type { CreateMediaUploadLinkFields } from '../../../ImageUpload';
import Modal from '../../../Modal';
import type { FormOptions, PlacementFormProps } from '../ProfileLayout';
import CreateEmployerModal from './CreateEmployerModal';
import CreateRoleTitleModal from './CreateRoleTitleModal';

export interface CompanyOption {
  id: string;
  name: string;
  companyStage?: {
    id: string;
    name: string;
  } | null;
}

export interface PlacementForm {
  id: string;
  companyId: string;
  roleTitleId: string;
  roleSeniorityId: string;
  roleFunctionId: string;
  companyStageId: string;
  cityId: string;
  firstYearBaseSalary: number | null;
  placedAt: Date | null;
  linkedIn: string;
}

interface Props {
  formOptions?: FormOptions;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  placement: PlacementForm | null;
  placementFormProps?: PlacementFormProps;
  createMediaUploadLink?: CreateMediaUploadLinkFields;
}

const PlacementFormModal: VFC<Props> = ({
  formOptions,
  open,
  setOpen,
  placement,
  placementFormProps,
  createMediaUploadLink,
}) => {
  const initialPlacement = {
    id: uuidv4(),
    companyId: '',
    roleTitleId: '',
    roleSeniorityId: '',
    roleFunctionId: '',
    companyStageId: '',
    cityId: '',
    firstYearBaseSalary: null,
    placedAt: null,
    linkedIn: '',
  };
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [initialValues, setInitialValues] =
    useState<PlacementForm>(initialPlacement);

  useEffect(() => {
    setInitialValues({
      id: placement?.id || uuidv4(),
      companyId: placement?.companyId || '',
      roleTitleId: placement?.roleTitleId || '',
      roleSeniorityId: placement?.roleSeniorityId || '',
      roleFunctionId: placement?.roleFunctionId || '',
      companyStageId: placement?.companyStageId || '',
      cityId: placement?.cityId || '',
      firstYearBaseSalary: placement?.firstYearBaseSalary || null,
      placedAt: placement?.placedAt || null,
      linkedIn: placement?.linkedIn || '',
    });
  }, [placement]);

  const closeEmployerModal = (): void => {
    setOpenCompanyModal(false);
    setOpen(true);
  };
  const closeRoleModal = (): void => {
    setOpenRoleModal(false);
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
  };
  const companiesData = placementFormProps?.companiesData;
  const getCompanyStage = (companyId: string): string => {
    const company = companiesData?.find(
      (companyData) => companyData.id === companyId
    );
    if (!company) return '';
    return company.companyStage?.id ? company.companyStage.id : '';
  };

  const companiesOptions = useMemo(() => {
    if (!companiesData) return [];
    return companiesData.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  }, [companiesData]);

  const onChangeCompanySelect = (
    value: string,
    setFieldValue: (field: string, value: string) => void
  ): void => {
    if (value) {
      const companyStageValue = getCompanyStage(value);
      if (companyStageValue) {
        setFieldValue('companyStageId', companyStageValue);
      }
    }
  };
  const today = endOfDay(new Date());
  const PlacementFormSchema = Yup.object().shape({
    companyId: Yup.string().required('Employer is required'),
    roleTitleId: Yup.string().required('Role is required'),
    roleSeniorityId: Yup.string().required('Level is required'),
    roleFunctionId: Yup.string().required('Function is required'),
    companyStageId: Yup.string().required('Company stage at hire is required'),
    cityId: Yup.string().required('Location is required'),
    placedAt: Yup.date()
      .typeError('Candidate start date must be a date')
      .max(today, 'Candidate start date must be in the past')
      .required('Candidate start date is required'),
    linkedIn: Yup.string()
      .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
      .required('LinkedIn URL is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(vals: PlacementForm): void => {
        if (placementFormProps?.savePlacement)
          placementFormProps.savePlacement.onSubmit(vals);
        setTimeout(() => {
          onClose();
        }, 200);
        setInitialValues(initialPlacement);
      }}
      validationSchema={PlacementFormSchema}
      enableReinitialize
    >
      {({ setFieldValue, submitForm }): JSX.Element => (
        <Form>
          <Modal
            open={open}
            title={
              placement ? 'Edit placement history' : 'Add placement history'
            }
            showXicon={false}
          >
            <div className="flex flex-col gap-7">
              <div className="grid md:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-4 flex-1">
                <div className="col-span-1">
                  <Select
                    name="companyId"
                    options={companiesOptions}
                    label="Employer"
                    isSearchable
                    onChange={(value): void =>
                      onChangeCompanySelect(value, setFieldValue)
                    }
                    actions={[
                      {
                        action: (): void => {
                          setOpenCompanyModal(true);
                          setOpen(false);
                        },
                        label: 'Add new employer',
                      },
                    ]}
                  />
                </div>
                <div className="col-span-1">
                  <Select
                    name="companyStageId"
                    options={formOptions?.companyStageOptions || []}
                    label="Company stage at hire"
                    isSearchable
                  />
                </div>
                <div className="col-span-1">
                  <Select
                    name="roleFunctionId"
                    options={formOptions?.functionsOptions || []}
                    label="Function"
                    isSearchable
                  />
                </div>
                <div className="col-span-1">
                  <Select
                    name="roleTitleId"
                    options={formOptions?.roleTitleOptions || []}
                    label="Role"
                    isSearchable
                    actions={[
                      {
                        action: (): void => {
                          setOpenRoleModal(true);
                          setOpen(false);
                        },
                        label: 'Add new role',
                      },
                    ]}
                  />
                </div>
                <div className="col-span-1">
                  <Select
                    name="roleSeniorityId"
                    options={formOptions?.roleSeniorityOptions || []}
                    label="Level"
                    isSearchable
                  />
                </div>
                <div className="col-span-1">
                  <Input
                    name="linkedIn"
                    label="Candidate LinkedIn"
                    helper="http://"
                  />
                </div>
                <div className="col-span-1">
                  <Select
                    name="cityId"
                    options={formOptions?.cityOptions || []}
                    label="Location"
                    isSearchable
                  />
                </div>
                <div className="col-span-1">
                  <DatePickerField
                    name="placedAt"
                    label="Candidate start date"
                    tooltip="Date the candidate was <br /> accepted and hired by the <br /> employer"
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="h-5 flex items-center justify-between w-full">
                {placement && (
                  <button
                    type="button"
                    onClick={(): void => {
                      if (placementFormProps?.deletePlacement)
                        placementFormProps.deletePlacement.onSubmit(
                          placement.id
                        );
                      onClose();
                    }}
                  >
                    <TrashIcon className="w-5 h-5 stroke-blue-400 hover:stroke-blue-300" />
                  </button>
                )}
                <div className="flex items-center justify-end gap-4 w-full">
                  <Button
                    buttonType="secondary"
                    className="!py-2"
                    onClick={(): void => {
                      setOpen(false);
                      setInitialValues(initialPlacement);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="!py-2"
                    onClick={submitForm}
                    loading={placementFormProps?.savePlacement.loading || false}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          <CreateEmployerModal
            open={openCompanyModal}
            onClose={closeEmployerModal}
            formOptions={formOptions}
            companyNames={companiesData?.map((company) => company.name) ?? []}
            createEmployer={placementFormProps?.createCompany.onSubmit}
            loading={placementFormProps?.createCompany.loading}
            createMediaUploadLink={createMediaUploadLink}
            setInitialValues={setInitialValues}
          />
          <CreateRoleTitleModal
            open={openRoleModal}
            onClose={closeRoleModal}
            loading={placementFormProps?.createRoleTitle.loading || false}
            createRoleTitle={placementFormProps?.createRoleTitle.onSubmit}
            setInitialValues={setInitialValues}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PlacementFormModal;
