import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import classNames from 'classnames';
import type { ReactElement } from 'react';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import { EarningsEmptyState } from '../Earnings';
import ErrorMessageBox from '../ErrorMessageBox';
import LoadingSpinner from '../LoadingSpinner';

export interface ComparativeBarGraphProps {
  titleGraph?: string;
  titleClassName?: string;
  labels: Array<string[] | string>;
  values: Array<number>;
  colors: Array<string>;
  subtitleComponent?: React.ReactElement;
  className?: string;
  loading?: boolean;
  error?: string | null;
  emptyState?: boolean;
  barThickness?: number;
  displayBarValues?: boolean;
  headerClassName?: string;
  barClassName?: string;
}

const ComparativeBarGraph = ({
  titleGraph,
  titleClassName = 'text-center',
  labels,
  values,
  colors,
  subtitleComponent,
  className,
  loading,
  error,
  emptyState = false,
  barThickness = 50,
  displayBarValues = true,
  headerClassName,
  barClassName,
}: ComparativeBarGraphProps): ReactElement => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ChartDataLabels
  );

  const getContent = useMemo(() => {
    const data = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: values,
          backgroundColor: colors,
          borderRadius: {
            topLeft: 10,
          },
          barThickness,
          hoverBackgroundColor: colors,
        },
      ],
    };
    if (loading) {
      return (
        <div className="flex flex-col justify-center items-center h-full">
          <LoadingSpinner className="p-4" />
        </div>
      );
    }
    if (error) {
      return <ErrorMessageBox error={error} />;
    }
    if (!emptyState) {
      return (
        <div className="flex items-center justify-center h-full mt-10">
          <EarningsEmptyState />
        </div>
      );
    }
    return (
      <div
        className={classNames(
          'flex justify-center content-center h-full',
          className
        )}
      >
        <div className={classNames('w-full', barClassName)}>
          <Bar
            options={{
              responsive: true,
              layout: {
                padding: {
                  top: 30,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    offset: true,
                  },
                },
                x: {
                  weight: 0.4,
                  display: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: 'rgba(48, 50, 61, 1)',
                    font: {
                      size: 14,
                      weight: 'bold',
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
                datalabels: {
                  anchor: 'end',
                  align: 'top',
                  color: 'rgba(48, 50, 61, 1)',
                  font: {
                    weight: 'bold',
                    size: 16,
                  },
                  display: displayBarValues,
                },
                tooltip: {
                  enabled: false,
                },
              },
              maintainAspectRatio: false,
            }}
            data={data}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
    );
  }, [
    barClassName,
    barThickness,
    className,
    colors,
    displayBarValues,
    emptyState,
    error,
    labels,
    loading,
    values,
  ]);

  return (
    <div className="w-full flex items-center flex-col  h-full">
      <div
        className={classNames(
          'flex flex-col gap-1 justify-center items-center w-full mb-4',
          headerClassName
        )}
      >
        <div className={classNames('font-bold text-xl w-full', titleClassName)}>
          {titleGraph}
        </div>
        {subtitleComponent && <div className="mb-4">{subtitleComponent}</div>}
      </div>
      {getContent}
    </div>
  );
};

export default ComparativeBarGraph;
