import Head from 'next/head';
import type { FC } from 'react';

const LinkedInButton: FC = () => (
  <div>
    <Head>
      <script src="https://platform.linkedin.com/in.js" type="text/javascript">
        lang: en_US
      </script>
    </Head>
    <script type="IN/FollowCompany" data-id="52200048" data-counter="right" />
  </div>
);
export default LinkedInButton;
