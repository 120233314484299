import type { MarketplaceFilters } from '.';

const marketplaceFiltersInitialValue: MarketplaceFilters = {
  roleType: { Public: false, Invite: false },
  function: [],
  companyStage: {
    'Early stage': false,
    'Growth stage': false,
    'Late stage': false,
  },
  location: [],
  roleLevel: {},
  salaryRange: { min: 0, max: 300000 },
  placementFee: { min: 0, max: 50 },
};

export default marketplaceFiltersInitialValue;
