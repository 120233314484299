import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}

const ChartBarIcon: VFC<Props> = ({ className }) => {
  const iconClassName = classNames('inline-block', className);
  return (
    <span className={iconClassName}>
      <svg
        width="63"
        height="48"
        viewBox="0 0 63 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.64319 44.2409H2.12785C1.41378 44.2409 0.83252 44.8222 0.83252 45.5362C0.83252 46.2503 1.41378 46.8316 2.12785 46.8316H60.5599C61.2739 46.8316 61.8552 46.2503 61.8552 45.5362C61.8552 44.8157 61.246 44.2409 60.5385 44.2409H53.0232V11.9576C53.0232 11.2435 52.4419 10.6622 51.7279 10.6622C51.0138 10.6622 50.4325 11.2435 50.4325 11.9576V44.2409H43.5512V3.09824H50.4325V7.54157C50.4325 8.25564 51.0138 8.8369 51.7279 8.8369C52.4419 8.8369 53.0232 8.25564 53.0232 7.54157V0.757568V0.507568H52.7732H41.2105H40.9605V0.757568V44.2622H37.3645V22.7522C37.3645 22.0382 36.7833 21.4569 36.0692 21.4569C35.3551 21.4569 34.7739 22.0382 34.7739 22.7522V44.2622H27.8925V13.8716H34.7739V18.3149C34.7739 19.029 35.3551 19.6102 36.0692 19.6102C36.7833 19.6102 37.3645 19.029 37.3645 18.3149V11.5309V11.2809H37.1145H25.5519H25.3019V11.5309V44.2409H21.7059V32.9069C21.7059 32.1928 21.1246 31.6116 20.4105 31.6116C19.6964 31.6116 19.1152 32.1928 19.1152 32.9069V44.2409H12.2339V24.0262H19.1152V28.4696C19.1152 29.1836 19.6965 29.7649 20.4105 29.7649C21.1246 29.7649 21.7059 29.1836 21.7059 28.4696V21.6856V21.4356H21.4559H9.89319H9.64319V21.6856V44.2409Z"
          fill="#FFA300"
          stroke="#FFA300"
          strokeWidth="0.5"
        />
      </svg>
    </span>
  );
};

export default ChartBarIcon;
