import { currencyFormatter } from '@headrace/utils';
import type { VFC } from 'react';
import React, { useMemo } from 'react';

import EllipsisText from './EllipsisText';
import ErrorMessageBox from './ErrorMessageBox';
import LoadingSpinner from './LoadingSpinner';
import ProgressBar from './ProgressBar';

interface Props {
  title: string;
  list: { name: string; value: number }[];
  currencyFormat?: boolean;
  emptyState?: React.ReactNode;
  loading?: boolean;
  error?: string | null;
}

const RankingList: VFC<Props> = ({
  title,
  list,
  currencyFormat,
  emptyState = null,
  loading = false,
  error,
}) => {
  const total = list.reduce((acc, item) => acc + item.value, 0);
  const content = useMemo(() => {
    if (loading)
      return (
        <div className="flex flex-col justify-center items-center h-full">
          <LoadingSpinner className="p-4" />
        </div>
      );
    if (error) return <ErrorMessageBox error={error} />;
    if (list.length === 0) return <div className="p-12">{emptyState}</div>;
    return (
      <ol className="relative border-l border-gray-200  flex flex-col gap-8">
        {list.map(({ name, value }, index) => (
          <li className="relative pl-7" key={name}>
            <div className="absolute w-8 h-8 rounded-full flex items-center justify-center -left-4 border-2 border-headraceYellow-700 bg-white font-bold">
              {index + 1}
            </div>
            <div className="flex justify-between items-center">
              <div className="truncate font-normal sm:w-5/12 w-4/5 -mb-1 text-base">
                <EllipsisText text={name} />
              </div>
              <div className="sm:w-7/12 w-1/5 flex justify-center items-center gap-2">
                <div className="w-5/6 sm:w-[75%] hidden sm:block">
                  <ProgressBar percentage={(value * 100) / total} />
                </div>
                <div className="text-xl font-light w-full sm:w-[25%] flex justify-end sm:justify-center">
                  {currencyFormat
                    ? currencyFormatter({
                        compactDisplay: 'short',
                        notation: 'compact',
                      }).format(value)
                    : value}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ol>
    );
  }, [currencyFormat, emptyState, error, list, loading, total]);
  return (
    <div className="flex flex-col gap-10 text-headraceBlack-800">
      <div className="font-bold text-center text-2xl ">{title}</div>
      {content}
    </div>
  );
};

export default RankingList;
