import { Tabs } from '@headrace/ui';
import type { TabsProps } from '@headrace/ui/src/Tabs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useRecruiter } from '@/lib/RecruiterProvider';

import MarketplaceFiltersBar from './MarketplaceFiltersBar';
import MarketplaceProvider, {
  useMarketplaceRoles,
} from './MarketplaceProvider';

const MarketplaceLayout: React.FC = ({ children }) => {
  const { filters, setFilters, isFiltersOpen, setIsFiltersOpen, currentTab } =
    useMarketplaceRoles();

  const router = useRouter();
  const { recruiter } = useRecruiter();

  useEffect(() => {
    if (recruiter?.isMarketplaceRecruiter === false) {
      router.push('/').catch(null);
    }
  }, [recruiter, router]);

  const tabs: TabsProps['tabs'] = [
    {
      id: 'invites',
      name: 'Invites',
      onClick: (): void => {
        router.push(`/marketplace/invites`).catch(null);
      },
      current: currentTab === 0,
    },
    {
      id: 'my_roles',
      name: 'My roles',
      onClick: (): void => {
        router.push(`/marketplace/my-roles`).catch(null);
      },
      current: currentTab === 1,
    },
    {
      id: 'not_interested',
      name: 'Not interested',
      onClick: (): void => {
        router.push(`/marketplace/not-interested`).catch(null);
      },
      current: currentTab === 2,
    },
  ];

  return (
    <div className="flex gap-6 hd:max-w-[1800px] hd:mx-auto">
      <MarketplaceFiltersBar
        filters={filters}
        setFilters={setFilters}
        className="hidden lg:block flex-shrink-0 w-[292px]"
        openModal={isFiltersOpen}
        setOpenModal={setIsFiltersOpen}
      />
      <div className="flex-auto min-h-[2000px]">
        {' '}
        <div className="flex">
          <Tabs tabs={tabs} className="mb-6 flex-auto" />
        </div>
        {children}
      </div>
    </div>
  );
};

const WrapperRoleDetailsLayout: React.FC = ({ children }) => (
  <MarketplaceProvider>
    <MarketplaceLayout>{children}</MarketplaceLayout>
  </MarketplaceProvider>
);

export default WrapperRoleDetailsLayout;
