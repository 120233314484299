import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import type { VFC } from 'react';
import { useEffect, useState } from 'react';

import Dots from './Dots';

interface Props {
  elements: React.ReactNode[];
  interval?: number;
  dots?: boolean;
  navigation?: boolean;
}

const Slider: VFC<Props> = ({
  elements,
  interval = 4000,
  dots = true,
  navigation = false,
}) => {
  let count = 0;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideIntervalId, setSlideIntervalId] = useState(0);

  const handleOnNextClick = (auto?: boolean): void => {
    count = ((auto ? count : currentIndex) + 1) % elements.length;
    setCurrentIndex(count);
  };

  const handleOnPrevClick = (): void => {
    const productsLength = elements.length;
    count = (currentIndex + productsLength - 1) % productsLength;
    setCurrentIndex(count);
  };

  const handleOnSelect = (index: number): void => {
    setCurrentIndex(index);
  };

  const startSlider = (): void => {
    const slideInterval = window.setInterval(() => {
      handleOnNextClick(true);
    }, interval);
    setSlideIntervalId(slideInterval);
  };

  const pauseSlider = (): void => {
    clearInterval(slideIntervalId);
  };

  useEffect(() => {
    if (elements.length > 1) startSlider();
    return elements.length > 1
      ? (): void => {
          pauseSlider();
        }
      : undefined;
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="w-full select-none relative h-full"
      onMouseEnter={elements.length > 1 ? pauseSlider : undefined}
      onMouseLeave={elements.length > 1 ? startSlider : undefined}
    >
      <div className="aspect-w-16 aspect-h-9 h-full">
        {elements[currentIndex]}
      </div>

      {dots && elements.length > 1 && (
        <Dots
          total={elements.length}
          currentIndex={currentIndex}
          handleOnSelect={handleOnSelect}
        />
      )}

      {navigation && elements.length > 1 && (
        <div className="absolute w-full top-1/2 transform -translate-y-1/2 px-3 flex justify-between items-center">
          <button
            type="button"
            className="text-headraceBlack-800 text-opacity-50 cursor-pointer"
            onClick={handleOnPrevClick}
          >
            <ArrowNarrowLeftIcon className="w-[30px]" />
          </button>
          <button
            type="button"
            className="text-headraceBlack-800 text-opacity-50 cursor-pointer"
            onClick={(): void => handleOnNextClick()}
          >
            <ArrowNarrowRightIcon className="w-[30px]" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Slider;
