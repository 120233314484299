import type {
  CommonPlacedIndustriesData,
  CompanyPanel,
  CompanyStageData,
  PlacementData,
  PlacementGeographiesGraphData,
  ProfileBasicDataType,
  SearchReview,
  TopRoleFunctionsData,
  TopRoleSeniorityData,
} from '@headrace/types';
import type { VFC } from 'react';
import { useMemo } from 'react';

import ErrorMessageBox from '../../ErrorMessageBox';
import type { OptionsProps } from '../../forms';
import type { CreateMediaUploadLinkFields } from '../../ImageUpload';
import type { ModalReport } from '../../Rating/ReportReviewModal';
import ProfileCard from '../ProfileCard';
import type { City } from './CitiesOfFocus';
import ClientCompanyStage from './ClientCompanyStage';
import CommonPlacedIndustries from './CommonPlacedIndustries';
import CompanyLogosCarousel from './CompanyLogosCarousel';
import type { CompanyFormValues } from './EditMode';
import type { BasicFormValues } from './EditMode/BasicData';
import type { EndorsementForm } from './EditMode/EndorsementCardForm';
import type {
  CompanyOption,
  PlacementForm,
} from './EditMode/PlacementFormModal';
import type { Endorsement } from './EndorsementsCard';
import EndorsementsCard from './EndorsementsCard';
import FinalizeOnboarding from './FinalizeOnboarding';
import MostPlacedSenority from './MostPlacedSenority';
import PlacementGeographies from './PlacementGeographies';
import PlacementHistory from './PlacementHistory';
import ProfileBasicData from './ProfileBasicData';
import RecruiterBio from './RecruiterBio';
import type { ReviewAverage } from './Reviews';
import Reviews from './Reviews';
import TimeToPlacementWidget from './TimeToPlacementWidget';
import type { ProfileOnboardingStep } from './ToDo';
import ToDo from './ToDo';
import TopRoleFunctions from './TopRoleFunctions';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

interface Query {
  error: string | null;
  loading: boolean;
}

export interface Recruiter extends ProfileBasicDataType {
  cities: City[];
}

interface PlacementsGeographies extends Query {
  data: PlacementGeographiesGraphData[];
}

interface CompanyStages extends Query {
  data: CompanyStageData[];
}

interface PlacementsCompanies extends Query {
  data: CompanyPanel[];
}

interface TopRoleFunctionsType extends Query {
  data: Partial<TopRoleFunctionsData>[];
}

interface ReviewsType extends Query {
  data: {
    searchReviews: SearchReview[];
    reviewAverages: ReviewAverage | null;
  } | null;
}

export interface FormOptions {
  cityOptions: OptionsProps[];
  functionsOptions: OptionsProps[];
  roleTitleOptions: OptionsProps[];
  roleSeniorityOptions: OptionsProps[];
  companyStageOptions: OptionsProps[];
  industryOptions: OptionsProps[];
}

interface SubmitActions {
  saveBasicData: {
    onSubmit: (values: BasicFormValues) => void;
    loading: boolean;
  };
  saveTimeToPlacement: {
    onSubmit: (values: { timeToPlacement: number }) => void;
    loading: boolean;
  };
  saveBio: {
    onSubmit: (values: { bio: string; bioJSON: string }) => void;
    loading: boolean;
  };
  saveEndorsements: {
    onSubmit: (values: { endorsements: EndorsementForm[] }) => void;
    loading: boolean;
  };
}

export interface PlacementFormProps {
  companiesData: CompanyOption[];
  refetchCompanies: () => Promise<void>;
  createCompany: {
    onSubmit: (values: CompanyFormValues) => Promise<{
      id: string;
      companyStageId?: string | null;
    } | null>;
    loading: boolean;
  };
  savePlacement: {
    onSubmit: (values: PlacementForm) => void;
    loading: boolean;
  };
  deletePlacement: {
    onSubmit: (id: string) => void;
    loading: boolean;
  };
  createRoleTitle: {
    onSubmit: (role: string) => Promise<string | null>;
    loading: boolean;
  };
}
export interface ProfileLayoutProps {
  recruiter: Recruiter | null;
  timeToPlacement: number;
  topRoleFunctions: TopRoleFunctionsType;
  placementsGeographies: PlacementsGeographies;
  placementsCompanies: PlacementsCompanies;
  commonPlacedIndustries: Query & {
    data: Partial<CommonPlacedIndustriesData>[];
  };
  topRoleSeniority: Query & {
    data: Partial<TopRoleSeniorityData>[];
  };
  companyStages: CompanyStages;
  searchReviews: ReviewsType;
  onSubmit: (values: ModalReport & SearchReview) => void;
  placements: PlacementData[];
  endorsements: Endorsement[];
  profileOnboardingSteps: ProfileOnboardingStep[];
  editMode?: boolean;
  formOptions?: {
    options: FormOptions;
    refetch: () => void;
  };
  createMediaUploadLink?: CreateMediaUploadLinkFields;
  submitActions?: SubmitActions;
  isManualTimeToPlacement?: boolean;
  placementFormProps?: PlacementFormProps;
}

const ProfileLayout: VFC<ProfileLayoutProps> = ({
  recruiter,
  timeToPlacement,
  companyStages,
  topRoleFunctions,
  placementsGeographies,
  placementsCompanies,
  topRoleSeniority,
  commonPlacedIndustries,
  searchReviews,
  onSubmit,
  placements,
  endorsements,
  profileOnboardingSteps,
  editMode = false,
  createMediaUploadLink,
  formOptions,
  submitActions,
  isManualTimeToPlacement = false,
  placementFormProps,
}) => {
  const companyLogos = useMemo(
    () => placementsCompanies.data.filter((company) => company.logoUrl),
    [placementsCompanies.data]
  );

  if (!recruiter) {
    return <ErrorMessageBox error="No recruiter found" />;
  }
  return (
    <div className="flex flex-col text-headraceBlack-800">
      {profileOnboardingSteps.length > 0 && (
        <div className="mb-6 mx-auto grid grid-cols-1 gap-6 xl:grid-flow-col-dense xl:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 w-full">
          <ProfileCard className="w-full xl:col-span-1 lg:col-span-full">
            <ToDo profileOnboardingSteps={profileOnboardingSteps} />
          </ProfileCard>
          <ProfileCard className="w-full xl:col-span-2 lg:col-span-full">
            <FinalizeOnboarding
              isReady={profileOnboardingSteps.every(
                (profileOnboardingStep) => profileOnboardingStep.isCompleted
              )}
            />
          </ProfileCard>
        </div>
      )}
      <div className="mb-6 mx-auto grid grid-cols-1 gap-6 xl:grid-flow-col-dense xl:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 w-full">
        <div className="w-full xl:col-span-2 lg:col-span-full">
          <ProfileBasicData
            profileImage={recruiter.profileImage}
            profileImageKey={recruiter.profileImageKey}
            bio={recruiter.bio}
            name={recruiter.name || 'No Name'}
            fullName={recruiter.fullName}
            agency={recruiter.agency}
            roleFunctions={recruiter.roleFunctions}
            email={recruiter.email}
            phone={recruiter.phone}
            website={recruiter.website}
            linkedIn={recruiter.linkedIn}
            title={recruiter.title}
            location={recruiter.location}
            locationId={recruiter.locationId}
            maxPricePercent={recruiter.maxPricePercent}
            minPricePercent={recruiter.minPricePercent}
            schedulingLink15Min={recruiter.schedulingLink15Min}
            schedulingLink30Min={recruiter.schedulingLink30Min}
            schedulingLink60Min={recruiter.schedulingLink60Min}
            avalaibleRequestProposal={recruiter.avalaibleRequestProposal}
            searchPreference={recruiter.searchPreference}
            cityOptions={formOptions?.options.cityOptions || []}
            functionsOptions={formOptions?.options.functionsOptions || []}
            createMediaUploadLink={createMediaUploadLink}
            saveData={submitActions?.saveBasicData}
            editMode={editMode}
          />
        </div>
        <TimeToPlacementWidget
          recruiter={timeToPlacement}
          average={3.5}
          editMode={editMode}
          isManual={isManualTimeToPlacement}
          onSubmit={submitActions?.saveTimeToPlacement.onSubmit}
          loading={submitActions?.saveTimeToPlacement.loading}
        />
      </div>
      {companyLogos && companyLogos.length ? (
        <CompanyLogosCarousel placementCompanies={companyLogos} />
      ) : null}
      <RecruiterBio
        bioJSON={recruiter.bioJSON || ''}
        bio={recruiter.bio || ''}
        editMode={editMode}
        onSubmit={submitActions?.saveBio.onSubmit}
        loading={submitActions?.saveBio.loading}
      />
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="col-span-4 xl:col-span-2 h-full">
          <div className="flex flex-col gap-4 h-fit xl:h-full lg:justify-center">
            <ClientCompanyStage
              loading={companyStages.loading}
              error={companyStages.error}
              companyStages={companyStages.data}
            />
            <TopRoleFunctions
              topRoleFunctions={topRoleFunctions.data}
              loading={topRoleFunctions.loading}
              error={topRoleFunctions.error}
            />
          </div>
        </div>
        <div className="col-span-4 xl:col-span-2 h-full">
          <div className="flex flex-col gap-4 h-full lg:justify-center">
            <CommonPlacedIndustries
              commonPlacedIndustries={commonPlacedIndustries.data}
              loading={commonPlacedIndustries.loading}
              error={commonPlacedIndustries.error}
            />
            <MostPlacedSenority
              topRoleSeniority={topRoleSeniority.data}
              loading={topRoleSeniority.loading}
              error={topRoleSeniority.error}
            />
          </div>
        </div>
      </div>
      {placements ? (
        <PlacementHistory
          placements={placements}
          entriesPerPage={10}
          editMode={editMode}
          placementFormProps={placementFormProps}
          formOptions={formOptions?.options}
          createMediaUploadLink={createMediaUploadLink}
        />
      ) : null}
      {endorsements ? (
        <EndorsementsCard
          endorsements={endorsements}
          editMode={editMode}
          onSubmit={submitActions?.saveEndorsements.onSubmit}
          loading={submitActions?.saveEndorsements.loading}
        />
      ) : null}
      <ProfileCard>
        <PlacementGeographies
          placementsData={placementsGeographies.data}
          loading={placementsGeographies.loading}
          error={placementsGeographies.error}
        />
      </ProfileCard>
      <Reviews
        searchReviews={searchReviews?.data?.searchReviews}
        reviewAverages={searchReviews?.data?.reviewAverages || null}
        loading={searchReviews?.loading}
        error={searchReviews?.error}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default ProfileLayout;
