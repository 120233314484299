import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}

const QuoteIcon: VFC<Props> = ({ className }) => {
  const svgClassName = classNames(
    className,
    'text-headraceYellow-700 inline-block'
  );
  return (
    <svg
      width="66"
      height="40"
      viewBox="0 0 66 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={svgClassName}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0129 0C23.3051 0 30.0276 6.78152 30.0276 15.1486C30.0276 27.0233 21.9426 33.7978 13.1361 39.7945C12.1353 40.4748 10.8923 39.3374 11.4682 38.2656C12.8499 35.688 13.7997 33.036 14.0333 30.2653C6.1976 29.7551 0 23.1826 0 15.1486C0 6.78152 6.72255 0 15.0129 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9871 0C59.2775 0 66 6.78152 66 15.1486C66 27.0233 57.9168 33.7978 49.1102 39.7945C48.1095 40.4748 46.8665 39.3374 47.4406 38.2656C48.8223 35.688 49.7739 33.036 50.0056 30.2653C42.1717 29.7551 35.9724 23.1826 35.9724 15.1486C35.9724 6.78152 42.6949 0 50.9871 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default QuoteIcon;
