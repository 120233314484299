import React, { useMemo, useState } from 'react';

interface PublicLayoutContextData {
  showLoginAndGetAccessButton: boolean;
  setShowLoginAndGetAccessButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const PublicLayoutContext = React.createContext<PublicLayoutContextData>({
  showLoginAndGetAccessButton: false,
  setShowLoginAndGetAccessButton: () => {},
});

const PublicLayoutProvider: React.FC = ({ children }) => {
  const [showLoginAndGetAccessButton, setShowLoginAndGetAccessButton] =
    useState(false);

  const publicLayoutContextState: PublicLayoutContextData = useMemo(
    () => ({
      showLoginAndGetAccessButton,
      setShowLoginAndGetAccessButton,
    }),
    [showLoginAndGetAccessButton]
  );

  return (
    <PublicLayoutContext.Provider value={publicLayoutContextState}>
      {children}
    </PublicLayoutContext.Provider>
  );
};

export const usePublicLayout = (): PublicLayoutContextData =>
  React.useContext(PublicLayoutContext);

export default PublicLayoutProvider;
