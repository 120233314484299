import { BASIC_URL } from '@headrace/constants';
import { currencyFormatter } from '@headrace/utils';
import * as Yup from 'yup';

const RoleFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Role name has a maximum limit of 50 characters.')
    .required('Role name is required'),
  description: Yup.string()
    .min(20, 'Role description has a minimum limit of 20 characters.')
    .required('Please provide a short description of the role'),
  roleSeniority: Yup.string().required('Please, select a seniority'),
  roleFunction: Yup.string().required('Please, select a function'),
  cities: Yup.array()
    .of(Yup.string())
    .min(1, '1 location as minimum')
    .required('Locations are required'),
  quantity: Yup.number()
    .integer('Quantity must be an integer.')
    .min(1, 'Quantity must be greater than 0.')
    .nullable()
    .required('This field is required'),
  expectedFirstYearSalaryMin: Yup.number()
    .positive('Must be a positive number')
    .nullable(),
  expectedFirstYearSalaryMax: Yup.number()
    .positive('Must be a positive number')
    .nullable()
    .min(
      Yup.ref('expectedFirstYearSalaryMin'),
      ({ min }) => `Must not be less than ${currencyFormatter().format(min)}`
    ),
  expectedFirstYearShareCount: Yup.number()
    .integer('Must be a positive integer')
    .nullable(),
  jobDescriptionLink: Yup.string().matches(BASIC_URL, 'Invalid URL'),
});

export default RoleFormSchema;
