import 'yup-phone-lite';

import * as Yup from 'yup';

const RecruiterFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  agencyName: Yup.string(),
  tou: Yup.boolean().required(),
});

export default RecruiterFormSchema;
