import type { TopRoleSeniorityData } from '@headrace/types';

import ErrorMessageBox from '../../ErrorMessageBox';
import { ComparativeBarGraph } from '../../graphs';
import LoadingSpinner from '../../LoadingSpinner';
import ProfileCard from '../ProfileCard';

interface Props {
  topRoleSeniority: Partial<TopRoleSeniorityData>[];
  loading?: boolean;
  error?: string | null;
}

const MostPlacedSenority: React.VFC<Props> = ({
  topRoleSeniority,
  loading,
  error,
}) => {
  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessageBox error={error} />;

  const getLabels = (): Array<string[] | string> =>
    topRoleSeniority.map((item) => {
      const finalArray = [];
      if (item.name) {
        let newString = '';
        for (let i = 0; i <= item.name.length; i += 1) {
          const nChar = item.name.charAt(i);
          if (item.name.charAt(i) === ' ' || i === item.name.length) {
            if (newString.length < 2) {
              newString += nChar;
            } else {
              finalArray.push(newString);
              newString = '';
            }
          } else {
            newString += nChar;
          }
        }
        return finalArray;
      }
      return '';
    });

  const getValues = (): number[] =>
    topRoleSeniority.map((item) => item.placementsCount || 0);

  return (
    <ProfileCard className="lg:h-full overflow-visible lg:h-[450px]">
      <ComparativeBarGraph
        titleGraph="Most placed seniority"
        titleClassName="self-start !text-2xl"
        labels={getLabels()}
        values={getValues()}
        colors={['#ffa300']}
        className="w-full"
        emptyState={topRoleSeniority.length > 0}
        loading={loading}
        error={error}
        barThickness={95}
        displayBarValues={false}
        headerClassName="mb-0 gap-0"
      />
    </ProfileCard>
  );
};

export default MostPlacedSenority;
