import type { SearchReview } from '@headrace/types';
import { numberFormatter } from '@headrace/utils';

import CircularChart from '../../../CircularChart';
import Rating from '../../../Rating';

interface Props {
  searchReviews?: SearchReview[];
  comunicationAverage: number;
  timelessAverage: number;
  overallRatingAverage: number;
  candidateSubmissionQualityAverage: number;
  timeToPlacementAverage: number;
}

const OverallRating: React.VFC<Props> = ({
  comunicationAverage,
  timelessAverage,
  overallRatingAverage,
  candidateSubmissionQualityAverage,
  timeToPlacementAverage,
}) => (
  <div className="flex items-center font-medium flex-wrap xl:flex-nowrap justify-center xl:justify-start p-10">
    <div className="flex items-center mb-8 xl:mb-0">
      <span className="text-[80px] mr-2">
        {numberFormatter({
          compactDisplay: 'short',
          notation: 'compact',
        }).format(overallRatingAverage)}
      </span>
      <div className="mr-12">
        <Rating initialRating={overallRatingAverage} iconSize="40" />
        <div className="text-3xl">Overall rating</div>
      </div>
    </div>
    <div className="flex w-full xl:w-6/12 gap-4 xl:gap-[23px]">
      <CircularChart
        value={comunicationAverage}
        maxValue={5}
        title="Communication"
        className="w-[100px] xl:w-full"
      />
      <CircularChart
        value={timelessAverage}
        maxValue={5}
        title="Timeliness"
        className="w-[100px] xl:w-full"
      />
      <CircularChart
        value={candidateSubmissionQualityAverage}
        maxValue={5}
        title="Candidate quality"
        className="w-[100px] xl:w-full"
      />
      <CircularChart
        value={timeToPlacementAverage}
        maxValue={5}
        title="Time to placement"
        className="w-[100px] xl:w-full"
      />
    </div>
  </div>
);

export default OverallRating;
