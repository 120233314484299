import type { VFC } from 'react';

import Button from '../Button';
import Modal from '../Modal';

interface Props {
  recruiterQuota: number;
  recruiterLimit: number;
  submissionAvailableDate?: string | null;
  open: boolean;
  onClose: () => void;
}

const CandidateSubmissionQuotaModal: VFC<Props> = ({
  recruiterQuota,
  recruiterLimit,
  submissionAvailableDate,
  open,
  onClose,
}) => {
  const formatDate = submissionAvailableDate || '';
  return (
    <Modal
      open={open}
      title="Candidate submission limit"
      onClose={onClose}
      className=""
    >
      <div className="flex flex-col gap-4 text-gray-500 text-sm">
        <div className="flex flex-col gap-5">
          This role has limited candidate submissions available to all
          recruiters on HeadRace. The purpose of this limit is to ensure
          employers receive consistent high-quality candidate submissions. The
          submission limit for this role is set to {recruiterLimit} candidates
          per week.
          <div>
            You currently have <b>{recruiterLimit - recruiterQuota}</b>{' '}
            submissions available.
          </div>
          {recruiterQuota >= recruiterLimit && (
            <div>
              You will unlock your next submission on <b>{formatDate}</b>.
            </div>
          )}
          <div>
            <p className="inline">
              To learn more about candidate submission limits, please read the
              documentation{' '}
              <a
                href="https://coda.io/@alex-davis/headrace-recruiter-faq/working-on-headrace-opportunities-16#_lu_C5"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};

export default CandidateSubmissionQuotaModal;
