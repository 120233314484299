import classNames from 'classnames';
import type { VFC } from 'react';

import { ChartBarIcon } from '../CustomIcons';

interface Props {
  className?: string;
  containerClassName?: string;
}

const EmptyState: VFC<Props> = ({ className, containerClassName }) => (
  <div
    className={classNames(
      'flex flex-col items-center justify-center',
      containerClassName
    )}
  >
    <div
      className={classNames('w-52 flex flex-col items-center gap-2', className)}
    >
      <ChartBarIcon />
      <div className="text-2xl font-medium  text-gray-700">Gathering data</div>
      <div className="text-gray-600 text-center text-sm">
        Data will show when required data is collected
      </div>
    </div>
  </div>
);

export default EmptyState;
