import './Tooltip.css';

import { InformationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import ErrorMessageBox from '../ErrorMessageBox';
import LoadingSpinner from '../LoadingSpinner';
import MiniDivider from '../MiniDivider';
import WavyCard from '../WavyCard';
import EmptyState from './EmptyState';

interface Props {
  icon: JSX.Element;
  title: string;
  value: string;
  className?: string;
  helper?: string;
  empty?: boolean;
  loading?: boolean;
  error?: string | null;
}

const EarningCard: React.VFC<Props> = ({
  icon,
  title,
  value,
  className,
  helper,
  empty = false,
  loading = false,
  error,
}) => {
  if (loading) {
    return (
      <WavyCard
        contentClassName="text-white flex flex-col gap-2 font-normal text-base p-6"
        className={classNames(className)}
      >
        <div className="flex flex-col gap-4 items-center bg-white rounded opacity-80">
          <LoadingSpinner className="p-4" />
        </div>
        <MiniDivider />
        <span className="font-medium text-xl">{title}</span>
      </WavyCard>
    );
  }

  if (error) {
    return (
      <WavyCard
        contentClassName="text-white flex flex-col gap-2 font-normal text-base p-6"
        className={classNames(className)}
      >
        <div className="flex flex-col gap-4 items-cente">
          <ErrorMessageBox error={error} />
        </div>
        <MiniDivider />
        <span className="font-medium text-xl">{title}</span>
      </WavyCard>
    );
  }

  return (
    <>
      <WavyCard
        contentClassName="text-white flex flex-col gap-10 font-normal text-base justify-between p-6"
        className={className}
      >
        <div
          className={classNames('flex  items-center', {
            'justify-between': !empty,
            'gap-5': empty,
          })}
        >
          <div className="w-7 font-light">{icon}</div>
          {empty && <div className="font-medium text-xl">{title}</div>}
          {helper && !empty && (
            <div className="flex-shrink-0 flex">
              <span
                data-for="earning-card-tooltip"
                data-tip={helper}
                data-class="my-tooltip"
              >
                <InformationCircleIcon className="w-5 opacity-50" />
              </span>
            </div>
          )}
        </div>

        {empty ? (
          <div className="flex flex-col items-center bg-white rounded opacity-80 p-2">
            <EmptyState className="!w-full" />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <span className="text-4xl font-medium">{value}</span>
            <MiniDivider />
            <span className="font-medium text-xl">{title}</span>
          </div>
        )}
      </WavyCard>
      <ReactTooltip
        id="earning-card-tooltip"
        place="top"
        effect="solid"
        multiline
        arrowColor="#30323D"
        offset={{ top: -5 }}
      />
    </>
  );
};

export default EarningCard;
