/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull recruiter'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 60
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/headrace/HeadRace/implementation/recruiter)
 */

import amplitude, { AmplitudeClient, Callback, Config } from 'amplitude-js';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '5d215cd5e61eda09cf589dc550a46061',
  development: '35613b326309ca088ca35a1a75527abf',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions: ConfigExt = {
  plan: {
    version: '60',
    branch: 'main',
    source: 'recruiter',
    versionId: 'aadeb178-d14e-40b2-80cb-a7f3fce2024a',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '1.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { options?: Partial<ConfigExt> };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; options?: Partial<ConfigExt> };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: AmplitudeClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  'agency id'?: string;
  'employee id'?: string;
  'employer id'?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SINGULAR, PLURAL |
   */
  'employer type'?: 'SINGULAR' | 'PLURAL';
  'is headrace admin'?: boolean;
  /**
   * Designates if this user is a test account and should not be used in metrics
   */
  'is test account'?: boolean;
  'recruiter id'?: string;
  'screen resolution'?: string;
  viewport?: string;
}

export interface AppPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  'url path': string;
}

export interface CandidateCandidateDocumentViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'candidate id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface CandidateCandidateViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'candidate id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface CandidateRemoveCandidateDocumentClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'candidate id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface CandidateUploadCandidateDocumentClickedProperties {
  'candidate id'?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface LoginAuth0UserLoadedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer, Recruiter_Chrome_Extension |
   */
  app: 'Recruiter' | 'Employer' | 'Recruiter_Chrome_Extension';
}

export interface PublicDossierPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Employer, Recruiter, Retool |
   */
  app: 'Employer' | 'Recruiter' | 'Retool';
  'dossier id': string;
}

export interface RoleDashboardRoleClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
}

export interface RoleRoleViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'employer id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface SearchAgreementSearchAgreementViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'search agreement id': string;
}

export interface UserAddTeamMemberClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
}

export class Identify implements BaseEvent {
  event_type = SpecialEventType.Identify;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AppPageViewed implements BaseEvent {
  event_type = 'App: page viewed';

  constructor(public event_properties: AppPageViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class CandidateCandidateDocumentViewed implements BaseEvent {
  event_type = 'Candidate: candidate document viewed';

  constructor(
    public event_properties: CandidateCandidateDocumentViewedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class CandidateCandidateViewed implements BaseEvent {
  event_type = 'Candidate: candidate viewed';

  constructor(public event_properties: CandidateCandidateViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class CandidateRemoveCandidateDocumentClicked implements BaseEvent {
  event_type = 'Candidate: remove candidate document clicked';
  event_properties: CandidateRemoveCandidateDocumentClickedProperties & {
    app: 'Recruiter';
  };

  constructor(
    event_properties: CandidateRemoveCandidateDocumentClickedProperties
  ) {
    this.event_properties = {
      ...event_properties,
      app: 'Recruiter',
    };
  }
}

export class CandidateUploadCandidateDocumentClicked implements BaseEvent {
  event_type = 'Candidate: upload candidate document clicked';
  event_properties: CandidateUploadCandidateDocumentClickedProperties & {
    app: 'Recruiter';
  };

  constructor(
    event_properties: CandidateUploadCandidateDocumentClickedProperties
  ) {
    this.event_properties = {
      ...event_properties,
      app: 'Recruiter',
    };
  }
}

export class EarningBankAccountConnected implements BaseEvent {
  event_type = 'Earning: bank account connected';
  event_properties = {
    app: 'Recruiter',
  };
}

export class EarningEarningsPageViewed implements BaseEvent {
  event_type = 'Earning: earnings page viewed';
  event_properties = {
    app: 'Recruiter',
  };
}

export class LoginAuth0UserLoaded implements BaseEvent {
  event_type = 'Login: auth0 user loaded';

  constructor(public event_properties: LoginAuth0UserLoadedProperties) {
    this.event_properties = event_properties;
  }
}

export class PublicDossierPageViewed implements BaseEvent {
  event_type = 'Public Dossier: page viewed';

  constructor(public event_properties: PublicDossierPageViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class RoleDashboardRoleClicked implements BaseEvent {
  event_type = 'Role: dashboard role clicked';

  constructor(public event_properties: RoleDashboardRoleClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class RoleRoleViewed implements BaseEvent {
  event_type = 'Role: role viewed';

  constructor(public event_properties: RoleRoleViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAgreementCreateSearchAgreementClicked implements BaseEvent {
  event_type = 'Search Agreement: create search agreement clicked';
  event_properties = {
    app: 'Recruiter',
  };
}

export class SearchAgreementSearchAgreementViewed implements BaseEvent {
  event_type = 'Search Agreement: search agreement viewed';

  constructor(
    public event_properties: SearchAgreementSearchAgreementViewedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class UserAddTeamMemberClicked implements BaseEvent {
  event_type = 'User: add team member clicked';

  constructor(public event_properties: UserAddTeamMemberClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserInviteEmployerClicked implements BaseEvent {
  event_type = 'User: invite employer clicked';
  event_properties = {
    app: 'Recruiter',
  };
}

export class UserSupportLinkClicked implements BaseEvent {
  event_type = 'User: support link clicked';
  event_properties = {
    app: 'Recruiter',
  };
}

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: AmplitudeClient;
  private middlewares: Middleware[] = [];

  get client(): AmplitudeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): void {
    this.disabled = options.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...options.client?.options });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: IdentifyOptions,
    extra?: MiddlewareExtra
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event: IdentifyEvent = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  private _identify(
    event: Event,
    options?: IdentifyOptions,
  ) {
    const amplitudeIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    this.amplitude?.identify(
      amplitudeIdentify,
      options?.callback,
      // options?.errorCallback
    );
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  track(event: Event, options?: EventOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent: BaseEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = (payload.event as BaseEvent).user_properties;
      if (userProperties) {
        const identifyEvent: IdentifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude?.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        // options?.errorCallback,
      );
    });
  }

  /**
   * App: page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/App%3A%20page%20viewed)
   *
   * Owner: Mateo Angel
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  appPageViewed(
    properties: AppPageViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AppPageViewed(properties), options, extra);
  }

  /**
   * Candidate: candidate document viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Candidate%3A%20candidate%20document%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  candidateCandidateDocumentViewed(
    properties: CandidateCandidateDocumentViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CandidateCandidateDocumentViewed(properties), options, extra);
  }

  /**
   * Candidate: candidate viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Candidate%3A%20candidate%20viewed)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  candidateCandidateViewed(
    properties: CandidateCandidateViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CandidateCandidateViewed(properties), options, extra);
  }

  /**
   * Candidate: remove candidate document clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Candidate%3A%20remove%20candidate%20document%20clicked)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. candidate id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  candidateRemoveCandidateDocumentClicked(
    properties: CandidateRemoveCandidateDocumentClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CandidateRemoveCandidateDocumentClicked(properties), options, extra);
  }

  /**
   * Candidate: upload candidate document clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Candidate%3A%20upload%20candidate%20document%20clicked)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. candidate id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  candidateUploadCandidateDocumentClicked(
    properties: CandidateUploadCandidateDocumentClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CandidateUploadCandidateDocumentClicked(properties), options, extra);
  }

  /**
   * Earning: bank account connected
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Earning%3A%20bank%20account%20connected)
   *
   * Owner: Hank Hwang
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  earningBankAccountConnected(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EarningBankAccountConnected(), options, extra);
  }

  /**
   * Earning: earnings page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Earning%3A%20earnings%20page%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  earningEarningsPageViewed(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EarningEarningsPageViewed(), options, extra);
  }

  /**
   * Login: auth0 user loaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Login%3A%20auth0%20user%20loaded)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  loginAuth0UserLoaded(
    properties: LoginAuth0UserLoadedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new LoginAuth0UserLoaded(properties), options, extra);
  }

  /**
   * Public Dossier: page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Public%20Dossier%3A%20page%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  publicDossierPageViewed(
    properties: PublicDossierPageViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PublicDossierPageViewed(properties), options, extra);
  }

  /**
   * Role: dashboard role clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Role%3A%20dashboard%20role%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  roleDashboardRoleClicked(
    properties: RoleDashboardRoleClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RoleDashboardRoleClicked(properties), options, extra);
  }

  /**
   * Role: role viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Role%3A%20role%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  roleRoleViewed(
    properties: RoleRoleViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RoleRoleViewed(properties), options, extra);
  }

  /**
   * Search Agreement: create search agreement clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Search%20Agreement%3A%20create%20search%20agreement%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchAgreementCreateSearchAgreementClicked(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchAgreementCreateSearchAgreementClicked(), options, extra);
  }

  /**
   * Search Agreement: search agreement viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Search%20Agreement%3A%20search%20agreement%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchAgreementSearchAgreementViewed(
    properties: SearchAgreementSearchAgreementViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchAgreementSearchAgreementViewed(properties), options, extra);
  }

  /**
   * User: add team member clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/User%3A%20add%20team%20member%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  userAddTeamMemberClicked(
    properties: UserAddTeamMemberClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new UserAddTeamMemberClicked(properties), options, extra);
  }

  /**
   * User: invite employer clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/User%3A%20invite%20employer%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  userInviteEmployerClicked(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new UserInviteEmployerClicked(), options, extra);
  }

  /**
   * User: support link clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/User%3A%20support%20link%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  userSupportLinkClicked(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new UserSupportLinkClicked(), options, extra);
  }

  addEventMiddleware(middleware: Middleware): void {
    this.middlewares.push(middleware);
  }

  private runMiddleware(payload: MiddlewarePayload, next: MiddlewareNext): void {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext: MiddlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next: MiddlewareNext = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type ConfigExt = Partial<Config> & { plan?: Plan };

export type Plan = {
  branch?: string;
  source?: string;
  version?: string;
  versionId?: string;
};

export enum SpecialEventType {
  Identify = '$identify',
  Group = '$groupidentify',
}

export type BaseEvent = {
  event_type: string;
  event_properties?: { [key: string]: any };
  plan?: Plan;
  user_id?: string;
  device_id?: string;
  user_properties?: { [key: string]: any };
};
export type IdentifyEvent = Omit<BaseEvent, 'user_properties'> & {
  event_type: SpecialEventType.Identify;
};
export type GroupEvent = Omit<BaseEvent, 'user_properties'> & {
  event_type: SpecialEventType.Group;
};
export type Event = BaseEvent | IdentifyEvent | GroupEvent;

type BaseEventOptions = Omit<BaseEvent, 'event_type' | 'event_properties'> & {
  callback?: Callback;
  errorCallback?: Callback;
};
export type EventOptions = BaseEventOptions;
export type IdentifyOptions = Omit<BaseEventOptions, 'user_properties'>;
export type GroupOptions = Omit<BaseEventOptions, 'user_properties'>;

/**
 * Unstructured object to let users pass extra data to middleware
 */
export interface MiddlewareExtra {
  [name: string]: any;
}

/**
 * Data to be processed by middleware
 */
export interface MiddlewarePayload {
  event: Event;
  extra?: MiddlewareExtra;
}

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 */
export type MiddlewareNext = (payload: MiddlewarePayload) => void;

/**
 * A function to run on the Event stream (each logEvent call)
 *
 * @param payload The event and extra data being sent
 * @param next Function to run the next middleware in the chain, not calling next will end the middleware chain
 */
export type Middleware = (
  payload: MiddlewarePayload,
  next: MiddlewareNext
) => void;
