import { PencilIcon } from '@heroicons/react/outline';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import * as Yup from 'yup';

import Button from '../../Button';
import { NumberInput, SubmitButton } from '../../forms';
import QuestionMarkIcon from '../../QuestionMarkIcon';
import ProfileCard from '../ProfileCard';

export interface TimeToPlacementWidgetProps {
  average: number;
  recruiter: number;
  editMode?: boolean;
  isManual?: boolean;
  onSubmit?: (values: { timeToPlacement: number }) => void;
  loading?: boolean;
}

const TimeToPlacementWidget = ({
  average,
  recruiter,
  editMode = false,
  isManual = false,
  onSubmit,
  loading = false,
}: TimeToPlacementWidgetProps): ReactElement => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const [edit, setEdit] = useState(false);

  const TimeToPlacementFormSchema = Yup.object().shape({
    timeToPlacement: Yup.number()
      .typeError('Time to placement must be a number')
      .required('Time to placement is required')
      .moreThan(0, 'Time to placement must be greater than 0')
      .max(52, 'Time to placement must be less than or equal to 52'),
  });

  const labels = ['Recruiter', 'Average'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [recruiter, average],
        backgroundColor: ['rgba(255, 163, 0, 1)', 'rgba(229, 231, 235, 1)'],
        borderRadius: {
          topLeft: 10,
        },
        barThickness: 50,
        hoverBackgroundColor: [
          'rgba(255, 163, 0, 1)',
          'rgba(229, 231, 235, 1)',
        ],
      },
    ],
  };
  const tooltipText =
    'This is an estimate and will be automatically calculated after three HeadRace placements.';
  return (
    <ProfileCard className="!p-0 xl:col-span-1 lg:col-span-full h-full">
      <Formik
        initialValues={{
          timeToPlacement: recruiter,
        }}
        onSubmit={(values): void => {
          if (onSubmit) onSubmit(values);
          setEdit(false);
        }}
        validationSchema={TimeToPlacementFormSchema}
        enableReinitialize
      >
        <Form>
          {edit && (
            <div className="flex justify-between px-10 py-4 items-center border-b">
              <div className="text-xl font-medium text-headraceBlack-800 leading-6">
                Time
              </div>
              <div className="h-5 flex items-center gap-4">
                <Button
                  buttonType="secondary"
                  className="!py-2"
                  onClick={(): void => setEdit(false)}
                >
                  Cancel
                </Button>
                <SubmitButton className="!py-2" loading={loading}>
                  Save
                </SubmitButton>
              </div>
            </div>
          )}
          <div
            className={classNames('px-10 pt-5 pb-10 h-full', {
              '!pt-10': !edit,
            })}
          >
            <div className="w-full flex items-center flex-col justify-between content-between">
              <div className="flex flex-col justify-center items-center w-full relative">
                <div className="flex justify-center items-center gap-1">
                  <div className="font-bold text-xl text-center">
                    Time to placement
                  </div>
                  {!isManual && editMode && (
                    <QuestionMarkIcon
                      tooltip={tooltipText}
                      fieldName="placement-widget"
                      forInput={false}
                      customTooltipStyles="w-48 !p-2.5 text-left !bg-headraceBlack-800"
                    />
                  )}
                </div>
                {!edit && editMode && isManual && (
                  <button
                    className="py-1 absolute top-0 right-0"
                    type="button"
                    onClick={(): void => {
                      setEdit(true);
                    }}
                  >
                    <PencilIcon className="w-5 text-blue-500" />
                  </button>
                )}
                <div className="font-medium text-gray-400 text-center text-sm">
                  months
                </div>
              </div>

              <div className="p-7 lg:w-4/5 md:w-3/5 w-10/12 h-60">
                <Bar
                  options={{
                    responsive: true,
                    layout: {
                      padding: {
                        top: 30,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        display: false,
                        grid: {
                          offset: true,
                        },
                      },
                      x: {
                        weight: 0.4,
                        display: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          color: 'rgba(48, 50, 61, 1)',
                          font: {
                            size: 14,
                            weight: 'bold',
                          },
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: false,
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'top',
                        color: 'rgba(48, 50, 61, 1)',
                        font: {
                          weight: 'bold',
                          size: 16,
                        },
                      },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    maintainAspectRatio: false,
                  }}
                  data={data}
                  plugins={[ChartDataLabels]}
                />
              </div>
              {editMode && edit && isManual && (
                <div className="text-center flex flex-col items-center max-w-[10rem]">
                  <NumberInput
                    name="timeToPlacement"
                    tooltip={tooltipText}
                    boxClassName="w-24"
                    tooltipClass="w-48 !p-2.5 text-left !bg-headraceBlack-800"
                    showErrorIcon={false}
                    min={0}
                    max={52}
                  />
                </div>
              )}
            </div>
          </div>
        </Form>
      </Formik>
    </ProfileCard>
  );
};

export default TimeToPlacementWidget;
