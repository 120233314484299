import type {
  NotificationStructureGroup,
  NotificationStructureOption,
  UserNotificationEnum,
} from '@headrace/types';

import {
  Checkbox,
  NotificationCheckbox,
  NotificationCheckboxState,
} from '../forms';

export const SettingsNotificationInput: React.VFC<{
  notification: NotificationStructureOption;
  verifyChecked: (value: string) => boolean;
  onChange?: (value: UserNotificationEnum, checked: boolean) => void;
}> = ({ notification, verifyChecked, onChange }) => {
  const onChangeCallback = onChange ?? ((): void => {});
  return (
    <Checkbox
      name={notification.label}
      label={notification.label}
      description={notification.description}
      checked={verifyChecked(notification.value)}
      onChange={(ev): void =>
        onChangeCallback(notification.value, ev.target.checked)
      }
    />
  );
};

export const SettingsNotificationGroup: React.VFC<{
  notification: NotificationStructureGroup;
  value: NotificationCheckboxState;
  verifyChildrenChecked: (value: string) => boolean;
  onChangeChildren: (value: UserNotificationEnum, checked: boolean) => void;
}> = ({ notification, value, verifyChildrenChecked, onChangeChildren }) => {
  const onChange = notification?.onChange ?? ((): void => {});
  return (
    <div className="flex flex-col gap-4">
      <NotificationCheckbox
        label={notification.label}
        description={notification.description}
        value={value}
        onChange={(state): void =>
          onChange(state === NotificationCheckboxState.ON)
        }
      />
      <div className="ml-8 flex flex-col gap-4">
        {notification.children &&
          notification.children.map((childNotification) => (
            <SettingsNotificationInput
              key={childNotification.value}
              notification={childNotification}
              verifyChecked={verifyChildrenChecked}
              onChange={onChangeChildren}
            />
          ))}
      </div>
    </div>
  );
};
