import type { SearchReview } from '@headrace/types';
import { Form, Formik } from 'formik';

import { Select, TextArea } from '../forms';
import ModalWithIcon from '../ModalWithIcon';
import ReportSchema from './ReportSchema';

interface Props {
  review?: SearchReview;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: ModalReport & SearchReview) => void;
}

export interface ModalReport {
  reason: string;
  details: string;
}

const rejectReasons = [
  {
    label: 'Inappropriate',
    value: 'Inappropriate',
  },
  {
    label: 'Review not accurate',
    value: 'Review not accurate',
  },
  {
    label: 'Candidate never placed',
    value: 'Candidate never placed',
  },
  {
    label: 'Replacement search requested',
    value: 'Replacement search requested',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const ReportReviewModal: React.VFC<Props> = ({
  review,
  open,
  onClose,
  onSubmit,
}) => {
  const submit = (values: ModalReport): void => {
    if (review && values) {
      onSubmit({
        reason: values.reason,
        details: values.details,
        ...review,
      });
      onClose();
    }
  };

  return (
    <Formik
      initialValues={{ reason: '', details: '' }}
      onSubmit={submit}
      validationSchema={ReportSchema}
    >
      {({ handleSubmit, resetForm }): JSX.Element => (
        <ModalWithIcon
          onClose={(): void => {
            onClose();
          }}
          open={open}
          title="Report review"
          subtitle="See issues with a review and want us to have a look? Provide us with details below."
          className="sm:min-w-[600px]"
          buttons={[
            {
              type: 'primary',
              label: 'Submit',
              action: (): void => {
                handleSubmit();
              },
            },
            {
              type: 'secondary',
              label: 'Cancel',
              action: (): void => {
                resetForm();
                onClose();
              },
            },
          ]}
        >
          <Form>
            <div>
              <div className="flex-1 mt-4">
                <Select name="reason" options={rejectReasons} label="Reason" />
              </div>
            </div>
            <div>
              <div className="flex-1 mt-4">
                <TextArea name="details" label="Details" />
              </div>
            </div>
          </Form>
        </ModalWithIcon>
      )}
    </Formik>
  );
};

export default ReportReviewModal;
