import type { VFC } from 'react';

interface Props {
  className?: string;
}

const FileIcon: VFC<Props> = ({ className }) => (
  <span className={className}>
    <svg
      className="mx-auto"
      width="108"
      height="118"
      viewBox="0 0 108 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.216 117.07L2.40578 117.622C2.04269 117.624 1.69381 117.481 1.43569 117.226C1.17758 116.97 1.03131 116.623 1.02896 116.26L0.421399 2.04094C0.419882 1.67785 0.562451 1.32898 0.817832 1.07087C1.07321 0.812752 1.42055 0.666477 1.78365 0.66413L105.594 0.11195C105.957 0.110435 106.306 0.253 106.564 0.508381C106.822 0.763762 106.969 1.1111 106.971 1.47419L107.578 115.693C107.58 116.056 107.437 116.405 107.182 116.663C106.927 116.921 106.579 117.068 106.216 117.07Z"
        fill="white"
      />
      <path
        d="M106.216 117.07L2.40578 117.622C2.04269 117.624 1.69381 117.481 1.43569 117.226C1.17758 116.97 1.03131 116.623 1.02896 116.26L0.421399 2.04094C0.419882 1.67785 0.562451 1.32898 0.817832 1.07087C1.07321 0.812752 1.42055 0.666477 1.78365 0.66413L105.594 0.11195C105.957 0.110435 106.306 0.253 106.564 0.508381C106.822 0.763762 106.969 1.1111 106.971 1.47419L107.578 115.693C107.58 116.056 107.437 116.405 107.182 116.663C106.927 116.921 106.579 117.068 106.216 117.07ZM1.78657 1.21194C1.56871 1.21333 1.3603 1.3011 1.20707 1.45597C1.05384 1.61084 0.968299 1.82017 0.96922 2.03803L1.57677 116.257C1.57817 116.475 1.66593 116.683 1.8208 116.836C1.97567 116.99 2.185 117.075 2.40286 117.074L106.213 116.522C106.431 116.521 106.64 116.433 106.793 116.278C106.946 116.123 107.032 115.914 107.031 115.696L106.423 1.47709C106.422 1.25923 106.334 1.05083 106.179 0.897595C106.024 0.744363 105.815 0.658821 105.597 0.659741L1.78657 1.21194Z"
        fill="#3F3D56"
      />
      <path
        d="M46.7702 38.5227L21.023 38.6597C20.6599 38.6612 20.3111 38.5186 20.053 38.2632C19.7948 38.0079 19.6486 37.6605 19.6462 37.2974L19.5093 11.5503C19.5078 11.1872 19.6503 10.8383 19.9057 10.5802C20.1611 10.3221 20.5084 10.1758 20.8715 10.1734L46.6187 10.0365C46.9818 10.035 47.3307 10.1775 47.5888 10.4329C47.8469 10.6883 47.9932 11.0356 47.9955 11.3987L48.1325 37.1459C48.134 37.509 47.9914 37.8579 47.736 38.116C47.4807 38.3741 47.1333 38.5204 46.7702 38.5227ZM20.8744 10.7212C20.6566 10.7226 20.4482 10.8104 20.2949 10.9653C20.1417 11.1201 20.0562 11.3295 20.0571 11.5473L20.194 37.2945C20.1954 37.5124 20.2832 37.7208 20.4381 37.874C20.5929 38.0273 20.8023 38.1128 21.0201 38.1119L46.7673 37.9749C46.9852 37.9735 47.1936 37.8858 47.3468 37.7309C47.5001 37.576 47.5856 37.3667 47.5847 37.1488L47.4477 11.4016C47.4463 11.1838 47.3586 10.9754 47.2037 10.8221C47.0488 10.6689 46.8395 10.5834 46.6216 10.5843L20.8744 10.7212Z"
        fill="#F2F2F2"
      />
      <path
        d="M39.3915 41.7121L13.6443 41.8491C13.3175 41.8505 13.0035 41.7221 12.7712 41.4923C12.5389 41.2625 12.4073 40.9499 12.4052 40.6231L12.2682 14.8759C12.2669 14.5491 12.3952 14.2351 12.625 14.0028C12.8549 13.7705 13.1675 13.6388 13.4943 13.6367L39.2414 13.4998C39.5682 13.4984 39.8822 13.6267 40.1145 13.8566C40.3468 14.0864 40.4785 14.399 40.4806 14.7258L40.6175 40.473C40.6189 40.7998 40.4906 41.1138 40.2607 41.3461C40.0309 41.5784 39.7183 41.71 39.3915 41.7121Z"
        fill="#E7E7E7"
      />
      <path
        d="M95.4652 104.195L14.5773 104.398C14.3723 104.4 14.1749 104.32 14.0283 104.177C13.8818 104.033 13.7981 103.838 13.7956 103.633L13.6197 87.4797C13.6177 87.2747 13.6971 87.0773 13.8405 86.9307C13.9839 86.7842 14.1795 86.7005 14.3845 86.698L95.2724 86.4951C95.4775 86.4931 95.6749 86.5725 95.8214 86.7159C95.968 86.8593 96.0517 87.0549 96.0541 87.2599L96.2301 103.413C96.2321 103.618 96.1527 103.815 96.0093 103.962C95.8659 104.108 95.6702 104.192 95.4652 104.195Z"
        fill="#FFA300"
      />
      <path
        d="M94.2398 54.0203L13.7114 54.4487C13.3845 54.4504 13.0703 54.3222 12.8379 54.0923C12.6055 53.8624 12.474 53.5496 12.4722 53.2227C12.4705 52.8958 12.5987 52.5816 12.8286 52.3492C13.0585 52.1168 13.3714 51.9853 13.6983 51.9835L94.2267 51.5552C94.3886 51.5543 94.549 51.5853 94.6989 51.6465C94.8488 51.7076 94.9851 51.7977 95.1002 51.9115C95.2152 52.0254 95.3068 52.1608 95.3695 52.31C95.4322 52.4592 95.465 52.6193 95.4658 52.7812C95.4667 52.9431 95.4357 53.1035 95.3745 53.2534C95.3134 53.4033 95.2233 53.5396 95.1095 53.6547C94.9956 53.7697 94.8602 53.8613 94.711 53.924C94.5618 53.9867 94.4017 54.0195 94.2398 54.0203Z"
        fill="#CCCCCC"
      />
      <path
        d="M74.8376 62.615L13.7565 62.9399C13.5946 62.9407 13.4342 62.9097 13.2843 62.8486C13.1344 62.7874 12.9981 62.6973 12.883 62.5835C12.6506 62.3536 12.5191 62.0408 12.5174 61.7139C12.5156 61.387 12.6438 61.0728 12.8737 60.8404C13.1037 60.608 13.4165 60.4765 13.7434 60.4747L74.8245 60.1498C74.9864 60.149 75.1468 60.18 75.2967 60.2411C75.4465 60.3023 75.5829 60.3923 75.698 60.5062C75.813 60.62 75.9045 60.7554 75.9673 60.9046C76.03 61.0539 76.0628 61.214 76.0636 61.3758C76.0645 61.5377 76.0334 61.6981 75.9723 61.848C75.9111 61.9979 75.8211 62.1343 75.7072 62.2493C75.5934 62.3644 75.458 62.4559 75.3088 62.5186C75.1596 62.5814 74.9995 62.6141 74.8376 62.615Z"
        fill="#CCCCCC"
      />
      <path
        d="M93.7998 22.7965L58.1919 22.9859C57.865 22.9876 57.5508 22.8594 57.3185 22.6295C57.0861 22.3996 56.9545 22.0868 56.9528 21.7599C56.9511 21.433 57.0793 21.1188 57.3092 20.8864C57.5391 20.654 57.8519 20.5225 58.1788 20.5207L93.7867 20.3313C94.1135 20.3296 94.4277 20.4578 94.6601 20.6877C94.7752 20.8016 94.8667 20.9369 94.9294 21.0862C94.9922 21.2354 95.0249 21.3955 95.0258 21.5574C95.0266 21.7192 94.9956 21.8797 94.9345 22.0295C94.8733 22.1794 94.7832 22.3158 94.6694 22.4308C94.5555 22.5459 94.4202 22.6374 94.2709 22.7002C94.1217 22.7629 93.9616 22.7956 93.7998 22.7965Z"
        fill="#CCCCCC"
      />
      <path
        d="M84.9584 31.3349L58.2371 31.4771C58.0752 31.4779 57.9148 31.4469 57.7649 31.3858C57.615 31.3246 57.4787 31.2345 57.3636 31.1207C57.2485 31.0068 57.157 30.8715 57.0943 30.7222C57.0315 30.573 56.9988 30.4129 56.9979 30.251C56.9971 30.0892 57.0281 29.9287 57.0893 29.7789C57.1504 29.629 57.2405 29.4926 57.3543 29.3776C57.4682 29.2625 57.6036 29.171 57.7528 29.1082C57.902 29.0455 58.0621 29.0128 58.224 29.0119L84.9453 28.8698C85.2722 28.868 85.5864 28.9962 85.8188 29.2262C86.0511 29.4561 86.1827 29.7689 86.1844 30.0958C86.1861 30.4227 86.058 30.7369 85.828 30.9693C85.5981 31.2017 85.2853 31.3332 84.9584 31.3349Z"
        fill="#CCCCCC"
      />
      <path
        d="M94.3301 71.0026L13.8017 71.431C13.4748 71.4327 13.1606 71.3045 12.9282 71.0746C12.6958 70.8447 12.5643 70.5319 12.5625 70.205C12.5608 69.8781 12.689 69.5639 12.9189 69.3315C13.1488 69.0991 13.4617 68.9676 13.7886 68.9658L94.317 68.5375C94.6439 68.5358 94.9581 68.6639 95.1905 68.8939C95.4229 69.1238 95.5544 69.4366 95.5561 69.7635C95.5579 70.0904 95.4297 70.4046 95.1998 70.637C94.9698 70.8694 94.657 71.0009 94.3301 71.0026Z"
        fill="#CCCCCC"
      />
      <path
        d="M74.9277 79.5969L13.8466 79.9218C13.6847 79.9227 13.5242 79.8917 13.3744 79.8305C13.2245 79.7694 13.0881 79.6793 12.9731 79.5654C12.858 79.4516 12.7665 79.3162 12.7037 79.167C12.641 79.0178 12.6083 78.8577 12.6074 78.6958C12.6066 78.5339 12.6376 78.3735 12.6987 78.2236C12.7599 78.0737 12.85 77.9374 12.9638 77.8223C13.0776 77.7072 13.213 77.6157 13.3623 77.553C13.5115 77.4903 13.6716 77.4575 13.8334 77.4567L74.9145 77.1318C75.0764 77.1309 75.2369 77.1619 75.3867 77.2231C75.5366 77.2842 75.673 77.3743 75.788 77.4881C75.9031 77.602 75.9946 77.7374 76.0573 77.8866C76.1201 78.0358 76.1528 78.1959 76.1537 78.3578C76.1545 78.5196 76.1235 78.6801 76.0624 78.83C76.0012 78.9798 75.9111 79.1162 75.7973 79.2313C75.6834 79.3463 75.5481 79.4378 75.3988 79.5006C75.2496 79.5633 75.0895 79.596 74.9277 79.5969Z"
        fill="#CCCCCC"
      />
    </svg>
  </span>
);

export default FileIcon;
