import 'yup-phone-lite';

import { LINKED_IN_URL } from '@headrace/constants';
import { CandidateDocumentTypeEnum } from '@headrace/types';
import * as Yup from 'yup';

const phoneSchema = Yup.string().phone();

const CandidateFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .transform((value: string) => (value.length > 4 ? value : ''))
    .test('test-phone', 'Invalid phone number', (value) => {
      if (value && value.length > 4) return phoneSchema.isValidSync(value);
      return true;
    }),
  linkedIn: Yup.string()
    .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
    .required('LinkedIn URL is required'),
  justification: Yup.string().required('Justification is required'),
  files: Yup.array()
    .of(
      Yup.object().shape({
        documentType: Yup.string()
          .oneOf([
            CandidateDocumentTypeEnum.RESUME,
            CandidateDocumentTypeEnum.OTHER,
          ])
          .required('Please select a document type'),
      })
    )
    .min(1, 'Candidate resume is required.'),
});

export default CandidateFormSchema;
