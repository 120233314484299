import type { SearchReview } from '@headrace/types';
import { formatDate } from '@headrace/utils';
import type { Dispatch, SetStateAction } from 'react';

import { FlagIcon } from '../CustomIcons';
import Rating from './index';

interface Props {
  review?: SearchReview;
  setReviewSelected: (review: SearchReview) => void;
  setReportModal: Dispatch<SetStateAction<boolean>>;
}

const ReviewCardRating: React.VFC<Props> = ({
  review,
  setReviewSelected,
  setReportModal,
}) => (
  <div className=" p-10 border-b-2 border-gray-200">
    {review && (
      <>
        <div className="flex justify-between items-center mb-2.5">
          <div className="flex items-center">
            <Rating initialRating={review?.overallRating || 0} iconSize="24" />
            <span className="ml-2 font-medium text-lg">
              {review.searchAgreementRole.role.name}
            </span>
          </div>
          <span className="text-sm font-normal text-gray-500">
            {review?.createdAt
              ? formatDate(review.createdAt, 'MMMM d, yyyy')
              : ''}
          </span>
        </div>
        <div className="text-sm font-normal text-gray-500 mb-4">
          {review.reviewedByEmployee?.employer?.company.name}
        </div>
        <div className="text-sm font-normal mb-[18px]">{review?.review}</div>
        <button
          className="text-sm font-normal text-headraceBlack-800 flex"
          onClick={(): void => {
            setReviewSelected(review);
            setReportModal(true);
          }}
          type="button"
        >
          <FlagIcon
            className="mr-3 stroke-1"
            classNamePath="stroke-2 stroke-gray-400"
          />
          Report
        </button>
      </>
    )}
  </div>
);

export default ReviewCardRating;
