import type {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import type { OptionsProps } from '@headrace/ui';
import { cityLabel } from '@headrace/utils';
import { useState } from 'react';

import type { RoleEnrichmentOptionsQuery } from '@/graphql/generated';
import { useRoleEnrichmentOptionsQuery } from '@/graphql/generated';

export interface EnrichmentOptions {
  roleSeniorityOptions: OptionsProps[];
  roleFunctionOptions: OptionsProps[];
  cityOptions: OptionsProps[];
}

const useRoleEnrichmentOptions = (): {
  options: EnrichmentOptions | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<RoleEnrichmentOptionsQuery>>;
} => {
  const [options, setOptions] = useState<EnrichmentOptions | null>(null);
  const { loading, error, refetch } = useRoleEnrichmentOptionsQuery({
    onCompleted: (res) => {
      const roleSeniorityOptions = res.roleSeniorities?.map((value) => ({
        label: value.name,
        value: value.id,
      }));
      const roleFunctionOptions = res.roleFunctions?.map((value) => ({
        label: value.name,
        value: value.id,
      }));
      const cityOptions = res.cities?.map((value) => ({
        label: cityLabel(value.cityName, value.stateName),
        value: value.id,
      }));
      if (roleSeniorityOptions && roleFunctionOptions && cityOptions)
        setOptions({
          roleSeniorityOptions,
          roleFunctionOptions,
          cityOptions,
        });
    },
  });

  return { options, loading, error, refetch };
};

export default useRoleEnrichmentOptions;
