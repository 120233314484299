import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';

interface ToggleProps {
  label?: string;
  name: string;
  classNameLabel?: string;
}

const Toggle: React.VFC<ToggleProps> = (props) => {
  const { label, name, classNameLabel } = props;

  const [field] = useField<boolean>(name);
  const { setFieldValue } = useFormikContext();

  return (
    <div className="flex items-center">
      <Switch
        id={name}
        checked={field.value}
        onChange={(e: boolean): void => setFieldValue(name, e)}
        className={`${field.value ? 'bg-headraceYellow-700' : 'bg-gray-200'}
          relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">{label}</span>
        <span
          aria-hidden="true"
          className={`${field.value ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <div
        className={classNames(
          'font-medium text-headraceBlack-700 ml-2',
          classNameLabel
        )}
      >
        {label}
      </div>
    </div>
  );
};

export default Toggle;
