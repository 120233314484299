import './CompanyLogoTooltip.css';

import type { VFC } from 'react';
import { useMemo } from 'react';

import Card from '../../Card';
import ImageWithFallback from '../../ImageWithFallback';
import InfinityCarousel from '../../InfinityCarousel';

interface Company {
  id: string;
  name: string;
  logoUrl: string;
}

interface Props {
  placementCompanies: Partial<Company>[];
}

const CompanyLogosCarousel: VFC<Props> = ({ placementCompanies }) => {
  const companyLogos = useMemo(
    () =>
      placementCompanies.map((company) => (
        <div
          className="flex justify-center items-center py-10 tooltip-container"
          key={company.name}
        >
          <ImageWithFallback
            className="object-scale-down shadow-lg rounded-lg"
            src={company.logoUrl || ''}
            alt="company-logo"
            objectFit="contain"
            width="100%"
            height={60}
            fallbackSrc="/placeholders/company_logo_placeholder.png"
          />
          <div className="my-tooltip">{company.name}</div>
        </div>
      )),
    [placementCompanies]
  );
  return (
    <Card className="mb-6">
      <InfinityCarousel
        elements={companyLogos}
        staticElement={
          <div className="flex justify-center items-center ">
            <div className="border-r-2 pr-2 w-24 border-headraceYellow-700 uppercase sm:text-sm text-xs font-bold text-[#30323D] opacity-50 text-right">
              Hired top talent for
            </div>
          </div>
        }
      />
    </Card>
  );
};

export default CompanyLogosCarousel;
