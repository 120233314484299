import Image from 'next/image';
import type { VFC } from 'react';

interface Props {
  image: string;
  text: string | JSX.Element;
}

const TeachableCard: VFC<Props> = ({ image, text }) => (
  <div className="flex flex-col items-center gap-5 h-[23.5rem] mt-4">
    <div className="block w-full">
      <Image
        layout="responsive"
        width={300}
        height={200}
        src={image}
        alt="illustration"
        className="object-contain"
      />
    </div>
    <div className="text-sm text-center font-normal text-gray-500">{text}</div>
  </div>
);

export default TeachableCard;
