import type { SearchReview } from '@headrace/types';
import { useState } from 'react';

import Pagination from '../PaginatedList';
import type { ModalReport } from './ReportReviewModal';
import ReportReviewModal from './ReportReviewModal';
import ReviewCardRating from './ReviewCardRating';

interface Props {
  searchReviews?: SearchReview[];
  onSubmit: (values: ModalReport & SearchReview) => void;
}

const ReviewsPagination: React.VFC<Props> = ({ searchReviews, onSubmit }) => {
  const [reviewSelected, setReviewSelected] = useState<SearchReview | null>();
  const [openReportModal, setReportModal] = useState<boolean>(false);

  const formatData = (searchReviewsPage: SearchReview[]): JSX.Element[] =>
    searchReviewsPage.map((review) => (
      <ReviewCardRating
        review={review}
        setReviewSelected={setReviewSelected}
        setReportModal={setReportModal}
      />
    ));

  if (searchReviews) {
    return (
      <>
        <Pagination
          data={searchReviews || []}
          formatData={formatData}
          entriesPerPage={5}
          containerClassName="bg-white border border-gray-200 rounded-lg  rounded-t-none text-gray-500 font-normal text-xs"
        />
        {openReportModal && (
          <ReportReviewModal
            open={openReportModal}
            onClose={(): void => {
              setReportModal(false);
              setReviewSelected(null);
            }}
            review={reviewSelected || undefined}
            onSubmit={onSubmit}
          />
        )}
      </>
    );
  }
  return null;
};

export default ReviewsPagination;
