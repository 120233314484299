import type {
  NotificationStructure,
  UserNotificationChannel,
  UserNotificationEnum,
  UserNotificationSetting,
} from '@headrace/types';
import { NotificationStructureType } from '@headrace/types';
import type { VFC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { NotificationCheckboxState } from '../../forms';
import {
  SettingsNotificationGroup,
  SettingsNotificationInput,
} from '../SettingsNotification';

interface NotificationSettingsState {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface SettingsNotificationFormProps {
  channels: UserNotificationChannel[];
  channelLegend: {
    [key: string]: string;
  };
  notifications: string[];
  notificationsStructure: NotificationStructure[];
  notificationsData?: UserNotificationSetting[];
  onChangeNotification: (data: UserNotificationSetting[]) => Promise<void>;
  isLoading: boolean;
}

const SettingsNotificationForm: VFC<SettingsNotificationFormProps> = ({
  channels,
  channelLegend,
  notifications,
  notificationsStructure,
  notificationsData,
  onChangeNotification,
  isLoading,
}) => {
  const initialNotificationsSettingsState = (): NotificationSettingsState => {
    const flags: NotificationSettingsState = {};
    channels.forEach((channel) => {
      flags[channel] = {};
      notifications.forEach((notif) => {
        flags[channel][notif] = true;
      });
    });
    return flags;
  };

  const [settings, setSettings] = useState<NotificationSettingsState>(
    initialNotificationsSettingsState()
  );

  useEffect(() => {
    if (!notificationsData) return;
    const remoteSettings = notificationsData;
    setSettings((localSettings) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newSettings: NotificationSettingsState = JSON.parse(
        JSON.stringify(localSettings)
      );
      remoteSettings.forEach((setting) => {
        newSettings[setting.channel][setting.type] = setting.enabled;
      });
      return newSettings;
    });
  }, [notificationsData]);

  const notificationIsActive = (channel: string, type: string): boolean =>
    settings[channel]?.[type] ?? false;
  const groupCheckStatus = (
    channel: UserNotificationChannel,
    notificationKeys: string[]
  ): NotificationCheckboxState => {
    let status = false;
    let currentStatus: boolean;
    const allSameStatus =
      notificationKeys.length &&
      notificationKeys.every((notificationKey) => {
        if (currentStatus === undefined) {
          currentStatus = settings[channel][notificationKey];
        }
        status = currentStatus;
        return currentStatus === settings[channel][notificationKey];
      });
    if (!allSameStatus) return NotificationCheckboxState.HALF;
    return status
      ? NotificationCheckboxState.ON
      : NotificationCheckboxState.OFF;
  };
  // Event Handler
  const handleChangeUserNotificationSetting = useCallback(
    async (
      channel: UserNotificationChannel,
      type: UserNotificationEnum,
      checked: boolean
    ) => {
      if (isLoading) return;
      await onChangeNotification([
        {
          channel,
          type,
          enabled: checked,
        },
      ]);
    },
    [isLoading, onChangeNotification]
  );
  const handleChangeNotificationGroupSetting = useCallback(
    async (
      channel: UserNotificationChannel,
      notificationKeys: UserNotificationEnum[],
      checked: boolean
    ) => {
      if (isLoading) return;
      const notificationInputs = notificationKeys.map((notification) => ({
        type: notification,
        channel,
        enabled: checked,
      }));
      await onChangeNotification(notificationInputs);
    },
    [isLoading, onChangeNotification]
  );

  return (
    <div className="mt-10 shadow sm:rounded-md sm:overflow-hidden">
      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
        <div className="md:col-span-2">
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Notifications
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Decide which communications you&apos;d like to receive and
                    how.
                  </p>
                </div>
              </div>
              {channels.map((channel) => (
                <div key={channel} className="md:col-span-2">
                  <div className="flex flex-col gap-4">
                    <div className="text-base font-medium text-gray-900">
                      {channelLegend[channel]}
                    </div>
                    {notificationsStructure.map((notificationStructure) => {
                      if (
                        notificationStructure.type ===
                        NotificationStructureType.GROUP
                      ) {
                        const notificationKeys =
                          notificationStructure.children?.map(
                            (childNotification) => childNotification.value
                          ) ?? [];
                        const onChange = (status: boolean): Promise<void> =>
                          handleChangeNotificationGroupSetting(
                            channel,
                            notificationKeys,
                            status
                          );
                        const notificationWithOnchange = {
                          ...notificationStructure,
                          onChange,
                        };
                        return (
                          <SettingsNotificationGroup
                            key={`${channel}-${notificationWithOnchange.label}`}
                            notification={notificationWithOnchange}
                            value={groupCheckStatus(channel, notificationKeys)}
                            verifyChildrenChecked={(type): boolean =>
                              notificationIsActive(channel, type)
                            }
                            onChangeChildren={(type, checked): Promise<void> =>
                              handleChangeUserNotificationSetting(
                                channel,
                                type,
                                checked
                              )
                            }
                          />
                        );
                      }
                      if (
                        notificationStructure.type ===
                        NotificationStructureType.OPTION
                      ) {
                        const onChange = (
                          type: UserNotificationEnum,
                          status: boolean
                        ): Promise<void> =>
                          handleChangeUserNotificationSetting(
                            channel,
                            type,
                            status
                          );
                        return (
                          <SettingsNotificationInput
                            key={notificationStructure.value}
                            notification={notificationStructure}
                            verifyChecked={(type): boolean =>
                              notificationIsActive(channel, type)
                            }
                            onChange={onChange}
                          />
                        );
                      }
                      return <span />;
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsNotificationForm;
