import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';

import Button from '../../../Button';

const OFFSET_PAGES = 3;

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  changeToPage: (index: number) => void;
}

const GoToPageButton: React.VFC<{
  label: string | number;
  current?: boolean;
  onClick?: () => void;
}> = ({ label, onClick, current = false }) => (
  <Button
    buttonType="link"
    className={classNames(
      'p-4 pb-0 text-sm font-medium !text-gray-500 mx-0',
      { 'cursor-default': !onClick || current },
      {
        '!text-headraceBlack-800 !opacity-100 border-t-2 border-headraceYellow-700':
          current,
      }
    )}
    onClick={onClick}
  >
    {label}
  </Button>
);

const Pagination: React.VFC<PaginationProps> = ({
  totalPages,
  currentPage,
  changeToPage,
}) => {
  const goToPreviousPage = useCallback((): void => {
    if (currentPage > 1) {
      changeToPage(currentPage - 1);
    }
  }, [currentPage, changeToPage]);
  const goToNextPage = useCallback((): void => {
    if (currentPage < totalPages) {
      changeToPage(currentPage + 1);
    }
  }, [currentPage, totalPages, changeToPage]);

  const leftPagination = useMemo(() => {
    const leftPaginationElements = [];
    if (totalPages <= OFFSET_PAGES) {
      for (let index = 1; index <= totalPages; index += 1) {
        leftPaginationElements.push(
          <GoToPageButton
            label={index}
            key={`goto-${index}`}
            current={currentPage === index}
            onClick={(): void => changeToPage(index)}
          />
        );
      }
      return leftPaginationElements;
    }
    if (totalPages > OFFSET_PAGES) {
      for (let index = 1; index <= OFFSET_PAGES; index += 1) {
        leftPaginationElements.push(
          <GoToPageButton
            label={index}
            key={`goto-${index}`}
            current={currentPage === index}
            onClick={(): void => changeToPage(index)}
          />
        );
      }
    }
    return leftPaginationElements;
  }, [currentPage, totalPages, changeToPage]);
  const rightPagination = useMemo(() => {
    const rightPaginationElements = [];
    const fromIndex = Math.max(totalPages - OFFSET_PAGES, OFFSET_PAGES);
    if (totalPages > OFFSET_PAGES) {
      for (let index = fromIndex + 1; index <= totalPages; index += 1) {
        rightPaginationElements.push(
          <GoToPageButton
            label={index}
            key={`goto-${index}`}
            current={currentPage === index}
            onClick={(): void => changeToPage(index)}
          />
        );
      }
    }
    return rightPaginationElements;
  }, [currentPage, totalPages, changeToPage]);
  const currentPagination = useMemo(() => {
    const currentPaginationElements = [];
    if (
      (currentPage <= OFFSET_PAGES ||
        currentPage > totalPages - OFFSET_PAGES) &&
      totalPages > OFFSET_PAGES * 2
    ) {
      return <GoToPageButton label="..." />;
    }
    if (
      currentPage > OFFSET_PAGES + 1 &&
      currentPage < totalPages - OFFSET_PAGES + 1
    ) {
      currentPaginationElements.push(<GoToPageButton label="..." />);
    }
    if (
      currentPage > OFFSET_PAGES &&
      currentPage < totalPages - OFFSET_PAGES + 1
    ) {
      currentPaginationElements.push(
        <GoToPageButton label={currentPage} current />
      );
    }
    if (currentPage > OFFSET_PAGES && currentPage < totalPages - OFFSET_PAGES) {
      currentPaginationElements.push(<GoToPageButton label="..." />);
    }
    return currentPaginationElements;
  }, [currentPage, totalPages]);

  return (
    <div className="mt-6 border-t border-gray-200 text-headraceBlack-800">
      <nav className="flex-1 flex items-end justify-between">
        <Button
          buttonType="link"
          onClick={goToPreviousPage}
          className="flex items-center"
        >
          <ArrowNarrowLeftIcon className="w-5 h-4 text-headraceYellow-700" />
          <p className="px-2 text-headraceBlack-800">Previous</p>
        </Button>

        <div className="flex flex-row items-center -m-px">
          {leftPagination}
          {currentPagination}
          {rightPagination}
        </div>

        <Button
          buttonType="link"
          onClick={goToNextPage}
          className="flex items-center"
        >
          <p className="px-2 text-headraceBlack-800">Next</p>
          <ArrowNarrowRightIcon className="w-5 h-4 text-headraceYellow-700" />
        </Button>
      </nav>
    </div>
  );
};

export default Pagination;
