import { MenuAlt4Icon, TrashIcon } from '@heroicons/react/outline';
import type { VFC } from 'react';

import { Input, TextArea } from '../../../forms';

export interface EndorsementForm {
  id: string;
  endorserTitle: string;
  endorserCompany: string;
  endorsement: string;
  order: number;
}

interface Props {
  index: number;
  deleteEndorsement: (index: number) => void;
}

const EndorsementCardForm: VFC<Props> = ({ index, deleteEndorsement }) => (
  <div className="flex flex-row px-10 gap-7">
    <div className="flex justify-start items-center">
      <MenuAlt4Icon className="w-6 stroke-gray-300 hover:stroke-gray-200" />
    </div>
    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 py-10 flex-1">
      <div className="col-span-1">
        <Input
          name={`endorsements.${index}.endorserTitle`}
          label="Endorser Title"
        />
      </div>
      <div className="col-span-1">
        <Input
          name={`endorsements.${index}.endorserCompany`}
          label="Endorser Company"
        />
      </div>
      <div className="sm:col-span-2 col-span-1">
        <TextArea
          name={`endorsements.${index}.endorsement`}
          label="Endorsement"
          maxLength={700}
          countDown
          rows={4}
        />
      </div>
    </div>
    <div className="flex order-2 flex-shrink-0 justify-end items-center">
      <button type="button" onClick={(): void => deleteEndorsement(index)}>
        <TrashIcon className="w-5 h-5 stroke-blue-400 hover:stroke-blue-300" />
      </button>
    </div>
  </div>
);

export default EndorsementCardForm;
