import { useAuth0 } from '@auth0/auth0-react';
import type { RegistrationFormValues } from '@headrace/ui';
import { RegistrationForm, RegistrationFormType } from '@headrace/ui';
import { formatApolloError } from '@headrace/utils';
import React, { useState } from 'react';

import { useCreateRecruiterMutation, useUserQuery } from '@/graphql/generated';
import { useRecruiter } from '@/lib/RecruiterProvider';
import { RecruiterFormSchema } from '@/validations';

interface RecruiterFormProps {
  onCompleted: () => void;
}

const RecruiterForm = (props: RecruiterFormProps): JSX.Element => {
  const { user, isLoading } = useAuth0();
  const [error, setError] = useState('');
  const { loadingRecruiter } = useRecruiter();
  const { onCompleted } = props;
  const [createRecruiter, { loading }] = useCreateRecruiterMutation({
    onCompleted,
    onError: (_error) => setError(formatApolloError(_error)),
  });
  const { data, loading: userLoading } = useUserQuery();

  const handleSubmit = async (
    values: RegistrationFormValues
  ): Promise<void> => {
    await createRecruiter({
      variables: {
        input: {
          agencyName: values.agencyName,
          user: {
            firstName: values.firstName ?? '',
            lastName: values.lastName ?? '',
            email: values.email ?? '',
          },
          tou: values.tou,
        },
      },
    });
  };

  return (
    <RegistrationForm
      initialValues={{
        firstName: user?.given_name ?? data?.getUser?.firstName ?? '',
        lastName: user?.family_name ?? data?.getUser?.lastName ?? '',
        email: user?.email,
        agencyName: '',
        tou: false,
      }}
      validationSchema={RecruiterFormSchema}
      onSubmit={handleSubmit}
      error={error}
      loading={loading || isLoading || loadingRecruiter || userLoading}
      type={RegistrationFormType.RECRUITER}
      userData={!!data?.getUser}
    />
  );
};

export default RecruiterForm;
