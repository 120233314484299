import { numberFormatter } from '@headrace/utils';
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import Link from 'next/link';
import type { ReactElement } from 'react';
import { useMemo } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

import ErrorMessageBox from '../ErrorMessageBox';
import LoadingSpinner from '../LoadingSpinner';
import EmptyState from './EmptyState';

export interface Props {
  current: number;
  title: string;
  previous?: number | null;
  link?: {
    href: string;
    text: string;
  };
  hasData?: boolean;
  loading?: boolean;
  error?: string | null;
}

const CircularChart = ({
  current,
  title,
  previous,
  link,
  hasData = false,
  loading = false,
  error,
}: Props): ReactElement => {
  const currentData = useMemo(() => {
    if (!current) return 0;
    return current;
  }, [current]);

  const priorPeriod = useMemo(() => {
    if (previous == null) return null;
    if (currentData > previous) return currentData - previous;
    if (currentData < previous) return previous - currentData;
    return null;
  }, [currentData, previous]);

  const percent = useMemo(() => {
    if (!current && !previous) return 0;
    if (!previous) return 100;
    if (currentData > previous) return 100;
    if (currentData < previous)
      return previous > 0 ? (currentData / previous) * 100 : 0;
    return 100;
  }, [current, currentData, previous]);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className="p-18">
          <LoadingSpinner className="p-4" />
        </div>
      );
    }

    if (error) {
      return <ErrorMessageBox error={error} />;
    }

    if (!hasData) {
      return (
        <div className="p-18">
          <EmptyState />
        </div>
      );
    }
    return (
      <div className="flex justify-center items-center  h-full">
        <div
          className={classNames('lg:px-0 xl:px-7 px-7', {
            'pt-12': !link,
          })}
        >
          <CircularProgressbarWithChildren
            value={percent}
            strokeWidth={8}
            styles={buildStyles({
              strokeLinecap: 'round',
              trailColor: '#F3F4F6',
              pathColor: '#ffa300',
            })}
            className="w-full align-middle inline-block"
          >
            <div
              className={classNames('text-center', {
                'lg:mt-0 xl:mt-6 mt-6': priorPeriod != null,
              })}
            >
              <h2 className="text-7xl font-light mb-3">
                {numberFormatter({
                  compactDisplay: 'short',
                  notation: 'compact',
                }).format(currentData)}
              </h2>
              {priorPeriod != null && (
                <div className="flex justify-center items-center text-gray-400 gap-1">
                  {percent === 100 ? (
                    <ArrowUpIcon className="w-6 text-green-400" />
                  ) : (
                    <ArrowDownIcon className="w-6 text-red-400" />
                  )}
                  <div className="text-3xl">
                    {' '}
                    {numberFormatter({
                      compactDisplay: 'short',
                      notation: 'compact',
                    }).format(priorPeriod)}
                  </div>
                  <div className="text-xs leading-3 text-left">
                    vs prior
                    <br />
                    period
                  </div>
                </div>
              )}
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  }, [currentData, error, hasData, link, loading, percent, priorPeriod]);

  return (
    <div className="h-full flex flex-col justify-araound">
      <div className="font-bold text-2xl text-center text-headraceBlack-800">
        {title}
      </div>
      {link && (
        <Link href={link.href}>
          <div className="flex justify-center items-center gap-3 mt-1 mb-3 cursor-pointer">
            <div className="text-base text-gray-500 font-light">
              {link.text}
            </div>
            <ArrowRightIcon className="w-6 text-headraceYellow-700" />
          </div>
        </Link>
      )}
      {content}
    </div>
  );
};

export default CircularChart;
