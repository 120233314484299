import classNames from 'classnames';
import type { VFC } from 'react';
import { useMemo } from 'react';

interface Props {
  currentIndex: number;
  total: number;
  handleOnSelect: (index: number) => void;
}

const Dots: VFC<Props> = ({ currentIndex, total, handleOnSelect }: Props) => {
  const dotElemets = useMemo(() => {
    const dots: JSX.Element[] = [];
    for (let i = 0; i < total; i += 1) {
      dots.push(
        <button key={i} type="button" onClick={(): void => handleOnSelect(i)}>
          <div
            className={classNames('w-4 h-4 rounded-full mx-2 bg-zinc-300', {
              'opacity-50': i !== currentIndex,
            })}
          />
        </button>
      );
    }
    return dots;
  }, [currentIndex, handleOnSelect, total]);
  return (
    <div className="flex justify-center bottom-0 mt-10 inset-x-2/4">
      {dotElemets}
    </div>
  );
};

export default Dots;
