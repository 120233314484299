import type { ApolloError } from '@apollo/client';
import { CandidateActivityHistoryActivityType } from '@headrace/types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type {
  CandidatesForRoleQuery,
  CandidatesForRoleQueryVariables,
  RoleByIdQuery,
  RoleQuestionsQuery,
} from '@/graphql/generated';
import {
  useCandidatesForRoleQuery,
  useRoleByIdQuery,
  useRoleQuestionsQuery,
} from '@/graphql/generated';
import { useRecruiter } from '@/lib/RecruiterProvider';

interface RoleDetailsContextData {
  roleData: {
    data?: RoleByIdQuery['roleById'];
    loading: boolean;
    error?: ApolloError;
  };
  refetchRole: () => Promise<void>;
  candidatesSubmission: {
    data?: CandidatesForRoleQuery['candidatesForRole'];
    loading: boolean;
    error?: ApolloError;
  };
  refetchCandidates: (
    variables?: CandidatesForRoleQueryVariables
  ) => Promise<void>;
  roleFetchedOnce: boolean;
  hasPlacedCandidates: boolean;
  roleQuestions: {
    data?: RoleQuestionsQuery['roleQuestions'];
    loading: boolean;
    error?: ApolloError;
    refetch: () => Promise<void>;
  };
}

interface RoleDetailsProviderProps {
  roleId: string;
}

const RoleDetailsContext = React.createContext<RoleDetailsContextData>({
  refetchRole: () => Promise.resolve(),
  roleData: {
    loading: false,
  },
  candidatesSubmission: {
    loading: false,
  },
  refetchCandidates: () => Promise.resolve(),
  roleFetchedOnce: true,
  hasPlacedCandidates: false,
  roleQuestions: {
    loading: false,
    refetch: () => Promise.resolve(),
  },
});

const RoleDetailsProvider: React.FC<RoleDetailsProviderProps> = ({
  children,
  roleId,
}) => {
  const roleFetchedOnce = useRef<boolean>(true);
  const [hasPlacedCandidates, setHasPlacedCandidates] =
    useState<boolean>(false);
  const { data, loading, error, refetch } = useRoleByIdQuery({
    variables: { id: roleId },
  });
  const { recruiter } = useRecruiter();

  const {
    data: candidatesData,
    loading: candidatesLoading,
    error: candidatesError,
    refetch: candidatesRefetch,
  } = useCandidatesForRoleQuery({
    variables: { roleId },
  });

  useEffect(() => {
    if (!candidatesData?.candidatesForRole) return;
    if (
      candidatesData.candidatesForRole.candidates.some(
        (c) =>
          c.status === CandidateActivityHistoryActivityType.OFFER_ACCEPTED &&
          c.recruiter?.id === recruiter?.id &&
          c.searchAgreementRole.id ===
            data?.roleById?.primarySearchAgreementRoleId
      )
    ) {
      setHasPlacedCandidates(true);
    }
  }, [
    candidatesData,
    data?.roleById?.primarySearchAgreementRoleId,
    recruiter?.id,
  ]);

  const refetchRole = useCallback(async (): Promise<void> => {
    roleFetchedOnce.current = false;
    await refetch();
  }, [refetch]);

  const refetchCandidates = useCallback(
    async (variables?: CandidatesForRoleQueryVariables): Promise<void> => {
      await candidatesRefetch(variables ?? { roleId });
    },
    [candidatesRefetch, roleId]
  );

  const {
    data: roleQuestionsData,
    loading: roleQuestionsLoading,
    error: roleQuestionsError,
    refetch: roleQuestionsRefetch,
  } = useRoleQuestionsQuery({
    variables: { filter: { roleId } },
  });

  const refetchRoleQuestions = useCallback(async (): Promise<void> => {
    await roleQuestionsRefetch();
  }, [roleQuestionsRefetch]);

  const roleDetailsContextState: RoleDetailsContextData = useMemo(
    () => ({
      refetchRole,
      roleData: {
        data: data?.roleById,
        loading,
        error,
      },
      candidatesSubmission: {
        data: candidatesData?.candidatesForRole,
        loading: candidatesLoading,
        error: candidatesError,
      },
      refetchCandidates,
      roleFetchedOnce: roleFetchedOnce.current,
      hasPlacedCandidates,
      roleQuestions: {
        data: roleQuestionsData?.roleQuestions,
        loading: roleQuestionsLoading,
        error: roleQuestionsError,
        refetch: refetchRoleQuestions,
      },
    }),
    [
      refetchRole,
      data?.roleById,
      loading,
      error,
      candidatesData?.candidatesForRole,
      candidatesLoading,
      candidatesError,
      refetchCandidates,
      hasPlacedCandidates,
      roleQuestionsData?.roleQuestions,
      roleQuestionsLoading,
      roleQuestionsError,
      refetchRoleQuestions,
    ]
  );

  return (
    <RoleDetailsContext.Provider value={roleDetailsContextState}>
      {children}
    </RoleDetailsContext.Provider>
  );
};

export const useRoleDetails = (): RoleDetailsContextData =>
  React.useContext(RoleDetailsContext);

export default RoleDetailsProvider;
