import type { VFC } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import type { OptionsProps } from './BasicSelect';
import BasicSelect from './BasicSelect';

interface SelectProps {
  selectOptions?: OptionsProps[];
  onChangeSelect?: (value: OptionsProps[]) => void;
  value?: OptionsProps[];
  name: string;
  validateSelect?: (values: OptionsProps[]) => boolean;
}

const MultiSelect: VFC<SelectProps> = (props) => {
  const { selectOptions, onChangeSelect, value, name, validateSelect } = props;

  const handleChangeSelect = (
    item: MultiValue<OptionsProps> | SingleValue<OptionsProps>
  ): void => {
    if (item) {
      const newItem = item as OptionsProps[];
      if (validateSelect && validateSelect(newItem)) {
        if (onChangeSelect) onChangeSelect(newItem);
      }
    }
  };

  return (
    <div className="w-full">
      {selectOptions && (
        <BasicSelect
          name={name}
          closeMenuOnSelect={false}
          isMulti
          isClearable={false}
          options={selectOptions}
          menuPosition="fixed"
          value={value}
          onChange={handleChangeSelect}
        />
      )}
    </div>
  );
};

export default MultiSelect;
