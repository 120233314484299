import Image from 'next/image';
import Link from 'next/link';
import type { ReactElement } from 'react';

import Button from '../../Button';
import MiniDivider from '../../MiniDivider';

const FinalizeOnboarding = ({
  isReady,
}: {
  isReady: boolean;
}): ReactElement => {
  const title = isReady
    ? 'Your profile is ready to submit for review!'
    : 'Complete your profile for approval';

  const content = isReady ? (
    <>
      You’ve built out your whole profile and you’re ready to submit it to
      HeadRace for approval.
      <br />
      <br />
      Finish any last minute edits and hit finalize below to finish your
      onboarding and submit your profile to HeadRace for approval.
    </>
  ) : (
    <>
      Show off your recruitment skills by completing your profile.
      <br />
      <br />
      Clients look at recruiter profiles to review things like past placements,
      job function specialization, company stage and more.
    </>
  );

  return (
    <div className="w-full flex h-full items-center gap-6">
      <div>
        <div className="font-bold text-xl mb-2">{title}</div>
        <MiniDivider />
        <div className="text-sm text-gray-500 mt-2">{content}</div>

        {isReady && (
          <Link href="/onboarding">
            <Button buttonType="primary" className="mt-6">
              Finalize onboarding
            </Button>
          </Link>
        )}
      </div>

      <Image
        src="/illustrations/headrace-finalize-onboarding-illusration.svg"
        width="250"
        height="241"
      />
    </div>
  );
};

export default FinalizeOnboarding;
