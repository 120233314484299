import type { VFC } from 'react';
import { useMemo } from 'react';

import ModalStepper from '../ModalStepper';
import TeachableCard from './TeachableCard';

interface Props {
  open: boolean;
  onClose: () => void;
  cards: {
    image: string;
    text: string | JSX.Element;
  }[];
  hideDots?: boolean;
  customCloseText?: string;
}

const TeachableModal: VFC<Props> = ({
  cards,
  open,
  onClose,
  hideDots,
  customCloseText,
}) => {
  const steps = useMemo(
    () =>
      cards.map((card) => (
        <TeachableCard image={card.image} text={card.text} />
      )),
    [cards]
  );
  return (
    <ModalStepper
      open={open}
      onClose={onClose}
      steps={steps}
      dots={!hideDots}
      customCloseText={customCloseText}
    />
  );
};

export default TeachableModal;
