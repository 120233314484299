import './carousel.css';

import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';

interface Props {
  elements: JSX.Element[];
  show?: number;
  infiniteLoop?: boolean;
  interval?: number;
  staticElement?: JSX.Element;
}

const InfinityCarousel: VFC<Props> = (props) => {
  const {
    elements,
    show = 4,
    infiniteLoop = true,
    interval = 4000,
    staticElement,
  } = props;

  let slideInterval: number;

  const carouselRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(
    infiniteLoop && elements.length > show ? show : 0
  );
  const [length, setLength] = useState(elements.length);

  const [isRepeating, setIsRepeating] = useState(
    infiniteLoop && elements.length > show
  );
  const [transitionEnabled, setTransitionEnabled] = useState(true);

  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  // Set the length to match current elements from props
  useEffect(() => {
    setLength(elements.length);
    setIsRepeating(infiniteLoop && elements.length > show);
  }, [elements, infiniteLoop, show]);

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true);
      }
    }
  }, [currentIndex, isRepeating, show, length]);

  const next = (): void => {
    if (
      (isRepeating && currentIndex < length + show) ||
      currentIndex < length - show
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = (): void => {
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  const handleTransitionEnd = (): void => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false);
        setCurrentIndex(length);
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false);
        setCurrentIndex(show);
      } else if (currentIndex > length + show) {
        setCurrentIndex(show);
      }
    }
  };

  const renderExtraPrev = (): JSX.Element[] => {
    const output = [];
    for (let index = 0; index < show; index += 1) {
      output.push(elements[length - 1 - index]);
    }
    output.reverse();
    return output;
  };

  const renderExtraNext = (): JSX.Element[] => {
    const output = [];
    for (let index = 0; index < show; index += 1) {
      output.push(elements[index]);
    }
    return output;
  };

  const startSlider = (): void => {
    slideInterval = window.setInterval(() => {
      next();
    }, interval);
  };

  const pauseSlider = (): void => {
    clearInterval(slideInterval);
  };

  useEffect(() => {
    carouselRef.current?.addEventListener('mouseenter', pauseSlider);
    carouselRef.current?.addEventListener('mouseleave', startSlider);

    startSlider();
    return (): void => {
      pauseSlider();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="flex w-full relative" ref={carouselRef}>
        {(isRepeating || currentIndex > 0) && (
          <button
            onClick={prev}
            className="absolute z-[1] top-1/2 -translate-y-2/4 w-7 h-7 left-10 sm:block hidden"
            type="button"
          >
            <ArrowNarrowLeftIcon className="w-5 h-5 stroke-headraceBlack-700" />
          </button>
        )}
        <div className="h-full w-full flex sm:px-20 px-6">
          {staticElement}
          <div
            className="relative overflow-auto w-full h-full scrollbar scrollbar-none"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div
              className={classNames(`carousel-content show-${show}`, {
                'child:!grow-0': elements.length < show,
              })}
              style={{
                transform: `translateX(-${currentIndex * (100 / show)}%)`,
                transition: !transitionEnabled ? 'none' : undefined,
              }}
              onTransitionEnd={(): void => handleTransitionEnd()}
            >
              {length > show && isRepeating && renderExtraPrev()}
              {elements}
              {length > show && isRepeating && renderExtraNext()}
            </div>
          </div>
        </div>
        {(isRepeating || currentIndex < length - show) && (
          <button
            onClick={next}
            className="absolute z-[1] top-1/2 -translate-y-2/4 w-7 h-7 right-10 sm:block hidden"
            type="button"
          >
            <ArrowNarrowRightIcon className="w-5 h-5 stroke-headraceBlack-700" />
          </button>
        )}
      </div>
    </div>
  );
};

export default InfinityCarousel;
