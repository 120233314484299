import type { TopRoleFunctionsData } from '@headrace/types';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { EarningsEmptyState } from '../../Earnings';
import ErrorMessageBox from '../../ErrorMessageBox';
import LoadingSpinner from '../../LoadingSpinner';
import ProfileCard from '../ProfileCard';

interface Props {
  topRoleFunctions?: Partial<TopRoleFunctionsData>[];
  loading?: boolean;
  error?: string | null;
}

const TopRoleFunctions: React.VFC<Props> = ({
  topRoleFunctions,
  loading,
  error,
}) => {
  ChartJS.register(ArcElement, Tooltip);

  const [activeIndex, setActiveIndex] = useState(0);

  const roleFunctions = useMemo(() => {
    if (!topRoleFunctions) return [];
    const totalTop =
      topRoleFunctions?.reduce(
        (acc, cur) => acc + (cur.placementsCount || 0),
        0
      ) || 0;
    if (topRoleFunctions.length === 4 && totalTop < 100) {
      return [
        ...topRoleFunctions,
        {
          id: 'other',
          name: 'Other',
          placementsCount: 100 - totalTop,
        },
      ];
    }
    return topRoleFunctions;
  }, [topRoleFunctions]);

  const maxData = useMemo(() => {
    if (!roleFunctions) {
      return null;
    }

    return roleFunctions[0];
  }, [roleFunctions]);

  const data = useMemo(() => {
    const colors = [
      'rgba(255, 163, 0, 1)',
      'rgba(240, 112, 20, 1)',
      'rgba(218, 20, 20, 1)',
      'rgba(255, 199, 0, 1)',
      'rgba(187, 68, 48, 1)',
    ];

    const graphColors = roleFunctions.map((roleFunction, index) => {
      if (roleFunction.id === maxData?.id) {
        return 'rgba(255, 163, 0, 1)';
      }
      return colors[index];
    });
    const graphColorMax = roleFunctions.map((roleFunction) => {
      if (roleFunction.id === maxData?.id) {
        return 'rgba(255, 163, 0, 1)';
      }
      return 'rgba(0, 0, 0, 0)';
    });

    const hoverColors: string[] = [];

    roleFunctions.forEach((_roleFunction, index) => {
      if (activeIndex !== index) {
        hoverColors.push('rgba(255, 255, 255, 0)');
      } else {
        hoverColors.push(graphColors[index]);
      }
    });
    return {
      labels: roleFunctions.map((roleFunction) => roleFunction.name),
      datasets: [
        {
          label: 'graph',
          data: roleFunctions.map(
            (roleFunction) => roleFunction.placementsCount
          ),
          backgroundColor: graphColors,
          borderColor: graphColors,
          borderWidth: 0,
          hoverBackgroundColor: graphColors,
          hoverBorderColor: graphColors,
          borderRadius: {
            outerStart: 3,
            outerEnd: 3,
          },
          cutout: '78%',
          spacing: 5,
        },
        {
          label: 'hover',
          pointRadius: 0,
          pointHitRadius: 0,
          data: roleFunctions.map(
            (roleFunction) => roleFunction.placementsCount
          ),
          backgroundColor: hoverColors,
          hoverBackgroundColor: graphColors,
          hoverBorderColor: graphColorMax,
          borderWidth: 0,
          borderRadius: {
            innerStart: 3,
            innerEnd: 3,
          },
          cutout: '85%',
          spacing: 5,
        },
      ],
    };
  }, [activeIndex, maxData?.id, roleFunctions]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessageBox error={error} />;

  return (
    <ProfileCard className="flex flex-col text-xl h-full lg:h-[450px]">
      <div className="font-bold text-2xl">Top job functions placed</div>
      {topRoleFunctions && topRoleFunctions.length ? (
        <div className="flex flex-col gap-2 text-headraceBlack-800 text-xl h-full justify-center">
          <div className="flex items-center justify-center lg:h-[280px] truncate h-[300px]">
            <Doughnut
              data={data}
              plugins={[
                {
                  id: 'my-doughnut-text-plugin',
                  afterDatasetsDraw: (chart): void => {
                    const { width } = chart;
                    const { height } = chart;
                    const { ctx } = chart;

                    let text = '';
                    let text2 = '';
                    if (
                      chart.getActiveElements() &&
                      chart.getActiveElements().length
                    ) {
                      const { labels, datasets } = chart.config.data;
                      const activePoints = chart.getActiveElements()[0].index;
                      const activeLabel = labels
                        ? (labels[activePoints] as string)
                        : '';
                      const activeData = datasets[0].data[activePoints];
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      text = activeData ? `${activeData}%` : '';
                      text2 = activeLabel;
                      setActiveIndex(activePoints);
                    } else {
                      text = maxData ? `${maxData.placementsCount ?? 0}%` : '';
                      text2 = maxData?.name || '';
                      setActiveIndex(0);
                    }

                    ctx.save();

                    // Get options from the center object in options
                    const fontStyle = 'Ubuntu';
                    const txt = text2;
                    const color = 'rgba(156, 163, 175, 1)';
                    const maxFontSize = 18;
                    const sidePadding = 20;
                    const sidePaddingCalculated = (sidePadding / 100) * width;
                    // Start with a base font of 30px
                    ctx.font = `700 30px ${fontStyle}`;

                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    const stringWidth = ctx.measureText(txt).width;
                    const elementWidth = width / 2 - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    const widthRatio = elementWidth / stringWidth;
                    const newFontSize = Math.floor(30 * widthRatio);
                    const elementHeight = height;
                    // Pick a new font size so it will not be larger than the height of label.
                    let fontSizeToUse = Math.min(
                      newFontSize,
                      elementHeight,
                      maxFontSize
                    );
                    const minFontSize = 18;
                    const lineHeight = 25;
                    let wrapText = false;
                    let positionY = 2.2;

                    if (minFontSize && fontSizeToUse < minFontSize) {
                      fontSizeToUse = minFontSize;
                      wrapText = true;
                    }
                    // Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    const centerX =
                      (chart.chartArea.left + chart.chartArea.right) / 2;

                    let centerY =
                      (chart.chartArea.top + chart.chartArea.bottom) / 1.7;

                    ctx.font = `700 ${fontSizeToUse}px ${fontStyle}`;
                    ctx.fillStyle = color;

                    if (!wrapText) {
                      ctx.fillText(txt, centerX, centerY);
                    } else {
                      positionY = 2.4;
                      const words = txt.split(' ');
                      let line = '';
                      const lines = [];

                      // Break words up into multiple lines if necessary
                      for (let n = 0; n < words.length; n += 1) {
                        const testLine = `${line + words[n]} `;
                        const metrics = ctx.measureText(testLine);
                        const testWidth = metrics.width;
                        if (testWidth > elementWidth && n > 0) {
                          lines.push(line);
                          line = `${words[n]} `;
                        } else {
                          line = testLine;
                        }
                      }

                      // Move the center up depending on line height and number of lines
                      centerY -= (lines.length / 2) * lineHeight;

                      for (let n = 0; n < lines.length; n += 1) {
                        ctx.fillText(lines[n], centerX, centerY);
                        centerY += lineHeight;
                      }
                      // Draw text in center
                      ctx.fillText(line, centerX, centerY);
                    }
                    ctx.restore();

                    const fontSize = (height / 130).toFixed(2);
                    ctx.font = `bold ${fontSize}em Ubuntu`;
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = 'rgba(48, 50, 61, 1)';

                    const textY = height / positionY;
                    const textX2 = Math.round(
                      (width - ctx.measureText(text).width) / 2
                    );

                    ctx.fillText(text, textX2, textY);

                    ctx.restore();
                  },
                },
              ]}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'nearest',
                  intersect: false,
                },
                layout: {
                  padding: 20,
                },
                plugins: {
                  tooltip: {
                    filter: (tooltipItem) => tooltipItem.datasetIndex === 0,
                    displayColors: false,
                    intersect: false,
                    enabled: false,
                  },
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    display: false,
                  },
                },
                animation: {
                  duration: 0,
                },
              }}
            />
          </div>
          <div className="flex text-xl justify-center font-light text-gray-500">
            Hover to see more
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full mt-10">
          <EarningsEmptyState />
        </div>
      )}
    </ProfileCard>
  );
};

export default TopRoleFunctions;
