import type { PlacementGeographiesGraphData } from '@headrace/types';
import type { VFC } from 'react';

interface TopCitiesProps {
  cities: PlacementGeographiesGraphData[];
}

const TopCities: VFC<TopCitiesProps> = ({ cities }) => (
  <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4">
    <div className="text-lg font-bold">Top cities</div>
    <div className="grid lg:grid-cols-1 gap-4">
      {cities.map((city) => (
        <div
          key={city.cityCode}
          className="flex items-center text-sm rounded-lg ring-1 pl-3 ring-gray-300 w-full font-medium truncate justify-between "
        >
          <div className="py-2 truncate">{city.cityName}</div>
          <div className="bg-headraceBlack-700 ml-2 min-w-[54px] h-full flex text-center items-center justify-center text-white">
            {city.placementsCount}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default TopCities;
