import { PencilIcon } from '@heroicons/react/outline';
import type { VFC } from 'react';
import { useMemo, useState } from 'react';

import CardWithHeader from '../../CardWithHeader';
import { QuoteIcon } from '../../CustomIcons';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import LoadingSpinner from '../../LoadingSpinner';
import Slider from '../../Slider';
import ProfileCard from '../ProfileCard';
import QuoteCard from '../QuoteCard';
import type { EndorsementValues } from './EditMode/EndorsementsForm';
import EndorsementsForm from './EditMode/EndorsementsForm';

export interface Endorsement {
  id: string;
  endorsement: string;
  endorserBy: string;
  endorserTitle?: string;
  endorserCompany?: string;
  order: number;
}

interface Props {
  endorsements: Endorsement[];
  editMode?: boolean;
  loading?: boolean;
  onSubmit?: (values: EndorsementValues) => void;
}

const EndorsementsCard: VFC<Props> = ({
  endorsements,
  editMode,
  onSubmit,
  loading,
}) => {
  const [edit, setEdit] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const endorsementCards = useMemo(
    () =>
      endorsements.map((endorsement) => (
        <QuoteCard
          key={endorsement.endorsement}
          quote={endorsement.endorsement}
          quoteBy={endorsement.endorserBy}
          className="sm:h-[640px] md:h-[608] lg:h-[480px] xl:h-[320px] 2xl:h-[288px] h-[550px]"
        />
      )),
    [endorsements]
  );

  const endorsementFormValues = endorsements.map((endorsement) => ({
    id: endorsement.id,
    endorserTitle: endorsement.endorserTitle || '',
    endorserCompany: endorsement.endorserCompany || '',
    endorsement: endorsement.endorsement,
    order: endorsement.order,
  }));

  if (endorsements.length === 0 && !edit && editMode)
    return (
      <CardWithHeader title="Endorsements" className="mb-6">
        {loadingForm ? (
          <LoadingSpinner />
        ) : (
          <EmptyStateIllustration
            button={{
              label: 'Add Endorsement',
              onClick: (): void => {
                setEdit(true);
              },
            }}
            title="Add endorsements from clients or colleagues"
            description={
              <div>
                We think you’re pretty awesome, so we know others think the
                same! Add up <br /> to 3 endorsements sharing what others think
                of working with you.
              </div>
            }
            image="/illustrations/profileEmptyState/endorsements-illustration.svg"
            showSeparator={false}
            descriptionClassName="w-full"
            className="!p-0"
          />
        )}
      </CardWithHeader>
    );

  if (edit)
    return (
      <EndorsementsForm
        endorsements={endorsementFormValues}
        setEdit={setEdit}
        onSubmit={onSubmit}
        loading={loading}
        setLoading={setLoadingForm}
      />
    );
  return endorsements.length > 0 ? (
    <ProfileCard className="mb-6">
      {loadingForm ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-full flex items-center justify-center relative">
            <QuoteIcon className="w-12" />
            {!edit && editMode && (
              <button
                className="py-1 absolute top-0 right-0"
                type="button"
                onClick={(): void => {
                  setEdit(true);
                }}
              >
                <PencilIcon className="w-5 text-blue-500" />
              </button>
            )}
          </div>
          <Slider elements={endorsementCards} navigation interval={3000} />
        </>
      )}
    </ProfileCard>
  ) : null;
};

export default EndorsementsCard;
