import type { FeeScheduleTypeEnum } from '@headrace/types';
import {
  MarketplaceVisibilityEnum,
  SearchAgreementStatus,
} from '@headrace/types';
import {
  CalculatorIcon,
  ChatIcon,
  CheckCircleIcon,
  ClockIcon,
  LockClosedIcon,
  LockOpenIcon,
  PauseIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { BadgeCheckIcon, InformationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { differenceInHours, formatDistanceToNow } from 'date-fns';
import type { Dispatch, SetStateAction, VFC } from 'react';
import { useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Button from '../Button';
import Card from '../Card';
import {
  InviteSearchIcon,
  NounMoneyIcon,
  PublicSearchIcon,
} from '../CustomIcons';
import DirectSearchIcon from '../CustomIcons/VisibilityTypeIcons/DirectSearchIcon';
import CalculatorModal from '../RoleDossier/CalculatorModal';
import CandidateSubmissionQuotaModal from './CandidateSubmissionQuotaModal';

export interface ButtonProductSection {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  helper?: string | JSX.Element;
}

export interface ProductSectionProps {
  name?: string;
  marketplaceVisibility?: string | null;
  amount?: string;
  calculatorValues?: {
    fee: number;
    salaryMin: number;
    salaryMax: number;
    feeType: FeeScheduleTypeEnum;
    singularRole: boolean;
  };
  primaryButton: ButtonProductSection | null;
  secondaryButton: ButtonProductSection | null;
  createdAt?: Date;
  statusSearch?: string | JSX.Element | null;
  recruiterQuota?: number;
  recruiterLimit?: number | null;
  submissionAvailableDate?: string | null;
  note?: string;
  elementNote?: JSX.Element;
  openQuotaModal: boolean;
  setOpenQuotaModal: Dispatch<SetStateAction<boolean>>;
}

const ProductItem: VFC<ProductSectionProps> = ({
  name,
  marketplaceVisibility,
  amount,
  primaryButton,
  secondaryButton,
  createdAt,
  statusSearch,
  calculatorValues,
  recruiterQuota,
  recruiterLimit,
  submissionAvailableDate,
  note,
  elementNote,
  openQuotaModal,
  setOpenQuotaModal,
}) => {
  const [openCalculatorModal, setOpenCalculatorModal] = useState(false);

  const getDisplayDate = useMemo(() => {
    if (!createdAt) return '';
    const distance = differenceInHours(new Date(), new Date(createdAt));
    if (distance > 24)
      return `Posted ${formatDistanceToNow(new Date(createdAt), {
        addSuffix: true,
      })}`;

    return 'Posted 1 day ago';
  }, [createdAt]);

  const statusBadge = useMemo(() => {
    if (typeof statusSearch === 'string') {
      switch (statusSearch) {
        case SearchAgreementStatus.ACTIVE:
          return (
            <div className="flex">
              <CheckCircleIcon className="text-green-500 w-5 mr-[9px]" />
              <p className="font-normal text-sm text-gray-500">Open</p>
            </div>
          );
        case SearchAgreementStatus.PAUSED:
          return (
            <div className="flex">
              <PauseIcon className="text-amber-500 w-5 mr-[9px]" />
              <p className="font-normal text-sm text-gray-500">Paused</p>
            </div>
          );
        case SearchAgreementStatus.ENDED:
        case SearchAgreementStatus.CANCELED:
          return (
            <div className="flex">
              <XCircleIcon className="text-red-500 w-5 mr-[9px]" />
              <p className="font-normal text-sm text-gray-500">Closed</p>
            </div>
          );
        default:
          return null;
      }
    }
    return statusSearch;
  }, [statusSearch]);

  const visibilityTypeSection = useMemo(() => {
    let searchName = '';
    let icon: JSX.Element | null = null;
    let dataTooltip = '';
    switch (marketplaceVisibility) {
      case MarketplaceVisibilityEnum.INVITE:
        searchName = 'Invite-only role';
        icon = <InviteSearchIcon className="w-8 h-8" />;
        dataTooltip =
          'Invite-only is a marketplace role open <br/> to multiple recruiters with a <br/> standard placement fee';
        break;
      case MarketplaceVisibilityEnum.PUBLIC:
        searchName = 'Public role';
        icon = <PublicSearchIcon className="w-8 h-8" />;
        dataTooltip =
          'Public roles are open to the <br/>marketplace and can be priced as a <br/>fixed fee or a % of first year salary';

        break;
      case MarketplaceVisibilityEnum.DIRECT:
        searchName = 'Direct role';
        icon = <DirectSearchIcon className="w-8 h-8" />;
        dataTooltip =
          'Direct is a 1-to-1 search relationship <br/> with a client and typically <br/>involves a contingent or retained fee';
        break;
      default:
        break;
    }

    return (
      <div
        className={classNames('flex gap-2 items-center py-4 justify-between', {
          'border-t': !name && !amount,
        })}
      >
        <div className="flex gap-2 items-center">
          {icon}
          <span className="text-lg font-medium text-gray-500">
            {searchName}
          </span>
          <div data-for="info" data-tip={dataTooltip}>
            <InformationCircleIcon className="h-4 w-4 text-gray-400" />
          </div>
          <ReactTooltip
            id="info"
            place="top"
            effect="solid"
            html
            arrowColor="transparent"
            className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal !text-center"
          />
        </div>
        {statusBadge}
      </div>
    );
  }, [amount, name, marketplaceVisibility, statusBadge]);

  return (
    <>
      <Card className="flex flex-col p-6 divide-y h-fit">
        {name && amount && (
          <div className="flex gap-4 pb-4">
            <NounMoneyIcon className="h-16 w-16 text-headraceYellow-700" />
            <div className="flex flex-col gap-1">
              <span className="text-4xl font-bold text-gray-700 leading-7">
                {amount}
              </span>
              <div className="flex gap-1">
                <span className="text-2xl font-medium text-gray-400">
                  {name}
                </span>
                {calculatorValues && (
                  <Button
                    buttonType="link"
                    className="!m-0 outline-none"
                    onClick={(): void => {
                      setOpenCalculatorModal(true);
                    }}
                  >
                    <CalculatorIcon className="w-6 h-6 text-blue-500" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
        {visibilityTypeSection}
        <div className="flex flex-col gap-2 h-full pt-4">
          <div className="flex flex-col gap-2 mb-2">
            <div className="flex gap-3">
              <BadgeCheckIcon className="h-5 w-5 text-green-500" />
              <span className="text-sm font-base text-gray-500">
                HeadRace verified employer
              </span>
            </div>
            <div className="flex gap-3">
              <ClockIcon className="h-5 w-5 text-gray-400" />
              <span className="text-sm font-base text-gray-500">
                {getDisplayDate}
              </span>
            </div>
            {recruiterQuota != null && recruiterLimit != null ? (
              <div className="flex gap-3">
                {recruiterQuota < recruiterLimit ? (
                  <LockOpenIcon className="h-5 w-5 text-green-500" />
                ) : (
                  <LockClosedIcon className="h-5 w-5 text-red-500" />
                )}
                <span className="text-sm font-base text-gray-500">
                  {recruiterQuota > recruiterLimit
                    ? recruiterLimit
                    : recruiterQuota}
                  /{recruiterLimit} candidate submissions{' '}
                  <button
                    type="button"
                    onClick={(): void => {
                      setOpenQuotaModal(true);
                    }}
                  >
                    (<span className="text-blue-500 underline">more info</span>)
                  </button>
                </span>
              </div>
            ) : (
              <div className="flex gap-3">
                <ChatIcon className="h-5 w-5 text-headraceYellow-700" />
                <span className="text-sm font-base text-gray-500">
                  Candidate feedback shared
                </span>
              </div>
            )}
          </div>
          {primaryButton && (
            <Button
              onClick={primaryButton.onClick}
              disabled={primaryButton.disabled}
              loading={primaryButton.loading}
            >
              {primaryButton.label}
            </Button>
          )}
          {secondaryButton && (
            <Button
              buttonType="secondary"
              onClick={secondaryButton.onClick}
              disabled={secondaryButton.disabled}
              loading={secondaryButton.loading}
              childrenClassName="w-full justify-center"
            >
              <div
                className={classNames('w-full flex', {
                  'justify-center': !secondaryButton.helper,
                  'justify-between': secondaryButton.helper,
                })}
              >
                <div className="flex justify-center w-full">
                  {secondaryButton.label}
                </div>
                {secondaryButton.helper}
              </div>
            </Button>
          )}
          {note && <div className="text-red-700 text-xs">{note}</div>}
          {elementNote}
        </div>
      </Card>
      {calculatorValues && (
        <CalculatorModal
          open={openCalculatorModal}
          onClose={(): void => {
            setOpenCalculatorModal(false);
          }}
          marketplaceVisibility={marketplaceVisibility}
          fee={calculatorValues.fee}
          feeType={calculatorValues.feeType}
          salary={(calculatorValues.salaryMin + calculatorValues.salaryMax) / 2}
          singularRole={calculatorValues.singularRole}
        />
      )}
      {recruiterQuota != null && recruiterLimit != null && (
        <CandidateSubmissionQuotaModal
          open={openQuotaModal}
          onClose={(): void => {
            setOpenQuotaModal(false);
          }}
          recruiterQuota={recruiterQuota}
          recruiterLimit={recruiterLimit}
          submissionAvailableDate={submissionAvailableDate}
        />
      )}
    </>
  );
};
export default ProductItem;
