import {
  CandidatePlacementEnum,
  CustomFeeTriggerEnum,
  FeeScheduleTypeEnum,
  FeeTypeEnum,
} from '@headrace/types';
import { useMemo } from 'react';
import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

const useSearchAgreementFormSchema = (
  addedFeeTypes: Array<{
    id: string;
    value: FeeTypeEnum;
  }>,
  addCreateValidations: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
  useMemo(() => {
    const validations = {
      employer: {},
      initialFees: {},
      performanceFees: {},
      recurringMonthlyFees: {},
      candidatePlacementFees: {},
      equityPlacementFees: {},
      customFees: Yup.array()
        .of(
          Yup.object().shape({
            feeType: Yup.string().oneOf([FeeTypeEnum.CUSTOM]).required(),
            feeAmount: Yup.number().positive().required(),
            feeTrigger: Yup.string()
              .oneOf(Object.values(CustomFeeTriggerEnum))
              .required(),
            daysFrom: Yup.number().when(
              'feeTrigger',
              (feeTrigger: CustomFeeTriggerEnum) =>
                feeTrigger === CustomFeeTriggerEnum.SPECIFIC_DATE
                  ? Yup.number().nullable()
                  : Yup.number().positive().required()
            ),
            onDate: Yup.date().min(today).required(),
            paymentTerm: Yup.number().integer().min(0).max(90).required(),
          })
        )
        .required(),
    };

    if (addCreateValidations) {
      validations.employer = {
        employerId: Yup.string().required('Employer name is required'),
      };
    }

    addedFeeTypes.forEach(({ value: feeType }) => {
      if (feeType === FeeTypeEnum.INITIAL_FEE) {
        validations.initialFees = {
          initialFeeType: Yup.string()
            .oneOf([FeeTypeEnum.INITIAL_FEE])
            .nullable(),
          initialFeeAmount: Yup.number().when(
            'initialFeeType',
            (initialFeeType: FeeTypeEnum) =>
              initialFeeType
                ? Yup.number().positive().required()
                : Yup.number().nullable()
          ),
          initialFeeTrigger: Yup.string().when(
            'initialFeeType',
            (initialFeeType: FeeTypeEnum) =>
              initialFeeType
                ? Yup.mixed()
                    .oneOf(Object.values(CandidatePlacementEnum))
                    .required()
                : Yup.string().nullable()
          ),
          initialFeePaymentTerm: Yup.number().when(
            'initialFeeType',
            (initialFeeType: FeeTypeEnum) =>
              initialFeeType
                ? Yup.number().integer().min(0).max(90).required()
                : Yup.number().nullable()
          ),
        };
      }

      if (feeType === FeeTypeEnum.PERFORMANCE_FEE) {
        validations.performanceFees = {
          performanceFeeType: Yup.string()
            .oneOf([FeeTypeEnum.PERFORMANCE_FEE])
            .nullable(),
          performanceFeeAmount: Yup.number().when(
            'performanceFeeType',
            (performanceFeeType: FeeTypeEnum) =>
              performanceFeeType
                ? Yup.number().positive().required()
                : Yup.number().nullable()
          ),
          performanceFeeAcceptedCandidates: Yup.number().when(
            'performanceFeeType',
            (performanceFeeType: FeeTypeEnum) =>
              performanceFeeType
                ? Yup.number().positive().required()
                : Yup.number().nullable()
          ),
          performanceFeePaymentTerm: Yup.number().when(
            'performanceFeeType',
            (performanceFeeType: FeeTypeEnum) =>
              performanceFeeType
                ? Yup.number().integer().min(0).max(90).required()
                : Yup.number().nullable()
          ),
        };
      }

      if (feeType === FeeTypeEnum.RECURRING_MONTHLY_FEE) {
        validations.recurringMonthlyFees = {
          recurringMonthlyFeeType: Yup.string()
            .oneOf([FeeTypeEnum.RECURRING_MONTHLY_FEE])
            .nullable(),
          recurringMonthlyFeeAmount: Yup.number().when(
            'recurringMonthlyFeeType',
            (recurringMonthlyFeeType: FeeTypeEnum) =>
              recurringMonthlyFeeType
                ? Yup.number().positive().required()
                : Yup.number().nullable()
          ),
          recurringMonthlyFeeMonthsQuantity: Yup.number().when(
            'recurringMonthlyFeeType',
            (recurringMonthlyFeeType: FeeTypeEnum) =>
              recurringMonthlyFeeType
                ? Yup.number().integer().min(1).max(12).required()
                : Yup.number().nullable()
          ),
          recurringMonthlyFeePaymentTerm: Yup.number().when(
            'recurringMonthlyFeeType',
            (recurringMonthlyFeeType: FeeTypeEnum) =>
              recurringMonthlyFeeType
                ? Yup.number().integer().min(0).max(90).required()
                : Yup.number().nullable()
          ),
        };
      }

      if (feeType === FeeTypeEnum.CANDIDATE_PLACEMENT_FEE) {
        validations.candidatePlacementFees = {
          candidatePlacementFeeType: Yup.string()
            .oneOf([FeeTypeEnum.CANDIDATE_PLACEMENT_FEE])
            .nullable(),
          candidatePlacementFeeAmountType: Yup.string().when(
            'candidatePlacementFeeType',
            (candidatePlacementFeeType: FeeTypeEnum) =>
              candidatePlacementFeeType
                ? Yup.string()
                    .oneOf([
                      FeeScheduleTypeEnum.CASH_FIXED,
                      FeeScheduleTypeEnum.CASH_PERCENT,
                    ])
                    .required()
                : Yup.string().nullable()
          ),
          candidatePlacementFeeAmount: Yup.number()
            .when(
              'candidatePlacementFeeType',
              (candidatePlacementFeeType: FeeTypeEnum) =>
                candidatePlacementFeeType
                  ? Yup.number().positive().required()
                  : Yup.number().nullable()
            )
            .when(
              'candidatePlacementFeeAmountType',
              (candidatePlacementFeeAmountType: FeeScheduleTypeEnum) =>
                candidatePlacementFeeAmountType ===
                FeeScheduleTypeEnum.CASH_PERCENT
                  ? Yup.number().positive().max(100).required()
                  : Yup.number().positive().required()
            ),
          candidatePlacementFeeTrigger: Yup.string().when(
            'candidatePlacementFeeType',
            (candidatePlacementFeeType: FeeTypeEnum) =>
              candidatePlacementFeeType
                ? Yup.mixed()
                    .oneOf(Object.values(CandidatePlacementEnum))
                    .required()
                : Yup.string().nullable()
          ),
          candidatePlacementFeeSubstractRetainerFees: Yup.boolean().required(),
          candidatePlacementFeePaymentTerm: Yup.number().when(
            'candidatePlacementFeeType',
            (candidatePlacementFeeType: FeeTypeEnum) =>
              candidatePlacementFeeType
                ? Yup.number().integer().min(0).max(90).required()
                : Yup.number().nullable()
          ),
        };
      }

      if (feeType === FeeTypeEnum.EQUITY_PLACEMENT_FEE) {
        validations.equityPlacementFees = {
          equityPlacementFeeType: Yup.string()
            .oneOf([FeeTypeEnum.EQUITY_PLACEMENT_FEE])
            .nullable(),
          equityPlacementFeeAmountType: Yup.string().when(
            'equityPlacementFeeType',
            (equityPlacementFeeType: FeeTypeEnum) =>
              equityPlacementFeeType
                ? Yup.string()
                    .oneOf([
                      FeeScheduleTypeEnum.EQUITY_FIXED,
                      FeeScheduleTypeEnum.EQUITY_PERCENT,
                    ])
                    .required()
                : Yup.string().nullable()
          ),
          equityPlacementFeeAmount: Yup.number()
            .when(
              'equityPlacementFeeType',
              (equityPlacementFeeType: FeeTypeEnum) =>
                equityPlacementFeeType
                  ? Yup.number().positive().required()
                  : Yup.number().nullable()
            )
            .when(
              'equityPlacementFeeAmountType',
              (equityPlacementFeeAmountType: FeeScheduleTypeEnum) =>
                equityPlacementFeeAmountType ===
                FeeScheduleTypeEnum.EQUITY_PERCENT
                  ? Yup.number().positive().max(100).required()
                  : Yup.number().positive().required()
            ),
          equityPlacementFeeTrigger: Yup.string().when(
            'equityPlacementFeeType',
            (equityPlacementFeeType: FeeTypeEnum) =>
              equityPlacementFeeType
                ? Yup.mixed()
                    .oneOf(Object.values(CandidatePlacementEnum))
                    .required()
                : Yup.string().nullable()
          ),
          equityPlacementFeePaymentTerm: Yup.number().when(
            'equityPlacementFeeType',
            (equityPlacementFeeType: FeeTypeEnum) =>
              equityPlacementFeeType
                ? Yup.number().integer().min(0).max(90).required()
                : Yup.number().nullable()
          ),
        };
      }
    });

    const {
      employer,
      initialFees,
      performanceFees,
      recurringMonthlyFees,
      candidatePlacementFees,
      equityPlacementFees,
      customFees,
    } = validations;

    return Yup.object().shape({
      signedByEmployeeId: Yup.string()
        .typeError('Employer Signatory is required')
        .required('Employer Signatory is required'),
      beginAt: Yup.date()
        .nullable()
        .typeError('Invalid Date')
        .required('Start date is required')
        .min(today, 'Start date must be in the future'),
      ...employer,
      ...initialFees,
      ...performanceFees,
      ...recurringMonthlyFees,
      ...candidatePlacementFees,
      ...equityPlacementFees,
      customFees,
      isExclusivityCarveOutExplicitListCandidates: Yup.boolean().required(),
      replacementSearchWindowMonths: Yup.number().when(
        'includeReplacementSearch',
        (includeReplacementSearch: boolean) =>
          includeReplacementSearch
            ? Yup.number()
                .integer()
                .typeError('Search window must be between 1 and 12 months')
                .min(
                  1,
                  'Search window must be greater than or equal to 1 month'
                )
                .max(
                  12,
                  'Search window must be less than or equal to 12 months'
                )
                .required('Search window is required')
            : Yup.number().nullable()
      ),
      replacementSearchNotificationWindowDays: Yup.number().when(
        'includeReplacementSearch',
        (includeReplacementSearch: boolean) =>
          includeReplacementSearch
            ? Yup.number()
                .integer()
                .typeError('Notification window must be between 0 and 60 days')
                .min(
                  0,
                  'Notification window must be greater than or equal to 0 day'
                )
                .max(
                  60,
                  'Notification window must be less than or equal to 60 days'
                )
                .required('Notification window is required')
            : Yup.number().nullable()
      ),
      replacementSearchTerminateDays: Yup.number().when(
        'includeReplacementSearch',
        (includeReplacementSearch: boolean) =>
          includeReplacementSearch
            ? Yup.number()
                .integer()
                .typeError('Departure trigger must be between 1 and 365 days')
                .min(
                  1,
                  'Departure trigger must be greater than or equal to 1 day'
                )
                .max(
                  365,
                  'Departure trigger must be less than 1 or equal to 365 days'
                )
                .required('Departure trigger is required')
            : Yup.number().nullable()
      ),

      exclusivityCarveOutCandidates: Yup.string().when(
        'isExclusivityCarveOutExplicitListCandidates',
        (isExclusivityCarveOutExplicitListCandidates: boolean) =>
          isExclusivityCarveOutExplicitListCandidates
            ? Yup.string()
                .min(1)
                .required('The list of excluded candidates is required')
            : Yup.string().nullable()
      ),
    });
  }, [addCreateValidations, addedFeeTypes]);

export default useSearchAgreementFormSchema;
