import {
  CompanyLogo,
  toastMessage,
  useHeaderTabs,
  useSecondaryNav,
} from '@headrace/ui';
import { formatApolloError, getScreenProperties } from '@headrace/utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';

import { useCreatePublicDossierMutation } from '@/graphql/generated';
import { ampli } from '@/lib/ampli';

import RoleDetailsProvider, { useRoleDetails } from './RoleDetailsProvider';

const RoleDetailsLayout: React.FC = ({ children }) => {
  const { query } = useRouter();
  const roleId = useMemo(() => {
    if (Array.isArray(query.roleId)) return query.roleId[0];
    return query.roleId ?? '';
  }, [query.roleId]);

  const { setTitle } = useSecondaryNav();
  const { setTabs, setRightContent } = useHeaderTabs();
  const { roleData } = useRoleDetails();

  const [createPublicDossier, { loading: creating }] =
    useCreatePublicDossierMutation({
      onCompleted: (data) => {
        window.open(data.createPublicDossier, '_ blank');
      },
      onError: (e) => {
        toast.error(
          toastMessage({
            title: 'Error updating candidate',
            subtitle: formatApolloError(e),
          }),
          { duration: 4000 }
        );
      },
    });

  useEffect(() => {
    // Amplitude tracking: role viewed
    if (roleData.data) {
      const { viewport, screenResolution } = getScreenProperties();
      ampli.roleRoleViewed(
        {
          app: 'Recruiter',
          'role id': roleData.data.id,
          'employer id': roleData.data.employer.id,
        },
        {
          user_properties: {
            viewport,
            'screen resolution': screenResolution,
          },
        }
      );
    }
  }, [roleData.data]);

  useEffect(() => {
    if (roleData.data) {
      setTitle(
        <div className="flex items-center">
          <div className="shadow-lg border-8 border-white rounded flex items-center">
            <CompanyLogo
              urlIcon={roleData.data.employer.company.logoUrl}
              height={32}
              width={32}
            />
          </div>
          <div className="ml-4">{`${roleData.data.employer.company.name} / ${roleData.data.name}`}</div>
        </div>
      );
    }
  }, [roleData.data, setTitle]);

  const getOnclickDossier = useCallback(
    () => async (): Promise<void> => {
      if (roleData.data?.publicDossierUrl) {
        window.open(roleData.data?.publicDossierUrl, '_ blank');
      } else {
        await createPublicDossier({
          variables: {
            roleId,
          },
        });
      }
    },
    [createPublicDossier, roleData.data?.publicDossierUrl, roleId]
  );

  useEffect(() => {
    if (roleData.data?.isDossierShareable) {
      const rightContent = (
        <div className="py-1">
          <button
            type="button"
            className="text-blue-500 font-medium cursor-pointer text-sm"
            onClick={getOnclickDossier()}
            disabled={creating}
          >
            View sharable dossier
          </button>
        </div>
      );
      setRightContent(rightContent);
    }
  }, [creating, getOnclickDossier, roleData.data, roleId, setRightContent]);

  useEffect(() => {
    const tabsOptions = [
      {
        name: 'Overview',
        href: `/roles/${roleId}`,
      },
      {
        name: 'Role dossier',
        href: `/roles/${roleId}/dossier`,
      },
      {
        name: 'Messaging',
        href: `/roles/${roleId}/messaging`,
      },
    ];
    setTabs(tabsOptions);
  }, [roleId, setTabs]);

  return <div>{children}</div>;
};

const WrapperRoleDetailsLayout: React.FC = ({ children }) => {
  const { query, pathname } = useRouter();
  const roleId = useMemo(() => {
    if (Array.isArray(query.roleId)) return query.roleId[0];
    return query.roleId ?? '';
  }, [query.roleId]);

  // This is a workaround to prevent the RoleDetailsProvider to be rendered in the RoleDossierTab
  if (pathname.includes('roles/[roleId]/dossier')) {
    return <RoleDetailsLayout>{children}</RoleDetailsLayout>;
  }
  return (
    <RoleDetailsProvider roleId={roleId}>
      <RoleDetailsLayout>{children}</RoleDetailsLayout>
    </RoleDetailsProvider>
  );
};

export default WrapperRoleDetailsLayout;
