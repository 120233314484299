import { useAuth0 } from '@auth0/auth0-react';
import {
  AuthenticationCheck,
  AuthorizedApolloProvider,
  isAdmin,
} from '@headrace/auth';
import { POLICY_VERSION, TERMS_VERSION } from '@headrace/constants';
import {
  useApiVersionLazyQuery,
  useUpdateUserTermsAndPolicyMutation,
} from '@headrace/graphql';
import {
  EntityTypeEnum,
  OnboardingStatus,
  UserConsentType,
} from '@headrace/types';
import {
  BecomeUserHeader,
  PageLoader,
  TailwindLayout,
  TermsAndPolicy,
} from '@headrace/ui';
import { checkApiVersion, formatApolloError } from '@headrace/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import RecruiterForm from '@/components/RecruiterForm';
import { navigation } from '@/constants/NavigationItems';
import RecruiterProvider, { useRecruiter } from '@/lib/RecruiterProvider';

import MarketplaceLayout from './Marketplace/MarketplaceLayout';
import RoleDetailsLayout from './Role/RoleDetails/RoleDetailsLayout';

interface FormValues {
  tou: boolean;
}

const DefaultLayout: React.VFC<{ children: JSX.Element }> = (props) => {
  const hotjarId = 3213738;
  const { children } = props;
  const { recruiter, loading, fetchRecruiter } = useRecruiter();

  const [getApiVersion] = useApiVersionLazyQuery();

  const { logout, user } = useAuth0();
  const { pathname, replace, reload } = useRouter();

  const [, , removeCookie] = useCookies(['become-user-id']);

  const navigationItems = useMemo(() => navigation(recruiter), [recruiter]);

  const [updateUserTermsAndPolicy, { loading: loadingTerms }] =
    useUpdateUserTermsAndPolicyMutation({
      onCompleted: () => fetchRecruiter(),
      onError: (_error) => toast.error(formatApolloError(_error)),
    });

  const handleUpdateTerms = async (values: FormValues): Promise<void> => {
    await updateUserTermsAndPolicy({
      variables: {
        data: {
          tou: values.tou,
          entity: EntityTypeEnum.RECRUITER,
        },
      },
    });
  };

  const userNavigation = useMemo(() => {
    const userNavigationData = [
      {
        name: 'My profile',
        href: '/profile',
      },
      {
        name: 'Sign out',
        onClick: (e: React.MouseEvent<HTMLButtonElement>): void => {
          e.preventDefault();
          removeCookie('become-user-id', { path: '/' });
          logout({ returnTo: window.location.origin });
        },
      },
    ];
    if (recruiter?.onboardingStatus === OnboardingStatus.COMPLETED) {
      userNavigationData.splice(1, 0, {
        name: 'Settings',
        href: '/settings',
      });
    }
    return userNavigationData;
  }, [logout, recruiter?.onboardingStatus, removeCookie]);

  const getLastApiVersion = async (): Promise<string> => {
    const apiVersionData = await getApiVersion();
    const apiVersion = apiVersionData.data?.apiVersion;
    return apiVersion || '';
  };

  const home = useMemo(() => {
    // User has switched back to the tab
    const onFocus = async (): Promise<void> => {
      // Check api version
      await checkApiVersion({
        getApiVersion: getLastApiVersion,
        frontendVersion: process.env.npm_package_version || '',
        reload,
      });
    };
    return (
      <>
        {isAdmin(user) && <BecomeUserHeader adminUserEmail={user?.email} />}
        <TailwindLayout
          currentPath={pathname}
          navigation={navigationItems}
          profileImg={recruiter?.user.photoUrl}
          userNavigation={userNavigation}
          hotjarId={hotjarId}
          onFocus={onFocus}
        >
          {children}
        </TailwindLayout>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    pathname,
    navigationItems,
    recruiter?.user.photoUrl,
    userNavigation,
    children,
    getApiVersion,
  ]);

  const allowedPathsOnboardingRecruiter = ['/onboarding', '/profile'];

  useEffect(() => {
    if (!recruiter) return;
    if (
      recruiter?.onboardingStatus !== OnboardingStatus.COMPLETED &&
      !allowedPathsOnboardingRecruiter.includes(pathname)
    ) {
      replace('/onboarding').finally(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, recruiter?.onboardingStatus]);

  // Loading
  if (loading) {
    return <PageLoader />;
  }

  // Headrace admin
  if (recruiter && recruiter.user.isHeadRaceAdmin) {
    return home;
  }

  // Recruiter exist and have at least 1 userConsent signed
  if (recruiter && recruiter.user.userConsents.length > 0) {
    const haveNewVersionPolicy = recruiter.user.userConsents.find(
      (item) =>
        item.userConsentType === UserConsentType.PRIVACY_POLICY &&
        item.versionNumber === POLICY_VERSION
    );
    const haveNewVersionTerms = recruiter.user.userConsents.find(
      (item) =>
        item.userConsentType === UserConsentType.TERMS_OF_USE &&
        item.versionNumber === TERMS_VERSION
    );
    if (haveNewVersionPolicy && haveNewVersionTerms) {
      return home;
    }
    return (
      <TermsAndPolicy
        handleSubmit={handleUpdateTerms}
        loadingTerms={loadingTerms}
      />
    );
  }

  // Recruiter was not found -> register form
  return (
    <>
      <Head>
        <title>Recruiter Registration</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content="Recruiter Registration" />
        <meta name="description" content="Recruiter Registration" key="desc" />
        <meta property="og:description" content="Recruiter Registration" />
        <link rel="icon" href="/headrace_icon.svg" />
        <meta property="og:image" content="/headrace_icon.svg" />
      </Head>
      <RecruiterForm onCompleted={fetchRecruiter} />
    </>
  );
};

const ChildrenWrapper: FC = ({ children }) => {
  const { recruiter } = useRecruiter();
  const router = useRouter();
  let includeInRoleDetailsLayout = false;
  const roleDetailsPage = navigation(recruiter).find(
    (nav) => nav.href === '/roles/[roleId]'
  );
  if (roleDetailsPage && roleDetailsPage.children) {
    includeInRoleDetailsLayout = roleDetailsPage.children.some((child) => {
      if (child === router.pathname) {
        return true;
      }
      return false;
    });
  }
  const isRoleDetailsPage = router.pathname === '/roles/[roleId]';
  if (includeInRoleDetailsLayout || isRoleDetailsPage) {
    return (
      <RoleDetailsLayout>
        <div className="flex flex-col flex-1">{children}</div>
      </RoleDetailsLayout>
    );
  }
  const isMarketplaceDetailsPage = router.pathname === '/marketplace/[roleId]';
  if (router.pathname.includes('/marketplace') && !isMarketplaceDetailsPage) {
    return (
      <MarketplaceLayout>
        <div className="flex flex-col flex-1">{children}</div>
      </MarketplaceLayout>
    );
  }
  return <div>{children}</div>;
};

/* DefaultLayoutWrapper
 * Wraps over DefaultLayout to pass on the required providers
 */
const WrappedDefaultLayout: React.VFC<{ children: JSX.Element }> = ({
  children,
}) => {
  const api = process.env.NEXT_PUBLIC_HEADRACE_API_URI ?? '';

  return (
    <AuthorizedApolloProvider uri={`${api}/graphql/recruiter`}>
      {/* AuthenticationCheck loads user (required before RecruiterProvider) */}
      <AuthenticationCheck>
        <RecruiterProvider>
          <DefaultLayout>
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </DefaultLayout>
        </RecruiterProvider>
      </AuthenticationCheck>
    </AuthorizedApolloProvider>
  );
};

export default WrappedDefaultLayout;
