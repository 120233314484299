import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import type { VFC } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  tootipText: string;
  name: string;
  classNameTooltip?: string;
  classNameIcon?: string;
  tooltipType?: 'multiline' | 'html';
  arrowColor?: string;
}

const QuestionMarkTooltip: VFC<Props> = ({
  name,
  tootipText,
  classNameTooltip,
  classNameIcon,
  tooltipType = 'multiline',
  arrowColor,
}) => (
  <>
    <QuestionMarkCircleIcon
      data-for={name}
      data-tip={tootipText}
      className={classNames(
        'h-4 w-4 text-gray-400 ml-1 focus:outline-0',
        classNameIcon
      )}
      aria-hidden="true"
    />
    <ReactTooltip
      id={name}
      arrowColor={arrowColor}
      place="top"
      effect="solid"
      multiline={tooltipType === 'multiline'}
      html={tooltipType === 'html'}
      offset={{ top: -5 }}
      className={classNames('bg-gray-300 w-48', classNameTooltip)}
    />
  </>
);

export default QuestionMarkTooltip;
