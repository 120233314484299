import { type NavigationItem, SettingsLayout } from '@headrace/ui';
import type { FC } from 'react';

export const navBarItems: NavigationItem[] = [
  {
    href: '/settings',
    name: 'General',
    showNavigation: false,
    title: 'Settings',
    yellowStripe: true,
  },
  {
    href: '/settings/notifications',
    name: 'Notifications',
    showNavigation: false,
    yellowStripe: true,
  },
  {
    name: 'Payments',
    href: '/settings/payments',
    showNavigation: false,
    yellowStripe: true,
  },
  {
    href: '/settings/team',
    name: 'Team',
    showNavigation: false,
    yellowStripe: true,
  },
  {
    href: '/settings/employer-invites',
    name: 'Employer invites',
    showNavigation: false,
    yellowStripe: true,
  },
];

const MainLayout: FC<{ className?: string }> = ({ children, className }) => (
  <SettingsLayout navigation={navBarItems} className={className}>
    {children}
  </SettingsLayout>
);

export default MainLayout;
