import { startOfDay } from 'date-fns';
import * as Yup from 'yup';

const today = startOfDay(new Date(new Date().toISOString()));

const CandidateAcceptSchema = Yup.object().shape({
  expectedFirstYearSalary: Yup.number()
    .positive('Must be a positive number')
    .min(40000, 'Minimum of 40,000')
    .required('This field is required'),
  expectedFirstYearShareCount: Yup.number()
    .nullable(true)
    .integer('Must be an integer'),
  expectedStartDate: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .required('Start date is required')
    .min(today, 'Start date must be in the future'),
  file: Yup.string().required('Offer letter is required'),
});

export default CandidateAcceptSchema;
