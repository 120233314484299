import type { CompanyStageData } from '@headrace/types';
import { useMemo } from 'react';

import ErrorMessageBox from '../../ErrorMessageBox';
import { ComparativeBarGraph } from '../../graphs';
import LoadingSpinner from '../../LoadingSpinner';
import ProfileCard from '../ProfileCard';

interface Props {
  companyStages: CompanyStageData[];
  loading?: boolean;
  error?: string | null;
}

const ClientCompanyStage: React.VFC<Props> = ({
  companyStages,
  loading,
  error,
}) => {
  const emptyState = useMemo(() => {
    if (companyStages.length) {
      return companyStages.every((companyStage) => companyStage.count === 0);
    }
    return false;
  }, [companyStages]);

  const getLabels = (): Array<string[] | string> =>
    companyStages.map((item) => {
      if (item.name) {
        return item.name.split(' ');
      }
      return '';
    });

  const getValues = (): number[] =>
    companyStages.map((item) => item.count || 0);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessageBox error={error} />;

  return (
    <ProfileCard className="overflow-visible lg:h-[450px]">
      <ComparativeBarGraph
        titleGraph="Client company stage"
        titleClassName="self-start !text-2xl"
        labels={getLabels()}
        values={getValues()}
        colors={['#ffa300']}
        className="w-full"
        emptyState={!emptyState}
        loading={loading}
        error={error}
        barThickness={133}
        displayBarValues={false}
        headerClassName="mb-0 gap-0"
      />
    </ProfileCard>
  );
};

export default ClientCompanyStage;
