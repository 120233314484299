import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';

import Dots from './Dots';

interface Props {
  elements: React.ReactNode[];
  interval?: number;
  dots?: boolean;
  navigation?: boolean;
  className?: string;
}

const WavySlider: VFC<Props> = ({
  elements,
  interval = 4000,
  dots = false,
  navigation = false,
  className,
}) => {
  let count = 0;
  let slideInterval: number;
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideRef = useRef<HTMLDivElement>(null);

  const removeAnimation = (): void => {
    slideRef?.current?.classList.remove('fade-anim');
  };

  const handleOnNextClick = (): void => {
    const productsLength = elements.length;
    count = (currentIndex + productsLength + 1) % productsLength;
    setCurrentIndex(count);
    slideRef.current?.classList.add('fade-anim');
  };
  const handleOnPrevClick = (): void => {
    const productsLength = elements.length;
    count = (currentIndex + productsLength - 1) % productsLength;
    setCurrentIndex(count);
    slideRef.current?.classList.add('fade-anim');
  };

  const handleOnNext = (): void => {
    count = (count + 1) % elements.length;
    setCurrentIndex(count);
    slideRef.current?.classList.add('fade-anim');
  };

  const handleOnSelect = (index: number): void => {
    setCurrentIndex(index);
    slideRef.current?.classList.add('fade-anim');
  };

  const startSlider = (): void => {
    slideInterval = window.setInterval(() => {
      handleOnNext();
    }, interval);
  };

  const pauseSlider = (): void => {
    clearInterval(slideInterval);
  };

  useEffect(() => {
    slideRef.current?.addEventListener('animationend', removeAnimation);
    slideRef.current?.addEventListener('mouseenter', pauseSlider);
    slideRef.current?.addEventListener('mouseleave', startSlider);

    startSlider();
    return (): void => {
      pauseSlider();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={slideRef}
      className={classNames('w-full select-none relative h-full', className)}
    >
      <div className="w-full h-full relative">
        <div className="absolute top-0 left-0 w-full h-full sm:hidden md:hidden hidden lg:block">
          <svg
            width="1101"
            height="250"
            viewBox="0 0 1101 250"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M955.137 84.8423C921.997 27.4914 858.478 3.05123 830.862 -2H-56V290H1101V258C1066.19 251.395 988.277 142.193 955.137 84.8423Z" />
          </svg>
        </div>
        <div className="absolute top-0 left-0 w-full h-full lg:hidden">
          <svg
            width="400"
            height="600"
            viewBox="0 0 400 600"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M 235 212.9 C 195.4 68.4 119.6 6.8 86.7 -6 H -79 V 730 H 409 V 649.4 C 367.5 632.8 274.5 357.4 235 212.9 Z" />
          </svg>
        </div>
        <div className="relative">{elements[currentIndex]}</div>
      </div>
      {dots && (
        <Dots
          total={elements.length}
          currentIndex={currentIndex}
          handleOnSelect={handleOnSelect}
        />
      )}

      {navigation && (
        <div className="absolute w-full top-1/2 transform -translate-y-1/2 px-3 flex justify-between items-center">
          <button
            type="button"
            className=" text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition flex items-center justify-center outline-none focus:outline-none"
            onClick={handleOnPrevClick}
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          <button
            type="button"
            className="text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition outline-none focus:outline-none"
            onClick={handleOnNextClick}
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default WavySlider;
