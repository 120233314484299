import * as Yup from 'yup';

const SettingsRecruiterForm = Yup.object().shape({
  jobFunctions: Yup.array()
    .of(Yup.string())
    .min(1, '1 function as minimum')
    .max(3, 'You can only select 3 functions')
    .required('Job functions are required'),
  citiesOfFocus: Yup.array()
    .of(Yup.string())
    .min(1, '1 city as minimum')
    .required('Cities of focus are required'),
  range: Yup.object().shape({
    min: Yup.number().min(10, 'Minimum range must be at least 10'),
    max: Yup.number().max(50),
  }),
});

export default SettingsRecruiterForm;
