import type { VFC } from 'react';

interface Props {
  className?: string;
}

const NoImageIcon: VFC<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 100 100"
    x="0px"
    y="0px"
    className={className}
  >
    <path
      d="M96,17H4V83H96Zm-4,4V69.59L72.37,49.44,58.94,61,36.86,43.72,8,72V21ZM8,79V77.58L37.15,49,59.06,66.16,72.13,54.93,92,75.32V79Z"
      fill="currentColor"
    />
    <path
      d="M78.33,45a10,10,0,1,0-10-10A10,10,0,0,0,78.33,45Zm0-16a6,6,0,1,1-6,6A6,6,0,0,1,78.33,29Z"
      fill="currentColor"
    />
  </svg>
);

export default NoImageIcon;
