import type { ProfileBasicDataType } from '@headrace/types';
import { SearchPreferenceEnum } from '@headrace/types';
import { capitalizeString } from '@headrace/utils';
import {
  DocumentTextIcon,
  PencilIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import ContactLinks from '../../ContactCard/ContactLinks';
import { AvatarPlaceholderIcon } from '../../CustomIcons';
import type { OptionsProps } from '../../forms';
import { BasicSelect, SliderInput } from '../../forms';
import type { CreateMediaUploadLinkFields } from '../../ImageUpload';
import MiniDivider from '../../MiniDivider';
import type { BasicFormValues } from './EditMode/BasicData';
import BasicData, { BasicFormSchema } from './EditMode/BasicData';

interface ProfileBasicDataProps extends ProfileBasicDataType {
  cityOptions?: OptionsProps[];
  functionsOptions?: OptionsProps[];
  createMediaUploadLink?: CreateMediaUploadLinkFields;
  saveData?: {
    onSubmit: (values: BasicFormValues) => void;
    loading: boolean;
  };
  editMode?: boolean;
}

const ProfileBasicData: React.VFC<ProfileBasicDataProps> = (props) => {
  const {
    profileImage,
    profileImageKey,
    name,
    fullName,
    agency,
    roleFunctions,
    location,
    locationId,
    title,
    email,
    phone,
    website,
    linkedIn,
    maxPricePercent,
    minPricePercent,
    schedulingLink15Min,
    schedulingLink30Min,
    schedulingLink60Min,
    avalaibleRequestProposal,
    publicProfile = false,
    searchPreference,
    cityOptions,
    functionsOptions,
    createMediaUploadLink,
    saveData,
    editMode = false,
  } = props;

  const [selectValue, setSelectValue] = useState<OptionsProps>();
  const [edit, setEdit] = useState<boolean>(false);

  const getOptionsSelect = (): OptionsProps[] => {
    const optionsSelect = [];
    if (schedulingLink15Min) {
      optionsSelect.push({ label: '15 minutes', value: schedulingLink15Min });
    }
    if (schedulingLink30Min) {
      optionsSelect.push({ label: '30 minutes', value: schedulingLink30Min });
    }
    if (schedulingLink60Min) {
      optionsSelect.push({ label: '60 minutes', value: schedulingLink60Min });
    }
    return optionsSelect;
  };

  const handleChangeSelect = (
    item: MultiValue<OptionsProps> | SingleValue<OptionsProps>
  ): void => {
    if (item) {
      const newItem = item as OptionsProps;
      setSelectValue(newItem);
      window.open(newItem.value, '_blank');
    }
  };

  const showschedulingSelect =
    (schedulingLink15Min || schedulingLink30Min || schedulingLink60Min) &&
    !avalaibleRequestProposal;

  const searchPreferenceValue = useMemo(() => {
    if (searchPreference === SearchPreferenceEnum.BOTH) {
      return `${capitalizeString(
        SearchPreferenceEnum.CONTINGENT.toLowerCase()
      )} | ${capitalizeString(
        SearchPreferenceEnum.RETAINED.toLocaleLowerCase()
      )}`;
    }
    return searchPreference
      ? capitalizeString(searchPreference.toLowerCase())
      : null;
  }, [searchPreference]);

  const initialValues = useMemo((): BasicFormValues => {
    const max = maxPricePercent ?? 100;
    return {
      photo: profileImageKey ?? '',
      priceRange: {
        max: max > 50 ? 50 : max,
        min: minPricePercent ?? 0,
      },
      firstName: fullName?.firstName ?? '',
      lastName: fullName?.lastName ?? '',
      linkedIn: linkedIn ?? '',
      location: locationId ?? null,
      title: title ?? '',
      roleFunctions: roleFunctions
        ? roleFunctions.map((rf) => rf?.id ?? '')
        : [],
      retainedSearch:
        searchPreference === SearchPreferenceEnum.RETAINED ||
        searchPreference === SearchPreferenceEnum.BOTH,
      contingentSearch:
        searchPreference === SearchPreferenceEnum.CONTINGENT ||
        searchPreference === SearchPreferenceEnum.BOTH,
    };
  }, [
    fullName?.firstName,
    fullName?.lastName,
    linkedIn,
    locationId,
    maxPricePercent,
    minPricePercent,
    profileImageKey,
    roleFunctions,
    searchPreference,
    title,
  ]);

  if (edit && saveData)
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values): void => {
          saveData.onSubmit(values);
          setEdit(false);
        }}
        validationSchema={BasicFormSchema}
        enableReinitialize
      >
        <Form>
          <BasicData
            profileImg={profileImage || ''}
            cityOptions={cityOptions || []}
            functionsOptions={functionsOptions || []}
            createMediaUploadLink={createMediaUploadLink}
            setEdit={setEdit}
            loading={saveData.loading}
          />
        </Form>
      </Formik>
    );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-6 gap-6 bg-white shadow px-12 sm:rounded-lg py-10 h-full">
      <div className="sm:col-span-2 flex flex-col justify-center items-center gap-4">
        {profileImage ? (
          <Image
            className="rounded-full w-56 h-56"
            src={profileImage}
            alt="user-profile-image"
            width={showschedulingSelect ? 224 : 190}
            height={showschedulingSelect ? 224 : 190}
          />
        ) : (
          <div className="relative">
            <AvatarPlaceholderIcon className="fill-gray-300 rounded-full" />
          </div>
        )}
        {!publicProfile && (
          <ContactLinks
            email={email || ''}
            linkedIn={linkedIn}
            phone={phone}
            website={website}
          />
        )}
        {showschedulingSelect && !publicProfile && (
          <BasicSelect
            name="schedule"
            id="schedule"
            isMulti={false}
            options={getOptionsSelect()}
            onChange={(value): void => {
              handleChangeSelect(value);
            }}
            value={selectValue}
            className="text-headraceBlack-700 text-sm font-medium"
            placeholder="Schedule a meeting"
            showIconDropdown
            showIndicatorSeparator
          />
        )}
      </div>
      <div className="sm:col-span-4 flex flex-col gap-y-2 text-headraceBlack-800 justify-center items-center sm:items-start">
        <div className="flex justify-center sm:justify-start w-full items-center relative">
          <h1 className="text-2xl font-bold ">{name}</h1>
          {editMode && (
            <button
              className="py-1 top-0 right-0 absolute"
              type="button"
              onClick={(): void => {
                setEdit(true);
              }}
            >
              <PencilIcon className="w-5 text-blue-500" />
            </button>
          )}
        </div>
        <div className="text-base font-normal text-gray-500">{title}</div>
        <MiniDivider />
        {location || agency ? (
          <div className="text-base font-medium text-gray-500">
            {publicProfile ? (
              location
            ) : (
              <>
                {agency} {location && ` | ${location}`}
              </>
            )}
          </div>
        ) : null}
        <div className="flex justify-start mt-2 gap-2">
          <div>
            <SearchIcon className="w-5 h-5 stroke-headraceYellow-700" />
          </div>
          <div className="text-sm text-gray-500 ">
            <div className="font-medium">Recruiting focus</div>
            <div>
              {roleFunctions &&
                roleFunctions.map((roleFunction, index) => (
                  <>
                    <span key={roleFunction.id}> {roleFunction?.name} </span>
                    {index + 1 !== roleFunctions.length && <span>|</span>}
                  </>
                ))}
            </div>
          </div>
        </div>
        {searchPreferenceValue && (
          <div className="flex justify-start mt-2 gap-2">
            <div>
              <DocumentTextIcon className="w-5 h-5 stroke-headraceYellow-700" />
            </div>
            <div className="text-sm text-gray-500">
              <div className="font-medium">Search preference</div>
              {searchPreferenceValue}
            </div>
          </div>
        )}
        {maxPricePercent && minPricePercent && (
          <div className="mt-2 w-full">
            <SliderInput
              min={0}
              max={50}
              name="range"
              label="Placement fee range"
              className="w-full"
              step={1}
              disabled
              value={{
                max: maxPricePercent > 50 ? 50 : maxPricePercent,
                min: minPricePercent,
              }}
              formatLabel={(value): string => `${value}%`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileBasicData;
