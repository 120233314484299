import { Button, Checkbox, SliderInput } from '@headrace/ui';
import MultiSelect from '@headrace/ui/src/forms/fields/MultiSelect';
import { currencyFormatter } from '@headrace/utils';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { EnrichmentOptions } from '@/lib/hooks/useRoleEnrichmentOptions';

import type { MarketplaceFilters } from '.';

export interface FilterFieldsReturnType {
  functionFilters: JSX.Element;
  companyStageFilters: JSX.Element;
  locationFilters: JSX.Element;
  roleLevelFilters: JSX.Element;
  salaryRangeFilters: JSX.Element;
  placementFeeFilters: JSX.Element;
}

interface FilterFieldsProps {
  filters: MarketplaceFilters;
  setFilters: Dispatch<SetStateAction<MarketplaceFilters>>;
  options: EnrichmentOptions | null;
}

const useFilterFields = (props: FilterFieldsProps): FilterFieldsReturnType => {
  const { filters, setFilters, options } = props;
  const [hideRoleLevels, setHideRoleLevels] = useState(true);

  const handleCompanyStageCheck = useCallback(
    (stageName: keyof MarketplaceFilters['companyStage']) => {
      setFilters((prev) => ({
        ...prev,
        companyStage: {
          ...prev.companyStage,
          [stageName]: !prev.companyStage[stageName],
        },
      }));
    },
    [setFilters]
  );

  const handleRoleLevelCheck = useCallback(
    (roleLevelId: string) => {
      setFilters((prev) => ({
        ...prev,
        roleLevel: {
          ...prev.roleLevel,
          [roleLevelId]: !prev.roleLevel[roleLevelId],
        },
      }));
    },
    [setFilters]
  );

  const roleLevelOptions = useMemo(() => {
    if (hideRoleLevels && options) {
      return options.roleSeniorityOptions.slice(0, 6);
    }
    return options?.roleSeniorityOptions ?? [];
  }, [hideRoleLevels, options]);

  return {
    companyStageFilters: (
      <div>
        <div className="flex justify-between py-4 border-t-[1px] border-t-gray-200">
          <p className="font-medium text-sm text-gray-700">Early stage</p>
          <Checkbox
            name="earlyStageFilter"
            checked={filters.companyStage['Early stage']}
            onChange={(): void => {
              handleCompanyStageCheck('Early stage');
            }}
          />
        </div>
        <div className="flex justify-between py-4 border-t-[1px] border-t-gray-200">
          <p className="font-medium text-sm text-gray-700">Growth stage</p>
          <Checkbox
            name="growthStageFilter"
            checked={filters.companyStage['Growth stage']}
            onChange={(): void => {
              handleCompanyStageCheck('Growth stage');
            }}
          />
        </div>
        <div className="flex justify-between py-4 border-t-[1px] border-t-gray-200">
          <p className="font-medium text-sm text-gray-700">Late stage</p>
          <Checkbox
            name="lateStageFilter"
            checked={filters.companyStage['Late stage']}
            onChange={(): void => {
              handleCompanyStageCheck('Late stage');
            }}
          />
        </div>
      </div>
    ),
    functionFilters: (
      <MultiSelect
        name="functions"
        selectOptions={options?.roleFunctionOptions}
        value={filters.function}
        validateSelect={(): boolean => true}
        onChangeSelect={(value): void => {
          setFilters((prev) => ({ ...prev, function: value }));
        }}
      />
    ),
    locationFilters: (
      <MultiSelect
        name="location"
        selectOptions={options?.cityOptions}
        value={filters.location}
        validateSelect={(): boolean => true}
        onChangeSelect={(value): void => {
          setFilters((prev) => ({ ...prev, location: value }));
        }}
      />
    ),
    placementFeeFilters: (
      <>
        <p className="flex justify-between font-normal text-xs leading-6 text-headraceBlack-800 mb-2">
          <span>{`${filters.placementFee.min}%`}</span>
          <span>{`${filters.placementFee.max}%`}</span>
        </p>
        <SliderInput
          name="placementFee"
          label=""
          min={0}
          max={50}
          step={1}
          value={filters.placementFee}
          onChange={(value): void => {
            if (typeof value !== 'number') {
              setFilters((prev) => ({ ...prev, placementFee: value }));
            }
          }}
          hideLabels
        />
      </>
    ),
    roleLevelFilters: (
      <>
        {roleLevelOptions.map((item) => (
          <div
            key={item.label}
            className="flex justify-between py-4 border-t-[1px] border-t-gray-200"
          >
            <p className="font-medium text-sm text-gray-700">{item.label}</p>
            <Checkbox
              name={item.value}
              checked={filters.roleLevel[item.value] ?? false}
              onChange={(): void => {
                handleRoleLevelCheck(item.value);
              }}
            />
          </div>
        ))}
        {options && options.roleSeniorityOptions.length > 6 && (
          <div className="flex justify-between py-4 border-t-[1px] border-t-gray-200">
            <Button
              buttonType="link"
              className="!m-0 font-normal text-blue-500 text-sm"
              onClick={(): void => {
                setHideRoleLevels(!hideRoleLevels);
              }}
            >
              Show {`${hideRoleLevels ? 'more' : 'less'}`}
            </Button>
          </div>
        )}
      </>
    ),
    salaryRangeFilters: (
      <>
        <p className="flex justify-between font-normal text-xs leading-6 text-headraceBlack-800 mb-2">
          <span>{`${currencyFormatter({ notation: 'compact' }).format(
            filters.salaryRange.min
          )}`}</span>
          <span>{`${currencyFormatter({ notation: 'compact' }).format(
            filters.salaryRange.max
          )}${filters.salaryRange.max === 300000 ? '+' : ''}`}</span>
        </p>
        <SliderInput
          name="salaryRange"
          label=""
          min={0}
          max={300000}
          step={1000}
          value={filters.salaryRange}
          onChange={(value): void => {
            if (typeof value !== 'number') {
              setFilters((prev) => ({ ...prev, salaryRange: value }));
            }
          }}
          hideLabels
        />
      </>
    ),
  };
};

export default useFilterFields;
