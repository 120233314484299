import './Tooltip.css';

import Link from 'next/link';

import { LongArrowRightIcon } from '../CustomIcons';
import MiniDivider from '../MiniDivider';

interface Props {
  title: string;
  description: string;
  button: { label: string; action: string | (() => void) };
}

const BillboardCard: React.VFC<Props> = ({ title, description, button }) => (
  <div className="text-white flex flex-col gap-4 font-normal text-base justify-between py-8 sm:px-16 px-14 h-[530px] lg:h-60 sm:h-28 md:h-[360px]">
    <span className="text-2xl font-medium">{title}</span>
    <MiniDivider />
    <span className="font-normal text-lg">{description}</span>
    {typeof button.action === 'function' ? (
      <button
        type="button"
        onClick={button.action}
        className="flex justify-center items-center rounded-tl-lg bg-white w-fit h-10 text-headraceBlack-800 px-4 text-base"
      >
        <div className="flex items-center justify-center gap-2">
          {button.label} <LongArrowRightIcon />
        </div>
      </button>
    ) : (
      <Link href={button.action} passHref>
        <div className="flex justify-center rounded-tl-lg cursor-pointer bg-white w-fit h-10 text-headraceBlack-800 px-4 text-base">
          <a className="flex items-center justify-center gap-2">
            <span>{button.label}</span> <LongArrowRightIcon />
          </a>
        </div>
      </Link>
    )}
  </div>
);

export default BillboardCard;
