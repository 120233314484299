/* eslint-disable react/jsx-props-no-spreading */
import { PlusIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { FieldArray, Form, Formik } from 'formik';
import type { Dispatch, SetStateAction, VFC } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '../../../Button';
import CardWithHeader from '../../../CardWithHeader';
import EmptyStateIllustration from '../../../EmptyStateIllustration';
import { SubmitButton } from '../../../forms';
import type { EndorsementForm } from './EndorsementCardForm';
import EndorsementCardForm from './EndorsementCardForm';

export interface EndorsementValues {
  endorsements: EndorsementForm[];
}

interface Props {
  endorsements: EndorsementForm[];
  onSubmit?: (values: EndorsementValues) => void;
  loading?: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const EndorsementsForm: VFC<Props> = ({
  endorsements,
  onSubmit,
  loading,
  setEdit,
  setLoading,
}) => {
  const BioFormSchema = Yup.object().shape({
    endorsements: Yup.array().of(
      Yup.object().shape({
        endorserTitle: Yup.string().required('Endorser title is required'),
        endorserCompany: Yup.string().required('Endorser company is required'),
        endorsement: Yup.string()
          .max(700, 'Endorsement must be less than 700 characters')
          .required('Endorsement is required'),
      })
    ),
  });

  const initialValues = {
    id: uuidv4(),
    endorserTitle: '',
    endorserCompany: '',
    endorsement: '',
    order: 1,
  };

  return (
    <Formik
      initialValues={{
        endorsements: endorsements.length > 0 ? endorsements : [initialValues],
      }}
      onSubmit={(vals): void => {
        setLoading(true);
        if (onSubmit) onSubmit(vals);
        setEdit(false);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }}
      validationSchema={BioFormSchema}
      enableReinitialize
    >
      {({ values }): JSX.Element => (
        <Form>
          <FieldArray name="endorsements" validateOnChange={false}>
            {({ push, remove, move }): JSX.Element => (
              <DragDropContext
                onDragEnd={(result: DropResult): void => {
                  const { source, destination } = result;

                  // if the user drops outside of a droppable destination
                  if (!destination) return;

                  // If the user drags and drops back in the same position
                  if (
                    destination.droppableId === source.droppableId &&
                    destination.index === source.index
                  ) {
                    return;
                  }

                  move(source.index, destination.index);
                }}
              >
                <CardWithHeader
                  className="mb-6 h-full"
                  title="Endorsements"
                  removeChildrenXPadding
                  removeChildrenYPadding
                  rightContent={
                    <div className="h-5 flex items-center gap-4">
                      <Button
                        buttonType="secondary"
                        className="!py-2"
                        onClick={(): void => setEdit(false)}
                      >
                        Cancel
                      </Button>
                      <SubmitButton className="!py-2" loading={loading}>
                        Save
                      </SubmitButton>
                    </div>
                  }
                >
                  <div className="divide-y divide-gray-200">
                    {values.endorsements.length ? (
                      <>
                        <Droppable droppableId="droppable">
                          {(droppableProvided): JSX.Element => (
                            <div
                              {...droppableProvided.droppableProps}
                              ref={droppableProvided.innerRef}
                              className="divide-y divide-gray-200"
                            >
                              {values.endorsements.map((endorsement, index) => (
                                <Draggable
                                  key={endorsement.id}
                                  draggableId={endorsement.id}
                                  index={index}
                                >
                                  {(
                                    draggableProvided,
                                    draggableSnapshot
                                  ): JSX.Element => (
                                    <div
                                      {...draggableProvided.dragHandleProps}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                      }}
                                      ref={draggableProvided.innerRef}
                                      className={classNames(
                                        'bg-white rounded-b-lg',
                                        {
                                          'opacity-80 border border-gray-200 shadow':
                                            draggableSnapshot.isDragging,
                                        }
                                      )}
                                    >
                                      <EndorsementCardForm
                                        index={index}
                                        deleteEndorsement={(): void =>
                                          remove(index)
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <div className="p-10">
                          <button
                            type="button"
                            className="py-6 flex flex-col justify-center items-center border-2 border-dashed border-gray-200 rounded-md w-full"
                            onClick={(): void => {
                              push(initialValues);
                            }}
                          >
                            <div className="flex flex-col items-center">
                              <div className="flex items-center gap-2">
                                <PlusIcon className="w-5 h-5 text-gray-400" />
                                <span className="whitespace-nowrap text-headraceBlack-700 font-medium text-sm">
                                  Add endorsements
                                </span>
                              </div>
                              <span className="whitespace-nowrap text-gray-500 font-noraml text-sm">
                                Add an endorsement from a past clients,
                                employers, or candidates.
                              </span>
                            </div>
                          </button>
                        </div>
                      </>
                    ) : (
                      <EmptyStateIllustration
                        button={{
                          label: 'Add Endorsement',
                          onClick: (): void => push(initialValues),
                        }}
                        title="Add endorsements from clients or colleagues"
                        description={
                          <div>
                            We think you&lsquo;re pretty awesome, so we know
                            others think the same! Add endorsements sharing what
                            others think of working with you.
                          </div>
                        }
                        image="/illustrations/profileEmptyState/endorsements-illustration.svg"
                        showSeparator={false}
                        descriptionClassName="w-full"
                      />
                    )}
                  </div>
                </CardWithHeader>
              </DragDropContext>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default EndorsementsForm;
