import type { CommonPlacedIndustriesData } from '@headrace/types';

import { EarningsEmptyState } from '../../Earnings';
import EllipsisText from '../../EllipsisText';
import ErrorMessageBox from '../../ErrorMessageBox';
import LoadingSpinner from '../../LoadingSpinner';
import ProfileCard from '../ProfileCard';

interface Props {
  commonPlacedIndustries: Partial<CommonPlacedIndustriesData>[];
  loading?: boolean;
  error?: string | null;
}

const CommonPlacedIndustries: React.VFC<Props> = ({
  commonPlacedIndustries,
  loading,
  error,
}) => {
  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessageBox error={error} />;

  return (
    <ProfileCard className="lg:h-full overflow-visible lg:h-[450px]">
      <div className="flex flex-col text-headraceBlack-800 text-2xl h-full">
        <div className="font-bold">Most placed industries</div>
        {commonPlacedIndustries.length <= 0 && (
          <div className="flex items-center justify-center h-full mt-10">
            <EarningsEmptyState />
          </div>
        )}
        {commonPlacedIndustries.map((industry) => (
          <div key={industry.id} className="flex justify-between mt-9">
            <EllipsisText text={industry.name || ''} />
            <div className="bg-headraceYellow-700 ml-2 rounded-[30px] min-w-[54px] h-full inline-block text-center ">
              {industry.placementsCount}
            </div>
          </div>
        ))}
      </div>
    </ProfileCard>
  );
};

export default CommonPlacedIndustries;
