import { BASIC_URL } from '@headrace/constants';
import * as Yup from 'yup';

const SchedulingSchema = Yup.object().shape({
  schedulingLink15Min: Yup.string()
    .matches(BASIC_URL, 'Invalid URL')
    .nullable(),
  schedulingLink30Min: Yup.string()
    .matches(BASIC_URL, 'Invalid URL')
    .nullable(),
  schedulingLink60Min: Yup.string()
    .matches(BASIC_URL, 'Invalid URL')
    .nullable(),
});

export default SchedulingSchema;
