import classNames from 'classnames';
import Link from 'next/link';
import type { VFC } from 'react';

export interface Timeline {
  id: string;
  content: string;
  target: string;
  href: string;
  date: string;
  icon: React.ElementType;
  iconBackground: string;
  extra?: string;
}

interface SimpleFeedProps {
  timeline: Timeline[];
  className?: string;
}

const SimpleFeed: VFC<SimpleFeedProps> = ({ timeline, className }) => (
  <div className={classNames('flow-root', className)}>
    <ul className="-mb-8">
      {timeline.map((event, eventIdx) => (
        <li key={event.id}>
          <div className="relative pb-8">
            {eventIdx !== timeline.length - 1 ? (
              <span
                className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={classNames(
                    event.iconBackground,
                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                  )}
                >
                  <event.icon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p className="text-sm text-gray-500">
                    {event.content}{' '}
                    <Link href={event.href} passHref>
                      <a className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </Link>{' '}
                    {event.extra}
                  </p>
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  {event.date}
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default SimpleFeed;
