import type { VFC } from 'react';

interface Props {
  className?: string;
}

const LongArrowRightIcon: VFC<Props> = ({ className }) => (
  <span className={className}>
    <svg
      width="31"
      height="10"
      viewBox="0 0 31 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7071 0.292908L30.7071 4.29291C31.0976 4.68343 31.0976 5.3166 30.7071 5.70712L26.7071 9.70712L25.2929 8.29291L27.5858 6.00001H0V4.00001H27.5858L25.2929 1.70712L26.7071 0.292908Z"
        fill="#FFA300"
      />
    </svg>
  </span>
);

export default LongArrowRightIcon;
