import type { PlacementData } from '@headrace/types';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Button from '../../../Button';
import EmptyStateIllustration from '../../../EmptyStateIllustration';
import type { CreateMediaUploadLinkFields } from '../../../ImageUpload';
import SearchBar from '../../../SearchBar';
import type { PlacementForm } from '../EditMode/PlacementFormModal';
import PlacementFormModal from '../EditMode/PlacementFormModal';
import type { FormOptions, PlacementFormProps } from '../ProfileLayout';
import Filter from './Filter';
import FilterHideableSidebar from './FilterHideableSidebar';
import HistoryCard from './HistoryCard';
import Pagination from './Pagination';

interface Props {
  placements: PlacementData[];
  entriesPerPage?: number;
  editMode?: boolean;
  formOptions?: FormOptions;
  placementFormProps?: PlacementFormProps;
  createMediaUploadLink?: CreateMediaUploadLinkFields;
}

const PlacementHistory: VFC<Props> = ({
  placements,
  entriesPerPage = 10,
  editMode = false,
  formOptions,
  placementFormProps,
  createMediaUploadLink,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [paginatedData, setPaginatedData] = useState<PlacementData[]>([]);
  const [filteredData, setFilteredData] = useState<PlacementData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectLevel, setSelectLevel] = useState<string[]>([]);
  const [selectFunctions, setSelectFunctions] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [placementToEdit, setPlacementToEdit] = useState<PlacementForm | null>(
    null
  );
  const [totalPages, setTotalPages] = useState(
    Math.floor(
      entriesPerPage ? Math.ceil(placements.length / entriesPerPage) : 1
    )
  );
  const [openSidebarFilter, setOpenSidebarFilter] = useState(false);
  const changeToPage = (index: number): void => {
    setCurrentPage(index);
    scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const calculateTotalPages = useCallback(
    (dataLength: number): void => {
      if (!entriesPerPage) return;
      setTotalPages(Math.ceil(dataLength / entriesPerPage));
    },
    [entriesPerPage]
  );

  const filters = useMemo(() => {
    const levels: string[] = [];
    const functions: string[] = [];
    placements.forEach((placement) => {
      if (placement.roleSeniority && !levels.includes(placement.roleSeniority))
        levels.push(placement.roleSeniority);
      if (placement.roleFunction && !functions.includes(placement.roleFunction))
        functions.push(placement.roleFunction);
    });
    return {
      levels,
      functions,
    };
  }, [placements]);

  useEffect(() => {
    const start = (currentPage - 1) * entriesPerPage;
    const end = currentPage * entriesPerPage;
    setPaginatedData(filteredData.slice(start, end));
  }, [currentPage, entriesPerPage, setPaginatedData, filteredData]);

  useEffect(() => {
    if (
      selectFunctions.length === 0 &&
      selectLevel.length === 0 &&
      searchValue === ''
    ) {
      calculateTotalPages(placements.length);
      setFilteredData(placements);
      return;
    }
    let filteredPlacements = placements;
    if (selectFunctions.length > 0) {
      filteredPlacements = filteredPlacements.filter((placement) =>
        selectFunctions.includes(placement.roleFunction || '')
      );
    }
    if (selectLevel.length > 0) {
      filteredPlacements = filteredPlacements.filter((placement) =>
        selectLevel.includes(placement.roleSeniority)
      );
    }
    if (searchValue !== '') {
      filteredPlacements = filteredPlacements.filter((placement) =>
        [
          placement.roleTitle || '',
          placement.company.name || '',
          placement.roleFunction || '',
          placement.roleSeniority,
        ].some((value) =>
          value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
    calculateTotalPages(filteredPlacements.length);
    setFilteredData(filteredPlacements);
  }, [
    setFilteredData,
    calculateTotalPages,
    selectFunctions,
    selectLevel,
    placements,
    searchValue,
  ]);
  const emptyState = useMemo(() => {
    if (editMode) {
      return (
        <EmptyStateIllustration
          button={{
            label: 'Add Placement',
            onClick: (): void => {
              setOpenEditModal(true);
              setPlacementToEdit(null);
            },
          }}
          title="Add your past placements"
          description={
            <div>
              Add <b>at least 10</b> past placements to generate placements
              stats for your
              <br /> employer-facing profile.
            </div>
          }
          image="/illustrations/profileEmptyState/placements-illustration.svg"
          showSeparator={false}
          descriptionClassName="w-full"
        />
      );
    }
    return null;
  }, [editMode]);

  if (editMode || (!editMode && placements.length > 0)) {
    return (
      <>
        <div className="bg-white shadow rounded-lg divide-y divide-gray-200 text-headraceBlack-700 mb-6 pb-5">
          <div
            className="px-8 py-5 font-medium text-lg sm:flex justify-between grid grid-cols-1 gap-2 sm:gap-0 text-headraceBlack-700 w-full"
            ref={scrollRef}
          >
            <div className="w-full">
              Placement history{' '}
              <span className="italic text-sm text-gray-400 font-normal">
                2 years of history required
              </span>
            </div>

            {placements.length > 0 && (
              <div className="flex justify-end items-end gap-3 w-full ">
                <div className="text-gray-500 sm:block hidden">
                  <Filter
                    filterOptions={filters.levels}
                    selectedValues={selectLevel}
                    setSelectedValues={setSelectLevel}
                    placeholder="Filter by level"
                    counterName="levels"
                  />
                </div>
                <div className="text-gray-500 sm:block hidden">
                  <Filter
                    filterOptions={filters.functions}
                    selectedValues={selectFunctions}
                    setSelectedValues={setSelectFunctions}
                    placeholder="Filter by function"
                    counterName="functions"
                  />
                </div>
                <div className="h-9 w-48 text-gray-500">
                  <SearchBar
                    value={searchValue}
                    onChange={setSearchValue}
                    placeholder="Search placements"
                    className="w-full"
                  />
                </div>
                {editMode && (
                  <div className="flex gap-4 w-32 h-9">
                    <Button
                      size="sm"
                      className="w-fit"
                      onClick={(): void => {
                        setPlacementToEdit(null);
                        setOpenEditModal(true);
                      }}
                    >
                      Add Placement
                    </Button>
                  </div>
                )}
                <button
                  className="text-gray-500 sm:hidden block"
                  type="button"
                  onClick={(): void => setOpenSidebarFilter(true)}
                >
                  <AdjustmentsIcon
                    className={classNames('h-7 w-7', {
                      'text-headraceYellow-700':
                        selectFunctions.length > 0 || selectLevel.length > 0,
                    })}
                  />
                </button>
              </div>
            )}
            <FilterHideableSidebar
              open={openSidebarFilter}
              setOpen={setOpenSidebarFilter}
              setSelectedLevels={setSelectLevel}
              setSelectedFunctions={setSelectFunctions}
              functions={filters.functions}
              levels={filters.levels}
              selectedFunctions={selectFunctions}
              selectedLevels={selectLevel}
            />
          </div>
          <div className="text-headraceBlack-800 text-sm divide-y">
            {paginatedData.length
              ? paginatedData.map((placement) => (
                  <HistoryCard
                    key={placement.id}
                    placement={placement}
                    editMode={editMode}
                    setOpenModal={setOpenEditModal}
                    setPlacementToEdit={setPlacementToEdit}
                  />
                ))
              : emptyState}
          </div>
          {entriesPerPage && totalPages > 1 ? (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              changeToPage={changeToPage}
            />
          ) : null}
        </div>
        <PlacementFormModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          formOptions={formOptions}
          placementFormProps={placementFormProps}
          placement={placementToEdit}
          createMediaUploadLink={createMediaUploadLink}
        />
      </>
    );
  }
  return null;
};

export default PlacementHistory;
