/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Image from 'next/image';
import type { VFC } from 'react';

import ImageWithFallback from './ImageWithFallback';

interface Props {
  urlIcon: string;
  height: number;
  width: number;
}

const CompanyLogo: VFC<Props> = (props) => {
  const { urlIcon, height = 32, width = 32 } = props;

  if (urlIcon) {
    return (
      <ImageWithFallback
        className="object-contain"
        src={urlIcon}
        objectFit="contain"
        width={width}
        height={height}
        fallbackSrc="/placeholders/company_logo_placeholder.png"
      />
    );
  }
  return (
    <Image
      className="object-scale-down "
      src="/placeholders/company_logo_placeholder.png"
      alt="company-logo"
      objectFit="contain"
      width={width}
      height={height}
    />
  );
};

export default CompanyLogo;
