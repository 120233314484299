import { Form, Formik, useFormikContext } from 'formik';
import Image from 'next/image';
import type { Dispatch, SetStateAction, VFC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

import Button from '../../../Button';
import { NoImageIcon } from '../../../CustomIcons';
import { Input, Select, SubmitButton } from '../../../forms';
import type { CreateMediaUploadLinkFields } from '../../../ImageUpload';
import ImageUpload from '../../../ImageUpload';
import Modal from '../../../Modal';
import type { FormOptions } from '../ProfileLayout';
import type { PlacementForm } from './PlacementFormModal';

export interface CompanyFormValues {
  name: string;
  logo?: string;
  industry: string;
  companyStage: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  companyNames: string[];
  formOptions?: FormOptions;
  createEmployer?: (values: CompanyFormValues) => Promise<{
    id: string;
    companyStageId?: string | null;
  } | null>;
  loading?: boolean;
  createMediaUploadLink?: CreateMediaUploadLinkFields;
  setInitialValues: Dispatch<SetStateAction<PlacementForm>>;
}

const CreateEmployerModal: VFC<Props> = ({
  open,
  onClose,
  formOptions,
  companyNames,
  createEmployer,
  loading,
  createMediaUploadLink,
  setInitialValues,
}) => {
  const [logo, setLogo] = useState<string | null>(null);
  const { values: placementValues } = useFormikContext<PlacementForm>();

  const onCloseModal = (): void => {
    setLogo(null);
    onClose();
    setInitialValues(placementValues);
  };

  const onCloseAndSubmit = (): void => {
    setLogo(null);
    onClose();
  };

  const CreateEmployerSchema = Yup.object().shape({
    industry: Yup.string().required('Please select an industry'),
    companyStage: Yup.string().required('Company stage is required'),
  });

  const onSubmit = async (values: CompanyFormValues): Promise<void> => {
    if (createEmployer) {
      const response = await createEmployer(values);
      setInitialValues({
        ...placementValues,
        companyId: response?.id || '',
        companyStageId: response?.companyStageId || '',
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      title="Add new employer"
      className="sm:w-[528px] sm:max-w-[528px] self-center"
    >
      <Formik
        initialValues={{
          name: '',
          logo: '',
          industry: '',
          companyStage: '',
        }}
        validationSchema={Yup.object()
          .shape({
            name: Yup.string()
              .notOneOf(
                companyNames,
                'An employer matching this name is already available'
              )
              .required('Employer name is required'),
          })
          .concat(CreateEmployerSchema)}
        onSubmit={async (values: CompanyFormValues): Promise<void> => {
          await onSubmit(values);
          onCloseAndSubmit();
        }}
      >
        {({ setFieldValue }): JSX.Element => (
          <Form>
            <div className="flex flex-col gap-4">
              <div>
                <p className="block text-sm font-medium text-gray-900 mb-1">
                  Company logo{' '}
                  <span className="text-gray-400 font-normal italic">
                    Optional
                  </span>
                </p>
                <div className="relative w-full h-64 border border-gray-300 mb-2">
                  {logo ? (
                    <Image
                      src={logo}
                      alt="user-profile-image"
                      layout="fill"
                      objectFit="contain"
                    />
                  ) : (
                    <NoImageIcon className="h-64 w-full border border-gray-300 bg-gray-300 text-headraceBlack-700" />
                  )}
                </div>
                {createMediaUploadLink && (
                  <ImageUpload
                    getImageData={(image: string, key: string): void => {
                      setLogo(image);
                      setFieldValue('logo', key);
                    }}
                    aspectRatio={16 / 9}
                    label="Upload photo"
                    cropSize={{
                      width: 300,
                      height: 169,
                    }}
                    createMediaUploadLink={createMediaUploadLink}
                  />
                )}
              </div>
              <Input
                name="name"
                label="Employer name"
                errorClassName="!text-left"
              />
              <Select
                options={formOptions?.industryOptions || []}
                name="industry"
                placeholder="Select..."
                label="Industry"
                maxMenuHeight={165}
                errorClassName="!text-left"
              />
              <Select
                name="companyStage"
                placeholder="Select..."
                label="Company stage"
                options={formOptions?.companyStageOptions || []}
                errorClassName="!text-left"
              />
              <div className="flex justify-end gap-3">
                <Button buttonType="secondary" onClick={onCloseModal}>
                  Cancel
                </Button>
                <SubmitButton loading={loading}>Add</SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateEmployerModal;
