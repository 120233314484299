import { PencilIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import type { VFC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

import Button from '../../Button';
import CardWithHeader from '../../CardWithHeader';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import { RichTextEditor, SubmitButton } from '../../forms';
import OnlyReadRichTextEditor from '../../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import LoadingSpinner from '../../LoadingSpinner';
import ProfileCard from '../ProfileCard';

interface RecruiterBioProps {
  bioJSON?: string;
  bio?: string;
  editMode?: boolean;
  onSubmit?: (values: { bio: string; bioJSON: string }) => void;
  loading?: boolean;
}

const RecruiterBio: VFC<RecruiterBioProps> = ({
  bio,
  bioJSON,
  editMode,
  onSubmit,
  loading,
}) => {
  const [edit, setEdit] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const BioFormSchema = Yup.object().shape({
    bio: Yup.string()
      .required('Bio is required')
      .min(300, 'Bio must be at least 300 characters'),
  });

  if (editMode && !bio && !edit) {
    return loadingForm ? (
      <LoadingSpinner />
    ) : (
      <CardWithHeader className="mb-6" title="About this recruiter">
        <EmptyStateIllustration
          button={{
            label: 'Add bio',
            onClick: (): void => {
              setEdit(true);
            },
          }}
          title="Add your bio"
          description="
            Tell employers about you and your experience making great placements"
          image="/illustrations/profileEmptyState/bio-illustration.svg"
          showSeparator={false}
          descriptionClassName="w-full"
          className="!p-0"
        />
      </CardWithHeader>
    );
  }

  return (
    <ProfileCard className="mb-6 !p-0">
      <Formik
        initialValues={{
          bio: bio || '',
          bioJSON: bioJSON || '',
        }}
        onSubmit={(values): void => {
          setLoadingForm(true);
          if (onSubmit) onSubmit(values);
          setEdit(false);
          setTimeout(() => {
            setLoadingForm(false);
          }, 1000);
        }}
        validationSchema={BioFormSchema}
        enableReinitialize
      >
        <Form>
          {edit && (
            <div className="flex justify-between px-10 py-4 items-center border-b">
              <div className="text-xl font-medium text-headraceBlack-800 leading-6">
                About this recruiter
              </div>
              <div className="h-5 flex items-center gap-4">
                <Button
                  buttonType="secondary"
                  className="!py-2"
                  onClick={(): void => setEdit(false)}
                >
                  Cancel
                </Button>
                <SubmitButton className="!py-2" loading={loading}>
                  Save
                </SubmitButton>
              </div>
            </div>
          )}
          <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4 p-10">
            <div className="flex justify-between w-full items-center">
              {!edit && (
                <div className="text-2xl font-bold">About this recruiter</div>
              )}
              {editMode && !edit && (
                <button
                  className="py-1"
                  type="button"
                  onClick={(): void => {
                    setEdit(true);
                  }}
                >
                  <PencilIcon className="w-5 text-blue-500" />
                </button>
              )}
            </div>
            {edit ? (
              <RichTextEditor
                name="bio"
                nameJSON="bioJSON"
                inputClassName="mt-8"
                characterMinCount={300}
              />
            ) : (
              <OnlyReadRichTextEditor
                name="bio"
                json={bioJSON || ''}
                value={bio || ''}
              />
            )}
          </div>
        </Form>
      </Formik>
    </ProfileCard>
  );
};

export default RecruiterBio;
