import './Tooltip.css';

import type { MarketplaceRoleWithStatus } from '@headrace/types';
import {
  MarketplaceRoleRecruiterStatus,
  RemoteWorkPolicyEnumLabels,
} from '@headrace/types';
import {
  capitalizeString,
  currencyFormatter,
  formatBounty,
  numberFormatter,
  remoteWorkPolicyLabel,
} from '@headrace/utils';
import {
  CashIcon,
  ChartPieIcon,
  CheckIcon,
  ThumbDownIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { BriefcaseIcon, InformationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { format, formatDistance } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import TruncateMarkup from 'react-truncate-markup';

import Badge from '../Badge';
import Button from '../Button';
import Card from '../Card';
import LoadingSpinner from '../LoadingSpinner';

const roleTypeTooltip = {
  INVITE:
    'Invite is a marketplace role open <br/>to multiple recruiters with a <br/>standard placement fee',
  PUBLIC:
    'Public roles are open to the <br/>marketplace and can be priced as a <br/>fixed fee or a % of first year salary',
};

export interface MarketplaceRoleCardProps {
  className?: string;
  role: MarketplaceRoleWithStatus;
  onUpdateStatus: (
    status: MarketplaceRoleRecruiterStatus,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
}

const MarketplaceRoleCard: React.VFC<MarketplaceRoleCardProps> = (props) => {
  const { role, className, onUpdateStatus } = props;
  const [loading, setLoading] = useState(false);
  const [fallBackImage, setFallBackImage] = useState(false);
  const { push } = useRouter();

  const remoteWorkPolicyLabels = Object.entries(RemoteWorkPolicyEnumLabels);
  const remoteLabel = remoteWorkPolicyLabel(
    remoteWorkPolicyLabels,
    role.role.remoteWorkPolicy
  );
  const subtitle = useMemo(
    () =>
      `${role.role.employer.company.name}${
        role.role.cities && role.role.cities.length
          ? ` | ${role.role.cities
              .map(
                (location) => `${location.cityName}, ${location.stateAbbrev}`
              )
              .join(' | ')}`
          : ''
      }`,
    [role]
  );

  const buttons = useMemo<{
    bookmark: JSX.Element | null;
    notInterested: JSX.Element | null;
  }>(
    () => ({
      bookmark:
        role.status === MarketplaceRoleRecruiterStatus.INVITED ||
        role.status === MarketplaceRoleRecruiterStatus.NOT_INTERESTED ? (
          <Button
            disabled={loading}
            className={classNames('rounded-[100%] h-10', {
              'transition hover:bg-headraceYellow-700 border-headraceYellow-700':
                !loading,
              'bg-gray-100 border-gray-300': loading,
            })}
            buttonType="secondary"
            width="w-10"
            onClick={(): void => {
              setLoading(true);
              onUpdateStatus(
                MarketplaceRoleRecruiterStatus.BOOKMARKED,
                setLoading
              );
            }}
          >
            <CheckIcon
              className={classNames('w-[22px] stroke-[2]', {
                'stroke-gray-400': loading,
                'stroke-headraceBlack-800': !loading,
              })}
            />
          </Button>
        ) : null,
      notInterested:
        role.status === MarketplaceRoleRecruiterStatus.INVITED ||
        role.status === MarketplaceRoleRecruiterStatus.BOOKMARKED ? (
          <Button
            disabled={loading}
            className={classNames('rounded-[100%] h-10', {
              'transition hover:bg-headraceYellow-700 border-headraceYellow-700':
                !loading,
              'bg-gray-100 border-gray-300': loading,
            })}
            buttonType="secondary"
            width="w-10"
            onClick={(): void => {
              setLoading(true);
              onUpdateStatus(
                MarketplaceRoleRecruiterStatus.NOT_INTERESTED,
                setLoading
              );
            }}
          >
            <ThumbDownIcon
              style={{ transform: 'rotateY(3.142rad)' }}
              className={classNames('w-[22px] stroke-[2]', {
                'stroke-gray-400': loading,
                'stroke-headraceBlack-800': !loading,
              })}
            />
          </Button>
        ) : null,
    }),
    [loading, onUpdateStatus, role]
  );

  const formatPlacementFee = useMemo((): string => {
    if (role.searchInfo.placementFee && role.searchInfo.feeType)
      return formatBounty(
        role.searchInfo.placementFee,
        role.searchInfo.feeType
      );
    return '';
  }, [role.searchInfo.placementFee, role.searchInfo.feeType]);

  const roleImage = useMemo(() => {
    if (role.role.employer.company.logoUrl && !fallBackImage) {
      return (
        <Image
          className="!w-[120px] !h-[120px]"
          src={role.role.employer.company.logoUrl}
          alt={role.role.employer.company.name}
          width={120}
          height={120}
          objectFit="contain"
          onError={(): void => {
            setFallBackImage(true);
          }}
        />
      );
    }
    return <BriefcaseIcon className="text-gray-400 w-[80px]" />;
  }, [
    fallBackImage,
    role.role.employer.company.logoUrl,
    role.role.employer.company.name,
  ]);

  return (
    <Card
      className={classNames(
        'sm:p-10 py-10 px-6 relative flex flex-col gap-6 h-fit ',
        className
      )}
    >
      {loading && (
        <div className="absolute w-full h-full top-0 left-0 bg-white bg-opacity-50 flex justify-center items-center">
          <LoadingSpinner className="w-12" />
        </div>
      )}
      <div className="flex flex-col gap-6">
        <div className="sm:flex sm:justify-between grid grid-cols-1 gap-4">
          <div className="flex gap-4 w-full lg:w-fit items-center">
            <div
              className={classNames(
                'w-[120px] h-[120px] border-[6px] border-white',
                {
                  'flex justify-center items-center bg-gray-200':
                    !role.role.employer.company.logoUrl || fallBackImage,
                }
              )}
              style={{ boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)' }}
              title={role.role.employer.company.name}
            >
              {roleImage}
            </div>
            <div className="w-full sm:!w-[70%] xl:!w-[449px]">
              <Link
                href={`/${
                  role.status === MarketplaceRoleRecruiterStatus.BOOKMARKED
                    ? 'roles'
                    : 'marketplace'
                }/${role.role.id}`}
                passHref
              >
                <a>
                  <p
                    title={role.role.name}
                    className="text-2xl leading-6 font-medium text-blue-500 cursor-pointer overflow-hidden line-clamp-2 text-ellipsis"
                  >
                    {role.role.name}
                  </p>
                </a>
              </Link>
              <span
                title={subtitle}
                className="text-gray-600 font-normal text-lg line-clamp-2"
              >
                {subtitle}
              </span>
              <div className="flex gap-3">
                {role.role.roleFunction && (
                  <Badge
                    label={role.role.roleFunction.name}
                    className="font-medium text-headraceBlack-800 text-xs max-w-fit h-fit mt-6 py-1 px-3"
                  />
                )}
                {remoteLabel && (
                  <Badge
                    label={remoteLabel}
                    className="font-medium text-headraceBlack-800 text-xs max-w-fit h-fit mt-6 py-1 px-3"
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'flex items-center gap-2 justify-between self-start',
              {
                'sm:w-[243px]':
                  role.status === MarketplaceRoleRecruiterStatus.INVITED,
                'sm:w-[203px]':
                  role.status !== MarketplaceRoleRecruiterStatus.INVITED,
              }
            )}
          >
            <p
              title={format(new Date(role.createdAt), "PP 'at' HH:mm:ss")}
              className="text-gray-400 font-normal text-base line-clamp-2 leading-[19px]"
            >
              Posted{' '}
              {formatDistance(new Date(role.createdAt), new Date(), {
                locale: {
                  code: 'en_US',
                },
                addSuffix: true,
                includeSeconds: true,
              }).replace('about ', '')}
            </p>
            <div
              className={classNames('hidden md:flex', {
                'gap-4': role.status === MarketplaceRoleRecruiterStatus.INVITED,
              })}
            >
              <div
                data-tip="Add to my roles"
                data-for="button-tooltips"
                data-class="MarketplaceRoleCardTooltip"
              >
                {buttons.bookmark}
              </div>
              <div
                data-tip="Not interested"
                data-for="button-tooltips"
                data-class="MarketplaceRoleCardTooltip"
              >
                {buttons.notInterested}
              </div>
              <ReactTooltip
                id="button-tooltips"
                effect="solid"
                offset={{ top: -8 }}
                arrowColor="transparent"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames('flex md:hidden justify-center', {
          'gap-4': role.status === MarketplaceRoleRecruiterStatus.INVITED,
        })}
      >
        {buttons.bookmark}
        {buttons.notInterested}
      </div>
      <TruncateMarkup
        lines={3}
        ellipsis={
          <>
            ...
            <Button
              buttonType="link"
              className="mx-0"
              onClick={async (): Promise<void> => {
                await push(`/roles/${role.role.id}`);
              }}
            >
              More
            </Button>
          </>
        }
      >
        <div>{role.role.description}</div>
      </TruncateMarkup>
      <div
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        }}
        className="p-6 flex flex-col sm:flex-row justify-evenly flex-wrap rounded-lg gap-[20px] border border-gray-200 text-xs"
      >
        <div className="flex gap-2 flex-1 items-start">
          <UserCircleIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
          <p className="font-medium text-sm text-gray-500">
            <span className="leading-[20px]">Visibility</span>
            <br />
            <span className="font-normal leading-[14px] inline-flex gap-[5px] items-center text-sm">
              {role.searchInfo.marketplaceVisibility === 'INVITE'
                ? 'Invite-only'
                : capitalizeString(
                    role.searchInfo.marketplaceVisibility.toLocaleLowerCase()
                  )}
              <InformationCircleIcon
                data-tip={
                  roleTypeTooltip[
                    role.searchInfo
                      .marketplaceVisibility as keyof typeof roleTypeTooltip
                  ] ?? `${role.searchInfo.marketplaceVisibility} role`
                }
                data-for="search-info-tooltip"
                className="w-4 text-gray-500 outline-none"
              />
            </span>
          </p>
        </div>
        <ReactTooltip
          id="search-info-tooltip"
          effect="solid"
          offset={{ top: -8 }}
          arrowColor="transparent"
          html
          className="!opacity-100 !bg-headraceBlack-800 !text-xs !leading-[14px] !font-normal !text-white !text-center"
        />
        <div className="flex gap-2 flex-1 items-start">
          <CashIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
          <p className="font-medium text-sm text-gray-500">
            <span className="leading-[20px]">Salary range</span>
            <br />
            <span className="font-normal leading-[14px]">
              {currencyFormatter().format(
                role.role.expectedFirstYearSalaryMin ?? 0
              )}{' '}
              -{' '}
              {currencyFormatter().format(
                role.role.expectedFirstYearSalaryMax ?? 0
              )}
            </span>
          </p>
        </div>
        <div className="flex gap-2 flex-1 items-start">
          <ChartPieIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
          <p className="font-medium text-sm text-gray-500">
            <span className="leading-[20px]">
              {role.searchInfo.marketplaceVisibility === 'INVITE'
                ? 'Placement fee'
                : 'Reward'}
            </span>
            <br />
            <span className="font-normal leading-[14px]">
              {formatPlacementFee}
            </span>
          </p>
        </div>
        <div className="flex gap-2 flex-1 items-start">
          <UserGroupIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
          <p className="font-medium text-sm text-gray-500">
            <span className="leading-[20px]">Candidates</span>
            <br />
            <span className="font-normal leading-[14px]">
              {numberFormatter().format(role.globalCandidateCount)}
            </span>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default MarketplaceRoleCard;
