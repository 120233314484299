/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { SimpleLayout } from '@headrace/ui';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import PublicLayoutProvider, { usePublicLayout } from './PublicLayoutProvider';

const WrappedLayout: React.FC = (props) => {
  const { children } = props;
  const { asPath } = useRouter();
  const [successful, setSuccessful] = useState(false);
  const { showLoginAndGetAccessButton } = usePublicLayout();

  const navigation = useMemo(
    () => [
      {
        label: 'Home',
        link: 'https://headrace.com',
      },
      {
        label: 'For recruiters',
        link: 'https://headrace.com/for-recruiters',
      },
      {
        label: 'For employers',
        link: 'https://headrace.com/for-employers',
      },
      {
        label: 'Content',
        link: 'https://headrace.com/content',
      },
    ],
    []
  );

  const loginPath = useMemo(() => {
    if (asPath.includes('recruiter'))
      return asPath.replace('recruiters', 'profiles');
    return asPath;
  }, [asPath]);

  // TODO add the ampli handleEvents
  return (
    <SimpleLayout
      navigation={navigation}
      loginPathEmployer={loginPath}
      accessAction={(): void => console.log('test')}
      onCloseGetAccess={(): void => {
        setSuccessful(false);
      }}
      sendAccesEmailState={{
        loading: false,
        successful,
      }}
      handleEvents={{
        onGetAccessClicked: (): void => console.log('test'),
        onLoginClicked: (): void => console.log('test'),
      }}
      showLoginAndGetAccessButton={showLoginAndGetAccessButton}
    >
      {children}
    </SimpleLayout>
  );
};

const PublicLayout: React.FC = (props) => {
  const { children } = props;

  const api = process.env.NEXT_PUBLIC_HEADRACE_API_URI ?? '';

  const httpLink = createHttpLink({ uri: `${api}/graphql/recruiter` });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={apolloClient}>
      <PublicLayoutProvider>
        <WrappedLayout>{children}</WrappedLayout>
      </PublicLayoutProvider>
    </ApolloProvider>
  );
};

export default PublicLayout;
