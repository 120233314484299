import { formatDate } from '@headrace/utils';
import { currencyWithTwoDigitsFormatter } from '@headrace/utils/src/currencyFormatter';
import {
  CalendarIcon,
  CashIcon,
  ChartPieIcon,
  DocumentIcon,
  TagIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';

import Button from '../Button';
import SplitSearchIcon from '../CustomIcons/SplitSearchIcon';

interface Props {
  contractType: string;
  estimatedEarnings: number;
  startDate: Date;
  firstInvoice: string;
  fee: string;
  feeSplit?: number;
  collaboratingRecruiter?: string;
  searchAgreementId: string;
  isCollaborationRecruiter?: boolean;
  isCollaborationEnabled: boolean;
  disableCollaborationButton?: boolean;
  onOpenCollaborateModal?: () => void;
  onCancelCollaboration?: () => void;
  leadPage?: boolean;
}

const SearchTerms: React.FC<Props> = ({
  estimatedEarnings,
  startDate,
  contractType,
  firstInvoice,
  fee,
  feeSplit,
  searchAgreementId,
  collaboratingRecruiter,
  isCollaborationRecruiter,
  isCollaborationEnabled,
  onOpenCollaborateModal,
  onCancelCollaboration,
  disableCollaborationButton = false,
  leadPage = false,
}) => (
  <div
    className={classNames(
      'bg-white shadow rounded-lg divide-y divide-gray-200 text-headraceBlack-700',
      { ' my-6': !leadPage }
    )}
  >
    <div className="p-5">
      <div className="flex flex-col md:flex-row justify-between md:items-center flex-wrap gap-4">
        <p
          className={classNames(
            'font-bold text-lg leading-6 text-headraceBlack-800 whitespace-nowrap md:flex-auto',
            { '!font-medium': leadPage }
          )}
        >
          Search Terms
        </p>
        <div className="flex gap-[13px] md:gap-4 items-center max-h-[38px] flex-auto md:flex-initial">
          {!leadPage &&
          !disableCollaborationButton &&
          !isCollaborationRecruiter &&
          !isCollaborationEnabled ? (
            <Button buttonType="secondary" onClick={onOpenCollaborateModal}>
              Collaborate
            </Button>
          ) : null}

          {!isCollaborationRecruiter && isCollaborationEnabled ? (
            <Button buttonType="secondary" onClick={onCancelCollaboration}>
              Cancel collaboration
            </Button>
          ) : null}

          {!isCollaborationRecruiter && (
            <Button
              onClick={(): void => {
                // eslint-disable-next-line no-restricted-globals
                location.href = `/searches/${searchAgreementId}`;
              }}
            >
              {leadPage ? <>View role</> : <>View agreement</>}
            </Button>
          )}
        </div>
      </div>

      <div className="grid sm:grid-cols-4 grid-cols-1 rounded-lg border p-5 mt-5">
        <div className="flex flex-wrap flex-col col-span-1 items-center justify-center">
          <p className="text-gray-400 text-xs font-ubuntu ">
            Estimated earnings
          </p>
          <p className="text-gray-400 text-xs font-ubuntu ">from this role</p>

          <p className="text-gray-600 text-3xl font-ubuntu my-2.5">
            {currencyWithTwoDigitsFormatter().format(estimatedEarnings)}
          </p>

          <Image
            src="/illustrations/searchTermsMan.svg"
            width={150}
            height={100}
          />
        </div>
        <div className="sm:col-span-3 col-span-1 divide-x">
          <div
            className={classNames('grid  p-5 mt-5 ', {
              'grid-cols-2 sm:grid-cols-3 gap-8': !leadPage,
              'grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8 sm:border-l border-l-0 border-t sm:border-t-0 border-headraceYellow-700':
                leadPage,
            })}
          >
            <div className="col-span-1 flex">
              <DocumentIcon
                width={20}
                height={20}
                className="text-headraceYellow-700"
              />
              <div className="ml-3 -mt-1">
                <p className="text-lg font-medium text-headraceBlack-800">
                  Contract type
                </p>
                <p className="text-gray-400 text-sm">{contractType}</p>
              </div>
            </div>
            <div className="col-span-1 flex">
              <CalendarIcon
                width={20}
                height={20}
                className="text-headraceYellow-700"
              />
              <div className="ml-3 -mt-1">
                <p className="text-lg font-medium text-headraceBlack-800">
                  Search start date
                </p>
                <p className="text-gray-400 text-sm">
                  {formatDate(startDate, 'MMMM d, yyyy')}
                </p>
              </div>
            </div>
            <div className="col-span-1 flex">
              <CashIcon
                width={20}
                height={20}
                className="text-headraceYellow-700"
              />
              <div className="ml-3 -mt-1">
                <p className="text-lg font-medium text-headraceBlack-800">
                  First invoice
                </p>
                <p className="text-gray-400 text-sm">{firstInvoice}</p>
              </div>
            </div>
            <div className="col-span-1 flex">
              <TagIcon
                width={20}
                height={20}
                className="text-headraceYellow-700"
              />
              <div className="ml-3 -mt-1">
                <p className="text-lg font-medium text-headraceBlack-800">
                  Search placement fee
                </p>
                <p className="text-gray-400 text-sm">{fee}</p>
              </div>
            </div>
            {isCollaborationEnabled && (
              <>
                <div className="col-span-1 flex">
                  <ChartPieIcon
                    width={20}
                    height={20}
                    className="text-headraceYellow-700"
                  />
                  <div className="ml-3 -mt-1">
                    <p className="text-lg font-medium text-headraceBlack-800">
                      {isCollaborationRecruiter ? (
                        <>Your placement fee split</>
                      ) : (
                        <>Collaboration placement fee</>
                      )}
                    </p>
                    <p className="text-gray-400 text-sm">{feeSplit}%</p>
                  </div>
                </div>
                <div className="col-span-1 flex">
                  <SplitSearchIcon className="text-headraceYellow-700 h-7 w-7 -mt-1" />
                  <div className="ml-3 -mt-1">
                    <p className="text-lg font-medium text-headraceBlack-800">
                      Collaborating recruiter
                    </p>
                    <p className="text-gray-400 text-sm">
                      {collaboratingRecruiter}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SearchTerms;
