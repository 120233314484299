import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useState } from 'react';

import Button from '../../../Button';
import type { OptionsProps } from '../../../forms';
import { BasicSelect } from '../../../forms';

interface FiHideableSidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  functions: string[];
  levels: string[];
  setSelectedFunctions: (newSelectedFunction: string[]) => void;
  setSelectedLevels: (newSelectedLevels: string[]) => void;
  selectedFunctions: string[];
  selectedLevels: string[];
}

const FiHideableSidebar: React.FC<FiHideableSidebarProps> = (props) => {
  const {
    open,
    setOpen,
    functions,
    levels,
    setSelectedFunctions,
    setSelectedLevels,
    selectedFunctions,
    selectedLevels,
  } = props;

  const [selectedFunctionOptions, setSelectedFunctionOptions] = useState<
    OptionsProps[]
  >(
    selectedFunctions.map((functionName) => ({
      value: functionName,
      label: functionName,
    }))
  );

  const [selectedLevelOptions, setSelectedLevelOptions] = useState<
    OptionsProps[]
  >(
    selectedLevels.map((levelName) => ({ value: levelName, label: levelName }))
  );

  useEffect(() => {
    setSelectedFunctionOptions(
      selectedFunctions.map((functionName) => ({
        value: functionName,
        label: functionName,
      }))
    );

    setSelectedLevelOptions(
      selectedLevels.map((levelName) => ({
        value: levelName,
        label: levelName,
      }))
    );
  }, [selectedFunctions, selectedLevels]);

  const functionsOptions = functions.map((functionName) => ({
    value: functionName,
    label: functionName,
  }));

  const levelsOptions = levels.map((level) => ({
    value: level,
    label: level,
  }));

  const handleCloseClick = (): void => {
    setOpen(false);
  };

  const saveFilters = (): void => {
    setSelectedFunctions(selectedFunctionOptions.map((option) => option.value));
    setSelectedLevels(selectedLevelOptions.map((option) => option.value));
    setOpen(false);
  };

  const resetFilters = (): void => {
    setSelectedFunctionOptions([]);
    setSelectedLevelOptions([]);
    setSelectedFunctions([]);
    setSelectedLevels([]);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 xl:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in duration-300 transform"
          leave="transition ease-in duration-300 transform"
          leaveFrom="-translate-x-full"
          leaveTo="-translate-x-0"
        >
          <div className="relative flex flex-col max-w-3/4  w-3/4 py-6 bg-white left-1/4 overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex flex-col justify-between h-full">
                <div className="px-4 flex flex-col gap-8 mb-4">
                  <div className="flex justify-between items-center">
                    <div className="text-gray-900 text-lg font-medium">
                      Filters
                    </div>
                    <div className="flex items-center gap-2 text-headraceBlack-700 font-light">
                      <button
                        type="button"
                        className="text-base"
                        onClick={resetFilters}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                        onClick={handleCloseClick}
                      >
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 divide-y">
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-medium text-headraceBlack-800">
                        Levels
                      </div>
                      <div className="flex relative rounded-md shadow-sm">
                        <BasicSelect
                          options={levelsOptions}
                          closeMenuOnSelect={false}
                          className="w-full min-h-[38px] sm:text-sm"
                          isMulti
                          isClearable={false}
                          value={selectedLevelOptions}
                          onChange={(options): void => {
                            if (options) {
                              setSelectedLevelOptions(
                                options as OptionsProps[]
                              );
                            } else {
                              setSelectedLevelOptions([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-4">
                      <div className="block text-sm font-medium text-headraceBlack-800">
                        Functions
                      </div>
                      <div className="flex relative rounded-md shadow-sm">
                        <BasicSelect
                          options={functionsOptions}
                          closeMenuOnSelect={false}
                          className="w-full min-h-[38px] sm:text-sm"
                          isMulti
                          isClearable={false}
                          value={selectedFunctionOptions}
                          onChange={(options): void => {
                            if (options) {
                              setSelectedFunctionOptions(
                                options as OptionsProps[]
                              );
                            } else {
                              setSelectedFunctionOptions([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 px-4 items-center">
                  <Button onClick={saveFilters} className="w-full">
                    Done
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Transition.Child>
        <div>
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FiHideableSidebar;
